body {
    font-family: initial !important;
    color: $textcolor;
    font-size: 14px;
    overflow-x: hidden;
    transition: margin 0.3s;

    @include media-breakpoint-down(lg) {
        &.menu_opened {
            margin-left: -315px;
            padding-right: 315px;
        }
    }

    @include media-breakpoint-down(sm) {
        &.menu_opened_two {
            background: #222a2d;
            margin-right: -215px;
            padding-left: 215px;
        }
    }
}

#header {
    height: 115px;
    position: relative;
    font-family: "Nunito Sans", "Noto Sans HK", "Noto Sans SC", "-apple-system", "BlinkMacSystemFont", "Segoe UI", "Roboto", "Helvetica Neue", Arial, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif !important;

    @include media-breakpoint-only(xs) {
        height: 140px;
    }

    #header_wrapper {
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 9999;

        #dp-notification {
            border: unset;
            border-radius: unset;
            margin: 0;
            padding-top: 8px;
            padding-bottom: 8px;
            opacity: 1;

            .clipboard {
                input {
                    border: 1px solid #fff !important;
                    border-radius: 0.5rem !important;
                    background: transparent !important;
                    color: #fff !important;
                    padding: 0;
                    font-size: 15px;
                    cursor: pointer;
                    line-height: 1.5;
                }
            }
        }

        .notificationbar-orange {
            background: rgb(232, 103, 12);
            background: linear-gradient(180deg, rgba(232, 103, 12, 1) 0%, rgba(253, 163, 42, 1) 100%);
        }
    }

    .modal-dialog {
        margin: 140px auto 30px;
    }

    #nav_top {
        position: relative;
        width: 100%;
        background-color: #fff !important;
        color: #333 !important;
        font-size: 13px;
        font-weight: 400;
        z-index: 100;
        border-bottom: 1px solid #ccc;

        >div>div>* {
            display: inline-block;
        }

        a,
        p {
            color: #333;
            //color: #333;
            font-size: 12.6px;
            font-weight: 500;
            margin: 0;
            //line-height: 45px;
            line-height: 40px;
        }

        a.current,
        a:hover {
            color: #0d5cbc;
            //color: #044388;
        }

        .kb {
            &:before {
                content: "\f059";
                font-family: 'Font Awesome 5 Free';
                color: #0d5cbc;
                font-size: 21px;
                position: absolute;
                left: -2px;
            }
        }

        .hotline {
            line-height: 35px !important;

            span {
                padding-left: 30px;
                background-image: url(https://www.dataplugs.com/wp-content/uploads/2024/10/dp-menu-icon-mail.png);
                background-position: center left;
                background-repeat: no-repeat;
                background-size: 25px;
                font-weight: 700;
            }

            a {
                margin: 0 4px;
                color: #0d5cbc !important;
                border-left: 1px solid #c8c8c8;
                padding-left: 7px;
                font-weight: 700;
            }
        }

        .lang {
            margin-left: 25px;
            display: inline-block;
            padding-left: 30px;
            background-image: url(https://www.dataplugs.com/wp-content/uploads/2024/10/dp-menu-icon-translate.png);
            background-position: center left;
            background-repeat: no-repeat;
            background-size: 25px;

            li {
                display: inline;

                &:not(:last-child)::after {
                    content: "|";
                    margin: 0 2px 0 5px;
                    color: #c8c8c8;
                    //color: #333;
                }
            }
        }

        .dropdown-toggle {
            display: block;

            &::after {
                content: "\f0d7";
                font-family: "Font Awesome 5 Free";
                border: 0;
                margin-left: 0;
                vertical-align: initial;
            }
        }

        .dropdown-menu {
            padding: 0;

            @include media-breakpoint-down(xs) {
                margin: 0;
                border-radius: 0;
                right: 0 !important;
                left: auto !important;
                top: 100% !important;
                margin-left: -100px;
                transform: initial !important;
            }

            li:not(:last-child) {
                border-bottom: 1px solid #e5e5e5;
            }

            &:after {
                /*
                content: "";
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 0 10px 10px 10px;
                border-color: transparent transparent #ffffff transparent;
                position: absolute;
                */
                /* right: 120px; */
                /*
                right: 5px;
                top: -10px;
                */
            }
        }

        .alerts {
            .dropdown-menu {
                min-width: 265px;
                margin: 0;

                @include media-breakpoint-down(xs) {
                    min-width: 225px;
                }
            }

            .list-unstyled li {
                padding: 10px 15px;

                &:nth-child(odd) {
                    background-color: #efefef;
                }

                a {
                    line-height: 1.4 !important;
                    margin-bottom: 10px !important;
                    display: block;

                    button {
                        width: 100%;
                        background-color: #357ebd;
                        margin-top: 5px;

                        &:hover {
                            background-color: #0e5095;
                        }
                    }
                }
            }
        }

        .right_col {
            >div {
                margin: 0 10px;
            }

            i {
                margin-right: 3px;
                font-size: 20px;
                vertical-align: middle;
            }

            #Secondary_Navbar-Account {
                .dropdown-toggle {
                    display: block;

                    &::after {
                        content: "\f0d7";
                        font-family: "Font Awesome 5 Free";
                        border: 0;
                        margin-left: 0;
                        vertical-align: initial;
                    }
                }

                .dropdown-menu {
                    width: 245px;
                    margin: 0;

                    a {
                        color: #333;
                        line-height: 40px;
                        display: block;
                        padding: 0 11px;
                        text-align: center;
                        font-size: 13.6px;

                        &:hover {
                            background-color: #fee188;
                        }
                    }
                }
            }
        }

        @include media-breakpoint-only(xs) {
            .row>div {
                text-align: center;
            }

            a,
            p,
            ul {
                float: none !important;
                line-height: 35px !important;
            }
        }

    }

    #nav_middle {
        position: relative;
        width: 100%;
        background-color: #fff;
        font-size: 14px;
        //box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.25);
        z-index: 99;

        .navbar {
            padding: 0;

            .navbar-brand {
                padding: 0;
                margin: 0 auto;
                line-height: 70px;

                img {
                    //filter: drop-shadow(2px 2px 2px #9ebde4);
                    width: 95%;
                }
            }

            @include media-breakpoint-down(lg) {
                .navbar-brand {
                    margin: 0 auto;

                    img {
                        //border-left: 2px solid #ff8200;
                        //padding: 0 15px;
                        margin: 0 5px;
                    }
                }
            }

            .navbar-toggler {
                padding: 0;
                right: 0;
                position: absolute;
                border-radius: 0;
                line-height: 35px;
                height: 35px;

                span {
                    display: block;
                    width: 25px;
                    height: 3px;
                    position: relative;

                    background: #2c2c2c;
                    border-radius: 7px;
                    transition: width 0.2s;
                }

                &.opened span {
                    &:nth-child(2) {
                        width: 23px;
                    }

                    &:nth-child(3) {
                        width: 18px;
                    }
                }

                span:not(:last-child) {
                    margin-bottom: 5px;
                }

                &:hover span {
                    background-color: #f29600;
                }
            }

            #main_menu {
                /* position: inherit; */

                @include media-breakpoint-down(lg) {
                    position: fixed;
                    top: 0;
                    right: -315px;
                    width: 315px;
                    background-color: #fff;
                    transition: left 0.3s;
                    height: 100%;
                    /* padding: 30px; */
                    border-left: 0.5px solid #ccc;

                    &.opened {
                        right: 0;
                        //box-shadow: 2px 3px 25px 30px rgba(0, 0, 0, .35);
                    }

                    #menu-main-menu-new {
                        max-height: calc(100vh - 150px);
                        overflow-x: auto;
                    }
                }

                >.navbar-nav {
                    //position: inherit;

                    @include media-breakpoint-down(lg) {
                        display: block;
                        position: relative !important;
                        height: calc(100% - 130px);
                        overflow-y: scroll;
                    }

                    >li {
                        float: left;
                        padding: 0;
                        position: initial;
                        margin-bottom: 25px;
                        margin-top: 25px;

                        >a {
                            color: #333;
                        }

                        @include media-breakpoint-down(lg) {
                            //padding-top: 10.5px;
                            padding-left: 10px;
                            padding-right: 10px;
                            border-bottom: 0.5px solid #ccc;
                            margin: 0 !important;

                            /* padding-bottom: 5.5px; */
                            >a {
                                color: #2c2c2c;
                                font-size: 16px;
                                font-weight: bold;
                                margin: 10px 0;
                            }
                        }

                        /*
                        &.menu-item-has-children > a::after {
                            content: "\f107";
                            font-family: "Font Awesome 5 Pro";
                            margin-left: 5px;

                        }
                        */

                        @include media-breakpoint-down(lg) {
                            &.menu-item-has-children>a::after {
                                content: "\f107";
                                font-family: "Font Awesome 5 Pro";
                                margin-left: 5px;
                                position: absolute;
                                right: 0;
                                transform: rotate(-90deg);
                                transition: transform 0.3s;
                            }

                            &.menu-item-has-children.on>a::after {
                                transform: rotate(0);
                            }
                        }

                        >a {
                            //padding: 0 30px;
                            border-top: 3.5px solid transparent;
                            border-bottom: 3.5px solid transparent;
                            display: block;
                            position: relative;

                            @include media-breakpoint-up(xl) {
                                line-height: 20px;
                                font-size: 18px;
                                font-weight: 600;
                                padding: 0 30px;
                            }

                            @include media-breakpoint-only(md) {
                                //padding: 0 8px;
                            }

                            @include media-breakpoint-down(lg) {
                                //padding: 0 0 0 30px;
                            }
                        }

                        &:hover>a {
                            @include media-breakpoint-up(xl) {
                                &:not(:last-child)::before {
                                    content: " ";
                                    background-image: url(https://www.dataplugs.com/wp-content/uploads/2024/10/icon-trangle-arrow-shadow.png);
                                    background-repeat: no-repeat;
                                    background-size: 40px;
                                    background-position: center bottom;
                                    width: 100%;
                                    left: 0;
                                    right: 0;
                                    height: 100%;
                                    bottom: -25px;
                                    position: absolute;
                                    z-index: 99999999999999;
                                }
                            }
                        }

                        /* &.current-menu-ancestor>a { */
                        &:hover>a {
                            color: #f29600;
                            cursor: pointer;
                        }

                        .current_page_item {
                            color: #f29600;
                            cursor: pointer;
                        }

                        &.highlight>a {
                            /* &.highlight:hover > a { */
                            background-color: #fe932b;
                            border-radius: 7px;
                            border: none;
                            /* height: 100%; */
                            height: unset;
                            color: #fff;
                            margin-left: 15px;
                            line-height: 33px;
                            margin-top: 21.5px;

                            @include media-breakpoint-only(md) {
                                margin-left: 5px;
                            }
                        }

                        &.highlight:hover>a {
                            color: #333333;
                        }
                    }
                }

                .current-menu-ancestor+.current-menu-ancestor>a {
                    color: #333 !important;
                    background-color: unset !important;
                }

                .current-menu-ancestor+.current-menu-ancestor:hover>a {
                    //background-color: #2d2d2d !important;
                    //color: #fff !important;
                }

                >ul>li>.sub-menu {

                    @include media-breakpoint-up(xl) {

                        .onecol {
                            padding: 0 !important;

                            >a {
                                border-left: 3.5px solid #fe932b;
                                padding: 2.5px 12px;
                                margin: 20px;
                            }

                            &.title {

                                >a {
                                    margin-top: 30px;
                                    margin-bottom: 15px;
                                    margin-left: 20px;
                                    //width: 100%;
                                    display: block;
                                    font-weight: 600;
                                    font-size: 18px;
                                    //margin-bottom: 12px;
                                    pointer-events: none;
                                    letter-spacing: .25px;
                                }

                                .sub-menu {
                                    border-top: 1px solid #0e377d;
                                    padding-top: 12px;
                                    padding-bottom: 12px;
                                    display: grid;
                                    grid-template-columns: 1fr 1fr;

                                    li {
                                        padding: 7.5px 20px;

                                        a {
                                            &::before {
                                                margin-right: 7px;
                                                font: normal normal normal 12px/1 "Font Awesome 5 Pro";
                                                content: "";
                                            }

                                            .tagline {
                                                //display: none !important;
                                                margin-left: 16px;
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        .dscol {
                            >a {
                                border-left: 3.5px solid #fe932b;
                                padding: 2.5px 12px;
                            }

                            //normal normal normal 14px/1 "Font Awesome 5 Pro"

                            &.region {
                                border-right: 1px solid #0e377d;

                                >a {
                                    background-image: url(https://www.dataplugs.com/wp-content/uploads/2024/02/menu-icon-pin-w.png);
                                    background-repeat: no-repeat;
                                    background-size: 25px;
                                    background-position: right;
                                    width: 100%;
                                    display: block;
                                    font-weight: 600;
                                    font-size: 18px;
                                    margin-bottom: 12px;
                                    pointer-events: none;
                                    letter-spacing: .25;
                                }

                                .sub-menu {
                                    display: block;

                                    .location {
                                        transition: 0.5s;
                                        margin: unset !important;

                                        >a {
                                            padding: 6px 0;
                                            //border-bottom: 1px solid #003365;
                                            width: 100%;
                                            display: block;
                                            font-size: 18px;
                                            cursor: pointer;

                                            &::before {
                                                content: "";
                                                font: normal normal normal 9.5px/1 "Font Awesome 5 Pro";
                                                margin-right: 5px;
                                                float: inline-start;
                                                padding: 7px 0;
                                            }

                                            &::after {
                                                content: "";
                                                font: normal normal normal 9.5px/1 "Font Awesome 5 Pro";
                                                margin-right: 5px;
                                                float: inline-end;
                                                padding: 7px 0;
                                            }
                                        }

                                        .sub-menu {
                                            display: none;
                                        }

                                        &.active {
                                            transition: 0.5s;

                                            >a {
                                                padding: 6px 0;
                                                border-bottom: 1px solid #003365;
                                                width: 100%;
                                                display: block;
                                                font-size: 18px;
                                                margin-bottom: 8px;

                                                &::before {
                                                    content: "";
                                                    font: normal normal normal 9.5px/1 "Font Awesome 5 Pro";
                                                    margin-right: 5px;
                                                    float: inline-start;
                                                    padding: 7px 0;
                                                }

                                                &::after {
                                                    content: "";
                                                    font: normal normal normal 9.5px/1 "Font Awesome 5 Pro";
                                                    margin-right: 5px;
                                                    float: inline-end;
                                                    padding: 7px 0;
                                                }
                                            }

                                            .sub-menu {
                                                display: block !important;
                                            }
                                        }

                                    }

                                    li {
                                        padding: 7.5px 0;
                                        margin-left: 15px;

                                        a {
                                            .tagline {
                                                //display: none !important;
                                                //margin-left: 15px;
                                            }
                                        }
                                    }
                                }
                            }

                            &.options {
                                border-right: 1px solid #0e377d;
                            }

                            &.options,
                            &.model {
                                >a {
                                    background-repeat: no-repeat;
                                    background-size: 25px;
                                    background-position: right;
                                    width: 100%;
                                    display: block;
                                    font-weight: 600;
                                    font-size: 18px;
                                    margin-bottom: 12px;
                                    pointer-events: none;
                                    letter-spacing: .25;
                                }

                                .sub-menu {
                                    display: block;

                                    li {
                                        padding: 7.5px 0;

                                        a {
                                            &::before {
                                                margin-right: 7px;
                                                font: normal normal normal 12px/1 "Font Awesome 5 Pro";
                                                content: "";
                                            }

                                            .tagline {
                                                //display: none !important;
                                                margin-left: 15px;
                                            }
                                        }
                                    }
                                }
                            }

                            &.options {
                                >a {
                                    background-image: url(https://www.dataplugs.com/wp-content/uploads/2024/02/menu-icon-server-w.png);
                                }
                            }

                            &.model {
                                >a {
                                    background-image: url(https://www.dataplugs.com/wp-content/uploads/2024/02/menu-icon-cpu-w2.png);
                                }
                            }

                            &.current-menu-ancestor {
                                &.region {
                                    >a {
                                        background-image: url(https://www.dataplugs.com/wp-content/uploads/2024/02/menu-icon-pin-o.png);
                                    }
                                }

                                &.options {
                                    >a {
                                        background-image: url(https://www.dataplugs.com/wp-content/uploads/2024/02/menu-icon-server-o.png);
                                    }
                                }

                                &.model {
                                    >a {
                                        background-image: url(https://www.dataplugs.com/wp-content/uploads/2024/02/menu-icon-cpu-o2.png);
                                    }
                                }
                            }

                        }
                    }

                    @include media-breakpoint-up(xl) {
                        display: none;
                        position: absolute;
                        width: 100%;
                        height: 70vh;
                        margin: 0 auto;
                        min-height: 700px;
                        background-color: #fff;
                        left: 0;
                        right: 0;
                        border-radius: 12px;
                        box-shadow: 2px 2px 30px 1px rgba(0, 0, 0, .17);
                        margin-top: 20px;

                        >li {
                            //padding: 8px;
                            //padding: 6px 20px;
                            padding: 0 !important;
                            height: 100%;

                            .boldtext {
                                >a {
                                    font-weight: 500;
                                    margin-bottom: 10px;
                                    display: block;
                                }
                            }


                            @include media-breakpoint-down(lg) {
                                margin-bottom: 15px;
                            }
                        }

                        a {
                            color: #2c2c2c;
                            font-size: 16px;

                            &:not([href="#"]):hover {
                                color: $linkhovercolor;
                            }

                            &[href="#"] {
                                cursor: default;
                            }
                        }

                        .tagline {
                            color: #bfbfbf;
                            font-size: 11px;
                        }

                        .wrapper {
                            a {
                                display: none;
                            }
                        }
                    }

                    .current_page_item>a {
                        color: $linkhovercolor;
                    }

                    .newproduct_ENG {
                        span::after {
                            content: "NEW";
                            color: #fff;
                            padding: 3px 8.5px;
                            border-radius: 1px;
                            background-color: #fd913a;
                            margin-left: 5px;
                            font-size: 10.5px;
                            font-weight: 500;
                        }
                    }

                    .newproduct_CHIN {
                        span::after {
                            content: "最新";
                            color: #fff;
                            padding: 2px 8.5px;
                            border-radius: 1px;
                            background-color: #fd913a;
                            margin-left: 5px;
                            font-size: 13.5px;
                            font-weight: 500;
                        }
                    }

                    @include media-breakpoint-down(lg) {
                        //display: none !important;
                        //padding: 0 15px;

                        .wrapper {
                            display: none;
                        }

                        .tagline {
                            color: #bfbfbf;
                            font-size: 11px;
                        }

                        .onecol {
                            >a {
                                border-left: 3.5px solid #fe932b;
                                padding: 2.5px 12px;
                            }

                            &.title {
                                >a {
                                    font-size: 15px;
                                    font-weight: bold;
                                    letter-spacing: 0.25px;
                                }
                            }

                            .sub-menu {
                                display: inline !important;
                            }
                        }

                        .dscol {
                            >a {
                                border-left: 3.5px solid #fe932b;
                                padding: 2.5px 12px;
                            }

                            &.region {
                                >a {
                                    font-size: 15px;
                                    font-weight: bold;
                                    letter-spacing: 0.25px;
                                }

                                .location {
                                    width: 100%;

                                    >a {
                                        font-size: 15px;
                                        border-bottom: 1px solid #fff;
                                        padding: 5px 0px;
                                    }
                                }
                            }

                            &.options {
                                >a {
                                    font-size: 15px;
                                    font-weight: bold;
                                    letter-spacing: 0.25px;
                                }
                            }

                            &.model {
                                >a {
                                    font-size: 15px;
                                    font-weight: bold;
                                    letter-spacing: 0.25px;
                                }
                            }
                        }


                        a {
                            display: block;
                            margin: 6.5px 0;
                            width: 100%;

                            &:hover {
                                color: #f29600;
                            }
                        }
                    }
                }

                li:hover .sub-menu {
                    display: inline-block;
                    z-index: 9999;
                    animation: fade 0.15s ease-in;
                }

                li.on .sub-menu {
                    @include media-breakpoint-down(lg) {
                        //display: flex !important;
                        //flex-wrap: wrap;
                        //z-index: 9999;
                    }
                }

                /*.submenu_type_1 .sub-menu li {
                    text-align: center;
                    padding: 0;

                    a {
                        font-size: 16px;
                        color: #23a9f4;
                        line-height: 1.7;
                        font-weight: 600;

                        .icon {
                            color: #fff;
                            margin: 5px 0;
                            height: 50px;
                        }

                        .tagline {
                            color: #fff;
                            font-size: 11px;
                            font-weight: 400;
                        }
                    }

                    &:hover a,
                    &.current_page_item a {
                        color: $linkhovercolor;
                    }
                }

                .submenu_type_2 .sub-menu .col-sm-6 {
                    padding: 0 30px;

                    > a {
                        font-size: 15px;
                        border-left: 3px solid #23a9f4;
                        padding-left: 5px;
                        margin-left: -5px;
                        line-height: 1.2;
                        display: block;
                        margin-bottom: 10px;
                    }

                    .sub-menu {
                        padding: 0;
                        flex-direction: column;

                        li {
                            font-size: 13px;
                            line-height: 2;
                        }
                    }
                }

                .submenu_type_3 .sub-menu .col-sm-6 {
                    padding: 0 30px;

                    > a {
                        font-size: 16px;
                        border-left: 3px solid #23a9f4;
                        padding-left: 5px;
                        margin-left: -5px;
                        line-height: 1.3;
                        display: block;
                    }
                }*/

                .client_info {
                    display: none !important;
                    font-size: 14px;
                    display: grid;
                    grid-template-columns: 32.5% 22.5% 45%;
                    align-items: center;
                    justify-items: stretch;
                    border-bottom: 1px solid #ccc;
                    text-transform: uppercase;

                    a {
                        color: #2c2c2c;
                        padding: 10px;

                        &:hover {
                            color: #f29600;
                        }

                        &.login {
                            padding-left: 30px;
                            border-left: 1px solid #ccc;
                            text-align: center;
                            position: relative;

                            &::before {
                                content: "\f007";
                                font-family: "Font Awesome 5 Pro";
                                position: absolute;
                                left: 15px;
                                top: 15%;
                                color: #0050c1;
                                font-size: 20px;

                            }
                        }

                        &.cur {
                            padding-left: 30px;
                            background-image: url(https://www.dataplugs.com/wp-content/uploads/2024/10/dp-menu-icon-price.png);
                            background-position: center left 5px;
                            background-repeat: no-repeat;
                            background-size: 22.5px;
                            border-left: 1px solid #ccc;
                            text-align: center;
                        }
                    }

                    .lang_mob {
                        display: grid;
                        height: 100%;
                        grid-template-columns: 1fr 1fr;
                        border-left: 1px solid #ccc;
                        align-items: center;
                        align-content: center;

                        a {
                            color: #2c2c2c;
                            text-transform: uppercase;
                            padding-left: 30px;
                            background-image: url(https://www.dataplugs.com/wp-content/uploads/2024/10/dp-menu-icon-translate.png);
                            background-position: center left 5px;
                            background-repeat: no-repeat;
                            background-size: 22.5px;

                            &:last-child {
                                border-left: 1.5px solid #ccc;
                            }
                        }
                    }
                }



                .extra_link {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    padding: 15px;

                    .trial {
                        background-color: #0050c1;
                        border-radius: 7px;
                        background-image: url(https://www.dataplugs.com/wp-content/uploads/2024/10/dp-menu-2024-fire.png);
                        background-position: center left 10px;
                        background-repeat: no-repeat;
                        background-size: 45px;
                        text-align: center;
                        line-height: 45px;
                        width: 100%;
                        display: block;
                        border-color: #0050c1;
                        font-size: 16px;
                        color: #fff !important;

                        a {
                            color: #fff !important;
                            font-weight: 500;
                        }
                    }

                    .support {
                        background-color: #f0f0f0;
                        border-radius: 7px;
                        background-image: url(https://www.dataplugs.com/wp-content/uploads/2024/10/dp-menu-icon-mail.png);
                        background-position: center left 15px;
                        background-repeat: no-repeat;
                        background-size: 35px;
                        text-align: center;
                        line-height: 45px;
                        width: 100%;
                        display: block;
                        border-color: #f0f0f0;
                        font-size: 16px;
                        margin-top: 10px;
                        color: #2c2c2c;
                    }


                    .login {
                        //margin-top: 15px;
                        color: #2c2c2c !important;
                        border: 1px solid #5fcbfc;
                        background-color: #5fcbfc;
                        border-radius: 7px;
                    }

                    .side_menu_lang {
                        display: grid;
                        grid-template-columns: 1fr 1fr;
                        gap: 5px;
                        padding: 5px 0;
                        border-top: 1px solid #ccc;

                        a {
                            color: #2c2c2c !important;
                            border: 1px solid #5fcbfc;
                            background-color: #5fcbfc;
                            border-radius: 7px;
                            padding: 5px;
                            text-transform: uppercase;

                            &:hover {
                                color: #fff !important;
                                border: 1px solid #0050c1;
                                background-color: #0050c1;
                            }
                        }
                    }

                    /*
                    .side_menu_lang a:first-child {
                        border-top-left-radius: 3px;
                        border-bottom-left-radius: 3px;
                        border-right: 0;
                    }

                    .side_menu_lang a:last-child {
                        border-top-right-radius: 3px;
                        border-bottom-right-radius: 3px;
                        border-left: 0;
                    }
                    */
                }
            }
        }

        @include media-breakpoint-down(lg) {
            box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, .7);
        }
    }
}


/* New Menu */
@include media-breakpoint-up(xl) {
    #main_menu {
        .desktop_link {
            a {
                padding: 10px 15px;
                border: 1px solid #ccc;
                font-size: 16px;
                border-radius: 7px;

                &.login {
                    background-color: transparent;
                    color: transparent;
                    border: none;
                    pointer-events: none;

                    &:hover {
                        background-color: #ff8200;
                        color: #fff;
                        border-color: #ff8200;
                    }
                }

                &.order {
                    background-color: #1e1e1e;
                    color: #fff;
                    border-color: #1e1e1e;

                    &:hover {
                        background-color: #ff8200;
                        color: #fff;
                        border-color: #ff8200;
                    }
                }
            }
        }

        .about {

            .depth_1 {
                font-size: 18px !important;
                font-weight: 500;
                border-bottom: 1px solid #ccc;
                padding: 12px 0;
                display: none;

                span {
                    border-bottom: 2px solid #ea5e06;
                    padding: 12px 24px;
                    margin-bottom: 2px;
                    font-size: 20px;
                    font-weight: 600;
                }

                &:after {
                    font-size: 18px;
                    font-weight: 500;
                    border-bottom: 1px solid #ccc;
                    padding: 8px 0;
                    margin-bottom: 10px;
                    display: block;
                }
            }

            .depth_2 {
                font-size: 16.5px;
                //border-left: 3px solid #ea5e06;
                //padding: 6.5px 10px;
                margin-bottom: 15px;
                display: block;
                font-size: 18px !important;
                margin-top: 7px;
                line-height: 50px;
                display: inline-flex;
                ;
                gap: 0;
                margin-top: 15px;
                font-size: 18px !important;

                &::before {
                    background-image: url(https://www.dataplugs.com/wp-content/uploads/2025/02/dp-menu-2025-icon-dc.png);
                    background-position: left center;
                    background-repeat: no-repeat;
                    background-size: contain;
                    content: ' ';
                    width: 50px;
                    height: 50px;
                    display: inline-block;
                }
            }

            .about {
                .depth_2 {
                    &::before {
                        background-image: url(https://www.dataplugs.com/wp-content/uploads/2025/02/dp-menu-2025-icon-about.png) !important;
                    }
                }
            }

            .sub-menu {
                height: 550px !important;
                min-height: 500px !important;
                /*
                width: 35% !important;
                height: 550px !important;
                min-height: 500px !important;
                left: unset !important;
                */
            }

            .sub-1-menu {
                height: 100%;
                display: grid;
                grid-template-columns: 1fr 1fr 1fr;

                .list {
                    padding: 15px 20px;
                    border-right: 1px solid #ccc;
                }

                .dccenter {
                    margin-bottom: 15px;

                    >a {
                        margin-bottom: 7.5px;
                        display: block;
                        color: #ff8200 !important;
                    }

                    .menucss {
                        li {
                            padding: 4.5px 18px;
                            border-left: 1px solid #ccc;
                            position: relative;

                            &::before {
                                content: ' ';
                                border-bottom: 1px solid #ccc;
                                width: 15px;
                                display: block;
                                position: absolute;
                                left: 0;
                                top: 15px;
                            }

                            &:last-child {
                                border-left: none;

                                &::after {
                                    position: absolute;
                                    border-left: 1px solid #ccc;
                                    content: '';
                                    left: 0;
                                    height: 48%;
                                    top: 0;
                                }
                            }
                        }
                    }
                }

                .list.about {
                    .menucss {
                        li {
                            padding: 4.5px 18px;
                            border-left: 1px solid #ccc;
                            position: relative;

                            &::before {
                                content: ' ';
                                border-bottom: 1px solid #ccc;
                                width: 15px;
                                display: block;
                                position: absolute;
                                left: 0;
                                top: 15px;
                            }

                            &:last-child {
                                border-left: none;

                                &::after {
                                    position: absolute;
                                    border-left: 1px solid #ccc;
                                    content: '';
                                    left: 0;
                                    height: 48%;
                                    top: 0;
                                }
                            }
                        }
                    }
                }
            }
        }

        .solutions {
            .depth_1 {
                font-size: 18px !important;
                font-weight: 500;
                border-bottom: 1px solid #ccc;
                padding: 12px 0;
                display: none;

                span {
                    border-bottom: 2px solid #ea5e06;
                    padding: 12px 24px;
                    margin-bottom: 2px;
                    font-size: 20px;
                    font-weight: 600;
                }

                &:after {
                    font-size: 18px;
                    font-weight: 500;
                    border-bottom: 1px solid #ccc;
                    padding: 8px 0;
                    margin-bottom: 10px;
                    display: block;
                }
            }

            .sub-menu {
                height: 550px !important;
                min-height: 500px !important;

                li {
                    >ul.sub-1-menu {
                        display: grid;
                        grid-template-columns: 1fr 1fr 1fr;
                        //height: calc(100% - 55px);
                        height: 100%;

                        >li {
                            //padding: 0 20px;
                            padding: 15px 20px;
                            border-right: 1px solid #ccc;
                        }


                        .usecase {
                            >a {
                                line-height: 50px;
                                display: inline-flex;
                                gap: 0px;
                                margin-top: 15px;
                                font-size: 18px !important;

                                &::before {
                                    background-image: url(https://www.dataplugs.com/wp-content/uploads/2024/10/dp-menu-2024-icon6.png);
                                    background-position: left center;
                                    background-repeat: no-repeat;
                                    background-size: contain;
                                    content: ' ';
                                    width: 50px;
                                    height: 50px;
                                    display: inline-block;
                                }
                            }
                        }

                        .ind {
                            >a {
                                line-height: 50px;
                                display: inline-flex;
                                gap: 0px;
                                margin-top: 15px;
                                font-size: 18px !important;

                                &::before {
                                    background-image: url(https://www.dataplugs.com/wp-content/uploads/2024/11/dp-menu-2024-icon9.png);
                                    background-position: left center;
                                    background-repeat: no-repeat;
                                    background-size: contain;
                                    content: ' ';
                                    width: 50px;
                                    height: 50px;
                                    display: inline-block;
                                }
                            }
                        }

                        .sec {
                            >a {
                                line-height: 50px;
                                display: inline-flex;
                                gap: 0px;
                                margin-top: 15px;
                                font-size: 18px !important;

                                &::before {
                                    background-image: url(https://www.dataplugs.com/wp-content/uploads/2024/10/dp-menu-2024-icon8.png);
                                    background-position: left center;
                                    background-repeat: no-repeat;
                                    background-size: contain;
                                    content: ' ';
                                    width: 50px;
                                    height: 50px;
                                    display: inline-block;
                                }
                            }
                        }

                        .tech {
                            >a {
                                line-height: 50px;
                                display: inline-flex;
                                gap: 0px;
                                margin-top: 15px;
                                font-size: 18px !important;

                                &::before {
                                    background-image: url(https://www.dataplugs.com/wp-content/uploads/2024/11/dp-menu-2024-icon10.png);
                                    background-position: left center;
                                    background-repeat: no-repeat;
                                    background-size: contain;
                                    content: ' ';
                                    width: 50px;
                                    height: 50px;
                                    display: inline-block;
                                }
                            }
                        }

                        .menucss {
                            li {

                                padding: 5px 18px;
                                border-left: 1px solid #ccc;
                                position: relative;

                                &::before {
                                    content: ' ';
                                    border-bottom: 1px solid #ccc;
                                    width: 15px;
                                    display: block;
                                    position: absolute;
                                    left: 0;
                                    top: 16px;
                                }

                                &:last-child {
                                    border-left: none;

                                    &::after {
                                        position: absolute;
                                        border-left: 1px solid #ccc;
                                        content: '';
                                        left: 0;
                                        height: 48%;
                                        top: 0;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .prod_service {

            .none {
                >a {
                    font-size: 0 !important;
                    line-height: 0 !important;
                    color: transparent !important;
                    display: none !important;
                    margin-bottom: 0px !important;
                }
            }

            .current_page_item {
                >.menu-item.depth_2 {
                    background-color: #0050c1 !important;
                    color: #fff !important;
                }
            }

            .depth_1 {
                cursor: default !important;

                span {
                    color: #232323 !important;
                }
            }

            .current-menu-ancestor {
                >.menu-item.depth_2 {
                    background-color: #0050c1 !important;
                    color: #fff !important;
                }
            }

            .sub-1-menu {
                padding: 20px;
                //height: calc(100% - 55px);
                height: 100%;
                padding-top: 30px;
            }

            .sub-2-menu {
                position: absolute;
                left: 25%;
                //top: 55px;
                top: 0 !important;
                width: 75%;
                padding: 0 0 0 15px;
                display: grid;
                grid-template-columns: 1fr 1fr 1fr;
                //height: calc(100% - 55px);
                height: 100%;

                >li {
                    padding: 20px;
                    border-left: 1px solid #ccc;
                }

                &.opened {
                    display: grid;
                }
            }

            .new {
                >a {
                    position: relative;

                    &::after {
                        content: 'NEW';
                        padding: 0 5px;
                        font-size: 65%;
                        font-weight: 800;
                        color: #fff;
                        border: 1px solid #007fff;
                        background-color: #007fff;
                        border-radius: 8px 0 8px 0;
                        margin-left: 5px;
                        letter-spacing: .5px;
                        position: absolute;
                        bottom: 5px;
                        line-height: 16px;
                        height: 16px;
                        min-width: 45px;
                        width: 45px;
                        text-align: center;
                    }
                }
            }

            .new-tc {
                >a {
                    position: relative;

                    &::after {
                        content: '最新';
                        padding: 0 5px;
                        font-size: 65%;
                        font-weight: 600;
                        color: #fff;
                        border: 1px solid #007fff;
                        background-color: #007fff;
                        border-radius: 8px 0 8px 0;
                        margin-left: 5px;
                        letter-spacing: .5px;
                        position: absolute;
                        bottom: 5px;
                        line-height: 16px;
                        height: 16px;
                        min-width: 45px;
                        width: 45px;
                        text-align: center;
                    }
                }
            }

            .new-sc {
                >a {
                    position: relative;

                    &::after {
                        content: '最新';
                        padding: 0 5px;
                        font-size: 65%;
                        font-weight: 600;
                        color: #fff;
                        border: 1px solid #007fff;
                        background-color: #007fff;
                        border-radius: 8px 0 8px 0;
                        margin-left: 5px;
                        letter-spacing: .5px;
                        position: absolute;
                        bottom: 5px;
                        line-height: 16px;
                        height: 16px;
                        min-width: 45px;
                        width: 45px;
                        text-align: center;
                    }
                }
            }

            .hot {
                >a {
                    position: relative;

                    &::after {
                        content: 'HOT';
                        padding: 0 5px;
                        font-size: 65%;
                        font-weight: 800;
                        color: #fff;
                        border: 1px solid #ea5e06;
                        background-color: #ea5e06;
                        border-radius: 8px 0 8px 0;
                        margin-left: 5px;
                        letter-spacing: .5px;
                        position: absolute;
                        bottom: 5px;
                        line-height: 16px;
                        height: 16px;
                        min-width: 50px;
                        width: 50px;
                        text-align: center;
                    }
                }
            }

            .hot-tc {
                >a {
                    position: relative;

                    &::after {
                        content: '熱賣';
                        padding: 0 5px;
                        font-size: 65%;
                        font-weight: 600;
                        color: #fff;
                        border: 1px solid #ea5e06;
                        background-color: #ea5e06;
                        border-radius: 8px 0 8px 0;
                        margin-left: 5px;
                        letter-spacing: .5px;
                        position: absolute;
                        bottom: 5px;
                        line-height: 16px;
                        height: 16px;
                        min-width: 45px;
                        width: 45px;
                        text-align: center;
                    }
                }
            }

            .hot-sc {
                >a {
                    position: relative;

                    &::after {
                        content: '热销';
                        padding: 0 5px;
                        font-size: 65%;
                        font-weight: 600;
                        color: #fff;
                        border: 1px solid #ea5e06;
                        background-color: #ea5e06;
                        border-radius: 8px 0 8px 0;
                        margin-left: 5px;
                        letter-spacing: .5px;
                        position: absolute;
                        bottom: 5px;
                        line-height: 16px;
                        height: 16px;
                        min-width: 45px;
                        width: 45px;
                        text-align: center;
                    }
                }
            }

            .sales {
                >a {
                    position: relative;

                    &::after {
                        content: 'SALE';
                        padding: 0 5px;
                        font-size: 65%;
                        font-weight: 800;
                        color: #fff;
                        border: 1px solid #ff8200;
                        background-color: #ff8200;
                        border-radius: 8px 0 8px 0;
                        margin-left: 5px;
                        letter-spacing: .5px;
                        position: absolute;
                        bottom: 5px;
                        line-height: 16px;
                        height: 16px;
                        min-width: 50px;
                        width: 50px;
                        text-align: center;
                    }
                }
            }

            .sales-tc {
                >a {
                    position: relative;

                    &::after {
                        content: '優惠';
                        padding: 0 5px;
                        font-size: 65%;
                        font-weight: 600;
                        color: #fff;
                        border: 1px solid #ff8200;
                        background-color: #ff8200;
                        border-radius: 8px 0 8px 0;
                        margin-left: 5px;
                        letter-spacing: .5px;
                        position: absolute;
                        bottom: 5px;
                        line-height: 16px;
                        height: 16px;
                        min-width: 45px;
                        width: 45px;
                        text-align: center;
                    }
                }
            }

            .sales-sc {
                >a {
                    position: relative;

                    &::after {
                        content: '优惠';
                        padding: 0 5px;
                        font-size: 65%;
                        font-weight: 600;
                        color: #fff;
                        border: 1px solid #ff8200;
                        background-color: #ff8200;
                        border-radius: 8px 0 8px 0;
                        margin-left: 5px;
                        letter-spacing: .5px;
                        position: absolute;
                        bottom: 5px;
                        line-height: 16px;
                        height: 16px;
                        min-width: 45px;
                        width: 45px;
                        text-align: center;
                    }
                }
            }

            .comingsoon {
                >a {
                    position: relative;

                    &::after {
                        content: 'Coming Soon';
                        padding: 0 5px;
                        font-size: 62.5%;
                        font-weight: 800;
                        color: #fff;
                        background-color: #1e1e1e;
                        border: 1px solid #1e1e1e;
                        border-radius: 8px 0 8px 0;
                        margin-left: 5px;
                        position: absolute;
                        bottom: 5px;
                        line-height: 16px;
                        height: 16px;
                        min-width: 90px;
                        width: 90px;
                        text-transform: uppercase;
                        text-align: center;
                    }
                }
            }

            .comingsoon-sc {
                >a {
                    position: relative;

                    &::after {
                        content: '即将推出';
                        padding: 0 5px;
                        font-size: 65%;
                        font-weight: 600;
                        color: #fff;
                        background-color: #1e1e1e;
                        border: 1px solid #1e1e1e;
                        border-radius: 8px 0 8px 0;
                        margin-left: 5px;
                        letter-spacing: .5px;
                        position: absolute;
                        bottom: 5px;
                        line-height: 16px;
                        height: 16px;
                        min-width: 57px;
                        width: 57px;
                        text-align: center;
                    }
                }
            }

            .comingsoon-tc {
                >a {
                    position: relative;

                    &::after {
                        content: '即將推出';
                        padding: 0 5px;
                        font-size: 65%;
                        font-weight: 600;
                        color: #fff;
                        background-color: #1e1e1e;
                        border: 1px solid #1e1e1e;
                        border-radius: 8px 0 8px 0;
                        margin-left: 5px;
                        letter-spacing: .5px;
                        position: absolute;
                        bottom: 5px;
                        line-height: 16px;
                        height: 16px;
                        min-width: 57px;
                        width: 57px;
                        text-align: center;
                    }
                }
            }

            .seller {
                >a {
                    //position: relative;

                    &::after {
                        content: 'Best Seller';
                        padding: 0 5px;
                        font-size: 60%;
                        font-weight: 700;
                        color: #fff;
                        border: 1px solid #ea5e06;
                        background-color: #ea5e06;
                        border-radius: 8px 0 8px 0;
                        margin-left: 5px;
                        letter-spacing: .5px;
                        position: absolute;
                        bottom: 10px;
                        line-height: 16px;
                        height: 16px;
                        width: fit-content;
                        text-align: center;
                        text-transform: uppercase;
                        z-index: 999999;
                    }
                }
            }

            .seller-sc {
                >a {
                    position: relative;

                    &::after {
                        content: '热销产品';
                        padding: 0 5px;
                        font-size: 65%;
                        font-weight: 600;
                        color: #fff;
                        background-color: #ea5e06;
                        border: 1px solid #ea5e06;
                        border-radius: 8px 0 8px 0;
                        margin-left: 5px;
                        letter-spacing: .5px;
                        position: absolute;
                        bottom: 5px;
                        line-height: 16px;
                        height: 16px;
                        min-width: 57px;
                        width: 57px;
                        text-align: center;
                        z-index: 999999;
                    }
                }
            }

            .seller-tc {
                >a {
                    position: relative;

                    &::after {
                        content: '熱銷產品';
                        padding: 0 5px;
                        font-size: 65%;
                        font-weight: 600;
                        color: #fff;
                        background-color: #ea5e06;
                        border: 1px solid #ea5e06;
                        border-radius: 8px 0 8px 0;
                        margin-left: 5px;
                        letter-spacing: .5px;
                        position: absolute;
                        bottom: 5px;
                        line-height: 16px;
                        height: 16px;
                        min-width: 57px;
                        width: 57px;
                        text-align: center;
                        z-index: 999999;
                    }
                }
            }


            .newarrival {
                >a {
                    //position: relative;

                    &::after {
                        content: 'New Arrivals';
                        padding: 0 5px;
                        font-size: 60%;
                        font-weight: 700;
                        color: #fff;
                        border: 1px solid #ff001a;
                        background-color: #ff001a;
                        border-radius: 8px 0 8px 0;
                        margin-left: 5px;
                        letter-spacing: .5px;
                        position: absolute;
                        bottom: 10px;
                        line-height: 16px;
                        height: 16px;
                        width: fit-content;
                        text-align: center;
                        text-transform: uppercase;
                        z-index: 999999;
                    }
                }
            }

            .newarrival-sc {
                >a {
                    position: relative;

                    &::after {
                        content: '最新上市';
                        padding: 0 5px;
                        font-size: 65%;
                        font-weight: 600;
                        color: #fff;
                        background-color: #ff001a;
                        border: 1px solid #ff001a;
                        border-radius: 8px 0 8px 0;
                        margin-left: 5px;
                        letter-spacing: .5px;
                        position: absolute;
                        bottom: 5px;
                        line-height: 16px;
                        height: 16px;
                        min-width: 57px;
                        width: 57px;
                        text-align: center;
                        z-index: 999999;
                    }
                }
            }

            .newarrival-tc {
                >a {
                    position: relative;

                    &::after {
                        content: '最新上市';
                        padding: 0 5px;
                        font-size: 65%;
                        font-weight: 600;
                        color: #fff;
                        background-color: #ff001a;
                        border: 1px solid #ff001a;
                        border-radius: 8px 0 8px 0;
                        margin-left: 5px;
                        letter-spacing: .5px;
                        position: absolute;
                        bottom: 5px;
                        line-height: 16px;
                        height: 16px;
                        min-width: 57px;
                        width: 57px;
                        text-align: center;
                        z-index: 999999;
                    }
                }
            }


            .popular {
                >a {
                    //position: relative;

                    &::after {
                        content: 'Most Popular';
                        padding: 0 5px;
                        font-size: 60%;
                        font-weight: 700;
                        color: #fff;
                        border: 1px solid #007fff;
                        background-color: #007fff;
                        border-radius: 8px 0 8px 0;
                        margin-left: 5px;
                        letter-spacing: .5px;
                        position: absolute;
                        bottom: 10px;
                        line-height: 16px;
                        height: 16px;
                        width: fit-content;
                        text-align: center;
                        text-transform: uppercase;
                        z-index: 999999;
                    }
                }
            }

            .popular-sc {
                >a {
                    //position: relative;

                    &::after {
                        content: '搜索度最高';
                        padding: 0 5px;
                        font-size: 65%;
                        font-weight: 600;
                        color: #fff;
                        background-color: #007fff;
                        border: 1px solid #007fff;
                        border-radius: 8px 0 8px 0;
                        margin-left: 5px;
                        letter-spacing: .5px;
                        position: absolute;
                        bottom: 10px;
                        line-height: 16px;
                        height: 16px;
                        width: fit-content;
                        text-align: center;
                        z-index: 999999;
                    }
                }
            }

            .popular-tc {
                >a {
                    //position: relative;

                    &::after {
                        content: '搜索度最高';
                        padding: 0 5px;
                        font-size: 65%;
                        font-weight: 600;
                        color: #fff;
                        background-color: #007fff;
                        border: 1px solid #007fff;
                        border-radius: 8px 0 8px 0;
                        margin-left: 5px;
                        letter-spacing: .5px;
                        position: absolute;
                        bottom: 10px;
                        line-height: 16px;
                        height: 16px;
                        width: fit-content;
                        text-align: center;
                        z-index: 999999;
                    }
                }
            }

            .toppick {
                >a {
                    // position: relative;

                    &::after {
                        content: 'Top Pick';
                        padding: 0 5px;
                        font-size: 60%;
                        font-weight: 700;
                        color: #fff;
                        border: 1px solid #007fff;
                        background-color: #007fff;
                        border-radius: 8px 0 8px 0;
                        margin-left: 5px;
                        letter-spacing: .5px;
                        position: absolute;
                        bottom: 10px;
                        line-height: 16px;
                        height: 16px;
                        width: fit-content;
                        text-align: center;
                        text-transform: uppercase;
                        z-index: 999999;
                    }
                }
            }

            .toppick-sc {
                >a {
                    //position: relative;

                    &::after {
                        content: '精选推介';
                        padding: 0 5px;
                        font-size: 65%;
                        font-weight: 600;
                        color: #fff;
                        background-color: #007fff;
                        border: 1px solid #007fff;
                        border-radius: 8px 0 8px 0;
                        margin-left: 5px;
                        letter-spacing: .5px;
                        position: absolute;
                        bottom: 10px;
                        line-height: 16px;
                        height: 16px;
                        width: fit-content;
                        text-align: center;
                        z-index: 999999;
                    }
                }
            }

            .toppick-tc {
                >a {
                    //position: relative;

                    &::after {
                        content: '精選推介';
                        padding: 0 5px;
                        font-size: 65%;
                        font-weight: 600;
                        color: #fff;
                        background-color: #007fff;
                        border: 1px solid #007fff;
                        border-radius: 8px 0 8px 0;
                        margin-left: 5px;
                        letter-spacing: .5px;
                        position: absolute;
                        bottom: 10px;
                        line-height: 16px;
                        height: 16px;
                        width: fit-content;
                        text-align: center;
                        z-index: 999999;
                    }
                }
            }

            .userchoice {
                >a {
                    //position: relative;

                    &::after {
                        content: "Users' Choice";
                        padding: 0 5px;
                        font-size: 60%;
                        font-weight: 700;
                        color: #fff;
                        border: 1px solid #007fff;
                        background-color: #007fff;
                        border-radius: 8px 0 8px 0;
                        margin-left: 5px;
                        letter-spacing: .5px;
                        position: absolute;
                        bottom: 10px;
                        line-height: 16px;
                        height: 16px;
                        width: fit-content;
                        text-align: center;
                        text-transform: uppercase;
                        z-index: 999999;
                    }
                }
            }

            .userchoice-sc {
                >a {
                    //position: relative;

                    &::after {
                        content: '用家之选';
                        padding: 0 5px;
                        font-size: 65%;
                        font-weight: 600;
                        color: #fff;
                        background-color: #007fff;
                        border: 1px solid #007fff;
                        border-radius: 8px 0 8px 0;
                        margin-left: 5px;
                        letter-spacing: .5px;
                        position: absolute;
                        bottom: 10px;
                        line-height: 16px;
                        height: 16px;
                        width: fit-content;
                        text-align: center;
                        z-index: 999999;
                    }
                }
            }

            .userchoice-tc {
                >a {
                    //position: relative;

                    &::after {
                        content: '用家之選';
                        padding: 0 5px;
                        font-size: 65%;
                        font-weight: 600;
                        color: #fff;
                        background-color: #007fff;
                        border: 1px solid #007fff;
                        border-radius: 8px 0 8px 0;
                        margin-left: 5px;
                        letter-spacing: .5px;
                        position: absolute;
                        bottom: 10px;
                        line-height: 16px;
                        height: 16px;
                        width: fit-content;
                        text-align: center;
                        z-index: 999999;
                    }
                }
            }

            /*
            .new {
                a {
                    content: ' ';
                    background-image: url(https://www.dataplugs.com/wp-content/uploads/2024/10/dp-menu-2024-new-en.png);
                    background-size: 30px;
                    background-repeat: no-repeat;
                    background-position: center;
                    display: block;
                    width: fit-content;
                    padding-right: 120px;
                }
            }
                */

            .list {
                .menucss {
                    li {
                        padding: 5px 18px;
                        position: relative;

                        &.half {
                            &::after {
                                position: absolute;
                                border-left: 1px solid #ccc;
                                content: '';
                                left: 0;
                                height: 50%;
                                top: 0;
                            }
                        }

                        &:not(.half) {
                            &::after {
                                position: absolute;
                                border-left: 1px solid #ccc;
                                content: '';
                                left: 0;
                                height: 100%;
                                top: 0;
                            }
                        }

                        &:not(.trial) {
                            &::before {
                                content: ' ';
                                border-bottom: 1px solid #ccc;
                                width: 15px;
                                display: block;
                                position: absolute;
                                left: 0;
                                top: 50%;
                            }

                            &:last-child {
                                border-left: none;

                                &::after {
                                    position: absolute;
                                    border-left: 1px solid #ccc;
                                    content: '';
                                    left: 0;
                                    height: 50%;
                                    top: 0;
                                }
                            }
                        }


                    }
                }
            }

            .sub-1-menu {
                >li {
                    width: 25%;

                    .depth_2 {
                        padding: 10px 15px;
                        border: 1px solid #eff4fa;
                        display: block;
                        margin-bottom: 10px;
                        margin-right: 10px;
                        border-radius: 7px;
                        background-color: #eff4fa;
                        color: #2c2c2c;
                        font-weight: 500;
                        font-size: 18px !important;
                        background-repeat: no-repeat, no-repeat;
                        background-size: 35px;
                        background-position: left 15px center, right 15px center;
                        padding-left: 60px;

                        &:hover {
                            background-color: #0050c1;
                            color: #fff !important;
                            cursor: pointer !important;
                        }
                    }
                }
            }

            .depth_1 {
                font-size: 18px !important;
                font-weight: 500;
                border-bottom: 1px solid #ccc;
                padding: 12px 0;
                //display: block;
                display: none;

                span {
                    border-bottom: 2px solid #ea5e06;
                    padding: 12px 24px;
                    margin-bottom: 2px;
                    font-size: 20px;
                    font-weight: 600;
                }

                &:after {
                    font-size: 18px;
                    font-weight: 500;
                    border-bottom: 1px solid #ccc;
                    padding: 8px 0;
                    margin-bottom: 10px;
                    display: block;
                }
            }

            .depth_3 {
                font-size: 16.5px;
                border-left: 3px solid #ea5e06;
                font-size: 18px !important;
                padding: 6.5px 10px;
                margin-bottom: 15px;
                display: block;
                margin-top: 12px;
            }

            li {
                &.server {
                    .menucss {
                        display: none !important;
                    }

                    .sub-3-menu {
                        >li:not(:first-child) {
                            margin-top: 10px;
                        }
                    }

                    &.active {
                        >a {
                            background-color: #0050c1;
                            color: #fff !important;
                        }

                        .depth_2 {
                            background-image: url(https://www.dataplugs.com/wp-content/uploads/2024/10/dp-menu-2024-icon1.png), url(https://www.dataplugs.com/wp-content/uploads/2024/10/dp-menu-2024-arrow-white.png) !important;
                        }

                        .menucss {
                            display: grid !important;
                            background-color: #fff;
                            border-top-right-radius: 12px;
                            border-bottom-right-radius: 12px;
                            z-index: 99999999999;
                            animation: fade 0.25s ease-in;
                        }
                    }

                    &.opened {
                        .menucss {
                            display: grid !important;
                            background-color: #fff;
                            border-top-right-radius: 12px;
                            border-bottom-right-radius: 12px;
                            z-index: 99999999999;
                            animation: fade 0.25s ease-in;
                        }
                    }

                    &.current-menu-ancestor {
                        .depth_2 {
                            background-image: url(https://www.dataplugs.com/wp-content/uploads/2024/10/dp-menu-2024-icon1.png), url(https://www.dataplugs.com/wp-content/uploads/2024/10/dp-menu-2024-arrow-white.png) !important;
                        }

                        .menucss {
                            display: grid !important;
                            background-color: #fff;
                            border-top-right-radius: 12px;
                            border-bottom-right-radius: 12px;
                            animation: fade 0.25s ease-in;
                        }
                    }

                    .depth_2 {
                        background-image: url(https://www.dataplugs.com/wp-content/uploads/2024/10/dp-menu-2024-icon1.png), url(https://www.dataplugs.com/wp-content/uploads/2024/10/dp-menu-2024-arrow-black.png) !important;

                        &:hover {
                            background-image: url(https://www.dataplugs.com/wp-content/uploads/2024/10/dp-menu-2024-icon1.png), url(https://www.dataplugs.com/wp-content/uploads/2024/10/dp-menu-2024-arrow-white.png) !important;
                        }
                    }

                    .trial {
                        background-color: #0050c1;
                        border-radius: 7px;
                        margin-top: 15px;
                        background-image: url(https://www.dataplugs.com/wp-content/uploads/2024/10/dp-menu-2024-fire.png);
                        background-position: center left 10px;
                        background-repeat: no-repeat;
                        background-size: 45px;
                        padding: 10px 15px;
                        text-align: center;

                        a {
                            color: #fff !important;
                            font-size: 18px !important;
                            display: block;
                        }

                        &:hover {
                            background-color: #eff4fa;

                            a {
                                color: #232323 !important;
                            }
                        }

                        &::after {
                            border-left: none !important;
                        }
                    }
                }

                &.hosting {
                    .menucss {
                        display: none !important;
                    }

                    &.active {
                        >a {
                            background-color: #0050c1;
                            color: #fff !important;
                        }

                        .depth_2 {
                            background-image: url(https://www.dataplugs.com/wp-content/uploads/2024/10/dp-menu-2024-icon2.png), url(https://www.dataplugs.com/wp-content/uploads/2024/10/dp-menu-2024-arrow-white.png) !important;
                        }

                        .menucss {
                            display: grid !important;
                            background-color: #fff;
                            border-top-right-radius: 12px;
                            border-bottom-right-radius: 12px;
                            z-index: 99999999999;
                            animation: fade 0.25s ease-in;
                        }
                    }

                    &.current-menu-ancestor {
                        .depth_2 {
                            background-image: url(https://www.dataplugs.com/wp-content/uploads/2024/10/dp-menu-2024-icon2.png), url(https://www.dataplugs.com/wp-content/uploads/2024/10/dp-menu-2024-arrow-white.png) !important;
                        }

                        .menucss {
                            display: grid !important;
                            background-color: #fff;
                            border-top-right-radius: 12px;
                            border-bottom-right-radius: 12px;
                            animation: fade 0.25s ease-in;
                        }
                    }

                    .depth_2 {
                        background-image: url(https://www.dataplugs.com/wp-content/uploads/2024/10/dp-menu-2024-icon2.png), url(https://www.dataplugs.com/wp-content/uploads/2024/10/dp-menu-2024-arrow-black.png) !important;

                        &:hover {
                            background-image: url(https://www.dataplugs.com/wp-content/uploads/2024/10/dp-menu-2024-icon2.png), url(https://www.dataplugs.com/wp-content/uploads/2024/10/dp-menu-2024-arrow-white.png) !important;
                        }
                    }
                }

                &.network {
                    .menucss {
                        display: none !important;
                    }

                    &.active {
                        >a {
                            background-color: #0050c1;
                            color: #fff !important;
                        }

                        .depth_2 {
                            background-image: url(https://www.dataplugs.com/wp-content/uploads/2024/10/dp-menu-2024-icon3.png), url(https://www.dataplugs.com/wp-content/uploads/2024/10/dp-menu-2024-arrow-white.png) !important;
                        }

                        .menucss {
                            display: grid !important;
                            background-color: #fff;
                            border-top-right-radius: 12px;
                            border-bottom-right-radius: 12px;
                            z-index: 99999999999;
                            animation: fade 0.25s ease-in;
                        }
                    }

                    &.current-menu-ancestor {
                        .depth_2 {
                            background-image: url(https://www.dataplugs.com/wp-content/uploads/2024/10/dp-menu-2024-icon3.png), url(https://www.dataplugs.com/wp-content/uploads/2024/10/dp-menu-2024-arrow-white.png) !important;
                        }

                        .menucss {
                            display: grid !important;
                            background-color: #fff;
                            border-top-right-radius: 12px;
                            border-bottom-right-radius: 12px;
                            animation: fade 0.25s ease-in;
                        }
                    }

                    .depth_2 {
                        background-image: url(https://www.dataplugs.com/wp-content/uploads/2024/10/dp-menu-2024-icon3.png), url(https://www.dataplugs.com/wp-content/uploads/2024/10/dp-menu-2024-arrow-black.png) !important;

                        &:hover {
                            background-image: url(https://www.dataplugs.com/wp-content/uploads/2024/10/dp-menu-2024-icon3.png), url(https://www.dataplugs.com/wp-content/uploads/2024/10/dp-menu-2024-arrow-white.png) !important;
                        }
                    }
                }

                &.domain {
                    .menucss {
                        display: none !important;
                    }

                    &.active {
                        >a {
                            background-color: #0050c1;
                            color: #fff !important;
                        }

                        .depth_2 {
                            background-image: url(https://www.dataplugs.com/wp-content/uploads/2024/10/dp-menu-2024-icon4.png), url(https://www.dataplugs.com/wp-content/uploads/2024/10/dp-menu-2024-arrow-white.png) !important;
                        }


                        .menucss {
                            display: grid !important;
                            background-color: #fff;
                            border-top-right-radius: 12px;
                            border-bottom-right-radius: 12px;
                            z-index: 99999999999;
                            animation: fade 0.25s ease-in;
                        }
                    }

                    &.current-menu-ancestor {
                        .depth_2 {
                            background-image: url(https://www.dataplugs.com/wp-content/uploads/2024/10/dp-menu-2024-icon4.png), url(https://www.dataplugs.com/wp-content/uploads/2024/10/dp-menu-2024-arrow-white.png) !important;
                        }

                        .menucss {
                            display: grid !important;
                            background-color: #fff;
                            border-top-right-radius: 12px;
                            border-bottom-right-radius: 12px;
                            animation: fade 0.25s ease-in;
                        }
                    }

                    .depth_2 {
                        background-image: url(https://www.dataplugs.com/wp-content/uploads/2024/10/dp-menu-2024-icon4.png), url(https://www.dataplugs.com/wp-content/uploads/2024/10/dp-menu-2024-arrow-black.png) !important;

                        &:hover {
                            background-image: url(https://www.dataplugs.com/wp-content/uploads/2024/10/dp-menu-2024-icon4.png), url(https://www.dataplugs.com/wp-content/uploads/2024/10/dp-menu-2024-arrow-white.png) !important;
                        }
                    }
                }

                &.colo {
                    .menucss {
                        display: none !important;
                    }

                    &.active {
                        >a {
                            background-color: #0050c1;
                            color: #fff !important;
                        }

                        .depth_2 {
                            background-image: url(https://www.dataplugs.com/wp-content/uploads/2024/10/dp-menu-2024-icon5.png), url(https://www.dataplugs.com/wp-content/uploads/2024/10/dp-menu-2024-arrow-white.png) !important;
                        }


                        .menucss {
                            display: grid !important;
                            background-color: #fff;
                            border-top-right-radius: 12px;
                            border-bottom-right-radius: 12px;
                            z-index: 99999999999;
                            animation: fade 0.25s ease-in;
                        }
                    }

                    &.current-menu-ancestor {
                        .depth_2 {
                            background-image: url(https://www.dataplugs.com/wp-content/uploads/2024/10/dp-menu-2024-icon5.png), url(https://www.dataplugs.com/wp-content/uploads/2024/10/dp-menu-2024-arrow-white.png) !important;
                        }

                        .menucss {
                            display: grid !important;
                            background-color: #fff;
                            border-top-right-radius: 12px;
                            border-bottom-right-radius: 12px;
                            animation: fade 0.25s ease-in;
                        }
                    }

                    .depth_2 {
                        background-image: url(https://www.dataplugs.com/wp-content/uploads/2024/10/dp-menu-2024-icon5.png), url(https://www.dataplugs.com/wp-content/uploads/2024/10/dp-menu-2024-arrow-black.png) !important;

                        &:hover {
                            background-image: url(https://www.dataplugs.com/wp-content/uploads/2024/10/dp-menu-2024-icon5.png), url(https://www.dataplugs.com/wp-content/uploads/2024/10/dp-menu-2024-arrow-white.png) !important;
                        }
                    }
                }
            }
        }

        @keyframes fade {
            0% {
                opacity: 0;
            }

            100% {
                opacity: 1;
            }
        }
    }
}

@include media-breakpoint-down(lg) {
    #main_menu {
        li {
            display: block;
            width: 100%;

            a {
                color: #2c2c2c;
            }

            >.sub-menu {
                display: none !important;

                >li {
                    >a {
                        display: none !important;
                    }

                    >ul {
                        >li {
                            >a {
                                font-size: 16px;
                                border-left: 3px solid #0050c1;
                                font-weight: 500;
                                padding: 0 10px;
                            }

                            ul {
                                li {
                                    a {
                                        &::before {
                                            content: ' ';
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            &.on {
                >.sub-menu {
                    display: block !important;

                    ul {
                        a {
                            margin: 18px 0 !important;
                        }
                    }
                }
            }

            &.solutions {
                .usecase {
                    >a {
                        border: none !important;
                        padding: 0;

                        &::before {
                            background-image: url(https://www.dataplugs.com/wp-content/uploads/2024/10/dp-menu-2024-icon6.png);
                            background-repeat: no-repeat;
                            background-size: 16px;
                            padding-right: 30px !important;
                            content: '';
                            background-position: center left;
                        }
                    }
                }

                .ind {
                    >a {
                        border: none !important;
                        padding: 0;

                        &::before {
                            background-image: url(https://www.dataplugs.com/wp-content/uploads/2024/11/dp-menu-2024-icon9.png);
                            background-repeat: no-repeat;
                            background-size: 23px;
                            padding-right: 30px !important;
                            content: '';
                            background-position: center left;
                        }
                    }
                }

                .sec {
                    >a {
                        border: none !important;
                        padding: 0;

                        &::before {
                            background-image: url(https://www.dataplugs.com/wp-content/uploads/2024/10/dp-menu-2024-icon8.png);
                            background-repeat: no-repeat;
                            background-size: 17px;
                            padding-right: 30px !important;
                            content: '';
                            background-position: center left;
                        }
                    }
                }

                .tech {
                    >a {
                        border: none !important;
                        padding: 0;

                        &::before {
                            background-image: url(https://www.dataplugs.com/wp-content/uploads/2024/11/dp-menu-2024-icon10.png);
                            background-repeat: no-repeat;
                            background-size: 17px;
                            padding-right: 30px !important;
                            content: '';
                            background-position: center left;
                        }
                    }
                }
            }

            &.about {
                .list {
                    >a {
                        border: none !important;
                        padding: 0;

                        &::before {
                            background-image: url(https://www.dataplugs.com/wp-content/uploads/2025/02/dp-menu-2025-icon-about.png);
                            background-repeat: no-repeat;
                            background-size: 17px;
                            padding-right: 30px !important;
                            content: '';
                            background-position: center left;
                        }
                    }

                    &:last-child {
                        >a {
                            &::before {
                                background-image: url(https://www.dataplugs.com/wp-content/uploads/2025/02/dp-menu-2025-icon-dc.png);
                                background-repeat: no-repeat;
                                background-size: 17px;
                                padding-right: 30px !important;
                                content: '';
                                background-position: center left;
                            }
                        }
                    }

                    .dccenter {
                        >a {
                            font-size: 16px;
                            font-weight: 500;
                            padding: 3px 6px !important;
                            background-color: #f0f0f0;
                            margin: 0 !important;
                            border-left: 3px solid #0050c1 !important;
                        }
                    }
                }
            }

            &.prod_service {

                .none {
                    >a {
                        font-size: 0 !important;
                        line-height: 0 !important;
                        color: transparent !important;
                        display: none !important;
                        margin-bottom: 0px !important;
                    }
                }

                .trial {
                    background-color: #0050c1;
                    border-radius: 7px;
                    background-image: url(https://www.dataplugs.com/wp-content/uploads/2024/10/dp-menu-2024-fire.png);
                    background-position: center left 10px;
                    background-repeat: no-repeat;
                    background-size: 45px;
                    text-align: center;
                    line-height: 45px;

                    a {
                        color: #fff;
                        font-weight: 600;
                    }
                }

                .server {
                    >a {
                        &::before {
                            background-image: url(https://www.dataplugs.com/wp-content/uploads/2024/10/dp-menu-2024-icon1.png);
                            background-repeat: no-repeat;
                            background-size: 23px;
                            padding-right: 30px !important;
                            content: '';
                            background-position: center left;
                        }
                    }
                }

                .hosting {
                    >a {
                        &::before {
                            background-image: url(https://www.dataplugs.com/wp-content/uploads/2024/10/dp-menu-2024-icon2.png);
                            background-repeat: no-repeat;
                            background-size: 23px;
                            padding-right: 30px !important;
                            content: '';
                            background-position: center left;
                        }
                    }
                }

                .network {
                    >a {
                        &::before {
                            background-image: url(https://www.dataplugs.com/wp-content/uploads/2024/10/dp-menu-2024-icon3.png);
                            background-repeat: no-repeat;
                            background-size: 23px;
                            padding-right: 30px !important;
                            content: '';
                            background-position: center left;
                        }
                    }
                }

                .domain {
                    >a {
                        &::before {
                            background-image: url(https://www.dataplugs.com/wp-content/uploads/2024/10/dp-menu-2024-icon4.png);
                            background-repeat: no-repeat;
                            background-size: 23px;
                            padding-right: 30px !important;
                            content: '';
                            background-position: center left;
                        }
                    }
                }

                .colo {
                    >a {
                        &::before {
                            background-image: url(https://www.dataplugs.com/wp-content/uploads/2024/10/dp-menu-2024-icon5.png);
                            background-repeat: no-repeat;
                            background-size: 23px;
                            padding-right: 30px !important;
                            content: '';
                            background-position: center left;
                        }
                    }
                }

                .sub-4-menu {
                    li:not(.trial) {
                        a {
                            &::before {
                                content: '-';
                                padding-right: 10px;
                            }
                        }
                    }
                }

                .sub-1-menu {
                    li {
                        >a {
                            font-size: 16px;
                            position: relative;
                            border-left: none !important;
                            padding: 0 !important;

                            &::after {
                                content: "\f107";
                                font-family: "Font Awesome 5 Pro";
                                margin-left: 5px;
                                position: absolute;
                                right: 0;
                                -webkit-transform: rotate(-90deg);
                                transform: rotate(-90deg);
                                transition: transform .3s;
                            }
                        }

                    }

                    .on {
                        >a {
                            &::after {
                                transform: rotate(0);
                            }
                        }


                        .sub-2-menu {
                            display: block;

                            li {
                                .depth_3 {
                                    font-size: 16px;
                                    font-weight: 500;
                                    padding: 3px 6px !important;
                                    background-color: #f0f0f0;
                                    margin: 0 !important;
                                    border-left: 3px solid #0050c1 !important;
                                }

                                /*
                                .comingsoon {
                                    >a {
                                        &::after {
                                            content: 'Coming Soon' !important;
                                            padding: 0 4px;
                                            font-size: 60%;
                                            font-weight: 800;
                                            color: #ea5e06;
                                            border: 1px solid #ea5e06;
                                            border-radius: 5px 0 5px 0;
                                            margin-left: 5px;
                                            transform: none !important;
                                        }
                                    }
                                }
                                */

                                .comingsoon {
                                    >a {
                                        position: relative;

                                        &::after {
                                            content: 'Coming Soon' !important;
                                            padding: 0 5px;
                                            font-size: 62.5%;
                                            font-weight: 800;
                                            color: #fff;
                                            background-color: #1e1e1e;
                                            border: 1px solid #1e1e1e;
                                            border-radius: 5px 0 5px 0;
                                            margin-left: 5px;
                                            transform: none;
                                            font-family: unset;
                                            text-transform: uppercase;
                                            height: 16px;
                                            line-height: 16px;
                                            position: absolute;
                                            bottom: 2.5px;
                                        }
                                    }
                                }

                                .comingsoon-sc {
                                    >a {
                                        position: relative;

                                        &::after {
                                            content: '即将推出' !important;
                                            padding: 0 4px;
                                            font-size: 70%;
                                            font-weight: 600;
                                            color: #fff;
                                            background-color: #1e1e1e;
                                            border: 1px solid #1e1e1e;
                                            border-radius: 5px 0 5px 0;
                                            margin-left: 5px;
                                            transform: none;
                                            font-family: unset;
                                            height: 16px;
                                            line-height: 16px;
                                            letter-spacing: 0.5px;
                                            line-height: 16px;
                                            position: absolute;
                                            bottom: 2.5px;
                                        }
                                    }
                                }

                                .comingsoon-tc {
                                    >a {
                                        position: relative;

                                        &::after {
                                            content: '即將推出' !important;
                                            padding: 0 4px;
                                            font-size: 70%;
                                            font-weight: 600;
                                            color: #fff;
                                            background-color: #1e1e1e;
                                            border: 1px solid #1e1e1e;
                                            border-radius: 5px 0 5px 0;
                                            margin-left: 5px;
                                            transform: none;
                                            font-family: unset;
                                            height: 16px;
                                            line-height: 16px;
                                            letter-spacing: 0.5px;
                                            position: absolute;
                                            bottom: 2.5px;
                                        }
                                    }
                                }

                                .new {
                                    >a {
                                        position: relative;

                                        &::after {
                                            content: 'NEW' !important;
                                            padding: 0 5px;
                                            font-size: 62.5%;
                                            font-weight: 800;
                                            color: #fff;
                                            background-color: #007fff;
                                            border: 1px solid #007fff;
                                            border-radius: 5px 0 5px 0;
                                            margin-left: 5px;
                                            transform: none;
                                            font-family: unset;
                                            text-transform: uppercase;
                                            line-height: 16px;
                                            height: 16px;
                                            position: absolute;
                                            bottom: 2.5px;
                                        }
                                    }
                                }

                                .new-tc {
                                    >a {
                                        position: relative;

                                        &::after {
                                            content: '最新' !important;
                                            padding: 0 4px;
                                            font-size: 70%;
                                            font-weight: 600;
                                            color: #fff;
                                            background-color: #007fff;
                                            border: 1px solid #007fff;
                                            border-radius: 5px 0 5px 0;
                                            margin-left: 5px;
                                            transform: none;
                                            font-family: unset;
                                            text-transform: uppercase;
                                            line-height: 16px;
                                            height: 16px;
                                            position: absolute;
                                            bottom: 2.5px;
                                            letter-spacing: .5px;
                                        }
                                    }
                                }

                                .new-sc {
                                    >a {
                                        position: relative;

                                        &::after {
                                            content: '最新' !important;
                                            padding: 0 4px;
                                            font-size: 70%;
                                            font-weight: 600;
                                            color: #fff;
                                            background-color: #007fff;
                                            border: 1px solid #007fff;
                                            border-radius: 5px 0 5px 0;
                                            margin-left: 5px;
                                            transform: none;
                                            font-family: unset;
                                            text-transform: uppercase;
                                            line-height: 16px;
                                            height: 16px;
                                            position: absolute;
                                            bottom: 2.5px;
                                            letter-spacing: .5px;
                                        }
                                    }
                                }

                                .hot {
                                    >a {
                                        position: relative;

                                        &::after {
                                            content: 'HOT' !important;
                                            padding: 0 4px;
                                            font-size: 70%;
                                            font-weight: 600;
                                            color: #fff;
                                            background-color: #ea5e06;
                                            border: 1px solid #ea5e06;
                                            border-radius: 5px 0 5px 0;
                                            margin-left: 5px;
                                            transform: none;
                                            font-family: unset;
                                            text-transform: uppercase;
                                            line-height: 16px;
                                            height: 16px;
                                            position: absolute;
                                            bottom: 2.5px;
                                            letter-spacing: .5px;
                                        }
                                    }
                                }


                                .hot-tc {
                                    >a {
                                        position: relative;

                                        &::after {
                                            content: '熱賣' !important;
                                            padding: 0 4px;
                                            font-size: 70%;
                                            font-weight: 600;
                                            color: #fff;
                                            background-color: #ea5e06;
                                            border: 1px solid #ea5e06;
                                            border-radius: 5px 0 5px 0;
                                            margin-left: 5px;
                                            transform: none;
                                            font-family: unset;
                                            text-transform: uppercase;
                                            line-height: 16px;
                                            height: 16px;
                                            position: absolute;
                                            bottom: 2.5px;
                                            letter-spacing: .5px;
                                        }
                                    }
                                }

                                .hot-sc {
                                    >a {
                                        position: relative;

                                        &::after {
                                            content: '热销' !important;
                                            padding: 0 4px;
                                            font-size: 70%;
                                            font-weight: 600;
                                            color: #fff;
                                            background-color: #ea5e06;
                                            border: 1px solid #ea5e06;
                                            border-radius: 5px 0 5px 0;
                                            margin-left: 5px;
                                            transform: none;
                                            font-family: unset;
                                            text-transform: uppercase;
                                            line-height: 16px;
                                            height: 16px;
                                            position: absolute;
                                            bottom: 2.5px;
                                            letter-spacing: .5px;
                                        }
                                    }
                                }

                                .sales {
                                    >a {
                                        position: relative;

                                        &::after {
                                            content: 'SALE' !important;
                                            padding: 0 5px;
                                            font-size: 62.5%;
                                            font-weight: 800;
                                            color: #fff;
                                            border: 1px solid #ff8200;
                                            background-color: #ff8200;
                                            border-radius: 5px 0 5px 0;
                                            margin-left: 5px;
                                            transform: none;
                                            font-family: unset;
                                            text-transform: uppercase;
                                            line-height: 16px;
                                            height: 16px;
                                            position: absolute;
                                            bottom: 2.5px;
                                        }
                                    }
                                }

                                .sales-tc {
                                    >a {
                                        position: relative;

                                        &::after {
                                            content: '優惠' !important;
                                            padding: 0 4px;
                                            font-size: 70%;
                                            font-weight: 600;
                                            color: #fff;
                                            border: 1px solid #ff8200;
                                            background-color: #ff8200;
                                            border-radius: 5px 0 5px 0;
                                            margin-left: 5px;
                                            transform: none;
                                            font-family: unset;
                                            text-transform: uppercase;
                                            line-height: 16px;
                                            height: 16px;
                                            position: absolute;
                                            bottom: 2.5px;
                                            letter-spacing: .5px;
                                        }
                                    }
                                }

                                .sales-sc {
                                    >a {
                                        position: relative;

                                        &::after {
                                            content: '优惠' !important;
                                            padding: 0 4px;
                                            font-size: 70%;
                                            font-weight: 600;
                                            color: #fff;
                                            border: 1px solid #ff8200;
                                            background-color: #ff8200;
                                            border-radius: 5px 0 5px 0;
                                            margin-left: 5px;
                                            transform: none;
                                            font-family: unset;
                                            text-transform: uppercase;
                                            line-height: 16px;
                                            height: 16px;
                                            position: absolute;
                                            bottom: 2.5px;
                                            letter-spacing: .5px;
                                        }
                                    }
                                }

                                .seller {
                                    >a {
                                        position: relative;

                                        &::after {
                                            content: 'Best Seller' !important;
                                            padding: 0 4px;
                                            font-size: 70%;
                                            font-weight: 600;
                                            color: #fff;
                                            background-color: #ea5e06;
                                            border: 1px solid #ea5e06;
                                            border-radius: 5px 0 5px 0;
                                            margin-left: 5px;
                                            transform: none;
                                            font-family: unset;
                                            text-transform: uppercase;
                                            line-height: 16px;
                                            height: 16px;
                                            position: absolute;
                                            bottom: 2.5px;
                                            letter-spacing: .5px;
                                        }
                                    }
                                }

                                .seller-tc {
                                    >a {
                                        position: relative;

                                        &::after {
                                            content: '熱銷產品' !important;
                                            padding: 0 4px;
                                            font-size: 70%;
                                            font-weight: 600;
                                            color: #fff;
                                            background-color: #ea5e06;
                                            border: 1px solid #ea5e06;
                                            border-radius: 5px 0 5px 0;
                                            margin-left: 5px;
                                            transform: none;
                                            font-family: unset;
                                            text-transform: uppercase;
                                            line-height: 16px;
                                            height: 16px;
                                            position: absolute;
                                            bottom: 2.5px;
                                            letter-spacing: .5px;
                                        }
                                    }
                                }

                                .seller-sc {
                                    >a {
                                        position: relative;

                                        &::after {
                                            content: '热销产品' !important;
                                            padding: 0 4px;
                                            font-size: 70%;
                                            font-weight: 600;
                                            color: #fff;
                                            background-color: #ea5e06;
                                            border: 1px solid #ea5e06;
                                            border-radius: 5px 0 5px 0;
                                            margin-left: 5px;
                                            transform: none;
                                            font-family: unset;
                                            text-transform: uppercase;
                                            line-height: 16px;
                                            height: 16px;
                                            position: absolute;
                                            bottom: 2.5px;
                                            letter-spacing: .5px;
                                        }
                                    }
                                }


                                .newarrival {
                                    >a {
                                        position: relative;

                                        &::after {
                                            content: 'New Arrivals' !important;
                                            padding: 0 4px;
                                            font-size: 70%;
                                            font-weight: 600;
                                            color: #fff;
                                            background-color: #ff001a;
                                            border: 1px solid #ff001a;
                                            border-radius: 5px 0 5px 0;
                                            margin-left: 5px;
                                            transform: none;
                                            font-family: unset;
                                            text-transform: uppercase;
                                            line-height: 16px;
                                            height: 16px;
                                            position: absolute;
                                            bottom: 2.5px;
                                            letter-spacing: .5px;
                                        }
                                    }
                                }

                                .newarrival-tc {
                                    >a {
                                        position: relative;

                                        &::after {
                                            content: '最新上市' !important;
                                            padding: 0 4px;
                                            font-size: 70%;
                                            font-weight: 600;
                                            color: #fff;
                                            background-color: #ff001a;
                                            border: 1px solid #ff001a;
                                            border-radius: 5px 0 5px 0;
                                            margin-left: 5px;
                                            transform: none;
                                            font-family: unset;
                                            text-transform: uppercase;
                                            line-height: 16px;
                                            height: 16px;
                                            position: absolute;
                                            bottom: 2.5px;
                                            letter-spacing: .5px;
                                        }
                                    }
                                }

                                .newarrival-sc {
                                    >a {
                                        position: relative;

                                        &::after {
                                            content: '最新上市' !important;
                                            padding: 0 4px;
                                            font-size: 70%;
                                            font-weight: 600;
                                            color: #fff;
                                            background-color: #ff001a;
                                            border: 1px solid #ff001a;
                                            border-radius: 5px 0 5px 0;
                                            margin-left: 5px;
                                            transform: none;
                                            font-family: unset;
                                            text-transform: uppercase;
                                            line-height: 16px;
                                            height: 16px;
                                            position: absolute;
                                            bottom: 2.5px;
                                            letter-spacing: .5px;
                                        }
                                    }
                                }


                                .popular {
                                    >a {
                                        position: relative;

                                        &::after {
                                            content: 'Most Popular' !important;
                                            padding: 0 4px;
                                            font-size: 70%;
                                            font-weight: 600;
                                            color: #fff;
                                            background-color: #007fff;
                                            border: 1px solid #007fff;
                                            border-radius: 5px 0 5px 0;
                                            margin-left: 5px;
                                            transform: none;
                                            font-family: unset;
                                            text-transform: uppercase;
                                            line-height: 16px;
                                            height: 16px;
                                            position: absolute;
                                            bottom: 2.5px;
                                            letter-spacing: .5px;
                                        }
                                    }
                                }

                                .popular-tc {
                                    >a {
                                        position: relative;

                                        &::after {
                                            content: '搜索度最高' !important;
                                            padding: 0 4px;
                                            font-size: 70%;
                                            font-weight: 600;
                                            color: #fff;
                                            background-color: #007fff;
                                            border: 1px solid #007fff;
                                            border-radius: 5px 0 5px 0;
                                            margin-left: 5px;
                                            transform: none;
                                            font-family: unset;
                                            text-transform: uppercase;
                                            line-height: 16px;
                                            height: 16px;
                                            position: absolute;
                                            bottom: 2.5px;
                                            letter-spacing: .5px;
                                        }
                                    }
                                }

                                .popular-sc {
                                    >a {
                                        position: relative;

                                        &::after {
                                            content: '搜索度最高' !important;
                                            padding: 0 4px;
                                            font-size: 70%;
                                            font-weight: 600;
                                            color: #fff;
                                            background-color: #007fff;
                                            border: 1px solid #007fff;
                                            border-radius: 5px 0 5px 0;
                                            margin-left: 5px;
                                            transform: none;
                                            font-family: unset;
                                            text-transform: uppercase;
                                            line-height: 16px;
                                            height: 16px;
                                            position: absolute;
                                            bottom: 2.5px;
                                            letter-spacing: .5px;
                                        }
                                    }
                                }


                                .toppick {
                                    >a {
                                        position: relative;

                                        &::after {
                                            content: 'Top Pick' !important;
                                            padding: 0 4px;
                                            font-size: 70%;
                                            font-weight: 600;
                                            color: #fff;
                                            background-color: #007fff;
                                            border: 1px solid #007fff;
                                            border-radius: 5px 0 5px 0;
                                            margin-left: 5px;
                                            transform: none;
                                            font-family: unset;
                                            text-transform: uppercase;
                                            line-height: 16px;
                                            height: 16px;
                                            position: absolute;
                                            bottom: 2.5px;
                                            letter-spacing: .5px;
                                        }
                                    }
                                }

                                .toppick-tc {
                                    >a {
                                        position: relative;

                                        &::after {
                                            content: '精選推介' !important;
                                            padding: 0 4px;
                                            font-size: 70%;
                                            font-weight: 600;
                                            color: #fff;
                                            background-color: #007fff;
                                            border: 1px solid #007fff;
                                            border-radius: 5px 0 5px 0;
                                            margin-left: 5px;
                                            transform: none;
                                            font-family: unset;
                                            text-transform: uppercase;
                                            line-height: 16px;
                                            height: 16px;
                                            position: absolute;
                                            bottom: 2.5px;
                                            letter-spacing: .5px;
                                        }
                                    }
                                }

                                .toppick-sc {
                                    >a {
                                        position: relative;

                                        &::after {
                                            content: '精选推介' !important;
                                            padding: 0 4px;
                                            font-size: 70%;
                                            font-weight: 600;
                                            color: #fff;
                                            background-color: #007fff;
                                            border: 1px solid #007fff;
                                            border-radius: 5px 0 5px 0;
                                            margin-left: 5px;
                                            transform: none;
                                            font-family: unset;
                                            text-transform: uppercase;
                                            line-height: 16px;
                                            height: 16px;
                                            position: absolute;
                                            bottom: 2.5px;
                                            letter-spacing: .5px;
                                        }
                                    }
                                }

                                .userchoice {
                                    >a {
                                        position: relative;

                                        &::after {
                                            content: "Users' Choice" !important;
                                            padding: 0 4px;
                                            font-size: 70%;
                                            font-weight: 600;
                                            color: #fff;
                                            background-color: #007fff;
                                            border: 1px solid #007fff;
                                            border-radius: 5px 0 5px 0;
                                            margin-left: 5px;
                                            transform: none;
                                            font-family: unset;
                                            text-transform: uppercase;
                                            line-height: 16px;
                                            height: 16px;
                                            position: absolute;
                                            bottom: 2.5px;
                                            letter-spacing: .5px;
                                        }
                                    }
                                }

                                .userchoice-tc {
                                    >a {
                                        position: relative;

                                        &::after {
                                            content: '用家之選' !important;
                                            padding: 0 4px;
                                            font-size: 70%;
                                            font-weight: 600;
                                            color: #fff;
                                            background-color: #007fff;
                                            border: 1px solid #007fff;
                                            border-radius: 5px 0 5px 0;
                                            margin-left: 5px;
                                            transform: none;
                                            font-family: unset;
                                            text-transform: uppercase;
                                            line-height: 16px;
                                            height: 16px;
                                            position: absolute;
                                            bottom: 2.5px;
                                            letter-spacing: .5px;
                                        }
                                    }
                                }

                                .userchoice-sc {
                                    >a {
                                        position: relative;

                                        &::after {
                                            content: '用家之选' !important;
                                            padding: 0 4px;
                                            font-size: 70%;
                                            font-weight: 600;
                                            color: #fff;
                                            background-color: #007fff;
                                            border: 1px solid #007fff;
                                            border-radius: 5px 0 5px 0;
                                            margin-left: 5px;
                                            transform: none;
                                            font-family: unset;
                                            text-transform: uppercase;
                                            line-height: 16px;
                                            height: 16px;
                                            position: absolute;
                                            bottom: 2.5px;
                                            letter-spacing: .5px;
                                        }
                                    }
                                }

                                >a {
                                    font-size: 14.5px;

                                    &::after {
                                        content: '' !important;
                                    }
                                }
                            }
                        }




                    }

                    //transform: rotate(0);
                }

                .sub-2-menu {
                    display: none;
                }
            }
        }
    }
}

#breadcrumb_row {
    background-color: #F0F0F0;

    .breadcrumb {
        background-color: inherit;
        border-radius: 0;
        list-style: outside none none;
        margin-bottom: 0;
        padding: 0;

        li:not(:last-child)::after {
            content: "\f105";
            margin-left: 5px;
            font-family: 'Font Awesome 5 Free';
        }

        >li+li::before {
            display: none;
        }

        li {
            margin: 0 2px;
            color: #555;

            a,
            h1 {
                color: #555;
                font-size: 14px;
                line-height: 40px;
                font-size: 14px;
                margin: 0;
            }

            &:first-child a {
                font-size: 18px;
            }
        }
    }

    .adminlogged {
        margin-right: 3px;
        font-size: 17px;

        @include media-breakpoint-only(xs) {
            position: absolute;
            right: 45px;
            top: 7px;
        }
    }

    #knowledgebase_search {
        position: relative;
        display: inline-block;

        @include media-breakpoint-only(xs) {
            position: absolute;
            right: 15px;
        }

        input[type='text'] {
            border: 1px solid #6fb3e0;
            border-radius: 14px !important;
            color: #666;
            height: 26px;
            margin-top: 8px;
            max-width: 40vw;
            width: 20px;
            padding: 0 12px;
            -webkit-transition: all 0.3s;
            /* Safari */
            transition: all 0.3s;
        }

        input[type='text']:focus,
        &:hover input[type='text'] {
            border-radius: 5px !important;
            width: 300px;
            padding-bottom: 2px;
            padding-right: 30px;
            padding-top: 2px;
        }

        input[type='submit'] {
            font-family: 'Font Awesome 5 Free';
            border: 0;
            position: absolute;
            right: 6px;
            top: 10px;
            background-color: transparent;
            padding: 0;
            color: #6fb3e0;
        }
    }

}

.menu_opened_two #header {
    @include media-breakpoint-down(xs) {
        //margin-left: 210px;
    }
}

#sidebar_btn {
    line-height: 70px;
    position: absolute;
    width: 60px;
    height: 70px;
    text-align: center;
}

.icon-animated-vertical {
    display: inline-block;
    animation: vertical 2s infinite ease 2s;
}

@keyframes vertical {
    0% {
        transform: translate(0, -3px);
    }

    4% {
        transform: translate(0, 3px);
    }

    8% {
        transform: translate(0, -3px);
    }

    12% {
        transform: translate(0, 3px);
    }

    16% {
        transform: translate(0, -3px);
    }

    20% {
        transform: translate(0, 3px);
    }

    22% {
        transform: translate(0, 0);
    }
}

.icon-animated-bell {
    display: inline-block;
    animation: ringing 2s infinite ease 1s;
    transform-origin: 50% 0;
}

@keyframes ringing {
    0% {
        transform: rotate(-15deg);
    }

    2% {
        transform: rotate(15deg);
    }

    4% {
        transform: rotate(-18deg);
    }

    6% {
        transform: rotate(18deg);
    }

    8% {
        transform: rotate(-22deg);
    }

    10% {
        transform: rotate(22deg);
    }

    12% {
        transform: rotate(-18deg);
    }

    14% {
        transform: rotate(18deg);
    }

    16% {
        transform: rotate(-12deg);
    }

    18% {
        transform: rotate(12deg);
    }

    20% {
        transform: rotate(0deg);
    }
}

@keyframes gradientAnimation2 {
    0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
}