.eno-tabs{
    .nav-tabs{
        .nav-item{
            .nav-link{
                padding: 10px 15px;
            }
        }
    }
}

.form-group{
    .checkbox{
        label{
            font-weight: normal;
        }
    }

    select{
        &:disabled{
            cursor: no-drop;
        }
    }
}

input{
    &[type="radio"]{
        &.tc{
            &:checked{
                +.labels{
                    &::before{
                        font-family: 'Font Awesome\ 5 Free';
                        line-height: 14px;
                    }
                }
            }
        }
    }
}

blockquote{
    padding: 10px 20px;
    margin: 0 0 20px;
    font-size: 17.5px;
    border-left-style: solid;
    border-left-width: 5px;
}