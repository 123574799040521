body.modal-open-noscroll {
	padding-right: 17px!important
}

body.modal-open-noscroll .breadcrumbs.fixed,
body.modal-open-noscroll .navbar-top,
body.modal-open-noscroll .qs-layout-menu {
	right: 17px
}

body.modal-open-noscroll .back-to-top {
	right: 32px
}

@media (max-width:991px) {
	body.front {
		margin-top: 90px
	}
}

body.front.top-nav-collapse .pre-header {
	display: none
}

@media (min-width:991px) {
	body.front.top-nav-collapse .top-navbar.dark-menu .navbar-left li a,
	body.front.top-nav-collapse .top-navbar.dark-menu .navbar-right li a {
		padding: 15px
	}
	body.front.top-nav-collapse .top-navbar.dark-menu .navbar-brand {
		padding: 10px
	}
}

body.front h1,
body.front h2,
body.front h3,
body.front h4 {
	font-weight: 300
}

@media (max-width:991px) {
	body.front.no-pre-header {
		margin-top: 55px
	}
}

@media (min-width:992px) {
	body.front.no-pre-header .mass-head {
		padding: 90px 0 0
	}
}

h1,
h2 {font-family: Roboto,sans-serif;}

a,
a:active,
a:focus,
a:hover {
	color: #3498db
}

a,
a:active,
a:focus,
a:hover,
a:visited {
	outline: 0!important
}

::-moz-focus-inner {
	border: 0;
	-moz-outline-style: none
}

#page-wrapper {
	padding: 0 15px;
	background-color: #eaeaea;
	border: none
}

@media (min-width:992px) {
	#page-wrapper {
		margin: 50px 0 0 210px
	}
	#page-wrapper.collapsed {
		margin: 50px 0 0 50px
	}
	#page-wrapper.no-sidebar {
		margin: 50px 0 0
	}
	#page-wrapper.fixed {
		margin: 90px 0 0 210px
	}
	#page-wrapper.fixed.collapsed {
		margin: 90px 0 0 50px
	}
	#page-wrapper.fixed.no-sidebar {
		margin: 90px 0 0
	}
}



@media (min-width:992px) {
	.footer .footer-inner {
		position: absolute;
		z-index: auto;
		left: 210px;
		right: 0;
		bottom: 0
	}
	.footer .footer-inner.collapsed {
		left: 50px
	}
	.footer .footer-inner.no-sidebar {
		left: 0
	}
}

#back-to-top {
	z-index: 999
}

.back-to-top {
	cursor: pointer;
	position: fixed;
	bottom: 10px;
	right: 15px;
	display: none
}

.back-to-top.btn {
	opacity: .7;
	filter: alpha(opacity=70)
}

ol.breadcrumb li.pull-right:before {
	content: normal
}

.blockquote-reverse,
blockquote,
blockquote.pull-right {
	border-color: #7bb7e5
}

.accordion-toggle:focus,
.btn:focus,
.dropdown-menu>li>a:focus,
.dropdown-menu>li>a:hover,
.modal {
	outline: 0
}

[class*=" fa-"],
[class*=" glyphicon-"],
[class^=fa-],
[class^=glyphicon-] {
	text-align: center
}

.popover {
	width: auto;
	border-radius: 0
}

.alert,
.breadcrumb,
.btn,
.dropdown-menu,
.form-control,
.input-group-addon,
.input-group-btn .btn,
.input-group-lg>.form-control,
.input-group-lg>.input-group-addon,
.input-group-lg>.input-group-btn>.btn,
.input-group-sm>.form-control,
.input-group-sm>.input-group-addon,
.input-group-sm>.input-group-btn>.btn,
.label,
.list-group-item:first-child,
.list-group-item:last-child,
.nav-pills>li>a,
.nav-tabs>li>a,
.pager li>a,
.pager li>span,
.panel,
.panel-group .panel,
.panel-group .panel-heading,
.panel-heading,
.progress,
.thumbnail,
.well {
	border-radius: 0
}

.dropdown-menu>.active>a,
.dropdown-menu>.active>a:focus,
.dropdown-menu>.active>a:hover {
	background-color: #7bb7e5
}

.nav-pills>li.active>a,
.nav-pills>li.active>a:focus,
.nav-pills>li.active>a:hover,
.pagination>.active>a,
.pagination>.active>a:focus,
.pagination>.active>a:hover,
.pagination>.active>span,
.pagination>.active>span:focus,
.pagination>.active>span:hover,
a.list-group-item.active,
a.list-group-item.active:focus,
a.list-group-item.active:hover {
	background-color: #7bb7e5;
	border-color: #6eb0e2
}

.nav-pills>li.active>a .badge,
.nav-pills>li.active>a:focus .badge,
.nav-pills>li.active>a:hover .badge,
.pagination>.active>a .badge,
.pagination>.active>a:focus .badge,
.pagination>.active>a:hover .badge,
.pagination>.active>span .badge,
.pagination>.active>span:focus .badge,
.pagination>.active>span:hover .badge,
a.list-group-item.active .badge,
a.list-group-item.active:focus .badge,
a.list-group-item.active:hover .badge {
	background: #fff!important
}

.nav-pills,
.nav-tabs {
	margin-bottom: 10px
}

.nav-pills .open>a,
.nav-pills .open>a:focus,
.nav-pills .open>a:hover,
.nav-tabs .open>a,
.nav-tabs .open>a:focus,
.nav-tabs .open>a:hover {
	border-color: #7bb7e5
}

.nav-pills .open>a,
.nav-pills .open>a:focus,
.nav-pills .open>a:hover,
.nav-pills ul.dropdown-menu>li>a:focus,
.nav-pills ul.dropdown-menu>li>a:hover,
.nav-pills>li>a:focus,
.nav-pills>li>a:hover,
.nav-tabs .open>a,
.nav-tabs .open>a:focus,
.nav-tabs .open>a:hover,
.nav-tabs ul.dropdown-menu>li>a:focus,
.nav-tabs ul.dropdown-menu>li>a:hover,
.nav-tabs>li>a:focus,
.nav-tabs>li>a:hover {
	background-color: #f2f2f2
}

.pagination>li:first-child>a,
.pagination>li:first-child>span {
	border-bottom-left-radius: 0;
	border-top-left-radius: 0;
	color: #7bb7e5
}

.pagination>li:last-child>a,
.pagination>li:last-child>span {
	border-bottom-right-radius: 0;
	border-top-right-radius: 0;
	color: #7bb7e5
}

.pagination>li a,
.pagination>li a:hover,
.pagination>li span,
.pagination>li span:hover {
	color: #7bb7e5
}

.pagination>li.active,
.pagination>li.active span {
	color: #fff!important
}

.panel-group,
.progress,
.thumbnail,
dl {
	margin-bottom: 15px
}

.thumbnail {
	padding: 3px
}

.modal-content {
	border-radius: 0;
	border: 0
}

.modal-content .modal-header {
	background-color: #f2f2f2
}

.modal-content .modal-header .modal-title {
	font-size: 16px!important
}

.modal-content .modal-header .close {
	outline: 0!important
}

.modal-content .modal-footer {
	padding: 10px 15px;
	border-top-color: #e2e1e1;
	-webkit-box-shadow: none;
	box-shadow: none;
	background-color: #f2f2f2
}

.modal-open {
	overflow-y: auto!important
}

.modal-open-noscroll {
	overflow-y: hidden!important
}

.modal-open .bootstrap-timepicker-widget,
.modal-open .colorpicker,
.modal-open .datepicker,
.modal-open .daterangepicker,
.modal-open .datetimepicker,
.modal-open .select2-drop,
.modal-open .select2-drop-mask,
.modal-open .select2-search {
	z-index: 1141!important
}

a.list-group-item .list-group-item-heading {
	color: #393939
}

.panel>.list-group:last-child .list-group-item:last-child,
.panel>.panel-collapse>.list-group:last-child .list-group-item:last-child {
	border-bottom-right-radius: 0!important;
	border-bottom-left-radius: 0!important
}

.navbar-side {
	background: #222a2d;
	width: 210px;
	border: 1px solid #293236;
	border-width: 0 1px 0 0;
	z-index: 1027
}

.navbar-side .side-nav {
	border-radius: 0;
	border: none;
	float: none
}

.navbar-side .side-nav li {
	border-bottom: 1px solid #293236;
	float: none
}

.navbar-side .side-nav li>a {
	color: #bac2c8;
	background-color: #222a2d;
	outline: 0;
	padding: 10px;
	text-shadow: none!important;
	display: block;
	font-size: 14px
}

.navbar-side .side-nav li>a i {
	font-size: 16px
}

.navbar-side .side-nav li>a .mtext {
	margin-left: 4px
}

.navbar-side .side-nav li>a .fa,
.navbar-side .side-nav li>a .glyphicon {
	color: #bac2c8;
	font-size: 16px
}

.navbar-side .side-nav li>a .fa {
	min-width: 20px
}

.navbar-side .side-nav li>a .glyphicon {
	min-width: 21px
}

.navbar-side .side-nav li>a.active {
	border-color: #7bb7e5;
	color: #fff
}

.navbar-side .side-nav li>a.active .fa,
.navbar-side .side-nav li>a.active .glyphicon {
	color: #fff
}

.navbar-side .side-nav li>a.active:after {
	display: block;
	content: "";
	position: absolute!important;
	right: 0;
	top: 4px;
	border-color: transparent #7bb7e5 transparent transparent;
	border-width: 16px 10px;
	border-style: solid
}

.navbar-side .side-nav li>a.active:focus,
.navbar-side .side-nav li>a.active:hover {
	border-color: #7bb7e5
}

.navbar-side .side-nav li a:active,
.navbar-side .side-nav li a:focus,
.navbar-side .side-nav li a:hover,
.navbar-side .side-nav li>a {
	border-left: 3px solid transparent;
	border-right: 3px solid transparent
}

.navbar-side .side-nav li a:active,
.navbar-side .side-nav li>a:focus,
.navbar-side .side-nav li>a:hover {
	background: #2b3539
}

.navbar-side .side-nav > li {
	border-width: 0 0 1px;
	margin: 0;
	border-radius: 0;
	-webkit-box-shadow: none;
	box-shadow: none
}

.navbar-side .side-nav > li ul li {
	border-top: none;
	border-bottom: none;
	position: relative;
}

.navbar-side .side-nav > li ul li a {
	color: #bac2c8;
	background-color: transparent;
	padding: 10px 15px
}

.navbar-side .side-nav > li ul li a.active {
	color: #fff
}

.navbar-side .side-nav > li ul li a:before {
	content: "\f0da";
	margin-right: 4px;
	font-family: "Font Awesome 5 Free";
	font-style: normal;
	font-weight: 400
}

.navbar-side .side-nav > li ul li a:active,
.navbar-side .side-nav > li ul li a:focus,
.navbar-side .side-nav > li ul li a:hover {
	color: #fff;
	background-color: #242d30
}

.navbar-side .side-nav > li .show a {
	background-color: #1b2224
}

.navbar-side .side-nav > li .show a,
.navbar-side .side-nav > li .show a:active,
.navbar-side .side-nav > li .show a:focus,
.navbar-side .side-nav > li .show a:hover {
	border-right: 3px solid #7bb7e5;
	border-left: 3px solid transparent
}

.navbar-side .side-nav > li .show a:active,
.navbar-side .side-nav > li .show a:focus,
.navbar-side .side-nav > li .show a:hover {
	background: #2b3539
}

.navbar-side .side-nav > li .show .fa,
.navbar-side .side-nav > li .show .glyphicon {
	color: #fff
}

.navbar-side .side-nav > li .show li .fa,
.navbar-side .side-nav > li .show li .glyphicon {
	color: #bac2c8
}

.navbar-side .side-nav .arrow {
	float: right;
	text-align: center;
	margin-top: 3px
}

.navbar-side .side-nav .fa.arrow:before {
	content: "\f104"
}


.navbar-side .side-nav > li a[aria-expanded="true"]>.fa.arrow:before {
	content: "\f107"
}

.navbar-side .side-nav .badge,
.navbar-side .side-nav .label {
	position: absolute;
	top: 11px;
	right: 15px;
	background-color: #d15b47 !important;
	color: #fff;
}

.navbar-side .side-nav a.accordion-toggle .badge,
.navbar-side .side-nav a.accordion-toggle .label {
	right: 30px
}

.navbar-side .side-nav .nav {
	background: #1b2224
}

.navbar-side .side-nav .nav>li>a {
	padding: 10px 7.5px
}

.navbar-side .side-nav .nav .nav-divider {
	display: none
}

.navbar-side h4,
.navbar-side>h4 {
	text-transform: uppercase;
	padding: 10px;
	font-size: 14px;
	margin: 10px 0 0;
	color: #fff
}

.navbar-side h4:after,
.navbar-side>h4:after {
	content: "\f103";
	margin-left: 4px;
	font-family: FontAwesome;
	font-style: normal;
	font-weight: 400;
	color: #7bb7e5
}

.navbar-side .media {
	padding: 1px 0 2px
}

.navbar-side .media .sidebar-shortcuts {
	display: block;
	padding: 10px;
	list-style: none;
	margin-bottom: 0;
	width: 100%;
}

.navbar-side .media .sidebar-shortcuts li {
	display: inline-block;
	position: relative
}

.navbar-side .media .sidebar-shortcuts li .btn {
	padding: 5px 10px;
	border-radius: 3px;
	line-height: 1.5;
	transition: 0.3s;
}

.navbar-side .media .sidebar-shortcuts li .btn:hover {
	opacity: 0.8;
}

.navbar-side .media .sidebar-shortcuts li .badge {
	position: absolute;
	top: -7px;
	right: -1px;
	padding: 3px 5px;
	font-size: 10px;
	border: 0
}

.navbar-side .media .sidebar-shortcuts a .fa {
	color: #fff;
	font-size: 14px
}

.navbar-side .sidebar-labels ul {
	padding-left: 20px
}

.navbar-side .sidebar-labels ul li {
	list-style: none;
	margin-top: 10px
}

.navbar-side .sidebar-labels ul li a {
	color: #fff;
	font-size: 13px
}

.navbar-side .sidebar-labels ul li a:focus,
.navbar-side .sidebar-labels ul li a:hover {
	text-decoration: none
}

.navbar-side .sidebar-labels ul li a .fa,
.navbar-side .sidebar-labels ul li a .glyphicon {
	color: #6e7e8a
}

.navbar-side .sidebar-labels ul li a .badge,
.navbar-side .sidebar-labels ul li a .label {
	float: right;
	margin-right: 15px
}

.navbar-side .sidebar-alerts .alert {
	padding: 15px 15px 15px 10px;
	margin: 0
}

.navbar-side .sidebar-alerts .alert .close {
	text-shadow: none;
	color: #bac2c8;
	opacity: .5;
	filter: alpha(opacity=50)
}

.navbar-side .sidebar-alerts .alert small,
.navbar-side .sidebar-alerts .alert span {
	color: #bac2c8
}

.navbar-side .media,
.navbar-side .media-search,
.navbar-side .sidebar-labels {
	border-bottom: 1px solid #293236
}

.navbar-side.no-sidebar,
.navbar-side.no-sidebar:focus,
.navbar-side.no-sidebar:hover {
	display: none
}


@media (min-width:992px) {
	.navbar-side {
		position: absolute;
		border-radius: 0;
		bottom: 0;
		top: 50px
	}
	.navbar-side.fixed {
		position: fixed;
		-webkit-transform: translateZ(0)
	}
}

@media (max-width:991px) {
	.navbar-side {
		position: fixed;
		margin: 0;
		-moz-transform: translateX(-220px);
		-webkit-transform: translateX(-220px);
		-o-transform: translateX(-220px);
		-ms-transform: translateX(-220px);
		transform: translateX(-220px);
		-webkit-transition: -webkit-transform .2s linear 0s, max-height 0s linear .2s;
		-moz-transition: -moz-transform .2s linear 0s, max-height 0s linear .2s;
		-o-transition: -o-transform .2s linear 0s, max-height 0s linear .2s;
		transition: transform .2s linear 0s, max-height 0s linear .2s
	}
	.navbar-side.display {
		-moz-transform: none!important;
		-webkit-transform: none!important;
		-o-transform: none!important;
		-ms-transform: none!important;
		transform: none!important
	}
	.navbar-side .navbar-nav {
		margin: 0
	}
	.navbar-side.sidebar-light {
		-webkit-box-shadow: 2px 1px 2px 0 rgba(0, 0, 0, .15);
		box-shadow: 2px 1px 2px 0 rgba(0, 0, 0, .15)
	}
}

@media (min-width:992px) {
	.navbar-top.fixed {
		position: fixed;
		-webkit-transform: translateZ(0)
	}
}

.space {
	max-height: 1px;
	min-height: 1px;
	overflow: hidden;
	margin: 12px 0
}

.space-32 {
	max-height: 1px;
	min-height: 1px;
	overflow: hidden;
	margin: 32px 0 31px
}

.space-30 {
	max-height: 1px;
	min-height: 1px;
	overflow: hidden;
	margin: 30px 0 29px
}

.space-28 {
	max-height: 1px;
	min-height: 1px;
	overflow: hidden;
	margin: 28px 0 27px
}

.space-26 {
	max-height: 1px;
	min-height: 1px;
	overflow: hidden;
	margin: 26px 0 25px
}

.space-24 {
	max-height: 1px;
	min-height: 1px;
	overflow: hidden;
	margin: 24px 0 23px
}

.space-22 {
	max-height: 1px;
	min-height: 1px;
	overflow: hidden;
	margin: 22px 0 21px
}

.space-20 {
	max-height: 1px;
	min-height: 1px;
	overflow: hidden;
	margin: 20px 0 19px
}

.space-18 {
	max-height: 1px;
	min-height: 1px;
	overflow: hidden;
	margin: 18px 0 17px
}

.space-16 {
	max-height: 1px;
	min-height: 1px;
	overflow: hidden;
	margin: 16px 0 15px
}

.space-14 {
	max-height: 1px;
	min-height: 1px;
	overflow: hidden;
	margin: 14px 0 13px
}

.space-12 {
	max-height: 1px;
	min-height: 1px;
	overflow: hidden;
	margin: 12px 0 11px
}

.space-10 {
	max-height: 1px;
	min-height: 1px;
	overflow: hidden;
	margin: 10px 0 9px
}

.space-8 {
	max-height: 1px;
	min-height: 1px;
	overflow: hidden;
	margin: 8px 0 7px
}

.space-6 {
	max-height: 1px;
	min-height: 1px;
	overflow: hidden;
	margin: 6px 0 5px
}

.space-4 {
	max-height: 1px;
	min-height: 1px;
	overflow: hidden;
	margin: 4px 0 3px
}

.space-2 {
	max-height: 1px;
	min-height: 1px;
	overflow: hidden;
	margin: 2px 0 1px
}

.hr {
	display: block;
	height: 0;
	overflow: hidden;
	font-size: 0;
	border-top: 1px solid #e2e1e1;
	margin: 12px 0
}

.hr-double {
	height: 3px;
	border-top: 1px solid #e2e1e1;
	border-bottom: 1px solid #e2e1e1
}

.hr-dotted,
.hr.dotted {
	border-top-style: dotted
}

.hr-double.dotted {
	border-bottom-style: dotted
}

.hr-32,
.hr32 {
	margin: 32px 0
}

.hr-30,
.hr30 {
	margin: 30px 0
}

.hr-28,
.hr28 {
	margin: 28px 0
}

.hr-26,
.hr26 {
	margin: 26px 0
}

.hr-24,
.hr24 {
	margin: 24px 0
}

.hr-22,
.hr22 {
	margin: 22px 0
}

.hr-20,
.hr20 {
	margin: 20px 0
}

.hr-18,
.hr18 {
	margin: 18px 0
}

.hr-16,
.hr16 {
	margin: 16px 0
}

.hr-14,
.hr14 {
	margin: 14px 0
}

.hr-12,
.hr12 {
	margin: 12px 0
}

.hr-10,
.hr10 {
	margin: 10px 0
}

.hr-8,
.hr8 {
	margin: 8px 0
}

.hr-6,
.hr6 {
	margin: 6px 0
}

.hr-4,
.hr4 {
	margin: 4px 0
}

.hr-2,
.hr2 {
	margin: 2px 0
}

hr.separator {
	clear: both;
	margin-top: 10px;
	margin-bottom: 13px;
	border: 0;
	height: 1px;
	background-image: -webkit-linear-gradient(left, rgba(0, 0, 0, 0), rgba(0, 0, 0, .15), rgba(0, 0, 0, 0));
	background-image: -moz-linear-gradient(left, rgba(0, 0, 0, 0), rgba(0, 0, 0, .15), rgba(0, 0, 0, 0));
	background-image: -ms-linear-gradient(left, rgba(0, 0, 0, 0), rgba(0, 0, 0, .15), rgba(0, 0, 0, 0));
	background-image: -o-linear-gradient(left, rgba(0, 0, 0, 0), rgba(0, 0, 0, .15), rgba(0, 0, 0, 0))
}

.bigger-110 {
	font-size: 110%
}

.bigger-120 {
	font-size: 120%
}

.bigger-130 {
	font-size: 130%
}

.bigger-140 {
	font-size: 140%
}

.bigger-150 {
	font-size: 150%
}

.bigger-160 {
	font-size: 160%
}

.bigger-170 {
	font-size: 170%
}

.bigger-180 {
	font-size: 180%
}

.bigger-190 {
	font-size: 190%
}

.bigger-200 {
	font-size: 200%
}

.bigger-210 {
	font-size: 210%
}

.bigger-220 {
	font-size: 220%
}

.bigger-230 {
	font-size: 230%
}

.bigger-240 {
	font-size: 240%
}

.bigger-250 {
	font-size: 250%
}

.bigger-260 {
	font-size: 260%
}

.bigger-270 {
	font-size: 270%
}

.bigger-280 {
	font-size: 280%
}

.bigger-290 {
	font-size: 290%
}

.bigger-300 {
	font-size: 300%
}

.bigger-125 {
	font-size: 125%
}

.bigger-175 {
	font-size: 175%
}

.bigger-225 {
	font-size: 225%
}

.bigger-275 {
	font-size: 275%
}

.bigger-500 {
	font-size: 50px;
	line-height: 50px
}

.smaller-90 {
	font-size: 90%
}

.smaller-80 {
	font-size: 80%
}

.smaller-70 {
	font-size: 70%
}

.smaller-60 {
	font-size: 60%
}

.smaller-50 {
	font-size: 50%
}

.smaller-40 {
	font-size: 40%
}

.smaller-30 {
	font-size: 30%
}

.smaller-20 {
	font-size: 20%
}

.smaller-75 {
	font-size: 75%
}

.action-buttons a {
	margin: 0 3px;
	display: inline-block;
	opacity: .85;
	-webkit-transition: all .1s;
	-o-transition: all .1s;
	transition: all .1s
}

.action-buttons a:hover {
	text-decoration: none;
	-moz-transform: scale(1.2);
	-webkit-transform: scale(1.2);
	-o-transform: scale(1.2);
	-ms-transform: scale(1.2);
	transform: scale(1.2);
	opacity: 1;
	filter: alpha(opacity=100)
}

.lighter {
	font-weight: 300!important
}

.bolder {
	font-weight: bolder!important
}

.bold {
	font-weight: 700!important
}

.text-underline {
	text-decoration: underline
}

.heading {
	padding: 0;
	margin: 0;
	height: 25px;
	line-height: 25px
}

.portlet .portlet-body p:last-child,
.well p:last-child {
	margin: 0
}

.no-padding {
	padding: 0!important
}

.no-padding-bottom {
	padding-bottom: 0!important
}

.no-padding-top {
	padding-top: 0!important
}

.no-padding-left {
	padding-left: 0!important
}

.no-padding-right {
	padding-right: 0!important
}

.no-margin {
	margin: 0!important
}

.no-margin-bottom {
	margin-bottom: 0!important
}

.no-margin-top {
	margin-top: 0!important
}

.no-margin-left {
	margin-left: 0!important
}

.no-margin-right {
	margin-right: 0!important
}

.no-border {
	border: none!important
}

.no-border-bottom {
	border-bottom: none!important
}

.no-border-top {
	border-top: none!important
}

.no-border-left {
	border-left: none!important
}

.no-border-right {
	border-right: none!important
}

.border-top {
	border-top: 1px solid #e2e1e1
}

.border-right {
	border-right: 1px solid #e2e1e1
}

.border-bottom {
	border-bottom: 1px solid #e2e1e1
}

.border-left {
	border-left: 1px solid #e2e1e1
}

.position-relative {
	position: relative
}

.position-absolute {
	position: absolute
}

.inline {
	display: inline-block!important
}

.block {
	display: block!important
}

.middle {
	vertical-align: middle
}

.align-middle {
	vertical-align: middle!important
}

.align-top {
	vertical-align: top!important
}

.align-bottom {
	vertical-align: bottom!important
}

.space-2x .alert,
.space-2x .note,
.space-2x .notice,
.space-2x .panel,
.space-2x .portlet,
.space-2x .pricing-table-1,
.space-2x .pricing-table-2,
.space-2x .pricing-table-3,
.space-2x .pricing-table-4,
.space-2x .progress,
.space-2x .tc-tabs,
.space-2x .tc-tabsbar,
.space-2x .tile-button,
.space-2x .well,
.space-2x dl {
	margin-bottom: 20px
}

.space-2x .portlet .portlet-body,
.space-2x .well {
	padding: 25px
}

.space-2x .portlet .portlet-body .well,
.space-2x .well .well {
	padding: 15px
}

.padding-25 {
	padding: 25px!important
}

.padding-2x {
	padding: 30px!important
}

.padding-all {
	padding: 15px
}

.toggle {
	cursor: pointer
}

.bg-danger,
.bg-gray,
.bg-info,
.bg-primary,
.bg-success,
.bg-warning,
.dark,
.gray,
.inverse {
	color: #fff!important
}

.bg-danger.alert .close,
.bg-danger.note .close,
.bg-gray.alert .close,
.bg-gray.note .close,
.bg-info.alert .close,
.bg-info.note .close,
.bg-primary.alert .close,
.bg-primary.note .close,
.bg-success.alert .close,
.bg-success.note .close,
.bg-warning.alert .close,
.bg-warning.note .close,
.dark.alert .close,
.dark.note .close,
.gray.alert .close,
.gray.note .close,
.inverse.alert .close,
.inverse.note .close {
	opacity: 1;
	filter: alpha(opacity=100);
	color: #fff
}

.bg-primary {
	background-color: #428bca!important;
	border-color: #428bca!important
}

.text-primary {
	color: #428bca!important
}

.bg-success {
	background-color: #82af6f!important;
	border-color: #82af6f!important
}

.text-success {
	color: #82af6f!important
}

.bg-warning {
	background-color: #ffb752!important;
	border-color: #ffb752!important
}

.text-warning {
	color: #ffb752!important
}

.bg-danger {
	background-color: #d15b47!important;
	border-color: #d15b47!important
}

.text-danger {
	color: #d15b47!important
}

.bg-info {
	background-color: #3a87ad!important;
	border-color: #3a87ad!important
}

.text-info {
	color: #3a87ad!important
}

.dark,
.inverse {
	background-color: #2b3539!important;
	border-color: #2b3539!important
}

.text-gray {
	color: #999!important
}

.bg-gray {
	background-color: #555!important
}

.white {
	background-color: #fff!important
}

.text-white {
	color: #fff!important
}

.light {
	background-color: #f2f2f2!important;
	border-color: #f2f2f2!important;
	color: #555!important
}

.theme-options {
	position: fixed;
	right: 0;
	top: auto;
	z-index: 1023;
	margin-top: -75px
}

.theme-options .btn.theme-setting-btn {
	float: left;
	display: inline-block;
	width: 42px!important;
	background: rgba(0, 0, 0, .3)!important;
	border-right: 0 none;
	vertical-align: top;
	margin: 0
}

.theme-options .btn.theme-setting-btn:focus,
.theme-options .btn.theme-setting-btn:hover {
	background: rgba(0, 0, 0, .5)!important
}

.theme-options .theme-setting-box {
	display: none;
	float: left;
	width: 210px;
	padding: 10px 14px;
	background: #fff;
	border: 1px solid #555;
	border-right: 0
}

.theme-options .theme-setting-box.open {
	display: inline-block
}

>.theme-options .theme-setting-boxdiv {
	margin: 6px 0;
	color: #2b3539;
	max-height: 24px
}

>.theme-options .theme-setting-boxdiv>label {
	font-size: 13px
}

.theme-options .theme-setting-box .btn {
	height: 15px;
	width: 15px;
	padding: 4px;
	border: 0
}

.theme-options .theme-setting-box .btn:focus,
.theme-options .theme-setting-box .btn:hover {
	-moz-transform: scale(1.1);
	-webkit-transform: scale(1.1);
	-o-transform: scale(1.1);
	-ms-transform: scale(1.1);
	transform: scale(1.1);
	opacity: .5;
	filter: alpha(opacity=50)
}

.theme-options .theme-setting-box ul {
	padding-left: 0
}

.theme-options .theme-setting-box ul li {
	display: inline-block;
	list-style: none
}

.theme-options.front {
	top: 125px;
	margin-top: 0
}

.btn {
	outline: 0!important
}

.btn .icon-only[class*=glyphicon-],
.btn>.icon-only[class*=fa-] {
	margin: 0;
	padding: 0
}

.btn [class*=glyphicon-],
.btn>[class*=fa-] {
	display: inline;
	margin-right: 4px
}

.btn [class*=glyphicon-].icon-on-right,
.btn>[class*=fa-].icon-on-right {
	margin-right: 0;
	margin-left: 4px
}

.btn.btn-app {
	position: relative;
	padding: 15px 5px;
	margin: 0 7.5px 15px;
	min-width: 80px;
	height: 60px;
	text-align: center;
	font-size: 12px
}

.btn.btn-app>.fa,
.btn.btn-app>.glyphicon,
.btn.btn-app>.ion {
	font-size: 20px;
	display: block;
	margin: 0
}

.btn.btn-app>.badge {
	position: absolute;
	top: -3px;
	right: -10px;
	font-size: 10px;
	font-weight: 400;
	color: #fff
}

.btn.btn-app.big {
	min-width: 110px;
	height: 90px;
	padding: 25px 15px;
	font-size: 14px
}

.btn.btn-app.big>.fa,
.btn.btn-app.big>.glyphicon,
.btn.btn-app.big>.ion {
	font-size: 30px;
	display: block
}

.btn.active,
.btn:active {
	-webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
	box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125)
}

.btn.btn-rounded {
	border-radius: 4px
}

.btn,
.btn-default {
	color: #fff;
	background-color: #abbac3;
	border-color: #abbac3
}

.btn-default.active.focus,
.btn-default.active:focus,
.btn-default.active:hover,
.btn-default:active.focus,
.btn-default:active:focus,
.btn-default:active:hover,
.btn.active.focus,
.btn.active:focus,
.btn.active:hover,
.btn:active.focus,
.btn:active:focus,
.btn:active:hover,
.open>.dropdown-toggle.btn-default.focus,
.open>.dropdown-toggle.btn-default:focus,
.open>.dropdown-toggle.btn-default:hover,
.open>.dropdown-toggle.btn.focus,
.open>.dropdown-toggle.btn:focus,
.open>.dropdown-toggle.btn:hover {
	color: #fff;
	background-color: #78909f;
	border-color: #637c8b
}

.btn-default.active,
.btn-default:active,
.btn.active,
.btn:active,
.open>.dropdown-toggle.btn,
.open>.dropdown-toggle.btn-default {
	background-image: none
}

.btn-default.disabled,
.btn-default.disabled.active,
.btn-default.disabled.focus,
.btn-default.disabled:active,
.btn-default.disabled:focus,
.btn-default.disabled:hover,
.btn-default[disabled],
.btn-default[disabled].active,
.btn-default[disabled].focus,
.btn-default[disabled]:active,
.btn-default[disabled]:focus,
.btn-default[disabled]:hover,
.btn.disabled,
.btn.disabled.active,
.btn.disabled.focus,
.btn.disabled:active,
.btn.disabled:focus,
.btn.disabled:hover,
.btn[disabled],
.btn[disabled].active,
.btn[disabled].focus,
.btn[disabled]:active,
.btn[disabled]:focus,
.btn[disabled]:hover,
fieldset[disabled] .btn,
fieldset[disabled] .btn-default,
fieldset[disabled] .btn-default.active,
fieldset[disabled] .btn-default.focus,
fieldset[disabled] .btn-default:active,
fieldset[disabled] .btn-default:focus,
fieldset[disabled] .btn-default:hover,
fieldset[disabled] .btn.active,
fieldset[disabled] .btn.focus,
fieldset[disabled] .btn:active,
fieldset[disabled] .btn:focus,
fieldset[disabled] .btn:hover {
	background-color: #abbac3;
	border-color: #abbac3
}

.btn .badge,
.btn-default .badge {
	color: #abbac3;
	background-color: #fff
}

.btn-default.active,
.btn-default.focus,
.btn-default:active,
.btn-default:focus,
.btn-default:hover,
.btn.active,
.btn.focus,
.btn:active,
.btn:focus,
.btn:hover,
.open>.dropdown-toggle.btn,
.open>.dropdown-toggle.btn-default {
	color: #fff;
	background-color: #abbac3;
	border-color: #abbac3
}

.btn-default.btn-app,
.btn.btn-app {
	color: #393939;
	background-color: #f2f2f2;
	border-color: #e2e1e1
}

.btn-default.btn-app.active.focus,
.btn-default.btn-app.active:focus,
.btn-default.btn-app.active:hover,
.btn-default.btn-app:active.focus,
.btn-default.btn-app:active:focus,
.btn-default.btn-app:active:hover,
.btn.btn-app.active.focus,
.btn.btn-app.active:focus,
.btn.btn-app.active:hover,
.btn.btn-app:active.focus,
.btn.btn-app:active:focus,
.btn.btn-app:active:hover,
.open>.dropdown-toggle.btn-default.btn-app.focus,
.open>.dropdown-toggle.btn-default.btn-app:focus,
.open>.dropdown-toggle.btn-default.btn-app:hover,
.open>.dropdown-toggle.btn.btn-app.focus,
.open>.dropdown-toggle.btn.btn-app:focus,
.open>.dropdown-toggle.btn.btn-app:hover {
	color: #393939;
	background-color: #c7c7c7;
	border-color: #a3a0a0
}

.btn-default.btn-app.active,
.btn-default.btn-app:active,
.btn.btn-app.active,
.btn.btn-app:active,
.open>.dropdown-toggle.btn-default.btn-app,
.open>.dropdown-toggle.btn.btn-app {
	background-image: none
}

.btn-default.btn-app.disabled,
.btn-default.btn-app.disabled.active,
.btn-default.btn-app.disabled.focus,
.btn-default.btn-app.disabled:active,
.btn-default.btn-app.disabled:focus,
.btn-default.btn-app.disabled:hover,
.btn-default.btn-app[disabled],
.btn-default.btn-app[disabled].active,
.btn-default.btn-app[disabled].focus,
.btn-default.btn-app[disabled]:active,
.btn-default.btn-app[disabled]:focus,
.btn-default.btn-app[disabled]:hover,
.btn.btn-app.disabled,
.btn.btn-app.disabled.active,
.btn.btn-app.disabled.focus,
.btn.btn-app.disabled:active,
.btn.btn-app.disabled:focus,
.btn.btn-app.disabled:hover,
.btn.btn-app[disabled],
.btn.btn-app[disabled].active,
.btn.btn-app[disabled].focus,
.btn.btn-app[disabled]:active,
.btn.btn-app[disabled]:focus,
.btn.btn-app[disabled]:hover,
fieldset[disabled] .btn-default.btn-app,
fieldset[disabled] .btn-default.btn-app.active,
fieldset[disabled] .btn-default.btn-app.focus,
fieldset[disabled] .btn-default.btn-app:active,
fieldset[disabled] .btn-default.btn-app:focus,
fieldset[disabled] .btn-default.btn-app:hover,
fieldset[disabled] .btn.btn-app,
fieldset[disabled] .btn.btn-app.active,
fieldset[disabled] .btn.btn-app.focus,
fieldset[disabled] .btn.btn-app:active,
fieldset[disabled] .btn.btn-app:focus,
fieldset[disabled] .btn.btn-app:hover {
	background-color: #f2f2f2;
	border-color: #e2e1e1
}

.btn-default.btn-app .badge,
.btn.btn-app .badge {
	color: #f2f2f2;
	background-color: #393939
}

.btn-default.btn-app.active,
.btn-default.btn-app.focus,
.btn-default.btn-app:active,
.btn-default.btn-app:focus,
.btn-default.btn-app:hover,
.btn.btn-app.active,
.btn.btn-app.focus,
.btn.btn-app:active,
.btn.btn-app:focus,
.btn.btn-app:hover,
.open>.dropdown-toggle.btn-default.btn-app,
.open>.dropdown-toggle.btn.btn-app {
	color: #393939;
	background-color: #f2f2f2;
	border-color: #e2e1e1
}

.btn-primary {
	color: #fff;
	background-color: #428bca;
	border-color: #357ebd
}

.btn-primary.active.focus,
.btn-primary.active:focus,
.btn-primary.active:hover,
.btn-primary:active.focus,
.btn-primary:active:focus,
.btn-primary:active:hover,
.open>.dropdown-toggle.btn-primary.focus,
.open>.dropdown-toggle.btn-primary:focus,
.open>.dropdown-toggle.btn-primary:hover {
	color: #fff;
	background-color: #285e8e;
	border-color: #193c5a
}

.btn-primary.active,
.btn-primary:active,
.open>.dropdown-toggle.btn-primary {
	background-image: none
}

.btn-primary.disabled,
.btn-primary.disabled.active,
.btn-primary.disabled.focus,
.btn-primary.disabled:active,
.btn-primary.disabled:focus,
.btn-primary.disabled:hover,
.btn-primary[disabled],
.btn-primary[disabled].active,
.btn-primary[disabled].focus,
.btn-primary[disabled]:active,
.btn-primary[disabled]:focus,
.btn-primary[disabled]:hover,
fieldset[disabled] .btn-primary,
fieldset[disabled] .btn-primary.active,
fieldset[disabled] .btn-primary.focus,
fieldset[disabled] .btn-primary:active,
fieldset[disabled] .btn-primary:focus,
fieldset[disabled] .btn-primary:hover {
	background-color: #428bca;
	border-color: #357ebd
}

.btn-primary .badge {
	color: #428bca;
	background-color: #fff
}

.btn-primary.active,
.btn-primary.focus,
.btn-primary:active,
.btn-primary:focus,
.btn-primary:hover,
.open>.dropdown-toggle.btn-primary {
	color: #fff;
	background-color: #428bca;
	border-color: #357ebd
}

.btn-success {
	color: #fff;
	background-color: #82af6f;
	border-color: #74a65f
}

.btn-success.active.focus,
.btn-success.active:focus,
.btn-success.active:hover,
.btn-success:active.focus,
.btn-success:active:focus,
.btn-success:active:hover,
.open>.dropdown-toggle.btn-success.focus,
.open>.dropdown-toggle.btn-success:focus,
.open>.dropdown-toggle.btn-success:hover {
	color: #fff;
	background-color: #588047;
	border-color: #3b5530
}

.btn-success.active,
.btn-success:active,
.open>.dropdown-toggle.btn-success {
	background-image: none
}

.btn-success.disabled,
.btn-success.disabled.active,
.btn-success.disabled.focus,
.btn-success.disabled:active,
.btn-success.disabled:focus,
.btn-success.disabled:hover,
.btn-success[disabled],
.btn-success[disabled].active,
.btn-success[disabled].focus,
.btn-success[disabled]:active,
.btn-success[disabled]:focus,
.btn-success[disabled]:hover,
fieldset[disabled] .btn-success,
fieldset[disabled] .btn-success.active,
fieldset[disabled] .btn-success.focus,
fieldset[disabled] .btn-success:active,
fieldset[disabled] .btn-success:focus,
fieldset[disabled] .btn-success:hover {
	background-color: #82af6f;
	border-color: #74a65f
}

.btn-success .badge {
	color: #82af6f;
	background-color: #fff
}

.btn-success.active,
.btn-success.focus,
.btn-success:active,
.btn-success:focus,
.btn-success:hover,
.open>.dropdown-toggle.btn-success {
	color: #fff;
	background-color: #82af6f;
	border-color: #74a65f
}

.btn-info {
	color: #fff;
	background-color: #3a87ad;
	border-color: #34789a
}

.btn-info.active.focus,
.btn-info.active:focus,
.btn-info.active:hover,
.btn-info:active.focus,
.btn-info:active:focus,
.btn-info:active:hover,
.open>.dropdown-toggle.btn-info.focus,
.open>.dropdown-toggle.btn-info:focus,
.open>.dropdown-toggle.btn-info:hover {
	color: #fff;
	background-color: #24546c;
	border-color: #142e3a
}

.btn-info.active,
.btn-info:active,
.open>.dropdown-toggle.btn-info {
	background-image: none
}

.btn-info.disabled,
.btn-info.disabled.active,
.btn-info.disabled.focus,
.btn-info.disabled:active,
.btn-info.disabled:focus,
.btn-info.disabled:hover,
.btn-info[disabled],
.btn-info[disabled].active,
.btn-info[disabled].focus,
.btn-info[disabled]:active,
.btn-info[disabled]:focus,
.btn-info[disabled]:hover,
fieldset[disabled] .btn-info,
fieldset[disabled] .btn-info.active,
fieldset[disabled] .btn-info.focus,
fieldset[disabled] .btn-info:active,
fieldset[disabled] .btn-info:focus,
fieldset[disabled] .btn-info:hover {
	background-color: #3a87ad;
	border-color: #34789a
}

.btn-info .badge {
	color: #3a87ad;
	background-color: #fff
}

.btn-info.active,
.btn-info.focus,
.btn-info:active,
.btn-info:focus,
.btn-info:hover,
.open>.dropdown-toggle.btn-info {
	color: #fff;
	background-color: #3a87ad;
	border-color: #34789a
}

.btn-warning {
	color: #fff;
	background-color: #ffb752;
	border-color: #ffac38
}

.btn-warning.active.focus,
.btn-warning.active:focus,
.btn-warning.active:hover,
.btn-warning:active.focus,
.btn-warning:active:focus,
.btn-warning:active:hover,
.open>.dropdown-toggle.btn-warning.focus,
.open>.dropdown-toggle.btn-warning:focus,
.open>.dropdown-toggle.btn-warning:hover {
	color: #fff;
	background-color: #fa9200;
	border-color: #b86b00
}

.btn-warning.active,
.btn-warning:active,
.open>.dropdown-toggle.btn-warning {
	background-image: none
}

.btn-warning.disabled,
.btn-warning.disabled.active,
.btn-warning.disabled.focus,
.btn-warning.disabled:active,
.btn-warning.disabled:focus,
.btn-warning.disabled:hover,
.btn-warning[disabled],
.btn-warning[disabled].active,
.btn-warning[disabled].focus,
.btn-warning[disabled]:active,
.btn-warning[disabled]:focus,
.btn-warning[disabled]:hover,
fieldset[disabled] .btn-warning,
fieldset[disabled] .btn-warning.active,
fieldset[disabled] .btn-warning.focus,
fieldset[disabled] .btn-warning:active,
fieldset[disabled] .btn-warning:focus,
fieldset[disabled] .btn-warning:hover {
	background-color: #ffb752;
	border-color: #ffac38
}

.btn-warning .badge {
	color: #ffb752;
	background-color: #fff
}

.btn-warning.active,
.btn-warning.focus,
.btn-warning:active,
.btn-warning:focus,
.btn-warning:hover,
.open>.dropdown-toggle.btn-warning {
	color: #fff;
	background-color: #ffb752;
	border-color: #ffac38
}

.btn-danger {
	color: #fff;
	background-color: #d15b47;
	border-color: #cc4933
}

.btn-danger.active.focus,
.btn-danger.active:focus,
.btn-danger.active:hover,
.btn-danger:active.focus,
.btn-danger:active:focus,
.btn-danger:active:hover,
.open>.dropdown-toggle.btn-danger.focus,
.open>.dropdown-toggle.btn-danger:focus,
.open>.dropdown-toggle.btn-danger:hover {
	color: #fff;
	background-color: #9b3727;
	border-color: #662419
}

.btn-danger.active,
.btn-danger:active,
.open>.dropdown-toggle.btn-danger {
	background-image: none
}

.btn-danger.disabled,
.btn-danger.disabled.active,
.btn-danger.disabled.focus,
.btn-danger.disabled:active,
.btn-danger.disabled:focus,
.btn-danger.disabled:hover,
.btn-danger[disabled],
.btn-danger[disabled].active,
.btn-danger[disabled].focus,
.btn-danger[disabled]:active,
.btn-danger[disabled]:focus,
.btn-danger[disabled]:hover,
fieldset[disabled] .btn-danger,
fieldset[disabled] .btn-danger.active,
fieldset[disabled] .btn-danger.focus,
fieldset[disabled] .btn-danger:active,
fieldset[disabled] .btn-danger:focus,
fieldset[disabled] .btn-danger:hover {
	background-color: #d15b47;
	border-color: #cc4933
}

.btn-danger .badge {
	color: #d15b47;
	background-color: #fff
}

.btn-danger.active,
.btn-danger.focus,
.btn-danger:active,
.btn-danger:focus,
.btn-danger:hover,
.open>.dropdown-toggle.btn-danger {
	color: #fff;
	background-color: #d15b47;
	border-color: #cc4933
}

.btn-dark,
.btn-gray,
.btn-inverse {
	color: #fff;
	background-color: #222;
	border-color: #161616
}

.btn-dark.active.focus,
.btn-dark.active:focus,
.btn-dark.active:hover,
.btn-dark:active.focus,
.btn-dark:active:focus,
.btn-dark:active:hover,
.btn-gray.active.focus,
.btn-gray.active:focus,
.btn-gray.active:hover,
.btn-gray:active.focus,
.btn-gray:active:focus,
.btn-gray:active:hover,
.btn-inverse.active.focus,
.btn-inverse.active:focus,
.btn-inverse.active:hover,
.btn-inverse:active.focus,
.btn-inverse:active:focus,
.btn-inverse:active:hover,
.open>.dropdown-toggle.btn-dark.focus,
.open>.dropdown-toggle.btn-dark:focus,
.open>.dropdown-toggle.btn-dark:hover,
.open>.dropdown-toggle.btn-gray.focus,
.open>.dropdown-toggle.btn-gray:focus,
.open>.dropdown-toggle.btn-gray:hover,
.open>.dropdown-toggle.btn-inverse.focus,
.open>.dropdown-toggle.btn-inverse:focus,
.open>.dropdown-toggle.btn-inverse:hover {
	color: #fff;
	background-color: #000;
	border-color: #000
}

.btn-dark.active,
.btn-dark:active,
.btn-gray.active,
.btn-gray:active,
.btn-inverse.active,
.btn-inverse:active,
.open>.dropdown-toggle.btn-dark,
.open>.dropdown-toggle.btn-gray,
.open>.dropdown-toggle.btn-inverse {
	background-image: none
}

.btn-dark.disabled,
.btn-dark.disabled.active,
.btn-dark.disabled.focus,
.btn-dark.disabled:active,
.btn-dark.disabled:focus,
.btn-dark.disabled:hover,
.btn-dark[disabled],
.btn-dark[disabled].active,
.btn-dark[disabled].focus,
.btn-dark[disabled]:active,
.btn-dark[disabled]:focus,
.btn-dark[disabled]:hover,
.btn-gray.disabled,
.btn-gray.disabled.active,
.btn-gray.disabled.focus,
.btn-gray.disabled:active,
.btn-gray.disabled:focus,
.btn-gray.disabled:hover,
.btn-gray[disabled],
.btn-gray[disabled].active,
.btn-gray[disabled].focus,
.btn-gray[disabled]:active,
.btn-gray[disabled]:focus,
.btn-gray[disabled]:hover,
.btn-inverse.disabled,
.btn-inverse.disabled.active,
.btn-inverse.disabled.focus,
.btn-inverse.disabled:active,
.btn-inverse.disabled:focus,
.btn-inverse.disabled:hover,
.btn-inverse[disabled],
.btn-inverse[disabled].active,
.btn-inverse[disabled].focus,
.btn-inverse[disabled]:active,
.btn-inverse[disabled]:focus,
.btn-inverse[disabled]:hover,
fieldset[disabled] .btn-dark,
fieldset[disabled] .btn-dark.active,
fieldset[disabled] .btn-dark.focus,
fieldset[disabled] .btn-dark:active,
fieldset[disabled] .btn-dark:focus,
fieldset[disabled] .btn-dark:hover,
fieldset[disabled] .btn-gray,
fieldset[disabled] .btn-gray.active,
fieldset[disabled] .btn-gray.focus,
fieldset[disabled] .btn-gray:active,
fieldset[disabled] .btn-gray:focus,
fieldset[disabled] .btn-gray:hover,
fieldset[disabled] .btn-inverse,
fieldset[disabled] .btn-inverse.active,
fieldset[disabled] .btn-inverse.focus,
fieldset[disabled] .btn-inverse:active,
fieldset[disabled] .btn-inverse:focus,
fieldset[disabled] .btn-inverse:hover {
	background-color: #222;
	border-color: #161616
}

.btn-dark .badge,
.btn-gray .badge,
.btn-inverse .badge {
	color: #222;
	background-color: #fff
}

.btn-dark.active,
.btn-dark.focus,
.btn-dark:active,
.btn-dark:focus,
.btn-dark:hover,
.btn-gray.active,
.btn-gray.focus,
.btn-gray:active,
.btn-gray:focus,
.btn-gray:hover,
.btn-inverse.active,
.btn-inverse.focus,
.btn-inverse:active,
.btn-inverse:focus,
.btn-inverse:hover,
.open>.dropdown-toggle.btn-dark,
.open>.dropdown-toggle.btn-gray,
.open>.dropdown-toggle.btn-inverse {
	color: #fff;
	background-color: #222;
	border-color: #161616
}

.btn-yellow {
	color: #963;
	background-color: #fee188;
	border-color: #fedb6f
}

.btn-yellow.active.focus,
.btn-yellow.active:focus,
.btn-yellow.active:hover,
.btn-yellow:active.focus,
.btn-yellow:active:focus,
.btn-yellow:active:hover,
.open>.dropdown-toggle.btn-yellow.focus,
.open>.dropdown-toggle.btn-yellow:focus,
.open>.dropdown-toggle.btn-yellow:hover {
	color: #963;
	background-color: #fdcb32;
	border-color: #ebb202
}

.btn-yellow.active,
.btn-yellow:active,
.open>.dropdown-toggle.btn-yellow {
	background-image: none
}

.btn-yellow.disabled,
.btn-yellow.disabled.active,
.btn-yellow.disabled.focus,
.btn-yellow.disabled:active,
.btn-yellow.disabled:focus,
.btn-yellow.disabled:hover,
.btn-yellow[disabled],
.btn-yellow[disabled].active,
.btn-yellow[disabled].focus,
.btn-yellow[disabled]:active,
.btn-yellow[disabled]:focus,
.btn-yellow[disabled]:hover,
fieldset[disabled] .btn-yellow,
fieldset[disabled] .btn-yellow.active,
fieldset[disabled] .btn-yellow.focus,
fieldset[disabled] .btn-yellow:active,
fieldset[disabled] .btn-yellow:focus,
fieldset[disabled] .btn-yellow:hover {
	background-color: #fee188;
	border-color: #fedb6f
}

.btn-yellow .badge {
	color: #fee188;
	background-color: #963
}

.btn-yellow.active,
.btn-yellow.focus,
.btn-yellow:active,
.btn-yellow:focus,
.btn-yellow:hover,
.open>.dropdown-toggle.btn-yellow {
	color: #963;
	background-color: #fee188;
	border-color: #fedb6f
}

.btn-purple {
	color: #fff;
	background-color: #9585bf;
	border-color: #8674b6
}

.btn-purple.active.focus,
.btn-purple.active:focus,
.btn-purple.active:hover,
.btn-purple:active.focus,
.btn-purple:active:focus,
.btn-purple:active:hover,
.open>.dropdown-toggle.btn-purple.focus,
.open>.dropdown-toggle.btn-purple:focus,
.open>.dropdown-toggle.btn-purple:hover {
	color: #fff;
	background-color: #66529c;
	border-color: #4a3b70
}

.btn-purple.active,
.btn-purple:active,
.open>.dropdown-toggle.btn-purple {
	background-image: none
}

.btn-purple.disabled,
.btn-purple.disabled.active,
.btn-purple.disabled.focus,
.btn-purple.disabled:active,
.btn-purple.disabled:focus,
.btn-purple.disabled:hover,
.btn-purple[disabled],
.btn-purple[disabled].active,
.btn-purple[disabled].focus,
.btn-purple[disabled]:active,
.btn-purple[disabled]:focus,
.btn-purple[disabled]:hover,
fieldset[disabled] .btn-purple,
fieldset[disabled] .btn-purple.active,
fieldset[disabled] .btn-purple.focus,
fieldset[disabled] .btn-purple:active,
fieldset[disabled] .btn-purple:focus,
fieldset[disabled] .btn-purple:hover {
	background-color: #9585bf;
	border-color: #8674b6
}

.btn-purple .badge {
	color: #9585bf;
	background-color: #fff
}

.btn-purple.active,
.btn-purple.focus,
.btn-purple:active,
.btn-purple:focus,
.btn-purple:hover,
.open>.dropdown-toggle.btn-purple {
	color: #fff;
	background-color: #9585bf;
	border-color: #8674b6
}

.btn-twitter {
	color: #fff;
	background-color: #2b97f0;
	border-color: #1d90ef
}

.btn-twitter.active.focus,
.btn-twitter.active:focus,
.btn-twitter.active:hover,
.btn-twitter:active.focus,
.btn-twitter:active:focus,
.btn-twitter:active:hover,
.open>.dropdown-toggle.btn-twitter.focus,
.open>.dropdown-toggle.btn-twitter:focus,
.open>.dropdown-toggle.btn-twitter:hover {
	color: #fff;
	background-color: #0d6ab7;
	border-color: #094c83
}

.btn-twitter.active,
.btn-twitter:active,
.open>.dropdown-toggle.btn-twitter {
	background-image: none
}

.btn-twitter.disabled,
.btn-twitter.disabled.active,
.btn-twitter.disabled.focus,
.btn-twitter.disabled:active,
.btn-twitter.disabled:focus,
.btn-twitter.disabled:hover,
.btn-twitter[disabled],
.btn-twitter[disabled].active,
.btn-twitter[disabled].focus,
.btn-twitter[disabled]:active,
.btn-twitter[disabled]:focus,
.btn-twitter[disabled]:hover,
fieldset[disabled] .btn-twitter,
fieldset[disabled] .btn-twitter.active,
fieldset[disabled] .btn-twitter.focus,
fieldset[disabled] .btn-twitter:active,
fieldset[disabled] .btn-twitter:focus,
fieldset[disabled] .btn-twitter:hover {
	background-color: #2b97f0;
	border-color: #1d90ef
}

.btn-twitter .badge {
	color: #2b97f0;
	background-color: #fff
}

.btn-twitter.active,
.btn-twitter.focus,
.btn-twitter:active,
.btn-twitter:focus,
.btn-twitter:hover,
.open>.dropdown-toggle.btn-twitter {
	color: #fff;
	background-color: #2b97f0;
	border-color: #1d90ef
}

.btn-facebook {
	color: #fff;
	background-color: #3b5a9b;
	border-color: #375490
}

.btn-facebook.active.focus,
.btn-facebook.active:focus,
.btn-facebook.active:hover,
.btn-facebook:active.focus,
.btn-facebook:active:focus,
.btn-facebook:active:hover,
.open>.dropdown-toggle.btn-facebook.focus,
.open>.dropdown-toggle.btn-facebook:focus,
.open>.dropdown-toggle.btn-facebook:hover {
	color: #fff;
	background-color: #23365c;
	border-color: #141e34
}

.btn-facebook.active,
.btn-facebook:active,
.open>.dropdown-toggle.btn-facebook {
	background-image: none
}

.btn-facebook.disabled,
.btn-facebook.disabled.active,
.btn-facebook.disabled.focus,
.btn-facebook.disabled:active,
.btn-facebook.disabled:focus,
.btn-facebook.disabled:hover,
.btn-facebook[disabled],
.btn-facebook[disabled].active,
.btn-facebook[disabled].focus,
.btn-facebook[disabled]:active,
.btn-facebook[disabled]:focus,
.btn-facebook[disabled]:hover,
fieldset[disabled] .btn-facebook,
fieldset[disabled] .btn-facebook.active,
fieldset[disabled] .btn-facebook.focus,
fieldset[disabled] .btn-facebook:active,
fieldset[disabled] .btn-facebook:focus,
fieldset[disabled] .btn-facebook:hover {
	background-color: #3b5a9b;
	border-color: #375490
}

.btn-facebook .badge {
	color: #3b5a9b;
	background-color: #fff
}

.btn-facebook.active,
.btn-facebook.focus,
.btn-facebook:active,
.btn-facebook:focus,
.btn-facebook:hover,
.open>.dropdown-toggle.btn-facebook {
	color: #fff;
	background-color: #3b5a9b;
	border-color: #375490
}

.btn-googleplus {
	color: #fff;
	background-color: #db2814;
	border-color: #cd2513
}

.btn-googleplus.active.focus,
.btn-googleplus.active:focus,
.btn-googleplus.active:hover,
.btn-googleplus:active.focus,
.btn-googleplus:active:focus,
.btn-googleplus:active:hover,
.open>.dropdown-toggle.btn-googleplus.focus,
.open>.dropdown-toggle.btn-googleplus:focus,
.open>.dropdown-toggle.btn-googleplus:hover {
	color: #fff;
	background-color: #8c190d;
	border-color: #581008
}

.btn-googleplus.active,
.btn-googleplus:active,
.open>.dropdown-toggle.btn-googleplus {
	background-image: none
}

.btn-googleplus.disabled,
.btn-googleplus.disabled.active,
.btn-googleplus.disabled.focus,
.btn-googleplus.disabled:active,
.btn-googleplus.disabled:focus,
.btn-googleplus.disabled:hover,
.btn-googleplus[disabled],
.btn-googleplus[disabled].active,
.btn-googleplus[disabled].focus,
.btn-googleplus[disabled]:active,
.btn-googleplus[disabled]:focus,
.btn-googleplus[disabled]:hover,
fieldset[disabled] .btn-googleplus,
fieldset[disabled] .btn-googleplus.active,
fieldset[disabled] .btn-googleplus.focus,
fieldset[disabled] .btn-googleplus:active,
fieldset[disabled] .btn-googleplus:focus,
fieldset[disabled] .btn-googleplus:hover {
	background-color: #db2814;
	border-color: #cd2513
}

.btn-googleplus .badge {
	color: #db2814;
	background-color: #fff
}

.btn-googleplus.active,
.btn-googleplus.focus,
.btn-googleplus:active,
.btn-googleplus:focus,
.btn-googleplus:hover,
.open>.dropdown-toggle.btn-googleplus {
	color: #fff;
	background-color: #db2814;
	border-color: #cd2513
}

.btn-linkedin {
	color: #fff;
	background-color: #0e76a8;
	border-color: #0d6c9a
}

.btn-linkedin.active.focus,
.btn-linkedin.active:focus,
.btn-linkedin.active:hover,
.btn-linkedin:active.focus,
.btn-linkedin:active:focus,
.btn-linkedin:active:hover,
.open>.dropdown-toggle.btn-linkedin.focus,
.open>.dropdown-toggle.btn-linkedin:focus,
.open>.dropdown-toggle.btn-linkedin:hover {
	color: #fff;
	background-color: #073e58;
	border-color: #031924
}

.btn-linkedin.active,
.btn-linkedin:active,
.open>.dropdown-toggle.btn-linkedin {
	background-image: none
}

.btn-linkedin.disabled,
.btn-linkedin.disabled.active,
.btn-linkedin.disabled.focus,
.btn-linkedin.disabled:active,
.btn-linkedin.disabled:focus,
.btn-linkedin.disabled:hover,
.btn-linkedin[disabled],
.btn-linkedin[disabled].active,
.btn-linkedin[disabled].focus,
.btn-linkedin[disabled]:active,
.btn-linkedin[disabled]:focus,
.btn-linkedin[disabled]:hover,
fieldset[disabled] .btn-linkedin,
fieldset[disabled] .btn-linkedin.active,
fieldset[disabled] .btn-linkedin.focus,
fieldset[disabled] .btn-linkedin:active,
fieldset[disabled] .btn-linkedin:focus,
fieldset[disabled] .btn-linkedin:hover {
	background-color: #0e76a8;
	border-color: #0d6c9a
}

.btn-linkedin .badge {
	color: #0e76a8;
	background-color: #fff
}

.btn-linkedin.active,
.btn-linkedin.focus,
.btn-linkedin:active,
.btn-linkedin:focus,
.btn-linkedin:hover,
.open>.dropdown-toggle.btn-linkedin {
	color: #fff;
	background-color: #0e76a8;
	border-color: #0d6c9a
}

.btn-white {
	color: #393939;
	background-color: #fff;
	border-color: #e2e1e1
}

.btn-white.active.focus,
.btn-white.active:focus,
.btn-white.active:hover,
.btn-white:active.focus,
.btn-white:active:focus,
.btn-white:active:hover,
.open>.dropdown-toggle.btn-white.focus,
.open>.dropdown-toggle.btn-white:focus,
.open>.dropdown-toggle.btn-white:hover {
	color: #393939;
	background-color: #d4d4d4;
	border-color: #a3a0a0
}

.btn-white.active,
.btn-white:active,
.open>.dropdown-toggle.btn-white {
	background-image: none
}

.btn-white.disabled,
.btn-white.disabled.active,
.btn-white.disabled.focus,
.btn-white.disabled:active,
.btn-white.disabled:focus,
.btn-white.disabled:hover,
.btn-white[disabled],
.btn-white[disabled].active,
.btn-white[disabled].focus,
.btn-white[disabled]:active,
.btn-white[disabled]:focus,
.btn-white[disabled]:hover,
fieldset[disabled] .btn-white,
fieldset[disabled] .btn-white.active,
fieldset[disabled] .btn-white.focus,
fieldset[disabled] .btn-white:active,
fieldset[disabled] .btn-white:focus,
fieldset[disabled] .btn-white:hover {
	background-color: #fff;
	border-color: #e2e1e1
}

.btn-white .badge {
	color: #fff;
	background-color: #393939
}

.btn-white.active,
.btn-white.focus,
.btn-white:active,
.btn-white:focus,
.btn-white:hover,
.open>.dropdown-toggle.btn-white {
	color: #393939;
	background-color: #fff;
	border-color: #e2e1e1
}

.ui-group-buttons .or {
	position: relative;
	float: left;
	width: .3em;
	height: 1.3em;
	z-index: 3;
	font-size: 12px
}

.ui-group-buttons .or:before {
	position: absolute;
	top: 50%;
	left: 50%;
	content: 'or';
	background-color: #fff;
	margin-top: -.1em;
	margin-left: -.9em;
	width: 1.8em;
	height: 1.8em;
	line-height: 1.55;
	color: #393939;
	font-style: normal;
	font-weight: 400;
	text-align: center;
	border-radius: 500px;
	-webkit-box-shadow: 0 0 0 1px rgba(0, 0, 0, .1);
	box-shadow: 0 0 0 1px rgba(0, 0, 0, .1);
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box
}

.ui-group-buttons .or:after {
	position: absolute;
	top: 0;
	left: 0;
	content: ' ';
	width: .3em;
	height: 2.84em;
	border-top: .6em solid #fff;
	border-bottom: .6em solid #fff
}

.ui-group-buttons .or.or-lg {
	height: 1.3em;
	font-size: 16px
}

.ui-group-buttons .or.or-lg:after {
	height: 2.85em
}

.ui-group-buttons .or.or-sm {
	height: 1em
}

.ui-group-buttons .or.or-sm:after {
	height: 2.5em
}

.ui-group-buttons .or.or-xs {
	height: .25em
}

.ui-group-buttons .or.or-xs:after {
	height: 1.84em;
	z-index: -1000
}

.ui-group-buttons {
	display: inline-block;
	vertical-align: middle
}

.ui-group-buttons:after {
	content: ".";
	display: block;
	height: 0;
	clear: both;
	visibility: hidden
}

.ui-group-buttons .btn {
	float: left;
	border-radius: 0
}

.ui-group-buttons .btn:first-child {
	margin-left: 0;
	border-top-left-radius: .25em;
	border-bottom-left-radius: .25em;
	padding-right: 15px
}

.ui-group-buttons .btn:last-child {
	border-top-right-radius: .25em;
	border-bottom-right-radius: .25em;
	padding-left: 15px
}

.btn-circle {
	width: 50px;
	height: 50px;
	text-align: center;
	padding: 12px 10px;
	font-size: 18px;
	border-radius: 25px
}

.btn-circle.btn-xs {
	width: 30px;
	height: 30px;
	padding: 6px 0;
	font-size: 11px;
	border-radius: 15px
}

.btn-circle.btn-sm {
	width: 40px;
	height: 40px;
	padding: 8px 10px;
	font-size: 16px;
	border-radius: 20px
}

.btn-circle.btn-lg {
	width: 70px;
	height: 70px;
	padding: 10px 16px;
	font-size: 34px;
	border-radius: 35px
}

.btn-line {
	background-color: #fff;
	color: inherit;
	border-bottom-width: 2px
}

.btn-line.btn-primary {
	color: #428bca
}

.btn-line.btn-primary:active,
.btn-line.btn-primary:focus,
.btn-line.btn-primary:hover {
	color: #fff
}

.btn-line.btn-success {
	color: #82af6f
}

.btn-line.btn-success.disabled,
.btn-line.btn-success:active,
.btn-line.btn-success:focus,
.btn-line.btn-success:hover {
	color: #fff
}

.btn-line.btn-info {
	color: #3a87ad
}

.btn-line.btn-info.disabled,
.btn-line.btn-info:active,
.btn-line.btn-info:focus,
.btn-line.btn-info:hover {
	color: #fff
}

.btn-line.btn-warning {
	color: #ffb752
}

.btn-line.btn-warning.disabled,
.btn-line.btn-warning:active,
.btn-line.btn-warning:focus,
.btn-line.btn-warning:hover {
	color: #fff
}

.btn-line.btn-danger {
	color: #d15b47
}

.btn-line.btn-danger.disabled,
.btn-line.btn-danger:active,
.btn-line.btn-danger:focus,
.btn-line.btn-danger:hover {
	color: #fff
}

.btn-line.btn-yellow,
.btn-line.btn-yellow.disabled,
.btn-line.btn-yellow:active,
.btn-line.btn-yellow:focus,
.btn-line.btn-yellow:hover {
	color: #963
}

.btn-line.btn-purple {
	color: #a069c3
}

.btn-line.btn-purple.disabled,
.btn-line.btn-purple:active,
.btn-line.btn-purple:focus,
.btn-line.btn-purple:hover {
	color: #fff
}

.btn-line.btn-white {
	color: #7bb7e5
}

.btn-line.btn-dark,
.btn-line.btn-gray,
.btn-line.btn-inverse,
.btn-line.btn-white.disabled,
.btn-line.btn-white:active,
.btn-line.btn-white:focus,
.btn-line.btn-white:hover {
	color: #555
}

.btn-line.btn-dark.disabled,
.btn-line.btn-dark:active,
.btn-line.btn-dark:focus,
.btn-line.btn-dark:hover,
.btn-line.btn-gray.disabled,
.btn-line.btn-gray:active,
.btn-line.btn-gray:focus,
.btn-line.btn-gray:hover,
.btn-line.btn-inverse.disabled,
.btn-line.btn-inverse:active,
.btn-line.btn-inverse:focus,
.btn-line.btn-inverse:hover {
	color: #fff
}

.btn-line:focus,
.btn-line:hover {
	-webkit-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	transition: all 300ms ease
}

.btn-line.btn-circle {
	border-bottom-width: 1px
}

.btn-group-vertical>.btn:last-child:not(:first-child) {
	border-bottom-left-radius: 0
}

.btn-group-vertical>.btn:first-child:not(:last-child) {
	border-top-right-radius: 0
}

.btn-group-xs>.btn,
.btn-xs {
	padding: 0 5px
}

.btn-group-sm .btn,
.btn-group-xs .btn,
btn-group-lg .btn {
	border-radius: 0
}

.btn-file {
	position: relative;
	overflow: hidden
}

.btn-file input[type=file] {
	position: absolute;
	top: 0;
	right: 0;
	min-width: 100%;
	min-height: 100%;
	text-align: right;
	opacity: 0;
	filter: alpha(opacity=0);
	outline: 0;
	cursor: inherit;
	display: block
}

.btn-colorpicker {
	display: inline-block;
	width: 20px;
	height: 20px;
	background-color: #f2f2f2;
	vertical-align: middle;
	border-radius: 0
}

.btn-group:first-child {
	margin-left: 0
}

.btn-group>.btn,
.btn-group>.btn+.btn,
.btn-group>.btn:first-child {
	margin: 0 1px 0 0
}

.btn-group .btn+.btn,
.btn-group .btn+.btn-group,
.btn-group .btn-group+.btn,
.btn-group .btn-group+.btn-group {
	margin-left: 0
}

.btn-group.btn-group-xs>.btn+.dropdown-toggle {
	padding: 0 5px
}

.portlet {
	margin-bottom: 15px;
	border: 1px solid #e2e1e1;
	-webkit-box-shadow: 0 3px 0 0 #dbdbdb;
	box-shadow: 0 3px 0 0 #dbdbdb;
	background: #fff
}

.portlet .portlet-heading {
	padding: 0 15px;
	border-bottom: 1px solid #e2e1e1;
	background: #fff;
	line-height: 38px;
	min-height: 39px
}

.portlet .portlet-heading .fa,
.portlet .portlet-heading .glyphicon {
	opacity: .55;
	filter: alpha(opacity=55.00000000000001)
}

.portlet .portlet-heading.bg-danger a,
.portlet .portlet-heading.bg-info a,
.portlet .portlet-heading.bg-primary a,
.portlet .portlet-heading.bg-success a,
.portlet .portlet-heading.bg-warning a,
.portlet .portlet-heading.dark a,
.portlet .portlet-heading.inverse a {
	color: #fff
}

.portlet .portlet-heading.bg-danger small,
.portlet .portlet-heading.bg-info small,
.portlet .portlet-heading.bg-primary small,
.portlet .portlet-heading.bg-success small,
.portlet .portlet-heading.bg-warning small,
.portlet .portlet-heading.dark small,
.portlet .portlet-heading.inverse small {
	color: #999
}

.portlet .portlet-heading.bg-danger .nav li.active>a,
.portlet .portlet-heading.bg-info .nav li.active>a,
.portlet .portlet-heading.bg-primary .nav li.active>a,
.portlet .portlet-heading.bg-success .nav li.active>a,
.portlet .portlet-heading.bg-warning .nav li.active>a,
.portlet .portlet-heading.dark .nav li.active>a,
.portlet .portlet-heading.inverse .nav li.active>a {
	color: #393939
}

.portlet .portlet-heading .nav li {
	margin-bottom: auto
}

.portlet .portlet-heading .nav li>a {
	padding: 7.5px 15px;
	font-size: 13px
}

.portlet .portlet-heading .nav li:not(.active)>a {
	border-color: transparent;
	background-color: transparent
}

.portlet .portlet-heading .nav li.active>a {
	background-color: #fff;
	border-bottom-color: transparent;
	box-shadow: none;
	color: #393939
}

.portlet .portlet-heading .nav.nav-tabs {
	float: right;
	border-bottom: none;
	margin-bottom: 0;
	position: relative;
	bottom: -5px
}

.portlet .portlet-heading .portlet-title {
	float: left
}

.portlet .portlet-heading a {
	color: #999
}

.portlet .portlet-heading a:active,
.portlet .portlet-heading a:focus,
.portlet .portlet-heading a:hover {
	outline: 0
}

.portlet .portlet-heading h4 {
	font-size: 16px;
	margin: 0;
	line-height: 37px
}

.portlet .portlet-heading .portlet-widgets {
	position: relative;
	text-align: right;
	float: right;
	padding-left: 15px;
	display: inline-block;
	font-size: 15px;
	line-height: 37px
}

.portlet .portlet-heading .portlet-widgets:before {
	display: inline-block;
	content: "";
	position: absolute;
	top: 3px;
	bottom: 3px;
	left: -1px;
	border: 1px solid rgba(0, 0, 0, .1);
	border-width: 0 1px 0 0
}

.portlet .portlet-heading .portlet-widgets .divider {
	margin: 0 3px
}

.portlet .portlet-heading .portlet-widgets .fa:hover {
	-moz-transform: scale(1.2);
	-webkit-transform: scale(1.2);
	-o-transform: scale(1.2);
	-ms-transform: scale(1.2);
	transform: scale(1.2);
	opacity: 1
}

.portlet .portlet-heading .portlet-widgets .dropdown-menu a {
	color: #555
}

.portlet .portlet-heading .portlet-widgets ul.dropdown-menu {
	min-width: 0
}

.portlet .portlet-heading.no-left-border .portlet-widgets:before {
	border: 0
}

.portlet .portlet-heading.large-heading {
	line-height: 49px;
	min-height: 50px
}

.portlet .portlet-heading.large-heading .portlet-widgets {
	line-height: 49px
}

.portlet .portlet-heading.large-heading h4 {
	line-height: 48px
}

.portlet form .form-actions {
	padding: 15px 10px 0
}

.portlet.no-border {
	border: 0
}

.portlet .portlet-body {
	background: #fff;
	padding: 15px
}

.portlet .portlet-body .table {
	margin-bottom: 0
}

.portlet .portlet-body .table>thead>tr>td,
.portlet .portlet-body .table>thead>tr>th {
	border-bottom: 3px double #e2e1e1
}

.portlet .portlet-body .datepicker.datepicker-inline {
	margin: 0
}

.portlet .portlet-body.no-padding .table.table-bordered {
	border-width: 1px 0 0
}

.portlet .portlet-body.no-padding .table.table-bordered td:first-child,
.portlet .portlet-body.no-padding .table.table-bordered th:first-child {
	border-left: 0 none
}

.portlet .portlet-body.no-padding .table.table-bordered td:last-child,
.portlet .portlet-body.no-padding .table.table-bordered th:last-child {
	border-right: 0 none
}

.portlet .portlet-body.no-padding .table.table-bordered tr:last-child td {
	border-bottom: 0 none
}

.portlet .portlet-footer {
	padding: 10px 15px;
	background: #f2f2f2;
	border-top: 1px solid #e2e1e1
}

.portlet .portlet-footer ul {
	margin: 0
}

.portlet.hidden-widgets .portlet-widgets {
	display: none
}

.portlet.hidden-widgets:hover .portlet-widgets {
	display: block
}

.portlet.portlet-danger .portlet-heading,
.portlet.portlet-danger .portlet-heading a,
.portlet.portlet-dark .portlet-heading,
.portlet.portlet-dark .portlet-heading a,
.portlet.portlet-gray .portlet-heading,
.portlet.portlet-gray .portlet-heading a,
.portlet.portlet-info .portlet-heading,
.portlet.portlet-info .portlet-heading a,
.portlet.portlet-inverse .portlet-heading,
.portlet.portlet-inverse .portlet-heading a,
.portlet.portlet-primary .portlet-heading,
.portlet.portlet-primary .portlet-heading a,
.portlet.portlet-success .portlet-heading,
.portlet.portlet-success .portlet-heading a,
.portlet.portlet-warning .portlet-heading,
.portlet.portlet-warning .portlet-heading a {
	color: #fff
}

.portlet.portlet-basic .portlet-heading {
	background: #f2f2f2
}

.portlet.portlet-basic .portlet-heading a {
	color: #999
}

.portlet-gray>.portlet-heading {
	background-color: #555
}

.portlet-dark>.portlet-heading,
.portlet-inverse>.portlet-heading {
	background-color: #2b3539
}

.portlet-success>.portlet-heading {
	background-color: #82af6f
}

.portlet-danger>.portlet-heading {
	background-color: #d15b47
}

.portlet-info>.portlet-heading {
	background-color: #3a87ad
}

.portlet-warning>.portlet-heading {
	background-color: #ffb752
}

.portlet-primary>.portlet-heading {
	background-color: #428bca
}

.portlet-primary,
.portlet-primary>.portlet-heading {
	border-color: #428bca
}

.portlet-danger,
.portlet-danger>.portlet-heading {
	border-color: #d15b47
}

.portlet-success,
.portlet-success>.portlet-heading {
	border-color: #82af6f
}

.portlet-warning,
.portlet-warning>.portlet-heading {
	border-color: #ffb752
}

.portlet-info,
.portlet-info>.portlet-heading {
	border-color: #3a87ad
}

.portlet-gray,
.portlet-gray>.portlet-heading {
	border-color: #555
}

.portlet-dark,
.portlet-dark>.portlet-heading,
.portlet-inverse,
.portlet-inverse>.portlet-heading {
	border-color: #2b3539
}

.btn.tile-button {
	display: block;
	text-align: left;
	padding: 0;
	margin-bottom: 15px;
	border-right: 7.5px solid #e2e1e1
}

.btn.tile-button .tile-content-wrapper {
	overflow: hidden;
	padding: 10px 15px;
	position: relative
}

.btn.tile-button .tile-content-wrapper .fa,
.btn.tile-button .tile-content-wrapper .glyphicon {
	position: absolute;
	font-size: 57px;
	top: 50%;
	margin-top: -25px;
	left: 15px;
	-webkit-transition: all 300ms ease-in-out;
	-o-transition: all 300ms ease-in-out;
	transition: all 300ms ease-in-out;
	-moz-transform: rotate(-20deg);
	-webkit-transform: rotate(-20deg);
	-o-transform: rotate(-20deg);
	-ms-transform: rotate(-20deg);
	transform: rotate(-20deg)
}

.btn.tile-button .tile-content-wrapper .tile-content {
	font-size: 35px;
	text-align: right;
	font-weight: 500
}

.btn.tile-button .tile-content-wrapper .tile-content span {
	font-size: 25px
}

.btn.tile-button .tile-content-wrapper small {
	text-align: right;
	font-size: 16px;
	display: block
}

.btn.tile-button:hover {
	background-color: #fff
}

.btn.tile-button:hover .tile-content-wrapper .fa,
.btn.tile-button:hover .tile-content-wrapper .glyphicon {
	left: -20px;
	margin-top: -45px;
	font-size: 110px!important;
	-moz-transform: rotate(20deg);
	-webkit-transform: rotate(20deg);
	-o-transform: rotate(20deg);
	-ms-transform: rotate(20deg);
	transform: rotate(20deg);
	opacity: .2;
	filter: alpha(opacity=20)
}

.btn.tile-button:hover .tile-content-wrapper .tile-content,
.btn.tile-button:hover .tile-content-wrapper small {
	opacity: .5;
	filter: alpha(opacity=50)
}

.btn.tile-button.btn-default {
	border-color: #5da7df
}

.btn.tile-button.btn-default:hover {
	color: #7bb7e5
}

.btn.tile-button.btn-dark,
.btn.tile-button.btn-inverse {
	border-color: #444
}

.btn.tile-button.btn-dark:hover,
.btn.tile-button.btn-inverse:hover {
	color: #555
}

.btn.tile-button.btn-white {
	border-color: #e2e1e1
}

.btn.tile-button.btn-white:hover {
	color: #999
}

.btn.tile-button.btn-danger {
	border-color: #c34631
}

.btn.tile-button.btn-danger:hover {
	color: #d15b47
}

.btn.tile-button.btn-warning {
	border-color: #ffa82e
}

.btn.tile-button.btn-warning:hover {
	color: #ffb752
}

.btn.tile-button.btn-primary {
	border-color: #3379b5
}

.btn.tile-button.btn-primary:hover {
	color: #428bca
}

.btn.tile-button.btn-info {
	border-color: #317292
}

.btn.tile-button.btn-info:hover {
	color: #3a87ad
}

.btn.tile-button.btn-success {
	border-color: #6fa159
}

.btn.tile-button.btn-success:hover {
	color: #82af6f
}

.btn.tile-button.btn-line {
	background: #fff;
	border-width: 1px 7.5px 1px 1px
}

.btn.tile-button.btn-line .tile-content-wrapper .fa,
.btn.tile-button.btn-line .tile-content-wrapper .glyphicon {
	margin-top: -45px;
	font-size: 110px;
	-moz-transform: rotate(20deg);
	-webkit-transform: rotate(20deg);
	-o-transform: rotate(20deg);
	-ms-transform: rotate(20deg);
	transform: rotate(20deg);
	left: -20px
}

.btn.tile-button.btn-line .tile-content-wrapper .fa,
.btn.tile-button.btn-line .tile-content-wrapper .glyphicon,
.btn.tile-button.btn-line .tile-content-wrapper .tile-content,
.btn.tile-button.btn-line .tile-content-wrapper small {
	opacity: .7;
	filter: alpha(opacity=70)
}

.btn.tile-button.btn-line:hover .tile-content-wrapper .tile-content {
	opacity: 1;
	filter: alpha(opacity=100)
}

.btn.tile-button.btn-line:hover .tile-content-wrapper .fa,
.btn.tile-button.btn-line:hover .tile-content-wrapper .glyphicon {
	margin-top: -25px;
	font-size: 57px!important;
	-moz-transform: rotate(-20deg);
	-webkit-transform: rotate(-20deg);
	-o-transform: rotate(-20deg);
	-ms-transform: rotate(-20deg);
	transform: rotate(-20deg);
	left: 15px
}

.btn.tile-button.btn-line:hover .tile-content-wrapper .fa,
.btn.tile-button.btn-line:hover .tile-content-wrapper .glyphicon,
.btn.tile-button.btn-line:hover .tile-content-wrapper small {
	opacity: .55;
	filter: alpha(opacity=55.00000000000001)
}

.btn.tile-button.btn-line.btn-default:active,
.btn.tile-button.btn-line.btn-default:focus {
	color: #7bb7e5
}

.btn.tile-button.btn-line.btn-primary:active,
.btn.tile-button.btn-line.btn-primary:focus {
	color: #428bca
}

.btn.tile-button.btn-line.btn-success:active,
.btn.tile-button.btn-line.btn-success:focus {
	color: #82af6f
}

.btn.tile-button.btn-line.btn-danger:active,
.btn.tile-button.btn-line.btn-danger:focus {
	color: #d15b47
}

.btn.tile-button.btn-line.btn-info:active,
.btn.tile-button.btn-line.btn-info:focus {
	color: #3a87ad
}

.btn.tile-button.btn-line.btn-warning:active,
.btn.tile-button.btn-line.btn-warning:focus {
	color: #ffb752
}

.btn.tile-button.btn-line.btn-dark:active,
.btn.tile-button.btn-line.btn-dark:focus,
.btn.tile-button.btn-line.btn-gray:active,
.btn.tile-button.btn-line.btn-gray:focus,
.btn.tile-button.btn-line.btn-inverse:active,
.btn.tile-button.btn-line.btn-inverse:focus {
	color: #2b3539
}

input[type=checkbox].tc,
input[type=radio].tc {
	opacity: 0;
	position: absolute;
	z-index: 12;
	width: 17px;
	height: 17px;
	cursor: pointer
}

input[type=checkbox].tc:checked,
input[type=checkbox].tc:focus,
input[type=radio].tc:checked,
input[type=radio].tc:focus {
	outline: 0!important
}

input[type=checkbox].tc+.labels,
input[type=radio].tc+.labels {
	position: relative;
	z-index: 11;
	display: inline-block;
	margin: 0;
	line-height: 20px;
	min-height: 18px;
	min-width: 18px;
	font-weight: 400
}

input[type=checkbox].tc+.labels::before,
input[type=radio].tc+.labels::before {
    font-family: "Font Awesome 5 Free";
	font-weight: 400;
	font-size: 10px;
	color: #32a3ce;
	content: "\a0";
	background-color: #FAFAFA;
	border: 1px solid #c8c8c8;
	box-shadow: 0 1px 2px rgba(0, 0, 0, .05);
	border-radius: 0;
	display: inline-block;
	text-align: center;
	vertical-align: middle;
	height: 16px;
	line-height: 14px;
	min-width: 16px;
	margin-right: 1px
}

input[type=checkbox].tc:checked+.labels::before,
input[type=radio].tc:checked+.labels::before {
	display: inline-block;
	content: '\f00c';
	background-color: #F5F8FC;
	border-color: #adb8c0;
	box-shadow: 0 1px 2px rgba(0, 0, 0, .05), inset 0 -15px 10px -12px rgba(0, 0, 0, .05), inset 15px 10px -12px rgba(255, 255, 255, .1)
}

input[type=checkbox].tc+.labels:hover::before,
input[type=checkbox].tc:hover+.labels::before,
input[type=radio].tc+.labels:hover::before,
input[type=radio].tc:hover+.labels::before {
	border-color: #ff893c
}

input[type=checkbox].tc:active+.labels::before,
input[type=checkbox].tc:checked:active+.labels::before,
input[type=radio].tc:active+.labels::before,
input[type=radio].tc:checked:active+.labels::before {
	box-shadow: 0 1px 2px rgba(0, 0, 0, .05), inset 0 1px 3px rgba(0, 0, 0, .1)
}

input[type=checkbox].tc.tc-gray+.labels::before,
input[type=radio].tc.tc-gray+.labels::before {
	box-shadow: none;
	border-color: #555
}

input[type=checkbox].tc.tc-gary:checked+.labels::before,
input[type=radio].tc.tc-gary:checked+.labels::before {
	background-color: #555;
	border-color: #3c3c3c;
	color: #fff
}

input[type=checkbox].tc.tc-danger+.labels::before,
input[type=radio].tc.tc-danger+.labels::before {
	box-shadow: none;
	border-color: #d15b47
}

input[type=checkbox].tc.tc-danger:checked+.labels::before,
input[type=radio].tc.tc-danger:checked+.labels::before {
	background-color: #d15b47;
	border-color: #b7422e;
	color: #fff
}

input[type=checkbox].tc.tc-success+.labels::before,
input[type=radio].tc.tc-success+.labels::before {
	box-shadow: none;
	border-color: #82af6f
}

input[type=checkbox].tc.tc-success:checked+.labels::before,
input[type=radio].tc.tc-success:checked+.labels::before {
	background-color: #82af6f;
	border-color: #689754;
	color: #fff
}

input[type=checkbox].tc.tc-info+.labels::before,
input[type=radio].tc.tc-info+.labels::before {
	box-shadow: none;
	border-color: #3a87ad
}

input[type=checkbox].tc.tc-info:checked+.labels::before,
input[type=radio].tc.tc-info:checked+.labels::before {
	background-color: #3a87ad;
	border-color: #2d6987;
	color: #fff
}

input[type=checkbox].tc.tc-primary+.labels::before,
input[type=radio].tc.tc-primary+.labels::before {
	box-shadow: none;
	border-color: #428bca
}

input[type=checkbox].tc.tc-primary:checked+.labels::before,
input[type=radio].tc.tc-primary:checked+.labels::before {
	background-color: #428bca;
	border-color: #3071a9;
	color: #fff
}

input[type=checkbox].tc.tc-warning+.labels::before,
input[type=radio].tc.tc-warning+.labels::before {
	box-shadow: none;
	border-color: #ffb752
}

input[type=checkbox].tc.tc-warning:checked+.labels::before,
input[type=radio].tc.tc-warning:checked+.labels::before {
	background-color: #ffb752;
	border-color: #ffa21f;
	color: #fff
}

input[type=checkbox].tc.disabled+.labels::before,
input[type=checkbox].tc:disabled+.labels::before,
input[type=checkbox].tc[disabled]+.labels::before,
input[type=radio].tc.disabled+.labels::before,
input[type=radio].tc:disabled+.labels::before,
input[type=radio].tc[disabled]+.labels::before {
	background-color: #f2f2f2!important;
	border-color: #e2e1e1!important;
	box-shadow: none!important;
	color: #BBB
}

input[type=radio].tc+.labels::before {
	border-radius: 100%;
	font-family: FontAwesome;
	text-shadow: 0 0 1px #32a3ce;
	line-height: 15px;
	height: 17px;
	min-width: 17px
}

input[type=radio].tc:checked+.labels::before {
	content: "\f111"
}

input[type=checkbox].tc.tc-switch {
	width: 55px;
	height: 25px
}

input[type=checkbox].tc.tc-switch+.labels {
	margin: 0 4px;
	min-height: 24px
}

input[type=checkbox].tc.tc-switch+.labels::before {
	font-family: Arial, Helvetica, sans-serif;
	content: "ON\a0\a0\a0\a0\a0\a0\a0\a0\a0OFF";
	color: #555;
	font-weight: 700;
	line-height: 18px;
	line-height: 21px\9;
	height: 20px;
	overflow: hidden;
	border-radius: 12px;
	background-color: #f2f2f2;
	-webkit-box-shadow: inset 0 2px 2px 0 rgba(0, 0, 0, .2);
	box-shadow: inset 0 2px 2px 0 rgba(0, 0, 0, .2);
	border: 1px solid #CCC;
	text-align: left;
	float: left;
	padding: 0;
	width: 52px;
	text-indent: -19px;
	text-indent: -21px\9;
	margin-right: 0;
	-webkit-transition: text-indent .4s ease;
	-o-transition: text-indent .4s ease;
	transition: text-indent .4s ease
}

input[type=checkbox].tc.tc-switch+.labels::after {
	font-family: Arial, Helvetica, sans-serif;
	content: '|||';
	font-size: 10px;
	font-weight: lighter;
	color: #555;
	background-color: #fff;
	text-align: center;
	border-radius: 100%;
	width: 22px;
	height: 22px;
	line-height: 20px;
	position: absolute;
	top: -2px;
	left: -3px;
	padding: 0;
	-webkit-box-shadow: 0 1px 1px 1px rgba(0, 0, 0, .3);
	box-shadow: 0 1px 1px 1px rgba(0, 0, 0, .3);
	text-shadow: 0 1px 1px rgba(0, 0, 0, .3) inset;
	-webkit-transition: left .4s ease;
	-o-transition: left .4s ease;
	transition: left .4s ease
}

input[type=checkbox].tc.tc-switch:checked+.labels::before {
	text-indent: 9px;
	color: #fff;
	background-color: #6eb0e2;
	border-color: #7bb7e5
}

input[type=checkbox].tc.tc-switch:checked+.labels::after {
	left: 34px;
	background-color: #fff;
	color: #555
}

input[type=checkbox].tc.tc-switch.tc-switch-2+.labels::before {
	content: "YES\a0\a0\a0\a0\a0\a0\a0\a0NO"
}

input[type=checkbox].tc.tc-switch.tc-switch-3+.labels::after {
	font-family: FontAwesome;
	font-size: 13px;
	line-height: 23px;
	content: "\f00d";
	top: -1px
}

input[type=checkbox].tc.tc-switch.tc-switch-3:checked+.labels::after {
	content: "\f00c"
}

input[type=checkbox].tc.tc-switch.tc-switch-4+.labels::before,
input[type=checkbox].tc.tc-switch.tc-switch-5+.labels::before {
	content: "ON\a0\a0\a0\a0\a0\a0\a0\a0\a0\a0\a0OFF";
	font-family: Arial, Helvetica, sans-serif;
	font-weight: bolder;
	line-height: 23px;
	height: 24px;
	overflow: hidden;
	line-height: 25px\9;
	border-radius: 12px;
	background-color: #7bb7e5;
	border: 1px solid #7bb7e5;
	color: #fff;
	width: 56px;
	text-indent: -25px;
	text-indent: -28px\9;
	display: inline-block;
	position: relative;
	box-shadow: none;
	-webkit-transition: all .4s ease;
	-o-transition: all .4s ease;
	transition: all .4s ease
}

input[type=checkbox].tc.tc-switch.tc-switch-4+.labels::after,
input[type=checkbox].tc.tc-switch.tc-switch-5+.labels::after {
	font-family: Helvetica, Arial, sans-serif;
	content: '|||';
	text-shadow: -1px 0 0 rgba(0, 0, 0, .2);
	font-size: 8px;
	font-weight: lighter;
	text-align: center;
	position: absolute;
	border-radius: 12px;
	color: #555;
	top: 2px;
	left: 2px;
	width: 20px;
	height: 20px;
	line-height: 18px;
	background-color: #fff;
	-webkit-transition: all .4s ease;
	-o-transition: all .4s ease;
	transition: all .4s ease
}

input[type=checkbox].tc.tc-switch.tc-switch-4:checked+.labels::before,
input[type=checkbox].tc.tc-switch.tc-switch-5:checked+.labels::before {
	text-indent: 9px;
	background-color: #999;
	border-color: #7bb7e5
}

input[type=checkbox].tc.tc-switch.tc-switch-4:checked+.labels::after,
input[type=checkbox].tc.tc-switch.tc-switch-5:checked+.labels::after {
	left: 34px;
	background-color: #fff
}

input[type=checkbox].tc.tc-switch.tc-switch-5+.labels::before {
	content: "YES\a0\a0\a0\a0\a0\a0\a0\a0\a0\a0NO"
}

input[type=checkbox].tc.tc-switch.tc-switch-5:checked+.labels::before {
	text-indent: 8px
}

input[type=checkbox].tc.tc-switch.tc-switch-6+.labels {
	position: relative
}

input[type=checkbox].tc.tc-switch.tc-switch-6+.labels::before {
	font-family: FontAwesome;
	content: "\f00d";
	text-shadow: 0 -1px 0 rgba(0, 0, 0, .25);
	box-shadow: none;
	border: none;
	font-weight: lighter;
	font-size: 16px;
	border-radius: 12px;
	display: inline-block;
	background-color: #555;
	color: #fff;
	width: 52px;
	height: 22px;
	line-height: 20px;
	text-indent: 32px;
	-webkit-transition: background .1s ease;
	-o-transition: background .1s ease;
	transition: background .1s ease
}

input[type=checkbox].tc.tc-switch.tc-switch-6+.labels::after {
	content: '';
	text-shadow: 0 -1px 0 rgba(0, 0, 0, .25);
	position: absolute;
	top: 2px;
	left: 3px;
	border-radius: 12px;
	box-shadow: 0 -1px 0 rgba(0, 0, 0, .25);
	width: 18px;
	height: 18px;
	text-align: center;
	background-color: #F2F2F2;
	border: 4px solid #F2F2F2;
	-webkit-transition: left .2s ease;
	-o-transition: left .2s ease;
	transition: left .2s ease
}

input[type=checkbox].tc.tc-switch.tc-switch-6:checked+.labels::before {
	content: "\f00c";
	text-indent: 6px;
	color: #fff;
	border-color: #216ca6;
	background-color: #7bb7e5
}

input[type=checkbox].tc.tc-switch.tc-switch-6:checked+.labels::after {
	left: 32px;
	background-color: #fff;
	border: 4px solid #fff;
	text-shadow: 0 -1px 0 rgba(0, 200, 0, .25)
}

input[type=checkbox].tc.tc-switch.tc-switch-7 {
	width: 75px
}

input[type=checkbox].tc.tc-switch.tc-switch-7+.labels {
	position: relative
}

input[type=checkbox].tc.tc-switch.tc-switch-7+.labels::before {
	content: "OFF\a0\a0\a0\a0\a0\a0\a0\a0\a0\a0\a0ON";
	font-weight: bolder;
	font-size: 14px;
	background-color: #fff;
	border: 1px solid #999;
	border-radius: 0;
	box-shadow: none;
	color: #999;
	width: 74px;
	height: 26px;
	line-height: 22px;
	overflow: hidden;
	text-indent: 4px;
	display: inline-block;
	position: relative;
	-webkit-transition: all .2s ease;
	-o-transition: all .2s ease;
	transition: all .2s ease
}

input[type=checkbox].tc.tc-switch.tc-switch-7+.labels::after {
	content: '\f00d';
	font-family: FontAwesome;
	font-size: 16px;
	position: absolute;
	top: 3px;
	left: 39px;
	width: 32px;
	height: 20px;
	line-height: 18px;
	text-align: center;
	background-color: #999;
	color: #fff;
	border-radius: 0;
	box-shadow: none;
	-webkit-transition: all .2s ease-in-out;
	-o-transition: all .2s ease-in-out;
	transition: all .2s ease-in-out
}

input[type=checkbox].tc.tc-switch.tc-switch-7:checked+.labels::before {
	color: #7bb7e5;
	background-color: #fff;
	text-indent: -33px;
	border-color: #7bb7e5
}

input[type=checkbox].tc.tc-switch.tc-switch-7:checked+.labels::after {
	left: 3px;
	content: '\f00c';
	background-color: #7bb7e5;
	color: #fff
}

.well {
	border: 1px solid #e2e1e1;
	-webkit-box-shadow: none!important;
	box-shadow: none!important;
	margin-bottom: 15px;
	padding: 15px;
	background: #f2f2f2
}

.well.well-sm {
	padding: 10px
}

.well h1,
.well h2,
.well h3,
.well h4,
.well h5,
.well h6 {
	margin-top: 0
}

.well h1,
.well h2,
.well h3 {
	line-height: 36px
}

.jumbotron {
	font-size: 18px;
	margin-bottom: 15px;
	background: #f2f2f2;
	border-radius: 0!important
}

.jumbotron.bordered {
	border: 1px solid #e2e1e1
}

@media (max-width:640px) {
	.jumbotron {
		font-size: 13px
	}
}

@media (max-width:320px) {
	.jumbotron h1 {
		font-size: 26px
	}
}

.note {
	border-left: 5px solid #999;
	background-color: #f2f2f2
}

.note h1,
.note h2,
.note h3,
.note h4 {
	margin-top: 0
}

.note p:last-child {
	margin-bottom: 0
}

.note .highlight,
.note code {
	background-color: #f2f2f2
}

.note-danger {
	background-color: #f2dede;
	border-color: #ce838f;
	color: #a94442
}

.note-warning {
	background-color: #fcf8e3;
	border-color: #f0c670;
	color: #8a6d3b
}

.note-info {
	background-color: #d9edf7;
	border-color: #70cee1;
	color: #31708f
}

.note-success {
	background-color: #dff0d8;
	border-color: #a1cd7c;
	color: #3c763d
}

.alert,
.note {
	padding: 15px;
	margin: 0 0 15px
}

.alert .close,
.note .close {
	font-size: 16px;
	outline: 0!important
}

.alert.alert-danger a,
.alert.alert-info a,
.alert.alert-success a,
.alert.alert-warning a,
.alert.bg-info a,
.alert.bg-primary a,
.alert.bg-success a,
.alert.bg-succsss a,
.alert.bg-warning a,
.alert.dark a,
.alert.gray a,
.alert.note-danger a,
.alert.note-info a,
.alert.note-success a,
.alert.note-warning a,
.note.alert-danger a,
.note.alert-info a,
.note.alert-success a,
.note.alert-warning a,
.note.bg-info a,
.note.bg-primary a,
.note.bg-success a,
.note.bg-succsss a,
.note.bg-warning a,
.note.dark a,
.note.gray a,
.note.note-danger a,
.note.note-info a,
.note.note-success a,
.note.note-warning a {
	text-decoration: underline
}

.alert.alert-success a,
.alert.note-success a,
.note.alert-success a,
.note.note-success a {
	color: #31708f
}

.alert.alert-danger a,
.alert.note-danger a,
.note.alert-danger a,
.note.note-danger a {
	color: #a94442
}

.alert.alert-info a,
.alert.note-info a,
.note.alert-info a,
.note.note-info a {
	color: #31708f
}

.alert.alert-warning a,
.alert.note-warning a,
.note.alert-warning a,
.note.note-warning a {
	color: #8a6d3b
}

.alert.bg-info a,
.alert.bg-primary a,
.alert.bg-success a,
.alert.bg-succsss a,
.alert.bg-warning a,
.alert.dark a,
.note.bg-info a,
.note.bg-primary a,
.note.bg-success a,
.note.bg-succsss a,
.note.bg-warning a,
.note.dark a {
	color: #fff
}

.tooltip.in {
	opacity: 1
}

.tooltip-inner {
	background-color: #222;
	color: #fff;
	font-size: 12px;
	white-space: nowrap;
	text-shadow: 1px 1px 0 rgba(42, 45, 50, .5);
	border-radius: 0;
	padding: 5px 9px
}

.tooltip.top .tooltip-arrow {
	border-top-color: #222
}

.tooltip.right .tooltip-arrow {
	border-right-color: #222
}

.tooltip.left .tooltip-arrow {
	border-left-color: #222
}

.tooltip.bottom .tooltip-arrow {
	border-bottom-color: #222
}

.tooltip-danger+.tooltip>.tooltip-inner {
	background-color: #d15b47;
	color: #fff;
	text-shadow: 1px 1px 0 rgba(100, 60, 20, .3);
	border-radius: 0
}

.tooltip-danger+.tooltip.top .tooltip-arrow {
	border-top-color: #d15b47
}

.tooltip-danger+.tooltip.right .tooltip-arrow {
	border-right-color: #d15b47
}

.tooltip-danger+.tooltip.left .tooltip-arrow {
	border-left-color: #d15b47
}

.tooltip-danger+.tooltip.bottom .tooltip-arrow {
	border-bottom-color: #d15b47
}

.tooltip-success+.tooltip>.tooltip-inner {
	background-color: #82af6f;
	color: #fff;
	text-shadow: 1px 1px 0 rgba(60, 100, 20, .3);
	border-radius: 0
}

.tooltip-success+.tooltip.top .tooltip-arrow {
	border-top-color: #82af6f
}

.tooltip-success+.tooltip.right .tooltip-arrow {
	border-right-color: #82af6f
}

.tooltip-success+.tooltip.left .tooltip-arrow {
	border-left-color: #82af6f
}

.tooltip-success+.tooltip.bottom .tooltip-arrow {
	border-bottom-color: #82af6f
}

.tooltip-warning+.tooltip>.tooltip-inner {
	background-color: #ffb752;
	color: #fff;
	text-shadow: 1px 1px 0 rgba(100, 90, 10, .3);
	border-radius: 0
}

.tooltip-warning+.tooltip.top .tooltip-arrow {
	border-top-color: #ffb752
}

.tooltip-warning+.tooltip.right .tooltip-arrow {
	border-right-color: #ffb752
}

.tooltip-warning+.tooltip.left .tooltip-arrow {
	border-left-color: #ffb752
}

.tooltip-warning+.tooltip.bottom .tooltip-arrow {
	border-bottom-color: #ffb752
}

.tooltip-info+.tooltip>.tooltip-inner {
	background-color: #3a87ad;
	color: #fff;
	text-shadow: 1px 1px 0 rgba(40, 50, 100, .3);
	border-radius: 0
}

.tooltip-info+.tooltip.top .tooltip-arrow {
	border-top-color: #3a87ad
}

.tooltip-info+.tooltip.right .tooltip-arrow {
	border-right-color: #3a87ad
}

.tooltip-info+.tooltip.left .tooltip-arrow {
	border-left-color: #3a87ad
}

.tooltip-info+.tooltip.bottom .tooltip-arrow {
	border-bottom-color: #3a87ad
}

.tooltip-primary+.tooltip>.tooltip-inner {
	background-color: #428bca;
	color: #fff;
	text-shadow: 1px 1px 0 rgba(40, 50, 100, .3);
	border-radius: 0
}

.tooltip-primary+.tooltip.top .tooltip-arrow {
	border-top-color: #428bca
}

.tooltip-primary+.tooltip.right .tooltip-arrow {
	border-right-color: #428bca
}

.tooltip-primary+.tooltip.left .tooltip-arrow {
	border-left-color: #428bca
}

.tooltip-primary+.tooltip.bottom .tooltip-arrow {
	border-bottom-color: #428bca
}

.lists {
	padding-left: 0
}

.lists a.list-group-item {
	color: #2b3539
}

.lists li {
	border: 1px solid #e2e1e1;
	border-width: 0 0 1px;
	margin-bottom: 0;
	padding: 0 15px;
	background-color: #fff;
	list-style: none;
	position: relative;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	height: 39px;
	line-height: 39px
}

.lists li:hover {
	background: #f2f2f2;
	cursor: pointer;
	-webkit-transition: all .3s ease;
	-o-transition: all .3s ease;
	transition: all .3s ease
}

.lists li:hover:after {
	content: "\e092";
	font-family: 'Glyphicons Halflings';
	display: block;
	position: absolute;
	right: 15px;
	top: 0;
	color: #999;
	height: 39px;
	line-height: 39px;
	font-size: 24px
}

.lists li:hover .icons {
	opacity: .7;
	filter: alpha(opacity=70)
}

.lists li div,
.lists li span {
	display: block;
	height: 39px;
	line-height: 39px;
	float: left;
	position: relative;
	overflow: hidden
}

.lists li div.feed,
.lists li span.feed {
	line-height: 15px!important;
	padding: 12px 0 0;
	white-space: nowrap;
	text-overflow: ellipsis;
	max-width: 75%;
	position: relative
}

.lists li div.feed p,
.lists li span.feed p {
	margin: 0;
	padding: 0;
	font-weight: 600;
	max-width: 45%;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden
}

.lists li div.feed small,
.lists li span.feed small {
	display: block;
	font-style: italic;
	color: #999;
	font-size: 100%;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden
}

.lists li div.feed a,
.lists li div.feed a:focus,
.lists li div.feed a:hover,
.lists li span.feed a,
.lists li span.feed a:focus,
.lists li span.feed a:hover {
	text-decoration: none
}

.lists li div.date,
.lists li div.icons,
.lists li div.users,
.lists li span.date,
.lists li span.icons,
.lists li span.users {
	padding-right: 10px;
	color: #393939
}

.lists li div.icons,
.lists li span.icons {
	font-size: 16px
}

.lists li div.date-time,
.lists li span.date-time {
	float: right;
	position: absolute;
	top: -10px;
	right: 15px;
	font-size: 11px;
	color: #393939
}

.lists li div img,
.lists li span img {
	border: 2px solid #7bb7e5;
	width: 37px;
	height: 37px;
	max-width: 37px;
	max-height: 37px
}

.lists li label {
	display: none;
	float: left;
	height: 39px;
	line-height: 39px;
	padding-right: 10px
}

.lists li label .labels {
	float: none
}

.lists li.lg,
.lists li.lg div,
.lists li.lg label,
.lists li.lg span,
.lists li.lg:hover:after {
	height: 55px;
	line-height: 55px
}

.lists li.lg div .labels,
.lists li.lg div input[type=checkbox],
.lists li.lg label .labels,
.lists li.lg label input[type=checkbox],
.lists li.lg span .labels,
.lists li.lg span input[type=checkbox] {
	margin-top: 15px
}

@media (min-width:767px) {
	.lists li label {
		display: block
	}
}

.task-lists a.list-group-item {
	color: #2b3539
}

.task-lists li {
	border: 0;
	padding: 5px 0
}

.task-lists.ui-sortable .list-group-item:hover,
.task-lists.ui-sortable a.list-group-item:focus {
	text-decoration: none
}

.task-widget ul.task-lists {
	list-style: none
}

.task-widget .list-group {
	margin-bottom: 0
}

.task-widget .tcb {
	display: inline-block;
	margin-bottom: -5px
}

.task-widget .tcb .labels {
	cursor: pointer
}

.task-widget.task-lists label.todo-done span {
	text-decoration: line-through;
	color: #999
}

.lists-2 {
	margin-bottom: 0
}

.lists-2>li {
	padding: 0
}

.lists-2>li a {
	display: block;
	padding: 10px 15px;
	color: #393939;
	text-decoration: none;
	font-weight: 500
}

.lists-2>li a [class*=fa-] {
	color: #999;
	font-size: 18px;
	width: 25px
}

.lists-2>li a [class*=glyphicon-] {
	width: 25px;
	font-size: 16px;
	color: #999;
	display: inline-block
}

.lists-2>li.active,
.lists-2>li.active:hover {
	background: #7bb7e5;
	border: none
}

.lists-2>li.active,
.lists-2>li.active .media-heading,
.lists-2>li.active [class*=glyphglyphicon-],
.lists-2>li.active [class*=fa-],
.lists-2>li.active p,
.lists-2>li.active p *,
.lists-2>li.active:hover,
.lists-2>li.active:hover .media-heading,
.lists-2>li.active:hover [class*=glyphglyphicon-],
.lists-2>li.active:hover [class*=fa-],
.lists-2>li.active:hover p,
.lists-2>li.active:hover p *,
.lists-2>li.active:hover>*,
.lists-2>li.active>* {
	color: #fff!important
}

.lists-2>li.active .badge,
.lists-2>li.active:hover .badge {
	border: 1px solid #fff
}

.lists-2>li:hover {
	background: #f2f2f2
}

.lists-2>li:hover>a>[class*=glyphglyphicon-],
.lists-2>li:hover>a>[class*=fa-] {
	color: #7bb7e5
}

.eno-tabs {
	margin-bottom: 15px
}

.eno-tabs .nav-tabs {
	border-color: #e2e1e1;
	margin-bottom: 0;
	margin-left: 0;
	position: relative;
	border-bottom: 0 none
}

.eno-tabs .nav-tabs>li>a,
.eno-tabs .nav-tabs>li>a:focus {
	border-radius: 0!important;
	background-color: #f2f2f2;
	color: #555;
	margin-right: -1px;
	line-height: 16px;
	border-color: #e2e1e1;
	position: relative;
	z-index: 11
}

.eno-tabs .nav-tabs>li>a:hover {
	background-color: #fff;
	color: #555;
	border-color: #e2e1e1
}

.eno-tabs .nav-tabs>li>a:active,
.eno-tabs .nav-tabs>li>a:focus {
	outline: 0!important
}

.eno-tabs .nav-tabs>li:first-child>a {
	margin-left: 0
}

.eno-tabs .nav-tabs>li:last-child>a {
	margin-right: 0
}

.eno-tabs .nav-tabs>li .badge {
	padding: 0 4px;
	line-height: 15px;
	margin-top: -5px;
	opacity: 1
}

.eno-tabs .nav-tabs>li.active>a,
.eno-tabs .nav-tabs>li.active>a:focus,
.eno-tabs .nav-tabs>li.active>a:hover {
	color: #555;
	border-color: #e2e1e1 #e2e1e1 transparent;
	border-top: 1px solid #7db4d8;
	background-color: #fff;
	z-index: 12;
	line-height: 16px
}

.eno-tabs .nav-tabs.no-padding>li:first-child a {
	border-left: 0 none
}

.eno-tabs .nav-tabs.no-padding>li a,
.eno-tabs .nav-tabs.no-padding>li.active>a,
.eno-tabs .nav-tabs.no-padding>li.active>a:focus,
.eno-tabs .nav-tabs.no-padding>li.active>a:hover {
	border-top-color: transparent
}

.eno-tabs .nav-tabs.pull-right>li:first-child a {
	border-left: 1px solid #e2e1e1
}

.eno-tabs .nav-tabs.pull-right>li:last-child a {
	border-right: 0 none
}

.eno-tabs .nav-tabs[class*=tab-color-]>li>a,
.eno-tabs .nav-tabs[class*=tab-color-]>li>a:focus,
.eno-tabs .nav-tabs[class*=tab-color-]>li>a:hover {
	color: #fff;
	border-color: transparent;
	margin-right: 2px
}

.eno-tabs .nav-tabs.tab-color-dark>li>a,
.eno-tabs .nav-tabs.tab-color-dark>li>a:focus {
	background-color: #7db4d8
}

.eno-tabs .nav-tabs[class*=tab-color-]>li.active>a,
.eno-tabs .nav-tabs[class*=tab-color-]>li.active>a:focus,
.eno-tabs .nav-tabs[class*=tab-color-]>li.active>a:hover {
	background-color: #fff;
	color: #393939;
	box-shadow: none
}

.eno-tabs .nav-tabs.tab-color-dark>li.active>a,
.eno-tabs .nav-tabs.tab-color-dark>li.active>a:focus,
.eno-tabs .nav-tabs.tab-color-dark>li.active>a:hover {
	color: #393939;
	border-color: #7db4d8 #e2e1e1 transparent
}

.tabs-below .eno-tabs .nav-tabs.tab-color-dark>li.active>a {
	border-color: transparent #e2e1e1 #e2e1e1
}

.eno-tabs .nav-tabs.background-dark {
	background-color: #f2f2f2;
	border: 1px solid #e2e1e1;
	border-bottom: 0 none;
	padding: 15px 15px 0
}

.eno-tabs.tabs-bottom .nav-tabs.tab-color-dark {
	border-bottom: 1px solid #e2e1e1
}

.eno-tabs .tab-content {
	padding: 15px;
	border: 1px solid #e2e1e1;
	position: relative;
	background: #fff;
	z-index: 11
}

.eno-tabs .nav-pills .open .dropdown-toggle,
.eno-tabs .nav>li.dropdown.open.active>a:focus,
.eno-tabs .nav>li.dropdown.open.active>a:hover {
	background-color: #7db4d8;
	border-color: #7db4d8;
	color: #f2f2f2
}

.eno-tabs .nav-pills .open .dropdown-toggle [class*=glyphicon-],
.eno-tabs .nav-pills .open .dropdown-toggle>[class*=fa-],
.eno-tabs .nav>li.dropdown.open.active>a:focus [class*=glyphicon-],
.eno-tabs .nav>li.dropdown.open.active>a:focus>[class*=fa-],
.eno-tabs .nav>li.dropdown.open.active>a:hover [class*=glyphicon-],
.eno-tabs .nav>li.dropdown.open.active>a:hover>[class*=fa-] {
	color: #f2f2f2!important
}

.eno-tabs.lg .nav-tabs>li>a {
	padding: 15px;
	font-size: 14px
}

.tabs-bottom>.nav-tabs {
	top: auto;
	margin-bottom: 0;
	margin-top: -1px;
	border-color: #e2e1e1;
	border-bottom-width: 0
}

.tabs-bottom>.nav-tabs>li>a,
.tabs-bottom>.nav-tabs>li>a:focus,
.tabs-bottom>.nav-tabs>li>a:hover {
	border-color: #e2e1e1
}

.tabs-bottom>.nav-tabs>li.active>a,
.tabs-bottom>.nav-tabs>li.active>a:focus,
.tabs-bottom>.nav-tabs>li.active>a:hover {
	border-color: transparent #e2e1e1 #e2e1e1;
	border-bottom: 1px solid #7db4d8;
	margin-top: 0
}

.tabs-left>.nav-tabs>li>a,
.tabs-right>.nav-tabs>li>a {
	min-width: 60px
}

.tabs-left>.nav-tabs {
	top: auto;
	margin-bottom: 0;
	border-color: #e2e1e1;
	float: left
}

.tabs-left>.nav-tabs>li {
	float: none
}

.tabs-left>.nav-tabs>li>a,
.tabs-left>.nav-tabs>li>a:focus,
.tabs-left>.nav-tabs>li>a:hover {
	border-color: #e2e1e1 transparent #e2e1e1 #e2e1e1;
	margin: 0
}

.tabs-left>.nav-tabs>li.active>a,
.tabs-left>.nav-tabs>li.active>a:focus,
.tabs-left>.nav-tabs>li.active>a:hover {
	border-color: #e2e1e1 transparent #e2e1e1 #e2e1e1;
	border-top-width: 1px;
	border-left: 1px solid #7db4d8;
	margin: 0 -1px 0 0;
	box-shadow: none
}

.tabs-right>.nav-tabs {
	top: auto;
	margin-bottom: 0;
	border-color: #e2e1e1;
	float: right
}

.tabs-right>.nav-tabs>li {
	float: none
}

.tabs-right>.nav-tabs>li>a,
.tabs-right>.nav-tabs>li>a:focus,
.tabs-right>.nav-tabs>li>a:hover {
	border-color: #e2e1e1 #e2e1e1 #e2e1e1 transparent;
	margin: 0
}

.tabs-right>.nav-tabs>li.active>a,
.tabs-right>.nav-tabs>li.active>a:focus,
.tabs-right>.nav-tabs>li.active>a:hover {
	border-color: #e2e1e1 #e2e1e1 #e2e1e1 transparent;
	border-top-width: 1px;
	border-right: 1px solid #7db4d8;
	margin: 0 0 0 -1px;
	box-shadow: none
}

.nav-tabs>li>a>.badge {
	padding: 0 4px;
	line-height: 15px
}

.nav-tabs li [class*=" fa-"],
.nav-tabs li [class*=" glyphicon-"],
.nav-tabs li [class^=fa-],
.nav-tabs li [class^=glyphicon-] {
	width: 1em;
	display: inline-block
}

.nav-tabs>li.open .dropdown-toggle {
	background-color: #7db4d8;
	border-color: #7db4d8;
	color: #fff
}

.nav-tabs>li.open .dropdown-toggle [class*=glyphicon-],
.nav-tabs>li.open .dropdown-toggle>[class*=fa-] {
	color: #fff
}

.tabs-left .tab-content,
.tabs-right .tab-content {
	overflow: auto
}

@media (max-width:480px) {
	.tabs-left>.nav-tabs,
	.tabs-right>.nav-tabs {
		float: none!important;
		margin-bottom: -1px
	}
	.tabs-left>.nav-tabs>li>a,
	.tabs-left>.nav-tabs>li>a:focus,
	.tabs-left>.nav-tabs>li>a:hover,
	.tabs-right>.nav-tabs>li>a,
	.tabs-right>.nav-tabs>li>a:focus,
	.tabs-right>.nav-tabs>li>a:hover {
		border-color: #e2e1e1;
		margin: 0
	}
	.tabs-left>.nav-tabs>li.active>a,
	.tabs-left>.nav-tabs>li.active>a:focus,
	.tabs-left>.nav-tabs>li.active>a:hover,
	.tabs-right>.nav-tabs>li.active>a,
	.tabs-right>.nav-tabs>li.active>a:focus,
	.tabs-right>.nav-tabs>li.active>a:hover {
		border-color: #e2e1e1;
		border-top-width: 1px;
		margin: 0;
		box-shadow: none
	}
}

.eno-tabs .tab-lg-button.nav-tabs>li>a {
	padding-left: 15px;
	padding-right: 15px
}

.eno-tabs .tab-lg-button.nav-tabs>li>a [class*=glyphicon-]:first-child,
.eno-tabs .tab-lg-button.nav-tabs>li>a>[class*=fa-]:first-child {
	display: block;
	margin-bottom: 6px;
	width: auto
}

.eno-tabs .tab-lg-button.nav-tabs>li>a>.badge {
	position: absolute;
	line-height: 15px;
	width: 17px;
	height: 17px;
	font-size: 10px;
	padding: 1px;
	border-radius: 50%;
	top: 7px;
	right: 10px
}

@media (max-width:480px) {
	.eno-tabs .tab-lg-button.nav-tabs.tab-color-dark {
		padding: 0;
		border: 0;
		margin-bottom: -1px
	}
	.eno-tabs .tab-lg-button.nav-tabs.tab-color-dark>li.active>a,
	.eno-tabs .tab-lg-button.nav-tabs.tab-color-dark>li.active>a:focus,
	.eno-tabs .tab-lg-button.nav-tabs.tab-color-dark>li.active>a:hover {
		border-bottom-color: #e2e1e1
	}
	.eno-tabs .tab-lg-button.nav-tabs.tab-color-dark>li>a {
		margin-right: 0;
		margin-bottom: 2px
	}
	.eno-tabs .tab-lg-button.nav-tabs.tab-color-dark>li>a.active,
	.eno-tabs .tab-lg-button.nav-tabs.tab-color-dark>li>a:focus,
	.eno-tabs .tab-lg-button.nav-tabs.tab-color-dark>li>a:hover {
		margin-right: 0
	}
	.eno-tabs .tab-lg-button.nav-tabs.tab-color-dark>li>a .badge {
		float: right!important
	}
	.eno-tabs .tab-lg-button.nav-tabs.tab-color-dark>li:last-child.active>a {
		border-bottom: 0 none
	}
	.eno-tabs .tab-lg-button.nav-tabs>li>a [class*=glyphicon-]:first-child,
	.eno-tabs .tab-lg-button.nav-tabs>li>a>[class*=fa-]:first-child {
		display: inline-block;
		margin-bottom: 0;
		width: auto
	}
	.eno-tabs .tab-lg-button.nav-tabs>li>a [class*=glyphicon-],
	.eno-tabs .tab-lg-button.nav-tabs>li>a>[class*=fa-] {
		margin-right: 5px;
		font-size: 13px!important
	}
	.eno-tabs .tab-lg-button.nav-tabs>li {
		float: none
	}
	.eno-tabs .tab-lg-button.nav-tabs>li.active>a,
	.eno-tabs .tab-lg-button.nav-tabs>li.active>a:focus,
	.eno-tabs .tab-lg-button.nav-tabs>li.active>a:hover {
		border-bottom-color: #e2e1e1
	}
	.eno-tabs .nav-tabs>li .badge {
		margin-top: 0
	}
}

.eno-tabs .tab-small-button.nav-tabs>li>a {
	padding: 12px 12px 5px
}

.eno-tabs .tab-small-button.nav-tabs>li>a [class*=glyphicon-]:first-child,
.eno-tabs .tab-small-button.nav-tabs>li>a>[class*=fa-]:first-child {
	display: block;
	margin-bottom: 6px;
	width: auto
}

.eno-tabs .tab-small-button.nav-tabs>li>a>.badge {
	position: absolute;
	line-height: 15px;
	width: 17px;
	height: 17px;
	font-size: 10px;
	padding: 1px;
	border-radius: 50%;
	top: 7px;
	right: 2px
}

.eno-tabs .nav-tabs.nav-justified {
	margin-bottom: -1px
}

.eno-tabs .nav-tabs.nav-justified>li>a {
	text-align: left!important
}

@media (max-width:767px) {
	.eno-tabs .nav-tabs.nav-justified {
		padding: 0;
		border: 0
	}
	.eno-tabs .nav-tabs.nav-justified>li.active>a,
	.eno-tabs .nav-tabs.nav-justified>li.active>a:focus,
	.eno-tabs .nav-tabs.nav-justified>li.active>a:hover {
		border-bottom-color: #e2e1e1
	}
	.eno-tabs .nav-tabs.nav-justified>li>a {
		margin-right: 0;
		margin-bottom: 0
	}
	.eno-tabs .nav-tabs.nav-justified>li>a.active,
	.eno-tabs .nav-tabs.nav-justified>li>a:focus,
	.eno-tabs .nav-tabs.nav-justified>li>a:hover {
		margin-right: 0
	}
	.eno-tabs .nav-tabs.nav-justified>li>a .badge {
		float: right!important
	}
	.eno-tabs .nav-tabs.nav-justified>li:last-child.active>a {
		border-bottom: 0 none
	}
	.eno-tabs .tab-color-dark.nav-justified>li>a {
		margin-bottom: 2px
	}
}

.eno-tabsbar {
	margin-bottom: 15px
}

.eno-tabsbar .nav-tabs {
	border: 1px solid #e2e1e1;
	margin-bottom: 15px
}

.eno-tabsbar .nav-tabs>li {
	height: 41px;
	padding: 1px;
	display: inline-block;
	margin-bottom: 0;
	border-right: 1px solid #e2e1e1
}

.eno-tabsbar .nav-tabs>li>a {
	border: 0;
	height: 39px;
	line-height: 39px;
	display: block;
	padding: 0 15px;
	color: #393939;
	margin-right: 0
}

.eno-tabsbar .nav-tabs>li>a .glyphicon {
	top: 2px
}

.eno-tabsbar .nav-tabs>li>a:active,
.eno-tabsbar .nav-tabs>li>a:focus {
	outline: 0!important
}

.eno-tabsbar .nav-tabs>li>a,
.eno-tabsbar .nav-tabs>li>a:focus {
	border-radius: 0!important
}

.eno-tabsbar .nav-tabs>li>a:hover {
	background-color: transparent
}

.eno-tabsbar .nav-tabs>li>a .badge {
	margin-left: 4px
}

.eno-tabsbar .nav-tabs>li>a:first-child {
	border-left: 0 none
}

.eno-tabsbar .nav-tabs>li.active>a {
	background: #7db4d8;
	color: #fff;
	border: 0
}

.eno-tabsbar .nav-tabs>li.active>a:focus,
.eno-tabsbar .nav-tabs>li.active>a:hover {
	border: 0
}

.eno-tabsbar .nav-tabs>li.active .badge {
	background-color: #fff!important;
	color: #7db4d8!important
}

.eno-tabsbar .nav-tabs.light {
	border-color: #e2e1e1!important
}

.eno-tabsbar .nav-tabs.white {
	background-color: #fff
}

.eno-tabsbar .nav-tabs.white>li>a {
	background: #fff
}

.eno-tabsbar .nav-tabs.white>li.active>a {
	background: #7db4d8!important
}

.eno-tabsbar .nav-tabs.transparent {
	background-color: transparent!important
}

.eno-tabsbar .nav-tabs.transparent>li>a {
	background: #fff
}

.eno-tabsbar .nav-tabs.transparent>li.active>a {
	background: #7db4d8!important
}

@media (min-width:768px) {
	.eno-tabsbar.lg .nav-tabs>li {
		height: 60px;
		padding: 3px
	}
	.eno-tabsbar.lg .nav-tabs>li>a {
		height: 54px;
		line-height: 54px;
		font-size: 14px
	}
}

.eno-tabsbar.arrow .nav-tabs>li.active:after {
	width: 0;
	height: 0;
	z-index: 2;
	content: " ";
	display: block;
	border: 10px solid;
	border-color: #7db4d8 transparent transparent;
	margin: 0 auto -7px
}

.eno-tabsbar.center-tabs .nav-tabs {
	text-align: center;
	margin: 0 auto 15px;
	border: 0;
	padding: 0
}

.eno-tabsbar.center-tabs .nav-tabs>li {
	display: inline-block;
	float: none;
	border: 1px solid #e2e1e1;
	padding: 0!important;
	margin-right: -4px;
	border-right: 0 none
}

.eno-tabsbar.center-tabs .nav-tabs>li.active {
	border-color: #7db4d8
}

.eno-tabsbar.center-tabs .nav-tabs>li.active:last-child {
	border-right: 1px solid #7db4d8
}

.eno-tabsbar.center-tabs .nav-tabs>li:first-child {
	margin-left: 0
}

.eno-tabsbar.center-tabs .nav-tabs>li:last-child {
	margin-right: 0;
	border-right: 1px solid #e2e1e1
}

@media (min-width:767px) {
	.eno-tabsbar.center-tabs.lg .nav-tabs>li>a {
		height: 58px;
		line-height: 58px
	}
}

.eno-tabsbar.center-tabs.tabs-success .nav-tabs li.active {
	border-color: #82af6f!important
}

.eno-tabsbar.center-tabs.tabs-danger .nav-tabs li.active {
	border-color: #d15b47!important
}

.eno-tabsbar.center-tabs.tabs-info .nav-tabs li.active {
	border-color: #3a87ad!important
}

.eno-tabsbar.center-tabs.tabs-warning .nav-tabs li.active {
	border-color: #ffb752!important
}

.eno-tabsbar.center-tabs.tabs-inverse .nav-tabs li.active {
	border-color: #222!important
}

.eno-tabsbar.tabs-success .nav-tabs li.active:after {
	border-color: #82af6f transparent transparent
}

.eno-tabsbar.tabs-success .nav-tabs li.active>a {
	background: #82af6f;
	border: 0
}

.eno-tabsbar.tabs-success .nav-tabs li.active .badge {
	color: #82af6f!important
}

.eno-tabsbar.tabs-danger .nav-tabs li.active:after {
	border-color: #d15b47 transparent transparent
}

.eno-tabsbar.tabs-danger .nav-tabs li.active>a {
	background: #d15b47;
	border: 0
}

.eno-tabsbar.tabs-danger .nav-tabs li.active .badge {
	color: #d15b47!important
}

.eno-tabsbar.tabs-info .nav-tabs li.active:after {
	border-color: #3a87ad transparent transparent
}

.eno-tabsbar.tabs-info .nav-tabs li.active>a {
	background: #3a87ad;
	border: 0
}

.eno-tabsbar.tabs-info .nav-tabs li.active .badge {
	color: #3a87ad!important
}

.eno-tabsbar.tabs-warning .nav-tabs li.active:after {
	border-color: #ffb752 transparent transparent
}

.eno-tabsbar.tabs-warning .nav-tabs li.active>a {
	background: #ffb752;
	border: 0
}

.eno-tabsbar.tabs-warning .nav-tabs li.active .badge {
	color: #ffb752!important
}

.eno-tabsbar.tabs-inverse .nav-tabs li.active:after {
	border-color: #222 transparent transparent
}

.eno-tabsbar.tabs-inverse .nav-tabs li.active>a {
	background: #222;
	border: 0
}

.eno-tabsbar.tabs-inverse .nav-tabs li.active .badge {
	color: #222!important
}

@media (max-width:767px) {
	.eno-tabsbar .nav-tabs {
		border: 0!important
	}
	.eno-tabsbar .nav-tabs>li {
		display: block!important;
		text-align: left!important;
		float: none!important;
		border: 1px solid #e2e1e1!important;
		margin-right: 0!important;
		margin-bottom: -1px;
		padding: 0!important
	}
	.eno-tabsbar .nav-tabs>li.active {
		border-color: #7db4d8!important
	}
	.eno-tabsbar.arrow .nav-tabs>li.active:after {
		display: none!important
	}
}

.tab-content.no-padding {
	padding: 0
}

.tab-content.no-border {
	border: 0;
	padding: 15px
}

.tab-content.padding-32 {
	padding: 32px 24px
}

.tab-content.no-border.padding-32 {
	padding: 32px
}

.tab-content.padding-30 {
	padding: 30px 23px
}

.tab-content.no-border.padding-30 {
	padding: 30px
}

.tab-content.padding-28 {
	padding: 28px 21px
}

.tab-content.no-border.padding-28 {
	padding: 28px
}

.tab-content.padding-26 {
	padding: 26px 20px
}

.tab-content.no-border.padding-26 {
	padding: 26px
}

.tab-content.padding-24 {
	padding: 24px 18px
}

.tab-content.no-border.padding-24 {
	padding: 24px
}

.tab-content.padding-22 {
	padding: 22px 17px
}

.tab-content.no-border.padding-22 {
	padding: 22px
}

.tab-content.padding-20 {
	padding: 20px 15px
}

.tab-content.no-border.padding-20 {
	padding: 20px
}

.tab-content.padding-18 {
	padding: 18px 14px
}

.tab-content.no-border.padding-18 {
	padding: 18px
}

.tab-content.padding-16 {
	padding: 16px 12px
}

.tab-content.no-border.padding-16 {
	padding: 16px
}

.tab-content.padding-14 {
	padding: 14px 11px
}

.tab-content.no-border.padding-14 {
	padding: 14px
}

.tab-content.padding-12 {
	padding: 12px 9px
}

.tab-content.no-border.padding-12 {
	padding: 12px
}

.tab-content.padding-10 {
	padding: 10px 8px
}

.tab-content.no-border.padding-10 {
	padding: 10px
}

.tab-content.padding-8 {
	padding: 8px 6px
}

.tab-content.no-border.padding-8 {
	padding: 8px
}

.tab-content.padding-6 {
	padding: 6px 5px
}

.tab-content.no-border.padding-6 {
	padding: 6px
}

.tab-content.padding-4 {
	padding: 4px 3px
}

.tab-content.no-border.padding-4 {
	padding: 4px
}

.tab-content.no-border.padding-2,
.tab-content.padding-2 {
	padding: 2px
}

.eno-accordion .panel .panel-heading,
.tab-content.no-border.padding-0,
.tab-content.padding-0 {
	padding: 0
}

.eno-accordion .panel .panel-heading a .panel-title {
	color: #393939;
	padding: 10px;
	font-size: 14px
}

.eno-accordion .panel .panel-heading a .panel-title [class*=" fa-"],
.eno-accordion .panel .panel-heading a .panel-title [class*=" glyphicon-"],
.eno-accordion .panel .panel-heading a .panel-title [class^=fa-],
.eno-accordion .panel .panel-heading a .panel-title [class^=glyphicon-] {
	width: 16px;
	min-width: 16px
}

.eno-accordion .panel .panel-heading a:focus,
.eno-accordion .panel .panel-heading a:hover {
	text-decoration: none
}

.eno-accordion .panel.panel-danger.panel,
.eno-accordion .panel.panel-danger.panel .panel-heading,
.eno-accordion .panel.panel-info.panel,
.eno-accordion .panel.panel-info.panel .panel-heading,
.eno-accordion .panel.panel-primary.panel,
.eno-accordion .panel.panel-primary.panel .panel-heading,
.eno-accordion .panel.panel-success.panel,
.eno-accordion .panel.panel-success.panel .panel-heading,
.eno-accordion .panel.panel-warning.panel,
.eno-accordion .panel.panel-warning.panel .panel-heading {
	border-color: #e2e1e1
}

.eno-accordion .panel.panel-danger.panel .panel-heading a .panel-title,
.eno-accordion .panel.panel-info.panel .panel-heading a .panel-title,
.eno-accordion .panel.panel-primary.panel .panel-heading a .panel-title,
.eno-accordion .panel.panel-success.panel .panel-heading a .panel-title,
.eno-accordion .panel.panel-warning.panel .panel-heading a .panel-title {
	color: #fff
}

.eno-accordion .panel.panel-default .panel-heading {
	background-color: #f2f2f2
}

.eno-accordion .panel.panel-primary .panel-heading {
	background-color: #428bca
}

.eno-accordion .panel.panel-success .panel-heading {
	background-color: #82af6f
}

.eno-accordion .panel.panel-info .panel-heading {
	background-color: #3a87ad
}

.eno-accordion .panel.panel-warning .panel-heading {
	background-color: #ffb752
}

.eno-accordion .panel.panel-danger .panel-heading {
	background-color: #d15b47
}

.eno-accordion.no-border .panel.panel-default {
	border: 0
}

.eno-accordion.no-border .panel.panel-default .panel-heading {
	border: 1px solid #7db4d8;
	border-width: 0 0 0 1px
}

.eno-accordion.no-border .panel.panel-default .panel-heading.accordion-active {
	background: #7db4d8
}

.eno-accordion.no-border .panel.panel-default .panel-heading.accordion-active a .panel-title {
	color: #fff
}

.eno-accordion.no-border .panel.panel-default .panel-heading.accordion-active a .panel-title [class*=" fa-"],
.eno-accordion.no-border .panel.panel-default .panel-heading.accordion-active a .panel-title [class*=" glyphicon-"],
.eno-accordion.no-border .panel.panel-default .panel-heading.accordion-active a .panel-title [class^=fa-],
.eno-accordion.no-border .panel.panel-default .panel-heading.accordion-active a .panel-title [class^=glyphicon-] {
	color: #fff!important
}

.eno-accordion.no-border .panel .panel-body {
	border: 0!important
}

.eno-accordion-simple .panel {
	border: 0
}

.eno-accordion-simple .panel .panel-heading {
	padding: 0;
	border-width: 0;
	background-color: transparent!important
}

.eno-accordion-simple .panel .panel-heading a .panel-title {
	padding: 6px 10px;
	font-size: 14px
}

.eno-accordion-simple .panel .panel-heading a .panel-title [class*=" fa-"],
.eno-accordion-simple .panel .panel-heading a .panel-title [class*=" glyphicon-"],
.eno-accordion-simple .panel .panel-heading a .panel-title [class^=fa-],
.eno-accordion-simple .panel .panel-heading a .panel-title [class^=glyphicon-] {
	width: 16px;
	min-width: 16px
}

.eno-accordion-simple .panel .panel-heading a:focus,
.eno-accordion-simple .panel .panel-heading a:hover {
	text-decoration: none
}

.eno-accordion-simple .panel .panel-heading.accordion-active a .panel-title {
	font-weight: 700
}

.eno-accordion-simple .panel .panel-heading+.panel-collapse .panel-body {
	border: 0;
	padding-top: 10px
}

.eno-accordion-simple .panel.panel-default .panel-heading a .panel-title {
	color: #393939
}

.eno-accordion-simple .panel.panel-primary .panel-heading a .panel-title {
	color: #428bca!important
}

.eno-accordion-simple .panel.panel-success .panel-heading a .panel-title {
	color: #82af6f!important
}

.eno-accordion-simple .panel.panel-warning .panel-heading a .panel-title {
	color: #ffb752!important
}

.eno-accordion-simple .panel.panel-info .panel-heading a .panel-title {
	color: #3a87ad!important
}

.progress {
	box-shadow: none;
	background: #e2e1e1;
	height: 18px
}

.progress .progress-bar {
	box-shadow: none;
	line-height: 18px
}

.progress[data-percent] {
	position: relative
}

.progress[data-percent]:after {
	display: inline-block;
	content: attr(data-percent);
	color: #fff;
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	line-height: 16px;
	text-align: center;
	font-size: 12px;
	font-family: Verdana
}

.progress.progress-yellow[data-percent]:after {
	color: #963
}

.progress.progress-small {
	height: 12px
}

.progress.progress-small .progress-bar,
.progress.progress-small[data-percent]:after {
	line-height: 11px;
	font-size: 11px
}

.progress.progress-mini {
	height: 9px
}

.progress.progress-mini .progress-bar,
.progress.progress-mini[data-percent]:after {
	line-height: 8px;
	font-size: 11px
}

.progress-bar {
	background-color: #999
}

.progress-bar-primary {
	background-color: #428bca
}

.progress-bar-danger {
	background-color: #d15b47
}

.progress-bar-success {
	background-color: #82af6f
}

.progress-bar-warning {
	background-color: #ffb752
}

.progress-bar-info {
	background-color: #3a87ad
}

.progress-bar-inverse {
	background-color: #2b3539
}

.progress {
	position: relative
}

.progress:before {
	display: inline-block;
	content: "";
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	background: rgba(0, 0, 255, 0)
}

.skills .progress[data-percent] {
	position: relative
}

.skills .progress[data-percent]:after {
	display: none
}

.skills .progress .progress-type {
	position: absolute;
	left: 0;
	padding: 1px 7px 0;
	line-height: 16px;
	font-size: 11px;
	color: #f2f2f2;
	background-color: rgba(25, 25, 25, .2)
}

.skills .progress .progress-completed {
	position: absolute;
	right: 0;
	padding: 0 7px;
	line-height: 16px
}

.skills .progress.progress-mini {
	height: 9px
}

.skills .progress.progress-mini .progress-bar {
	line-height: 8px
}

.skills .progress.progress-mini .progress-completed,
.skills .progress.progress-mini .progress-type {
	display: none
}

.skills .progress.progress-small .progress-completed,
.skills .progress.progress-small .progress-type {
	font-size: 11px;
	line-height: 11px!important
}

#eno-slider ul.thumbnails,
#eno-slider-2 ul.thumbnails,
.owl-carousel ul.thumbnails {
	list-style: none;
	margin: 0;
	padding: 0
}

@media (max-width:640px) {
	#eno-slider ul.thumbnails li,
	#eno-slider-2 ul.thumbnails li,
	.owl-carousel ul.thumbnails li {
		margin-bottom: 30px
	}
}

#eno-slider .slide-item,
#eno-slider .thumbnail,
#eno-slider-2 .slide-item,
#eno-slider-2 .thumbnail,
.owl-carousel .slide-item,
.owl-carousel .thumbnail {
	position: relative;
	overflow: hidden
}

#eno-slider .slide-item img,
#eno-slider .thumbnail img,
#eno-slider-2 .slide-item img,
#eno-slider-2 .thumbnail img,
.owl-carousel .slide-item img,
.owl-carousel .thumbnail img {
	-webkit-transition: all .5s;
	-o-transition: all .5s;
	transition: all .5s
}

#eno-slider .slide-item .tools,
#eno-slider .thumbnail .tools,
#eno-slider-2 .slide-item .tools,
#eno-slider-2 .thumbnail .tools,
.owl-carousel .slide-item .tools,
.owl-carousel .thumbnail .tools {
	position: absolute;
	top: -100%;
	right: 0;
	width: 100%;
	height: 100%;
	text-align: center;
	color: #fff!important;
	z-index: 2;
	-webkit-transition: all .5s ease-in-out;
	-o-transition: all .5s ease-in-out;
	transition: all .5s ease-in-out
}

#eno-slider .slide-item .tools .fa,
#eno-slider .slide-item .tools .glyphicon,
#eno-slider .thumbnail .tools .fa,
#eno-slider .thumbnail .tools .glyphicon,
#eno-slider-2 .slide-item .tools .fa,
#eno-slider-2 .slide-item .tools .glyphicon,
#eno-slider-2 .thumbnail .tools .fa,
#eno-slider-2 .thumbnail .tools .glyphicon,
.owl-carousel .slide-item .tools .fa,
.owl-carousel .slide-item .tools .glyphicon,
.owl-carousel .thumbnail .tools .fa,
.owl-carousel .thumbnail .tools .glyphicon {
	background: rgba(0, 0, 0, .55);
	font-size: 16px;
	padding: 15px;
	border-radius: 100%;
	color: #f2f2f2;
	-webkit-transition: all .5s linear;
	-o-transition: all .5s linear;
	transition: all .5s linear
}

#eno-slider .slide-item .tools .fa:hover,
#eno-slider .slide-item .tools .glyphicon:hover,
#eno-slider .thumbnail .tools .fa:hover,
#eno-slider .thumbnail .tools .glyphicon:hover,
#eno-slider-2 .slide-item .tools .fa:hover,
#eno-slider-2 .slide-item .tools .glyphicon:hover,
#eno-slider-2 .thumbnail .tools .fa:hover,
#eno-slider-2 .thumbnail .tools .glyphicon:hover,
.owl-carousel .slide-item .tools .fa:hover,
.owl-carousel .slide-item .tools .glyphicon:hover,
.owl-carousel .thumbnail .tools .fa:hover,
.owl-carousel .thumbnail .tools .glyphicon:hover {
	background: #7bb7e5
}

#eno-slider .slide-item .tools .btn,
#eno-slider .thumbnail .tools .btn,
#eno-slider-2 .slide-item .tools .btn,
#eno-slider-2 .thumbnail .tools .btn,
.owl-carousel .slide-item .tools .btn,
.owl-carousel .thumbnail .tools .btn {
	background: rgba(0, 0, 0, .55);
	border: 1px solid rgba(0, 0, 0, .1);
	font-size: 13px;
	color: #f2f2f2;
	-webkit-transition: all .5s linear;
	-o-transition: all .5s linear;
	transition: all .5s linear
}

#eno-slider .slide-item .tools .btn:hover,
#eno-slider .thumbnail .tools .btn:hover,
#eno-slider-2 .slide-item .tools .btn:hover,
#eno-slider-2 .thumbnail .tools .btn:hover,
.owl-carousel .slide-item .tools .btn:hover,
.owl-carousel .thumbnail .tools .btn:hover {
	background: #7bb7e5
}

#eno-slider .slide-item:hover img,
#eno-slider .thumbnail:hover img,
#eno-slider-2 .slide-item:hover img,
#eno-slider-2 .thumbnail:hover img,
.owl-carousel .slide-item:hover img,
.owl-carousel .thumbnail:hover img {
	-moz-transform: scale(1.3);
	-webkit-transform: scale(1.3);
	-o-transform: scale(1.3);
	-ms-transform: scale(1.3);
	transform: scale(1.3)
}

#eno-slider .slide-item:hover .tools,
#eno-slider .thumbnail:hover .tools,
#eno-slider-2 .slide-item:hover .tools,
#eno-slider-2 .thumbnail:hover .tools,
.owl-carousel .slide-item:hover .tools,
.owl-carousel .thumbnail:hover .tools {
	top: 35%
}

#eno-slider .carousel {
	margin-bottom: 0
}

#eno-slider .item div.slide-contents {
	padding: 15px 0;
	display: block;
	border-top: 1px dotted #e2e1e1
}

#eno-slider .left-btn,
#eno-slider .right-btn {
	top: -30px
}

#testimonials .carousel {
	margin-bottom: 0
}

#testimonials .item div.testimonials-slide {
	padding: 15px;
	color: #8a8a8a;
	display: block;
	font-size: 14px;
	line-height: 20px;
	font-style: italic;
	background: #f8f8f8
}

#testimonials .carousel-info {
	padding-top: 15px;
	background: url(../../images/arrow-testimonials.png) 83px 0 no-repeat
}

#testimonials .carousel-info img {
	width: 75px;
	height: 75px;
	padding: 3px;
	border-radius: 150px!important;
	border: 1px solid #f5f5f5
}

#testimonials .carousel-info {
	overflow: hidden
}

#testimonials .carousel-info img {
	margin-right: 15px
}

#testimonials .carousel-info span {
	display: block
}

#testimonials span.testimonials-name {
	color: #7bb7e5;
	font-size: 14px;
	margin: 23px 0 7px
}

#testimonials span.testimonials-post {
	color: #656565;
	font-size: 12px
}

#testimonials .left-btn,
#testimonials .right-btn {
	bottom: 30px
}

#eno-slider .left-btn,
#eno-slider .right-btn,
#testimonials .left-btn,
#testimonials .right-btn {
	width: 26px;
	height: 26px;
	position: absolute;
	display: inline-block;
	background: #f2f2f2;
	color: #999;
	text-decoration: none;
	font-size: 18px;
	line-height: 25px;
	text-align: center
}

#eno-slider .left-btn:hover,
#eno-slider .right-btn:hover,
#testimonials .left-btn:hover,
#testimonials .right-btn:hover {
	background: #7bb7e5;
	color: #fff
}

#eno-slider .left-btn,
#testimonials .left-btn {
	right: 27px
}

#eno-slider .left-btn:before,
#testimonials .left-btn:before {
	content: "\f104";
	font-family: FontAwesome;
	font-style: normal;
	font-weight: 400
}

#eno-slider .left-btn:hover:before,
#testimonials .left-btn:hover:before {
	content: "\f105"
}

#eno-slider .right-btn,
#testimonials .right-btn {
	right: 0;
	background: #f2f2f2
}

#eno-slider .right-btn:before,
#testimonials .right-btn:before {
	content: "\f105";
	font-family: FontAwesome;
	font-style: normal;
	font-weight: 400
}

#eno-slider .right-btn:hover:before,
#testimonials .right-btn:hover:before {
	content: "\f104"
}

.carousel {
	margin-bottom: 15px
}

.carousel .carousel-caption {
	text-shadow: none;
	background: rgba(0, 0, 0, .5);
	left: 0;
	right: 0;
	bottom: 0
}

.carousel .carousel-caption h4 {
	color: #fff;
	text-shadow: none;
	font-weight: 500
}

.carousel .carousel-control {
	text-shadow: none;
	color: transparent
}

.carousel .carousel-control.left,
.carousel .carousel-control.right {
	background-image: none!important
}

.carousel .carousel-control.right {
	text-align: center
}

.carousel .carousel-indicators {
	bottom: 0
}

.carousel .carousel-indicators li {
	border-radius: 0
}

.carousel.eno-carousel .item {
	overflow: hidden
}

.carousel.eno-carousel .carousel-indicators {
	top: auto;
	bottom: -23px;
	margin: 0;
	width: auto;
	right: 0;
	left: 0
}

.carousel.eno-carousel .carousel-indicators li {
	background: #fff;
	border-color: #7bb7e5
}

.carousel.eno-carousel .carousel-indicators li.active {
	background: #d0e5f6
}

.carousel.eno-carousel .carousel-indicators.right {
	text-align: right
}

.carousel.eno-carousel .carousel-indicators.left {
	text-align: left
}

.carousel.focus .carousel-control,
.carousel:hover .carousel-control {
	color: #7bb7e5;
	opacity: .55;
	filter: alpha(opacity=55.00000000000001)
}

.tp-slider {
	z-index: 1;
	position: relative;
	overflow: hidden;
	border-bottom: 1px solid #e2e1e1
}

.tp-slider .tp-banner-container {
	width: 100%;
	position: relative;
	padding: 0
}

.tp-slider .tp-banner {
	width: 100%;
	position: relative
}

.tp-slider .subtitle_white,
.tp-slider .title,
.tp-slider .title_white {
	font-weight: 300
}

.tp-slider .subtitle,
.tp-slider .title_white_bold {
	font-weight: 400
}

.tp-slider .title,
.tp-slider .title_white {
	font-size: 47px
}

.tp-slider .title {
	color: #2b3539
}

.tp-slider .title_white {
	color: #fff
}

.tp-slider .subtitle,
.tp-slider .subtitle_white {
	font-size: 18px;
	color: #fff;
	padding: 5px 10px;
	text-align: center
}

.tp-slider .subtitle_white {
	border-top: 1px solid #fff!important;
	border-bottom: 1px solid #fff!important
}

.tp-slider .subtitle {
	background-color: #7bb7e5
}

.tp-slider .slide_desc,
.tp-slider .subtitle,
.tp-slider .title {
	-webkit-box-sizing: content-box;
	-moz-box-sizing: content-box;
	box-sizing: content-box
}

.tp-slider .slide_desc {
	font-size: 14px;
	line-height: 24px
}

.tp-slider .slidetitle-2 {
	font-size: 47px;
	color: #555;
	text-decoration: none;
	padding: 15px 10px 5px;
	text-shadow: none;
	text-align: center;
	border-bottom: 3px solid #7bb7e5
}

.tp-slider .tp-leftarrow.default,
.tp-slider .tp-rightarrow.default {
	text-decoration: none;
	font-size: 32px;
	line-height: 38px;
	text-align: center;
	width: 40px!important;
	height: 40px!important;
	background: rgba(0, 0, 0, .7)!important;
	color: #fff;
	border-radius: 100%
}

.tp-slider .tp-leftarrow.default:hover,
.tp-slider .tp-rightarrow.default:hover {
	background-color: #7bb7e5!important
}

.tp-slider .tp-leftarrow.default:before {
	content: "\f104";
	font-family: FontAwesome;
	font-style: normal;
	font-weight: 400
}

.tp-slider .tp-rightarrow.default:before {
	content: "\f105";
	font-family: FontAwesome;
	font-style: normal;
	font-weight: 400
}

.owl-carousel {
	margin-bottom: 15px
}

.owl-carousel .slide-item {
	padding: 0 5px
}

.owl-carousel .slide-item a.contents {
	padding: 10px;
	display: block;
	background: #fff;
	color: #393939;
	text-decoration: none
}

.owl-carousel .slide-item a.contents .title {
	display: block;
	font-size: 14px;
	font-weight: 300
}

.owl-carousel .slide-item a.contents small {
	font-size: 13px;
	font-weight: 300
}

.owl-carousel .slide-item:hover a.contents {
	background: #7bb7e5
}

.owl-carousel .slide-item:hover a.contents:after {
	content: "\e092";
	font-family: 'Glyphicons Halflings';
	display: block;
	position: absolute;
	right: 15px;
	top: auto;
	bottom: 10px;
	color: #fff
}

.owl-carousel .slide-item:hover a.contents .title,
.owl-carousel .slide-item:hover a.contents small {
	color: #fff
}

.owl-carousel.with-contents .slide-item img {
	-webkit-transition: none;
	-o-transition: none;
	transition: none
}

.owl-carousel.with-contents:hover img {
	-moz-transform: scale(1);
	-webkit-transform: scale(1);
	-o-transform: scale(1);
	-ms-transform: scale(1);
	transform: scale(1)
}

.owl-carousel.no-padding-items .slide-item {
	padding: 0
}

.owl-buttons {
	position: absolute;
	top: -37px;
	right: 10px
}

.owl-buttons .owl-next,
.owl-buttons .owl-prev {
	background: #f2f2f2;
	color: #999;
	text-decoration: none;
	font-size: 18px;
	line-height: 25px;
	text-align: center
}

.owl-buttons .owl-next:hover,
.owl-buttons .owl-prev:hover {
	background: #7bb7e5;
	color: #fff
}

.owl-buttons .owl-prev:before {
	content: "\f104";
	font-family: FontAwesome;
	font-style: normal;
	font-weight: 400;
	text-indent: 0
}

.owl-buttons .owl-prev:hover:before {
	content: "\f105"
}

.owl-buttons .owl-next:before {
	content: "\f105";
	font-family: FontAwesome;
	font-style: normal;
	font-weight: 400;
	text-indent: 0
}

.owl-buttons .owl-next:hover:before {
	content: "\f104"
}

.owl-buttons div {
	display: inline-block;
	width: 26px;
	height: 26px;
	margin-left: 5px;
	position: static
}

.owl-controls {
	text-align: center;
	margin-top: 10px
}

.owl-controls .owl-page {
	display: inline-block;
	zoom: 1;
	*display: inline
}

.owl-controls .owl-page span {
	display: block;
	width: 12px;
	height: 12px;
	margin: 5px 7px;
	border: 1px solid #7bb7e5;
	background: #fff;
	color: #7bb7e5
}

.owl-controls .owl-controls.clickable .owl-page:hover span,
.owl-controls .owl-page.active span {
	background: #7bb7e5;
	color: #fff
}

.owl-controls .owl-page span.owl-numbers {
	height: auto;
	width: auto;
	padding: 2px 10px;
	font-size: 12px;
	border-radius: 30px
}

.ui-datepicker {
	background-color: #fff;
	border: 1px solid #e2e1e1
}

.ui-datepicker .ui-widget-header {
	font-weight: 400;
	background: #7bb7e5;
	color: #fff
}

.ui-datepicker .ui-datepicker-next,
.ui-datepicker .ui-datepicker-prev {
	height: 26px;
	min-width: 32px;
	max-width: 32px;
	text-align: center;
	cursor: pointer;
	color: transparent;
	line-height: 26px
}

.ui-datepicker .ui-datepicker-next .ui-icon,
.ui-datepicker .ui-datepicker-prev .ui-icon {
	color: transparent;
	visibility: hidden
}

.ui-datepicker .ui-datepicker-next:hover,
.ui-datepicker .ui-datepicker-prev:hover {
	text-decoration: none
}

.ui-datepicker .ui-datepicker-next:before,
.ui-datepicker .ui-datepicker-prev:before {
	display: inline;
	font-family: FontAwesome;
	font-size: 14px;
	content: "\f100";
	color: #fff
}

.ui-datepicker .ui-datepicker-next-hover,
.ui-datepicker .ui-datepicker-prev-hover {
	top: 2px
}

.ui-datepicker .ui-datepicker-next:before {
	content: "\f101"
}

.ui-datepicker .ui-datepicker-prev-hover {
	left: 2px
}

.ui-datepicker .ui-datepicker-next-hover {
	right: 2px
}

.ui-datepicker td {
	padding: 0
}

.ui-datepicker td>a,
.ui-datepicker td>span {
	display: inline-block;
	min-width: 24px;
	max-width: 24px;
	text-align: center;
	color: #555;
	font-size: 14px
}

.ui-datepicker td>a:hover {
	background-color: #f2f2f2
}

.ui-datepicker td>a.ui-state-highlight {
	background-color: #fee188;
	border: 1px solid #fedb6f
}

.ui-datepicker td>a.ui-state-active {
	background-color: #7bb7e5;
	color: #fff;
	border: 0
}

.ui-datepicker td>a.ui-priority-secondary,
.ui-datepicker td>span {
	color: #999
}

.ui-datepicker td .ui-datepicker-buttonpane {
	background-color: #e2e1e1
}

.ui-spinner {
	border: 1px solid #e2e1e1
}

.ui-spinner-button {
	border-width: 0!important;
	line-height: 15px;
	padding: 0;
	width: 18px;
	cursor: pointer;
	color: #fff!important;
	height: 15px;
	margin: 1px;
	font-size: 13px
}

.ui-spinner-button>[class*=fa-] {
	width: 18px;
	font-size: 10px;
	display: inline-block
}

.ui-spinner-input {
	padding: 5px;
	max-width: 75px;
	width: 75px;
	outline: 0!important;
	margin-right: 23px;
	margin-left: 3px;
	margin-bottom: 4px
}

.ui-widget-overlay {
	background: rgba(0, 0, 0, .25);
	opacity: 1!important;
	filter: alpha(opacity=100)!important;
	z-index: 1040!important
}

.ui-dialog,
.ui-jqdialog {
	z-index: 1050!important;
	background-color: #fff;
	padding: 0;
	border: 1px solid #e2e1e1;
	box-shadow: none
}

.ui-dialog .ui-dialog-titlebar,
.ui-dialog .ui-jqdialog-titlebar,
.ui-jqdialog .ui-dialog-titlebar,
.ui-jqdialog .ui-jqdialog-titlebar {
	background-color: #f2f2f2;
	font-size: 16px;
	color: #555;
	padding: 10px;
	font-weight: 400
}

.ui-dialog .ui-dialog-title,
.ui-dialog .ui-jqdialog-title,
.ui-jqdialog .ui-dialog-title,
.ui-jqdialog .ui-jqdialog-title {
	float: none;
	width: auto
}

.ui-dialog .widget-header,
.ui-jqdialog .widget-header {
	margin: 0;
	border-width: 0 0 1px
}

.ui-dialog .ui-dialog-buttonpane,
.ui-dialog .ui-jqdialog-buttonpane,
.ui-jqdialog .ui-dialog-buttonpane,
.ui-jqdialog .ui-jqdialog-buttonpane {
	background-color: #f2f2f2;
	border-top: 1px solid #e2e1e1
}

.ui-dialog .ui-dialog-titlebar-close,
.ui-dialog .ui-jqdialog-titlebar-close,
.ui-jqdialog .ui-dialog-titlebar-close,
.ui-jqdialog .ui-jqdialog-titlebar-close {
	border: none;
	background: 0 0;
	opacity: .4;
	color: #d15b47;
	padding: 0;
	top: 50%;
	right: 8px!important;
	text-align: center
}

.ui-dialog .ui-dialog-titlebar-close:before,
.ui-dialog .ui-jqdialog-titlebar-close:before,
.ui-jqdialog .ui-dialog-titlebar-close:before,
.ui-jqdialog .ui-jqdialog-titlebar-close:before {
	content: "\f00d";
	display: inline;
	font-family: FontAwesome;
	font-size: 16px
}

.ui-dialog .ui-dialog-titlebar-close:hover,
.ui-dialog .ui-jqdialog-titlebar-close:hover,
.ui-jqdialog .ui-dialog-titlebar-close:hover,
.ui-jqdialog .ui-jqdialog-titlebar-close:hover {
	opacity: 1;
	text-decoration: none;
	padding: 0
}

.ui-dialog .ui-dialog-titlebar-close .ui-button-text,
.ui-dialog .ui-jqdialog-titlebar-close .ui-button-text,
.ui-jqdialog .ui-dialog-titlebar-close .ui-button-text,
.ui-jqdialog .ui-jqdialog-titlebar-close .ui-button-text {
	text-indent: 0;
	visibility: hidden
}

.ui-dialog .widget-header .ui-dialog-titlebar-close,
.ui-dialog .widget-header .ui-jqdialog-titlebar-close,
.ui-jqdialog .widget-header .ui-dialog-titlebar-close,
.ui-jqdialog .widget-header .ui-jqdialog-titlebar-close {
	right: 10px!important
}

.ui-progressbar {
	background-color: #f2f2f2;
	height: 22px
}

.ui-progressbar .ui-progressbar-value {
	margin: 0
}

.ui-progressbar .ui-progressbar-value[class=progress-bar] {
	background-color: #999
}

.ui-slider {
	background-color: #e0e2e2
}

.ui-slider-horizontal {
	height: 10px
}

.ui-slider-vertical {
	width: 10px
}

.ui-slider .ui-slider-handle {
	border-radius: 0;
	width: .9em;
	height: 1.45em;
	cursor: pointer;
	background-color: #f2f2f2;
	border: 1px solid
}

.ui-slider .ui-slider-handle:before {
	display: inline-block
}

.ui-slider .ui-slider-handle:hover {
	background-color: #fff
}

.ui-slider .ui-slider-handle:active,
.ui-slider .ui-slider-handle:focus,
.ui-slider .ui-slider-handle:hover {
	outline: 0;
	box-shadow: 1px 1px 1px 0 rgba(0, 0, 0, .3);
	text-decoration: none
}

.ui-slider .ui-slider-handle:active:before,
.ui-slider .ui-slider-handle:focus:before,
.ui-slider .ui-slider-handle:hover:before {
	text-shadow: 1px 1px 1px rgba(0, 0, 0, .3)
}

.ui-slider-horizontal .ui-slider-handle {
	margin-left: -.725em;
	top: -.4em
}

.ui-slider-vertical .ui-slider-handle {
	left: -.35em;
	margin-bottom: -.65em;
	width: 1.45em;
	height: .9em
}

.ui-slider-range {
	background-color: #7bb7e5
}

.ui-slider-handle {
	outline: 0!important
}

.ui-slider-handle,
.ui-slider-handle:active,
.ui-slider-handle:focus,
.ui-slider-handle:hover {
	border-color: #7bb7e5;
	color: #7bb7e5
}

.ui-slider-inverse .ui-slider-range {
	background-color: #2b3539
}

.ui-slider-inverse .ui-slider-handle,
.ui-slider-inverse .ui-slider-handle:active,
.ui-slider-inverse .ui-slider-handle:focus,
.ui-slider-inverse .ui-slider-handle:hover {
	border-color: #2b3539;
	color: #2b3539
}

.ui-slider-primary .ui-slider-range {
	background-color: #428bca
}

.ui-slider-primary .ui-slider-handle,
.ui-slider-primary .ui-slider-handle:active,
.ui-slider-primary .ui-slider-handle:focus,
.ui-slider-primary .ui-slider-handle:hover {
	border-color: #428bca;
	color: #428bca
}

.ui-slider-success .ui-slider-range {
	background-color: #82af6f
}

.ui-slider-success .ui-slider-handle,
.ui-slider-success .ui-slider-handle:active,
.ui-slider-success .ui-slider-handle:focus,
.ui-slider-success .ui-slider-handle:hover {
	border-color: #82af6f;
	color: #82af6f
}

.ui-slider-danger .ui-slider-range {
	background-color: #d15b47
}

.ui-slider-danger .ui-slider-handle,
.ui-slider-danger .ui-slider-handle:active,
.ui-slider-danger .ui-slider-handle:focus,
.ui-slider-danger .ui-slider-handle:hover {
	border-color: #d15b47;
	color: #d15b47
}

.ui-slider-info .ui-slider-range {
	background-color: #3a87ad
}

.ui-slider-info .ui-slider-handle,
.ui-slider-info .ui-slider-handle:active,
.ui-slider-info .ui-slider-handle:focus,
.ui-slider-info .ui-slider-handle:hover {
	border-color: #3a87ad;
	color: #3a87ad
}

.ui-slider-warning .ui-slider-range {
	background-color: #ffb752
}

.ui-slider-warning .ui-slider-handle,
.ui-slider-warning .ui-slider-handle:active,
.ui-slider-warning .ui-slider-handle:focus,
.ui-slider-warning .ui-slider-handle:hover {
	border-color: #ffb752;
	color: #ffb752
}

.ui-tabs .ui-tabs-nav {
	padding: 0;
	border-bottom: 1px solid #e2e1e1
}

.ui-tabs .ui-tabs-nav li {
	margin: 0
}

.ui-tabs .ui-tabs-nav li.ui-state-default>a {
	background-color: #f2f2f2;
	border: 1px solid #e2e1e1;
	color: #666;
	font-weight: 400;
	line-height: 16px;
	margin-right: -1px;
	padding: 8px 12px;
	position: relative;
	top: 1px
}

@media (max-width:360px) {
	.ui-tabs .ui-tabs-nav li.ui-state-default>a {
		color: #666;
		font-weight: 400;
		line-height: 10px;
		margin-right: -1px;
		padding: 8px 6px;
		position: relative;
		font-size: 11px
	}
}

.ui-tabs .ui-tabs-nav li>a:focus {
	outline: 0
}

.ui-tabs .ui-tabs-nav li.ui-tabs-active>a {
	border: 1px solid;
	border-color: #7bb7e5 #e2e1e1 transparent;
	border-top-width: 1px;
	margin-bottom: 0;
	background: #fff;
	color: #7bb7e5;
	position: relative
}

.ui-tabs .ui-tabs-panel {
	border: 1px solid #e2e1e1;
	border-top-width: 0;
	margin: 0;
	left: auto;
	right: auto;
	top: auto;
	bottom: auto
}

.ui-tooltip {
	background-color: #555;
	color: #fff
}

.ui-accordion .ui-accordion-header {
	color: #555;
	font-weight: 400;
	background-color: #f5f5f5;
	border: 1px solid #e2e1e1;
	padding: 8px 8px 9px 24px;
	font-size: 14px
}

.ui-accordion .ui-accordion-header:hover {
	color: #555
}

.ui-accordion .ui-accordion-header.ui-state-active {
	color: #555;
	position: relative
}

.ui-accordion .ui-accordion-header .ui-accordion-header-icon {
	text-indent: 0;
	margin-top: 0;
	position: absolute;
	left: 10px;
	top: 7px
}

.ui-accordion .ui-accordion-header .ui-accordion-header-icon:before {
	display: inline;
	font-family: FontAwesome;
	font-size: 15px;
	content: "\f105"
}

.ui-accordion .ui-accordion-header.ui-state-active .ui-accordion-header-icon:before {
	content: "\f107";
	font-weight: 400
}

.ui-accordion .ui-accordion-content {
	border: 1px solid #e2e1e1;
	border-top-width: 0;
	padding: 11px 16px
}

.ui-menu {
	-webkit-box-sizing: content-box;
	-moz-box-sizing: content-box;
	box-sizing: content-box;
	width: 150px;
	-webkit-box-shadow: 0 2px 4px rgba(0, 0, 0, .2);
	box-shadow: 0 2px 4px rgba(0, 0, 0, .2);
	background-color: #fff;
	border: 1px solid #e2e1e1;
	padding: 3px
}

.ui-menu .ui-menu-item {
	padding: 5px 10px 6px;
	color: #393939;
	cursor: pointer;
	display: block;
	-webkit-box-sizing: inherit;
	-moz-box-sizing: inherit;
	box-sizing: inherit
}

.ui-menu .ui-menu-item .ui-menu-icon {
	float: right;
	position: relative;
	left: auto;
	right: 4px;
	bottom: auto;
	text-indent: 0
}

.ui-menu .ui-menu-item .ui-menu-icon:before {
	content: "\f105";
	font-family: FontAwesome;
	font-size: 14px;
	display: inline
}

.ui-menu .ui-menu-item:hover,
.ui-menu .ui-menu-item:hover>.ui-menu-icon,
.ui-menu .ui-state-active,
.ui-menu .ui-state-active>.ui-menu-icon,
.ui-menu .ui-state-focus,
.ui-menu .ui-state-focus>.ui-menu-icon {
	text-decoration: none;
	background-color: #7bb7e5;
	color: #fff;
	margin: auto;
	font-weight: 400
}

.ui-menu .ui-menu-item:hover .ui-menu-icon,
.ui-menu .ui-menu-item:hover>.ui-menu-icon .ui-menu-icon,
.ui-menu .ui-state-active .ui-menu-icon,
.ui-menu .ui-state-active>.ui-menu-icon .ui-menu-icon,
.ui-menu .ui-state-focus .ui-menu-icon,
.ui-menu .ui-state-focus>.ui-menu-icon .ui-menu-icon {
	color: #fff
}

.ui-menu .ui-state-disabled,
.ui-menu .ui-state-disabled .ui-menu-icon {
	color: #999;
	cursor: default
}

.ui-menu .ui-state-disabled.ui-state-active,
.ui-menu .ui-state-disabled.ui-state-active .ui-menu-icon,
.ui-menu .ui-state-disabled.ui-state-focus,
.ui-menu .ui-state-disabled.ui-state-focus .ui-menu-icon,
.ui-menu .ui-state-disabled:hover,
.ui-menu .ui-state-disabled:hover .ui-menu-icon {
	background-color: #fff;
	color: #999
}

.ui-selectmenu-button {
	border: 1px solid #aaa
}

.ui-selectmenu-button[aria-expanded=true] {
	border-color: #4492C9
}

.ui-selectmenu-button span.ui-icon {
	text-indent: 0;
	margin-top: -10px
}

.ui-selectmenu-button .ui-icon:before {
	content: "\f0d7";
	display: inline-block;
	font-family: FontAwesome;
	font-size: 14px
}

.ui-autocomplete {
	background-color: #fff;
	border: 1px solid rgba(0, 0, 0, .2);
	-webkit-box-shadow: 0 2px 4px rgba(0, 0, 0, .2);
	box-shadow: 0 2px 4px rgba(0, 0, 0, .2)
}

.ui-autocomplete-category {
	padding: 6px;
	position: relative;
	background-color: #f2f2f2;
	color: #3498db;
	font-weight: bolder;
	border: 1px solid #e2e1e1;
	border-width: 1px 0
}

.ui-sortable-helper,
.ui-sortable-helper>a,
.ui-sortable-placeholder,
.ui-sortable-placeholder>a {
	cursor: move!important
}

.draggable-placeholder {
	border: 1px dashed #fffefc!important;
	background-color: #fff!important
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
	.label:empty,
	::safari-only {
		display: inline-block
	}
	.badge:empty,
	::safari-only {
		display: inline-block
	}
}

.badge {
	font-size: 11px;
	padding: 3px 6px;
	font-weight: 400;
	background-color: #999!important;
	-webkit-border-radius: 12px!important;
	-moz-border-radius: 12px!important;
	border-radius: 12px!important;
	text-shadow: none!important
}

.badge.no-radius {
	border-radius: 0
}

.badge-transparent {
	background-color: transparent!important
}

.badge-info {
	background-color: #3a87ad!important
}

.badge-primary {
	background-color: #428bca!important
}

.badge-success {
	background-color: #82af6f!important
}

.badge-danger {
	background-color: #d15b47!important
}

.badge-inverse {
	background-color: #2b3539!important
}

.badge-warning {
	background-color: #ffb752!important
}

.badge-light {
	background-color: #f2f2f2!important
}

.badge-pending {
	background-color: #3a87ad!important
}

.badge-active {
	background-color: #82af6f!important
}

.badge-suspended {
	background-color: #ffb752!important
}

.badge-paid {
	background-color: #82af6f!important
}

.badge-terminated,
.badge-unpaid {
	background-color: #d15b47!important
}

.badge-cancelled {
	background-color: #bfbfbf!important
}

.badge-expired,
.badge-refunded {
	background-color: #999!important
}

.badge-collections {
	background-color: #ffb752!important
}

.badge-fraud {
	background-color: #222!important
}

.badge-light {
	color: #999!important
}

form .tcb {
	display: block;
	min-height: 20px;
	margin-top: 10px;
	margin-bottom: 10px
}

@media (min-width:768px) {
	form.form-inline .tcb {
		display: inline-block;
		margin-top: 0;
		margin-bottom: 0;
		padding-left: 0;
		vertical-align: middle
	}
}

form .labels {
	cursor: pointer
}

form label {
	font-weight: 600
}

form .form-actions {
	display: block;
	border-top: 1px solid #e2e1e1;
	padding: 15px 10px
}

form .form-actions .form-group {
	margin-bottom: 0
}

form .input-group-addon {
	border: 1px solid #e2e1e1
}

form .input-group-addon [class*=" fa-"],
form .input-group-addon [class*=" glyphicon-"],
form .input-group-addon [class^=fa-],
form .input-group-addon [class^=glyphicon-] {
	color: #393939
}

form .input-group-addon [class*=" glyphicon-"],
form .input-group-addon [class^=glyphicon-] {
	font-size: 13px
}

form .form-group .help-block {
	font-size: 12px;
	margin-bottom: 5px
}

form .form-group.has-success>.input-icon [class*=fa-],
form .form-group.has-success>.input-icon [class*=glyphicon-],
form .form-group.has-success>.input-icon [class^=fa-],
form .form-group.has-success>.input-icon [class^=glyphicon-] {
	color: #3c763d
}

form .form-group.has-warning>.input-icon [class*=fa-],
form .form-group.has-warning>.input-icon [class*=glyphicon-],
form .form-group.has-warning>.input-icon [class^=fa-],
form .form-group.has-warning>.input-icon [class^=glyphicon-] {
	color: #8a6d3b
}

form .form-group.has-error>.input-icon [class*=fa-],
form .form-group.has-error>.input-icon [class*=glyphicon-],
form .form-group.has-error>.input-icon [class^=fa-],
form .form-group.has-error>.input-icon [class^=glyphicon-] {
	color: #a94442
}

form .input-icon {
	position: relative
}

form .input-icon input {
	padding-left: 33px!important
}

form .input-icon [class*=fa-],
form .input-icon [class*=" glyphicon-"],
form .input-icon [class^=fa-],
form .input-icon [class^=glyphicon-] {
	display: block;
	position: absolute;
	margin: 11px 2px 4px 10px;
	width: 16px;
	min-width: 16px;
	height: 16px;
	font-size: 16px
}

form .input-icon.right input {
	padding-left: 12px!important;
	padding-right: 33px!important
}

form .input-icon.right [class*=fa-],
form [class^=fa-] {
	right: 8px;
	float: right
}

.form-horizontal .tcb {
	margin-top: 0!important;
	margin-bottom: 0!important
}

.form-horizontal .tcb-inline {
	display: inline-block;
	padding-left: 0!important;
	margin-bottom: 0;
	vertical-align: middle;
	margin-right: 15px;
	min-height: 20px
}

@media (max-width:767px) {
	.form-horizontal .checkbox,
	.form-horizontal .checkbox-inline,
	.form-horizontal .control-label,
	.form-horizontal .radio,
	.form-horizontal .radio-inline {
		margin-bottom: 5px
	}
}

input[type=text],
input[type=password],
input[type=datetime],
input[type=datetime-local],
input[type=date],
input[type=month],
input[type=time],
input[type=week],
input[type=number],
input[type=email],
input[type=url],
input[type=search],
input[type=find],
input[type=tel],
input[type=color],
textarea {
	border-radius: 0!important;
	background-color: #fff;
	border: 1px solid #e2e1e1;
	font-size: 13px;
	box-shadow: none!important;
	padding: 6px
}

input[type=text]:hover,
input[type=password]:hover,
input[type=datetime]:hover,
input[type=datetime-local]:hover,
input[type=date]:hover,
input[type=month]:hover,
input[type=time]:hover,
input[type=week]:hover,
input[type=number]:hover,
input[type=email]:hover,
input[type=url]:hover,
input[type=search]:hover,
input[type=find]:hover,
input[type=tel]:hover,
input[type=color]:hover,
textarea:hover {
	border-color: #c9c7c7
}

input[type=text]:focus,
input[type=password]:focus,
input[type=datetime]:focus,
input[type=datetime-local]:focus,
input[type=date]:focus,
input[type=month]:focus,
input[type=time]:focus,
input[type=week]:focus,
input[type=number]:focus,
input[type=email]:focus,
input[type=url]:focus,
input[type=search]:focus,
input[type=find]:focus,
input[type=tel]:focus,
input[type=color]:focus,
textarea:focus {
	-webkit-box-shadow: none;
	box-shadow: none;
	border-color: #f59942;
	background-color: #fff;
	outline: 0;
	-webkit-transition-duration: .1s;
	transition-duration: .1s
}

input[readonly] {
	cursor: default!important
}

.input-mini {
	width: 60px;
	max-width: 100%
}

.input-small {
	width: 90px;
	max-width: 100%
}

.input-md,
.input-medium {
	width: 150px;
	max-width: 100%
}

.input-large {
	width: 210px;
	max-width: 100%
}

.input-xlarge {
	width: 270px;
	max-width: 100%
}

.input-xxlarge {
	width: 530px;
	max-width: 100%
}

select option,
select.form-control option {
	padding: 3px 4px
}

select option:active,
select option:focus,
select option:hover,
select.form-control option:active,
select.form-control option:focus,
select.form-control option:hover {
	background-color: #fff;
	color: #393939
}

select option[value=""],
select.form-control option[value=""] {
	padding: 0
}

.form-control,
select {
	border-radius: 0;
	-webkit-box-shadow: none!important;
	box-shadow: none!important;
	color: #393939;
	background-color: #fff;
	border: 1px solid #e2e1e1
}

.form-control:focus,
select:focus {
	color: #393939;
	border-color: #e2e1e1;
	background-color: #fff;
	outline: 0
}

select {
	padding: 3px 4px;
	height: 30px
}

select.form-control {
	padding: 4px 6px
}

select.form-control[multiple],
select[multiple] {
	height: auto
}

select.input-sm {
	border-radius: 0;
	padding: 2px 3px
}

select.input-lg {
	border-radius: 0;
	padding: 6px 8px
}

input[disabled] {
	color: #848484!important;
	background-color: #eee!important
}

input[disabled]:hover {
	border-color: #e2e1e1!important
}

input[readonly] {
	color: #939192;
	background: #f5f5f5!important
}

input[readonly]:hover {
	border-color: #c3c3c3
}

input[readonly]:focus {
	-webkit-box-shadow: none;
	box-shadow: none;
	border-color: #aaa;
	background-color: #f9f9f9
}

input:-webkit-autofill {
	-webkit-box-shadow: 0 0 0 50px #fff inset!important
}

.login-container {
	width: 360px;
	margin: 10% auto 50px
}

.login-box {
	width: 360px;
	padding: 20px;
	padding-bottom: 0;
	background: rgba(255, 255, 255, .85);
	border: 6px solid rgba(0, 0, 0, .5);
	visibility: hidden;
	position: absolute;
	overflow: hidden;
	-moz-transform: scale(0, 1) translate(-150px);
	-webkit-transform: scale(0, 1) translate(-150px);
	-o-transform: scale(0, 1) translate(-150px);
	-ms-transform: scale(0, 1) translate(-150px);
	transform: scale(0, 1) translate(-150px)
}

.login-box.visible {
	visibility: visible;
	-moz-transform: scale(1, 1) translate(0);
	-webkit-transform: scale(1, 1) translate(0);
	-o-transform: scale(1, 1) translate(0);
	-ms-transform: scale(1, 1) translate(0);
	transform: scale(1, 1) translate(0);
	transition: all .3s ease;
	-o-transition: none;
	-webkit-transition: none
}

.login-box .page-header {
	margin: -15px 0 15px
}

.login-box .footer-wrap {
	background: rgba(255, 255, 255, .7);
	margin: 20px -20px 0;
	padding: 10px 20px;
	border-top: 2px solid #e2e1e1
}

.login-box .footer-wrap a {
	text-decoration: none
}

.login-box .social-or-login {
	margin-top: 4px;
	position: relative;
	z-index: 1;
	text-align: center
}

.login-box .social-or-login :first-child {
	display: inline-block;
	background: rgba(255, 255, 255, .95);
	padding: 2px 8px;
	color: #3498db;
	font-size: 13px
}

.login-box .social-or-login:before {
	content: "";
	display: block;
	position: absolute;
	z-index: -1;
	top: 50%;
	left: 0;
	right: 0;
	border-top: 1px dotted rgba(255, 255, 255, .95)
}

.login-box .hr {
	border-color: rgba(255, 255, 255, .9)
}

@media (max-width:480px) {
	.login-box,
	.login-container {
		width: 95%
	}
}

body.error,
body.login {
	background: url(../../images/loginBg.jpg) center center no-repeat #4a4f56;
	background-size: cover;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover
}

.error-container {
	margin: 10% auto 50px
}

.error-container .error-box {
	padding: 15px;
	text-align: center;
	background: rgba(255, 255, 255, .75);
	border: 6px solid rgba(0, 0, 0, .5)
}

.error-container .error-box .error-code {
	font-size: 700%;
	font-weight: bolder;
	border-bottom: 1px solid #d15b47;
	padding: 10px;
	color: #d15b47
}

@media (max-width:768px) {
	.error-container .error-box .error-code {
		font-size: 360%
	}
}

@media (max-width:480px) {
	.error-container .error-box .error-code {
		font-size: 200%
	}
	.error-container .error-box h3 {
		font-size: 13px!important
	}
}

.top-navbar .navbar-nav>li>a,
.top-navbar .navbar-nav>li>a:focus,
.top-navbar .navbar-nav>li>a:hover,
.top-navbar .navbar-right>li>a,
.top-navbar .navbar-right>li>a:focus,
.top-navbar .navbar-right>li>a:hover {
	color: #fff
}

.top-navbar .nav li a {
	border: 0
}

.top-navbar .navbar-toggle {
	outline: 0;
	margin-top: 6px;
	margin-bottom: auto;
	border-color: transparent;
	color: #fff
}

.top-navbar .navbar-toggle:active,
.top-navbar .navbar-toggle:focus,
.top-navbar .navbar-toggle:hover {
	background: 0 0
}

.top-navbar .navbar-toggle .fa {
	font-size: 21px
}

.top-navbar .navbar-collapse {
	border-color: transparent
}

.top-navbar .nav .open>a,
.top-navbar .nav .open>a:focus,
.top-navbar .nav .open>a:hover {
	background-color: transparent;
	border-color: transparent
}

.top-navbar .navbar-left>li>a,
.top-navbar .navbar-right>li>a {
	font-size: 15px
}

.top-navbar .top-menu {
	background-color: #222a2d
}

.top-navbar.navbar {
	background-color: #fff;
	margin-bottom: 0;
	border-bottom: 0 none
}

.top-navbar .navbar-header {
	background-color: #fff;
	padding: 0 0 5px
}

.top-navbar.dark-menu .nav-top {
	min-height: 0
}

.top-navbar.dark-menu .container {
	position: relative
}

.top-navbar.dark-menu .navbar-toggle {
	float: left;
	margin-right: 0
}

.top-navbar.dark-menu .navbar-header {
	background: #222a2d
}

.top-navbar.dark-menu .navbar-right {
	position: absolute;
	right: 15px
}

.top-navbar.dark-menu .navbar-right li {
	display: inline-block
}

.top-navbar.dark-menu .navbar-right li a {
	padding: 18px 10px
}

.top-navbar.dark-menu .navbar-right li a:focus,
.top-navbar.dark-menu .navbar-right li a:hover {
	background-color: transparent
}

.top-navbar.dark-menu .navbar-right li .dropdown-menu li {
	display: block
}

.top-navbar.dark-menu .navbar-right li .dropdown-menu li a {
	padding: 10px 15px;
	font-size: 14px
}

.top-navbar.dark-menu .navbar-right li .dropdown-menu li a:focus,
.top-navbar.dark-menu .navbar-right li .dropdown-menu li a:hover {
	background-color: #f5f5f5
}

.top-navbar.dark-menu .navbar-right li .dropdown-menu.dropdown-user {
	margin-top: -2px!important
}

.top-navbar.dark-menu .badge.up {
	top: 11px
}

.top-navbar.dark-menu .pre-header {
	padding-top: 10px;
	background: #fff;
	border-bottom: 1px solid #e2e1e1;
	font-size: 13px
}

.top-navbar.dark-menu .pre-header a {
	color: #393939
}

.top-navbar.dark-menu .pre-header a:focus,
.top-navbar.dark-menu .pre-header a:hover {
	color: #7bb7e5;
	text-decoration: none
}

.top-navbar.dark-menu .pre-header li {
	padding-left: 10px;
	padding-right: 15px;
	border-right: solid 1px #e2e1e1
}

.top-navbar.dark-menu .pre-header li:last-child {
	border: none
}

.top-navbar.dark-menu .pre-header li .fa {
	margin-right: 4px
}

.top-navbar.dark-menu .pre-header .additional-nav li:last-child {
	padding-right: 0
}

.top-navbar.dark-menu .pre-header .badge.up {
	top: -7px;
	right: 25px
}

.top-navbar.dark-menu .nav-search .form-group {
	margin-bottom: 0
}

.top-navbar.dark-menu .nav-search .form-group span.fa,
.top-navbar.dark-menu .nav-search .form-group span.glyphicon {
	position: absolute;
	top: 0;
	right: 0;
	z-index: 2;
	display: block;
	width: 34px;
	height: 34px;
	max-width: 34px;
	max-height: 34px;
	line-height: 34px;
	text-align: center;
	left: initial
}

.top-navbar.dark-menu .nav-search .form-group input[type=text] {
	width: 32px;
	height: 32px;
	max-width: 32px;
	max-height: 32px;
	border: 0;
	border-radius: 100%!important
}

.top-navbar.dark-menu .nav-search .form-group ::-webkit-input-placeholder {
	color: #fff
}

.top-navbar.dark-menu .nav-search .form-group :-moz-placeholder {
	color: #fff
}

.top-navbar.dark-menu .nav-search .form-group ::-moz-placeholder {
	color: #fff
}

.top-navbar.dark-menu .nav-search .form-group :-ms-input-placeholder {
	color: #fff
}

.top-navbar.dark-menu .nav-search .form-group .form-control {
	display: inline-block
}

.top-navbar.dark-menu .nav-search:focus .form-group input[type=text],
.top-navbar.dark-menu .nav-search:hover .form-group input[type=text] {
	width: 100%;
	max-width: 100%;
	border-radius: 4px 25px 25px 4px!important
}

.top-navbar.dark-menu .nav-search:focus .form-group ::-webkit-input-placeholder,
.top-navbar.dark-menu .nav-search:hover .form-group ::-webkit-input-placeholder {
	color: #999
}

.top-navbar.dark-menu .nav-search:focus .form-group :-moz-placeholder,
.top-navbar.dark-menu .nav-search:hover .form-group :-moz-placeholder {
	color: #999
}

.top-navbar.dark-menu .nav-search:focus .form-group ::-moz-placeholder,
.top-navbar.dark-menu .nav-search:hover .form-group ::-moz-placeholder {
	color: #999
}

.top-navbar.dark-menu .nav-search:focus .form-group :-ms-input-placeholder,
.top-navbar.dark-menu .nav-search:hover .form-group :-ms-input-placeholder {
	color: #999
}

@media (max-width:992px) {
	.top-navbar .nav-top {
		margin-left: -15px;
		margin-right: -15px;
		padding: 0 10px;
		background-color: #222a2d
	}
	.top-navbar .navbar-left.pull-right {
		float: none!important
	}
}

@media (min-width:992px) {
	.top-navbar.navbar {
		border-bottom: none
	}
	.top-navbar .search-box {
		min-width: 210px
	}
	.top-navbar .navbar-brand {
		margin-left: 0!important
	}
	.top-navbar .navbar-nav>li>a,
	.top-navbar .navbar-right>li>a {
		padding: 15px
	}
	.top-navbar .navbar-right>li>a {
		color: #393939!important
	}
	.top-navbar .nav-top {
		margin: 0
	}
	.top-navbar .navbar-header,
	.top-navbar .navbar-right {
		-webkit-transition: background .5s ease-in-out, padding .5s ease-in-out;
		-moz-transition: background .5s ease-in-out, padding .5s ease-in-out;
		transition: background .5s ease-in-out, padding .5s ease-in-out;
		padding: 10px 0
	}
	.top-navbar.top-nav-collapse .navbar-header,
	.top-navbar.top-nav-collapse .navbar-right {
		padding: 0
	}
	.top-navbar .navbar-left.navbar-nav>li {
		border: 1px solid rgba(0, 0, 0, .2);
		border-width: 0 1px 0 0
	}
	.top-navbar .navbar-left.navbar-nav>li:first-child {
		border-width: 0 1px!important
	}
	.top-navbar .navbar-left.navbar-nav>li .btn-primary:focus,
	.top-navbar .navbar-left.navbar-nav>li .btn-primary:hover {
		background-color: #7bb7e5!important
	}
	.top-navbar.dark-menu .navbar-right>li>a {
		color: #fff!important
	}
	.top-navbar.dark-menu .navbar-header {
		background: #222a2d
	}
	.top-navbar.dark-menu .navbar-header,
	.top-navbar.dark-menu .navbar-right {
		padding: 0
	}
	.top-navbar.dark-menu .navbar-brand {
		padding-top: 29px
	}
	.top-navbar.dark-menu .badge.up {
		top: 21px
	}
	.top-navbar.dark-menu .navbar-left li a,
	.top-navbar.dark-menu .navbar-right li a {
		padding: 29px 15px 30px;
		text-shadow: 0 1px 0 rgba(0, 0, 0, .25)
	}
	.top-navbar.dark-menu .navbar-left li.dropdown.dropdown-megamenu li a,
	.top-navbar.dark-menu .navbar-left li.dropdown>.dropdown-menu li a,
	.top-navbar.dark-menu .navbar-right li.dropdown.dropdown-megamenu li a,
	.top-navbar.dark-menu .navbar-right li.dropdown>.dropdown-menu li a {
		padding: 10px 15px;
		text-shadow: none
	}
	.top-navbar.dark-menu .pre-header .list-inline {
		margin-left: 0
	}
	.top-navbar.dark-menu .navbar-left>li.dropdown:focus,
	.top-navbar.dark-menu .navbar-left>li.dropdown:hover,
	.top-navbar.dark-menu .navbar-left>li:focus,
	.top-navbar.dark-menu .navbar-left>li:hover {
		background: #293236;
		border-top: 0 none
	}
	.top-navbar.dark-menu .navbar-left>li.dropdown>.dropdown-menu {
		border-top: 0 none
	}
	.top-navbar.dark-menu .navbar-left>li.dropdown>.dropdown-menu:after {
		display: none
	}
	.top-navbar.dark-menu .navbar-left>li.dropdown>.dropdown-menu {
		margin-top: 0!important
	}
	.top-navbar.dark-menu .navbar-left>li.dropdown>.dropdown-menu li:first-child {
		border-top: 0 none
	}
	.top-navbar.dark-menu.navbar {
		background: #222a2d
	}
}

@media (max-width:359px) {
	.top-navbar.dark-menu .pre-header {
		font-size: 12px
	}
}

@media (max-width:480px) {
	.top-navbar.dark-menu .navbar-brand img {
		max-width: 85%
	}
}

.mass-head {
	width: 100%;
	height: auto
}

.mass-head.inner {
	padding: 15px 0 0;
	border-bottom: 1px solid #e2e1e1;
	background: #f2f2f2
}

.mass-head.inner h1 {
	font-size: 31px;
	margin-top: 0
}

.mass-head.inner h1 small {
	font-size: 13px
}

.mass-head.inner.bg-danger h1,
.mass-head.inner.bg-info h1,
.mass-head.inner.bg-primary h1,
.mass-head.inner.bg-success h1,
.mass-head.inner.bg-warning h1,
.mass-head.inner.dark h1,
.mass-head.inner.gray h1 {
	color: rgba(255, 255, 255, .95)
}

.mass-head.inner.bg-danger small,
.mass-head.inner.bg-info small,
.mass-head.inner.bg-primary small,
.mass-head.inner.bg-success small,
.mass-head.inner.bg-warning small,
.mass-head.inner.dark small,
.mass-head.inner.gray small {
	color: rgba(255, 255, 255, .65)
}

@media (min-width:992px) {
	.mass-head {
		padding: 120px 0 0
	}
	.mass-head.inner {
		padding: 140px 0 0
	}
}

.block-s1 {
	padding: 10px 0
}

.block-s1 h1,
.block-s1 h2,
.block-s1 h3,
.block-s1 h4,
.block-s1 h5,
.block-s1 h6,
.block-s1 p {
	padding: 0;
	margin: 10px 15px
}

@media (max-width:992px) {
	.block-s1 .text-left,
	.block-s1 .text-right {
		text-align: center!important
	}
}

@media (max-width:480px) {
	.block-s1 h1,
	.block-s1 h2,
	.block-s1 h3,
	.block-s1 h4 {
		font-size: 16px;
		line-height: 26px
	}
}

.block-s1.notice {
	padding: 10px
}

.block-s2,
.block-s4 {
	padding: 20px 0 5px
}

.block-s3 {
	padding: 15px 0
}

.block-s1.tech-specs h3,
.block-s2.tech-specs h3,
.block-s3.tech-specs h3,
.block-s4.tech-specs h3 {
	border-bottom: 3px double #e2e1e1;
	padding: 0 0 10px 10px
}

.block-s1.tech-specs ul,
.block-s2.tech-specs ul,
.block-s3.tech-specs ul,
.block-s4.tech-specs ul {
	padding-left: 0;
	margin-bottom: 25px
}

.block-s1.tech-specs ul li,
.block-s2.tech-specs ul li,
.block-s3.tech-specs ul li,
.block-s4.tech-specs ul li {
	list-style: none;
	padding: 5px 0 0 10px
}

.block-s1.tech-specs ul li:last-child,
.block-s2.tech-specs ul li:last-child,
.block-s3.tech-specs ul li:last-child,
.block-s4.tech-specs ul li:last-child {
	padding-bottom: 0
}

.block-s1.tech-specs ul li:before,
.block-s2.tech-specs ul li:before,
.block-s3.tech-specs ul li:before,
.block-s4.tech-specs ul li:before {
	content: "\f05d";
	margin-right: 4px;
	font-family: FontAwesome;
	color: #7bb7e5;
	font-size: 15px
}

.block-breadcrumbs {
	padding: 5px 0;
	background: #151a1c
}

.block-breadcrumbs .breadcrumb {
	margin-bottom: 0;
	background: 0 0;
	padding: 8px 0
}

.block-breadcrumbs .breadcrumb a,
.block-breadcrumbs .breadcrumb li {
	font-size: 12px;
	text-decoration: none
}

.block-breadcrumbs .breadcrumb>li+li:before {
	content: "//\00a0";
	padding: 0 5px
}

.block-footer {
	padding: 40px 0 10px;
	background: #1b2224;
	color: #7e959d
}

.block-footer ul.list-unstyled {
	padding: 0 10px
}

.block-footer ul.list-unstyled a {
	color: #7e959d
}

.block-footer ul.list-unstyled a:active,
.block-footer ul.list-unstyled a:focus,
.block-footer ul.list-unstyled a:hover {
	color: #e3e8ea;
	text-decoration: none
}

.block-footer ul.list-unstyled li {
	padding-top: 5px
}

.block-footer ul.list-unstyled .fa,
.block-footer ul.list-unstyled .glyphicon {
	font-size: 110%
}

.block-footer h4 {
	text-transform: uppercase;
	color: #e3e8ea;
	font-size: 15px
}

.block-footer .social li,
.block-footer .social ul {
	padding: 0!important
}

.block-footer .well {
	background: #151a1c;
	border: 0;
	color: #7e959d
}

.block-footer .copyright {
	padding-top: 10px;
	margin-top: 35px;
	background: #151a1c;
	font-size: 13px
}

.block-footer .copyright ul a {
	color: #7e959d
}

.block-footer .copyright ul a:active,
.block-footer .copyright ul a:focus,
.block-footer .copyright ul a:hover {
	color: #e3e8ea;
	text-decoration: none
}

@media (min-width:992px) {
	.block-footer .copyright ul {
		float: right
	}
	.block-footer .additional-info {
		margin-top: 35px
	}
}

@media (max-width:991px) {
	.block-footer .copyright {
		text-align: center;
		border-bottom: 0 none;
		margin-top: 0
	}
	.block-footer .copyright p {
		margin: 0
	}
}

@media (max-width:991px) {
	.block-footer {
		padding: 15px 0
	}
}

.page-container {
	background: #fff
}

@media (min-width:767px) {
	.block-s2 {
		padding: 40px 0 25px
	}
}

@media (max-width:992px) {
	.container {
		width: 100%!important
	}
}

.blog-posts {
	margin: 0 0 15px;
	border-bottom: 1px double #e2e1e1;
	padding-bottom: 15px
}

.blog-posts h1,
.blog-posts h2 {
	margin-top: 10px
}

.blog-posts h1 a,
.blog-posts h2 a {
	color: #393939
}

.blog-posts h1 a:focus,
.blog-posts h1 a:hover,
.blog-posts h2 a:focus,
.blog-posts h2 a:hover {
	color: #3498db
}

@media (max-width:480px) {
	.blog-posts h1,
	.blog-posts h2 {
		font-size: 18px
	}
}

.blog-posts .article-info {
	margin: 20px 0 15px;
	background: #fff;
	padding: 7.5px 0;
	border: 1px solid #e2e1e1;
	border-width: 1px 0
}

.blog-posts .article-info li {
	display: inline-block;
	margin-right: 10px;
	border-left: solid 1px #e2e1e1;
	padding-left: 15px
}

.blog-posts .article-info li:first-child {
	padding-left: 0;
	border: 0
}

.blog-posts .article-info li a {
	color: #393939
}

.blog-posts .article-info li .fa {
	margin-right: 4px;
	font-size: 14px;
	color: #7bb7e5
}

.blog-posts .article-info .pull-right li {
	margin-right: 0
}

.blog-posts .article-info.top {
	border: 0;
	padding: 0;
	margin: 0 0 15px
}

.blog-posts .article-info.top li {
	border: 0;
	padding-left: 0
}

.blog-posts img {
	margin: 10px 0 15px
}

.blog-posts .lead {
	font-size: 16px
}

.blog-posts .comments {
	border: 1px solid #e2e1e1;
	border-width: 1px 0;
	padding: 15px 0;
	margin-bottom: 15px
}

.blog-posts .comments h4 {
	padding-top: 0;
	margin: 10px 0 4px;
	font-size: 16px
}

.blog-posts .comments .media-object {
	width: 60px;
	max-width: 60px;
	height: 60px;
	max-hegiht: 60px;
	border: 1px solid #e2e1e1;
	padding: 3px
}

.blog-posts .video {
	position: relative;
	padding-bottom: 56.25%;
	padding-top: 30px;
	height: 0;
	overflow: hidden;
	margin-bottom: 10px
}

.blog-posts .video embed,
.blog-posts .video iframe,
.blog-posts .video object {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%
}

.blog-tags {
	margin-bottom: 15px
}

.blog-tags h3 {
	margin: 0 0 10px
}

.blog-tags ul {
	list-style: none;
	margin: 0;
	padding: 0
}

.blog-tags ul li {
	position: relative;
	margin: 0 16px 7px 0;
	display: inline-block
}

.blog-tags ul li a {
	background: #f2f2f2;
	display: inline-block;
	padding: 3px 5px 3px 10px;
	color: #999
}

.blog-tags ul li a:focus,
.blog-tags ul li a:hover {
	color: #fff;
	background: #7bb7e5;
	text-decoration: none
}

.blog-tags ul li a:focus:after,
.blog-tags ul li a:hover:after {
	border-left-color: #7bb7e5
}

.blog-tags ul li a:after {
	top: 50%;
	width: 0;
	left: 100%;
	height: 0;
	z-index: 2;
	content: " ";
	display: block;
	margin-top: -12px;
	position: absolute;
	border-left: 12px solid #f2f2f2;
	border-top: 12px solid transparent;
	border-bottom: 12px solid transparent
}

.blog-categories {
	margin-bottom: 15px
}

.blog-categories h3 {
	margin: 0 0 10px
}

.blog-categories ul li {
	padding: 5px 15px;
	border-bottom: 1px solid #f5f5f5
}

.blog-categories ul li a:focus,
.blog-categories ul li a:hover {
	text-decoration: none;
	color: #393939
}

.blog-categories ul li :before {
	content: "\f105";
	margin-left: 4px;
	font-family: FontAwesome;
	font-style: normal;
	margin-right: 4px
}

@media (min-width:1200px) {
	body.min-style2 .container {
		width: 1170px
	}
}

@media (min-width:992px) {
	body.min-style2 .container {
		width: 980px
	}
}

.hero-1 {
	background: url(../../images/top-hero.jpg) center center no-repeat;
	color: #fff;
	text-align: center;
	background-size: cover;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	z-index: 1;
	position: relative
}

.hero-1:before {
	background: #7bb7e5;
	content: "";
	z-index: -1;
	position: absolute;
	height: 100%;
	width: 100%;
	left: 0;
	right: 0;
	opacity: .9;
	filter: alpha(opacity=90);
	top: 0
}

.hero-1 .hero-inner {
	padding: 35px 0 25px
}

.hero-1 .hero-inner ul {
	margin-top: 15px
}

.hero-1 .hero-inner ul li,
.hero-1 .hero-inner ul li a {
	font-size: 14px;
	font-weight: 300
}

.hero-1 .hero-inner h1 {
	text-transform: uppercase;
	font-size: 26px;
	font-weight: 700;
	margin: 0
}

.hero-1 .hero-inner h2 {
	margin-top: 0
}

.hero-1 .hero-inner p {
	font-size: 110%;
	font-weight: 300
}

.hero-1 .hero-inner .domains-input {
	position: relative;
	color: #999;
	border-radius: 25px 0 0 25px!important;
	padding: 10px 16px;
	outline: 0;
	margin: 0;
	border: 0
}

.hero-1 .hero-inner .domain-btn {
	position: absolute;
	border-radius: 100px 0 100px 100px;
	display: inline-block;
	margin-left: -200px;
	margin-top: 9px;
	padding: 27px 0;
	width: 190px;
	font-size: 18px;
	border: 0!important
}

.hero-1 .hero-inner .domain-pricing {
	width: 75%;
	margin: 15px auto 10px
}

.hero-1 .hero-inner .domain-pricing .tld {
	font-size: 110%;
	font-weight: 300
}

.hero-1 .hero-inner .domain-pricing .price {
	font-size: 170%
}

.hero-1 .hero-inner .domain-pricing .WebRupee {
	font-weight: 300;
	font-size: 21px
}

.hero-1 .hero-inner .domain-pricing .promo {
	background: url(../../images/d-promo.png) no-repeat;
	position: absolute;
	width: 50px;
	height: 17px;
	left: 40%;
	text-indent: -999em;
	display: none
}

@media (min-width:768px) {
	.hero-1 .hero-inner {
		padding: 35px 0
	}
	.hero-1 .hero-inner ul li,
	.hero-1 .hero-inner ul li a {
		font-size: 19px;
		font-weight: 300
	}
	.hero-1 .hero-inner h1 {
		font-size: 42px
	}
	.hero-1 .hero-inner h1.extra-large {
		font-size: 56px
	}
	.hero-1 .hero-inner p {
		font-size: 170%
	}
	.hero-1 .hero-inner .domain-pricing .tld {
		font-size: 150%
	}
	.hero-1 .hero-inner .domain-pricing .promo {
		display: block
	}
	.hero-1 .hero-inner.home-hero {
		padding: 95px 0 80px
	}
	.hero-1 .hero-inner.home-hero .btn-lg {
		font-size: 30px;
		padding: 10px 30px
	}
	.hero-1 .hero-inner.home-hero .robo {
		background: url(../../images/robo.png) center no-repeat;
		position: absolute;
		height: 100%;
		right: 0;
		bottom: -10px;
		width: 290px
	}
}

.hero-1.bg-success:before {
	background: #82af6f
}

.hero-1.bg-danger:before {
	background: #d15b47
}

.hero-1.bg-warning:before {
	background: #ffb752
}

.hero-1.bg-info:before {
	background: #3a87ad
}

.hero-1.dark:before,
.hero-1.inverse:before {
	background: #2b3539
}

@media (min-width:767px) {
	.hero-1 {
		text-align: left
	}
	.hero-1 .hero-inner .domains-input {
		font-size: 25px!important;
		padding: 32px 40px!important;
		width: 80%;
		border-radius: 100px 0 100px 100px!important
	}
}

.block-holder-1 {
	background: url(../../images/block-holder1.jpg) center no-repeat fixed;
	background-origin: initial;
	background-clip: initial;
	background-size: cover;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover
}

.arrow-block {
	display: block;
	height: auto;
	position: relative;
	padding: 15px
}

.arrow-block:before {
	content: "";
	width: 25px;
	height: 25px;
	display: block;
	position: absolute;
	background-color: inherit;
	margin-left: -15px;
	top: 100%;
	margin-top: -15px;
	left: 50%;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	-o-transform: rotate(45deg);
	transform: rotate(45deg)
}

.mega-menu ul {
	padding: 0;
	margin: 0 0 10px;
	list-style: none
}

.mega-menu ul li {
	padding: 0;
	border: none!important
}

.mega-menu ul li>a {
	display: block;
	text-decoration: none
}

.mega-menu h4 {
	font-size: 15px;
	margin: 0 0 5px;
	padding: 10px 10px 5px;
	border-bottom: 3px double #f5f5f5;
	font-weight: 700!important
}

@media (max-width:991px) {
	.mega-menu ul li>a {
		color: #fff;
		font-size: 11px;
		padding: 5px 15px!important
	}
	.mega-menu ul li>a:focus,
	.mega-menu ul li>a:hover {
		background-color: transparent!important
	}
	.mega-menu h4 {
		color: #fff
	}
}

@media (min-width:992px) {
	.mega-menu {
		padding: 20px 30px;
		min-width: 600px
	}
	.mega-menu [class*=col-] {
		padding: 0
	}
	.mega-menu ul {
		padding: 0;
		margin: 0 0 10px;
		list-style: none
	}
	.mega-menu ul li>a {
		color: #393939
	}
	.mega-menu ul li>a:before {
		content: "\f101";
		margin-left: 4px;
		font-family: FontAwesome;
		font-style: normal;
		margin-right: 4px
	}
	.mega-menu ul li>a:focus,
	.mega-menu ul li>a:hover {
		background-color: #f5f5f5!important
	}
}

.offer [class*=col-] {
	min-height: 125px
}

.offer [class*=col-] h3 {
	margin-top: 0
}

.offer [class*=col-] .offer-content {
	padding: 15px 15px 15px 30px
}

.offer [class*=col-]:first-child {
	text-align: center
}

.offer [class*=col-]:first-child h3 {
	margin-bottom: 0;
	margin-top: 20px
}

.offer [class*=col-]:first-child .offer-content {
	padding-left: 15px
}

.offer [class*=col-]:first-child:before {
	content: "";
	width: 25px;
	height: 25px;
	display: block;
	position: absolute;
	background-color: inherit;
	margin-left: -15px;
	top: 50%;
	margin-top: -15px;
	left: 100%;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	-o-transform: rotate(45deg);
	transform: rotate(45deg);
	z-index: 1
}

.offer [class*=col-]:last-child .offer-content {
	position: relative;
	overflow: hidden
}

.offer [class*=col-]:last-child .offer-content .fa,
.offer [class*=col-]:last-child .offer-content .glyphicon {
	position: absolute;
	font-size: 110px;
	top: 50%;
	margin-top: -25px;
	right: -10px;
	-moz-transform: rotate(-20deg);
	-webkit-transform: rotate(-20deg);
	-o-transform: rotate(-20deg);
	-ms-transform: rotate(-20deg);
	transform: rotate(-20deg);
	opacity: .2;
	filter: alpha(opacity=20)
}

@media (min-width:768px) {
	.offer [class*=col-] {
		height: 125px
	}
}

@media (min-width:768px) and (max-width:991px) {
	.offer [class*=col-] h3 {
		font-size: 16px
	}
}

@media only screen and (max-width:767px) {
	.offer [class*=col-]:first-child {
		text-align: left
	}
	.offer [class*=col-]:first-child:before {
		margin-left: -15px;
		top: 100%;
		margin-top: -15px;
		left: 50%
	}
	.offer [class*=col-]:last-child h3 {
		margin-top: 10px;
		font-size: 16px
	}
}

.pricing-table-1 {
	padding: 0 25px 15px;
	margin-bottom: 15px;
	text-align: center;
	background: #fff;
	border: 3px solid #e2e1e1
}

.pricing-table-1 .plan-title {
	margin-top: 15px
}

.pricing-table-1 .plan-pricing {
	width: 140px;
	height: 140px;
	margin: 30px auto;
	border: 3px solid #e2e1e1;
	background: #fff;
	border-radius: 100%;
	display: table
}

.pricing-table-1 .plan-pricing .plan-price {
	font-size: 30px;
	line-height: 20px;
	vertical-align: middle;
	display: table-cell
}

.pricing-table-1 .plan-pricing .plan-unit {
	display: block;
	margin-top: 5px;
	font-size: 11px;
	text-transform: uppercase;
	font-weight: 400
}

.pricing-table-1 .plan-pricing .plan-text {
	text-transform: none;
	display: block;
	margin-bottom: 5px;
	font-size: 11px;
	font-weight: 400
}

.pricing-table-1 .plan-features {
	margin: 30px auto;
	padding: 15px 0 0;
	border-top: 1px solid #e2e1e1
}

.pricing-table-1 .plan-features li {
	list-style: none;
	padding: 5px 0
}

.pricing-table-1 .plan-button {
	margin-bottom: 15px
}

.pricing-table-1.active {
	border-color: #7bb7e5
}

.pricing-table-1.active .plan-pricing {
	background: #7bb7e5;
	border-color: #6eb0e2
}

.pricing-table-1.active .plan-price,
.pricing-table-1.active .plan-unit {
	color: #fff
}

.pricing-table-1:hover {
	border-color: #7bb7e5
}

.pricing-table-1 .ribbon-wrapper .ribbon {
	top: 14px
}

.pricing-table-2 {
	margin-bottom: 15px;
	border: 1px solid #e2e1e1
}

.pricing-table-2 .product-image {
	display: block;
	position: relative;
	z-index: 2
}

.pricing-table-2 .icon-attachment {
	color: #fff
}

.pricing-table-2 .details {
	position: relative;
	background: #fff;
	padding: 0 10px 25px;
	z-index: 3
}

.pricing-table-2 .details h4 {
	margin: 0;
	padding: 5px 0;
	font-weight: 500
}

.pricing-table-2 .details h4 a {
	color: #393939
}

.pricing-table-2 .details h4 a:hover {
	text-decoration: none;
	color: #7bb7e5
}

.pricing-table-2 .details p {
	padding: 0;
	font-size: 11px
}

.pricing-table-2 .details .intents {
	text-align: right;
	position: absolute;
	top: 10px;
	right: 10px
}

.pricing-table-2 .details .intents .count {
	display: inline-block;
	position: relative;
	top: -3px;
	margin: 0 5px
}

.pricing-table-2 .actions {
	height: 25px;
	font-size: 18px;
	font-weight: 400
}

.pricing-table-2 .actions .btn-round {
	position: relative;
	border-radius: 100%;
	padding: 10px 0;
	width: 38px;
	height: 38px;
	top: -19px;
	text-align: center;
	border-color: transparent;
	background: #fff
}

.pricing-table-2 .actions .btn-round:hover {
	background: #fff
}

.pricing-table-2 .actions .counter {
	float: right
}

.pricing-table-2 .actions .counter.text {
	font-size: 14px
}

.pricing-table-2 .actions a:hover {
	text-decoration: none
}

.pricing-table-2 .pricing {
	position: absolute;
	z-index: 3;
	color: #fff;
	font-weight: 500;
	font-size: 1.7em;
	right: 10px;
	bottom: 5px;
	padding: 0 10px;
	background-color: rgba(0, 0, 0, .55);
	border: 1px solid rgba(0, 0, 0, .35)
}

.pricing-table-2 .tag {
	position: absolute;
	z-index: 2;
	top: 0;
	left: 10px
}

.pricing-table-2 .tag .text {
	font-weight: 700;
	position: absolute;
	top: 5px;
	right: 0;
	width: 40px;
	color: #fff;
	z-index: 4;
	text-align: center
}

.pricing-table-2 .tag .caret {
	border-top: 8px solid transparent!important;
	display: block;
	color: #fff;
	z-index: 3;
	position: relative;
	top: 0;
	right: 0;
	text-align: center;
	-moz-transform: rotate(180deg);
	-webkit-transform: rotate(180deg);
	-o-transform: rotate(180deg);
	-ms-transform: rotate(180deg);
	transform: rotate(180deg);
	border-left: 20px solid rgba(0, 0, 0, .55);
	border-right: 20px solid rgba(0, 0, 0, .55);
	border-bottom: 30px solid rgba(0, 0, 0, .55)
}

.pricing-table-2 .tag .bg-danger,
.pricing-table-2 .tag .bg-info,
.pricing-table-2 .tag .bg-primary,
.pricing-table-2 .tag .bg-success,
.pricing-table-2 .tag .bg-warning,
.pricing-table-2 .tag .dark,
.pricing-table-2 .tag .gray,
.pricing-table-2 .tag .inverse {
	background-color: transparent!important
}

.pricing-table-3 {
	padding: 15px;
	width: 100%;
	height: 100%;
	border: 3px solid #e2e1e1;
	margin-bottom: 15px;
	background: #fff
}

.pricing-table-3 .price-small {
	font-size: 24px
}

.pricing-table-3 h3,
.pricing-table-3 h4 {
	margin-bottom: 5px;
	margin-top: 0
}

.pricing-table-3 .desc {
	margin-bottom: 10px;
	padding-bottom: 0
}

.pricing-table-3 .rate {
	background: #7bb7e5;
	padding: 15px;
	margin: 15px -15px;
	color: #fff;
	clear: both
}

.pricing-table-3 .rate .price {
	display: inline-block;
	float: left;
	clear: both
}

.pricing-table-3 .rate .price .term {
	margin-left: 4px;
	float: left
}

.pricing-table-3 .rate .price .amount {
	padding-top: 4px;
	float: left;
	text-align: right;
	font-size: 36px;
	line-height: 35px;
	font-weight: 300
}

.pricing-table-3 .rate .btn {
	margin-top: 3px;
	float: right;
	display: block
}

.pricing-table-3 .rate:before {
	display: table;
	line-height: 0;
	content: ""
}

.pricing-table-3 .rate:after {
	display: table;
	line-height: 0;
	content: "";
	clear: both
}

.pricing-table-3 ul {
	padding-left: 5px;
	margin-bottom: 15px
}

.pricing-table-3 ul li {
	list-style: none;
	padding: 7.5px 0;
	border-bottom: solid 1px #f2f2f2
}

.pricing-table-3 ul li:before {
	content: "\f105";
	margin-right: 4px;
	font-family: FontAwesome;
	font-style: normal;
	font-weight: 400
}

.pricing-table-3.active,
.pricing-table-3:hover {
	border-color: #7bb7e5
}

.pricing-table-3 .ribbon-wrapper .ribbon {
	top: 14px
}

.pricing-table-3.primary .rate {
	background: #428bca
}

.pricing-table-3.primary.active,
.pricing-table-3.primary:hover {
	border-color: #428bca
}

.pricing-table-3.success .rate {
	background: #82af6f
}

.pricing-table-3.success.active,
.pricing-table-3.success:hover {
	border-color: #82af6f
}

.pricing-table-3.danger .rate {
	background: #d15b47
}

.pricing-table-3.danger.active,
.pricing-table-3.danger:hover {
	border-color: #d15b47
}

.pricing-table-3.dark,
.pricing-table-3.inverse {
	background: #fff!important;
	color: #393939!important;
	border-color: #e2e1e1!important
}

.pricing-table-3.dark .rate,
.pricing-table-3.inverse .rate {
	background: #2b3539
}

.pricing-table-3.dark.active,
.pricing-table-3.dark:hover,
.pricing-table-3.inverse.active,
.pricing-table-3.inverse:hover {
	border-color: #2b3539!important
}

.pricing-table-3.info .rate {
	background: #3a87ad
}

.pricing-table-3.info.active,
.pricing-table-3.info:hover {
	border-color: #3a87ad
}

.pricing-table-4 {
	padding: 10px 25px 15px;
	margin-bottom: 15px;
	text-align: center;
	background: #fff;
	border: 3px double #e2e1e1
}

.pricing-table-4 .plan-title {
	margin-top: 15px
}

.pricing-table-4 .plan-pricing {
	margin: 15px auto
}

.pricing-table-4 .plan-pricing .plan-price {
	font-size: 37px;
	line-height: 20px
}

.pricing-table-4 .plan-pricing .plan-unit {
	display: block;
	margin-top: 5px;
	font-size: 12px;
	text-transform: uppercase;
	font-weight: 400
}

.pricing-table-4 .plan-pricing .plan-text {
	text-transform: none;
	display: block;
	margin-bottom: 5px;
	font-size: 12px;
	font-weight: 400
}

.pricing-table-4 .plan-features {
	margin: 30px auto;
	padding: 15px 0 0
}

.pricing-table-4 .plan-features li {
	list-style: none;
	padding: 5px 0
}

.pricing-table-4 .plan-button {
	margin-bottom: 15px
}

.pricing-table-4.active {
	background: #222!important
}

.pricing-table-4.active .plan-features,
.pricing-table-4.active .plan-price,
.pricing-table-4.active .plan-title,
.pricing-table-4.active .plan-unit {
	color: #fff!important
}

.pricing-table-4 .ribbon-wrapper .ribbon {
	top: 14px
}

@media (min-width:767px) {
	.pricing-table-4.center-table {
		border-width: 3px 0
	}
}

.tc-gallery {
	list-style: none;
	margin: 0;
	padding: 0
}

.tc-gallery>li {
	float: left;
	display: block;
	position: relative;
	overflow: hidden;
	margin: 0 15px 15px 0
}

.tc-gallery>li>:first-child {
	display: block;
	position: relative
}

.tc-gallery>li>:first-child:focus {
	outline: 0
}

.tc-gallery>li .tags {
	display: inline-block;
	position: absolute;
	bottom: 0;
	right: 0;
	overflow: visible;
	direction: rtl;
	padding: 0;
	margin: 0;
	height: auto;
	width: auto;
	background-color: transparent;
	border-width: 0;
	vertical-align: inherit
}

.tc-gallery>li .tags>.label-holder {
	opacity: .92;
	filter: alpha(opacity=92);
	display: table;
	margin: 1px 0 0;
	direction: ltr;
	text-align: left
}

.tc-gallery>li .tags>.label-holder:hover {
	opacity: 1;
	filter: alpha(opacity=100)
}

.tc-gallery>li>.tools {
	position: absolute;
	top: 0;
	bottom: 0;
	left: -30px;
	width: 24px;
	background-color: rgba(0, 0, 0, .55);
	text-align: center;
	vertical-align: middle;
	-webkit-transition: all .2s ease;
	-o-transition: all .2s ease;
	transition: all .2s ease
}

.tc-gallery>li>.tools.tools-right {
	left: auto;
	right: -30px
}

.tc-gallery>li>.tools.tools-bottom {
	width: auto;
	height: 28px;
	left: 0;
	right: 0;
	top: auto;
	bottom: -30px
}

.tc-gallery>li>.tools.tools-top {
	width: auto;
	height: 28px;
	left: 0;
	right: 0;
	top: -30px;
	bottom: auto
}

.tc-gallery>li:hover>.tools {
	left: 0;
	right: 0
}

.tc-gallery>li:hover>.tools.tools-bottom {
	top: auto;
	bottom: 0
}

.tc-gallery>li:hover>.tools.tools-top {
	bottom: auto;
	top: 0
}

.tc-gallery>li:hover>.tools.tools-right {
	left: auto;
	right: 0
}

.tc-gallery>li>.in.tools {
	left: 0;
	right: 0
}

.tc-gallery>li>.in.tools.tools-bottom {
	top: auto;
	bottom: 0
}

.tc-gallery>li>.in.tools.tools-top {
	bottom: auto;
	top: 0
}

.tc-gallery>li>.in.tools.tools-right {
	left: auto;
	right: 0
}

.tc-gallery>li>.tools>a,
.tc-gallery>li>:first-child .inner a {
	display: inline-block;
	color: #FFF;
	font-size: 18px;
	font-weight: 400;
	padding: 0 4px
}

.tc-gallery>li>.tools>a:hover,
.tc-gallery>li>:first-child .inner a:hover {
	text-decoration: none;
	color: #C9E2EA
}

.tc-gallery>li .tools.tools-bottom>a,
.tc-gallery>li .tools.tools-top>a {
	display: inline-block
}

.tc-gallery>li>:first-child>.text {
	position: absolute;
	right: 0;
	left: 0;
	bottom: 0;
	top: 0;
	text-align: center;
	color: #FFF;
	background-color: rgba(0, 0, 0, .55);
	opacity: 0;
	filter: alpha(opacity=0);
	-webkit-transition: all .2s ease;
	-o-transition: all .2s ease;
	transition: all .2s ease
}

.tc-gallery>li>:first-child>.text:before {
	content: '';
	display: inline-block;
	height: 100%;
	vertical-align: middle;
	margin-right: 0
}

.tc-gallery>li>:first-child>.text>.inner {
	padding: 4px 0;
	margin: 0;
	display: inline-block;
	vertical-align: middle;
	max-width: 90%
}

.tc-gallery>li:hover>:first-child>.text {
	opacity: 1;
	filter: alpha(opacity=100)
}

.tc-gallery-2 {
	list-style: none;
	margin: 0;
	padding: 0
}

.tc-gallery-2>li {
	float: left;
	display: block;
	position: relative;
	margin: 0 15px 15px 0
}

.tc-gallery-2>li>:first-child {
	display: block;
	position: relative
}

.tc-gallery-2>li>:first-child:focus {
	outline: 0
}

.tc-gallery-2>li.thumbnail {
	padding: 10px;
	-moz-transform: translate3d(0, 0, 0);
	-webkit-transform: translate3d(0, 0, 0);
	-o-transform: translate3d(0, 0, 0);
	-ms-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
	background: #f2f2f2
}

.tc-gallery-2>li.thumbnail .thumb-preview {
	position: relative;
	z-index: 2
}

.tc-gallery-2>li.thumbnail .thumb-preview .thumb-image {
	display: block;
	overflow: hidden
}

.tc-gallery-2>li.thumbnail .thumb-preview img {
	width: 100%;
	-webkit-transition: all .1s linear;
	-o-transition: all .1s linear;
	transition: all .1s linear;
	border: 1px solid #e2e1e1
}

.tc-gallery-2>li.thumbnail .thumb-preview .gl-thumb-options {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: rgba(0, 0, 0, .55);
	visibility: hidden;
	overflow: hidden
}

.tc-gallery-2>li.thumbnail .thumb-preview .gl-thumb-options .gl-zoom {
	position: absolute;
	top: 0;
	right: 0;
	padding: 5px 15px 10px;
	font-size: 18px;
	color: #fff;
	cursor: pointer;
	-webkit-transition: transform .1s linear .1s;
	-o-transition: transform .1s linear .1s;
	transition: transform .1s linear .1s;
	-moz-transform: translate(100%, 0);
	-webkit-transform: translate(100%, 0);
	-o-transform: translate(100%, 0);
	-ms-transform: translate(100%, 0);
	transform: translate(100%, 0)
}

.tc-gallery-2>li.thumbnail .thumb-preview .gl-thumb-options .gl-toolbar {
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: rgba(0, 0, 0, .55);
	color: #fff;
	padding: 6px 10px;
	-webkit-transition: transform .1s linear .1s;
	-o-transition: transform .1s linear .1s;
	transition: transform .1s linear .1s;
	-moz-transform: translate(0, 100%);
	-webkit-transform: translate(0, 100%);
	-o-transform: translate(0, 100%);
	-ms-transform: translate(0, 100%);
	transform: translate(0, 100%)
}

.tc-gallery-2>li.thumbnail .thumb-preview .gl-thumb-options .gl-toolbar .gl-option {
	margin: 0
}

.tc-gallery-2>li.thumbnail .thumb-preview .gl-thumb-options .gl-toolbar .gl-group>a {
	color: #fff;
	padding: 0 5px;
	text-decoration: none
}

.tc-gallery-2>li.thumbnail .thumb-preview .gl-thumb-options .gl-toolbar .gl-group .gl-toggle {
	color: #fff;
	background: 0 0;
	border: none;
	padding: 2px 2px 2px 10px;
	border-left: 1px solid rgba(0, 0, 0, .55)
}

.tc-gallery-2>li.thumbnail .thumb-preview .gl-thumb-options .gl-toolbar .checkbox-inline {
	padding-left: 0
}

.tc-gallery-2>li.thumbnail .thumb-preview .gl-thumb-options .gl-toolbar:after {
	content: "";
	display: table;
	clear: both
}

.tc-gallery-2>li.thumbnail .gl-title {
	margin: 10px 0 2px;
	padding-bottom: 2px;
	display: inline-block
}

.tc-gallery-2>li.thumbnail .gl-title small {
	position: relative;
	top: 0;
	left: 0;
	color: #999;
	opacity: 0;
	z-index: 1;
	font-size: 10px;
	font-size: 1rem
}

.tc-gallery-2>li.thumbnail .gl-description:after {
	content: "";
	display: table;
	clear: both
}

.tc-gallery-2>li.thumbnail.thumbnail-selected {
	border-color: #fff;
	box-shadow: 0 0 8px -1px #e2e1e1
}

.tc-gallery-2>li.thumbnail.thumbnail-selected .gl-title small {
	opacity: 1
}

.tc-gallery-2>li.thumbnail.thumbnail-selected .thumb-preview .gl-thumb-options {
	visibility: visible
}

.tc-gallery-2>li.thumbnail.thumbnail-selected .thumb-preview .gl-thumb-options .gl-toolbar {
	-moz-transform: translate(0, 0);
	-webkit-transform: translate(0, 0);
	-o-transform: translate(0, 0);
	-ms-transform: translate(0, 0);
	transform: translate(0, 0)
}

.tc-gallery-2>li.thumbnail:focus .gl-title small,
.tc-gallery-2>li.thumbnail:hover .gl-title small {
	opacity: 1
}

.tc-gallery-2>li.thumbnail:focus .thumb-preview img,
.tc-gallery-2>li.thumbnail:hover .thumb-preview img {
	-moz-transform: scale(1.1);
	-webkit-transform: scale(1.1);
	-o-transform: scale(1.1);
	-ms-transform: scale(1.1);
	transform: scale(1.1)
}

.tc-gallery-2>li.thumbnail:focus .thumb-preview .gl-thumb-options,
.tc-gallery-2>li.thumbnail:hover .thumb-preview .gl-thumb-options {
	visibility: visible
}

.tc-gallery-2>li.thumbnail:focus .thumb-preview .gl-thumb-options .gl-toolbar,
.tc-gallery-2>li.thumbnail:focus .thumb-preview .gl-thumb-options .gl-zoom,
.tc-gallery-2>li.thumbnail:hover .thumb-preview .gl-thumb-options .gl-toolbar,
.tc-gallery-2>li.thumbnail:hover .thumb-preview .gl-thumb-options .gl-zoom {
	-moz-transform: translate(0, 0);
	-webkit-transform: translate(0, 0);
	-o-transform: translate(0, 0);
	-ms-transform: translate(0, 0);
	transform: translate(0, 0)
}

@media only screen and (max-width:460px) {
	.tc-gallery {
		text-align: center
	}
	.tc-gallery-2>li,
	.tc-gallery>li {
		float: none;
		display: inline-block;
		margin: 2px
	}
}

.notice {
	display: block;
	min-width: 200px;
	height: auto;
	position: relative;
	background-color: #f2f2f2;
	padding: 15px;
	margin-bottom: 15px
}

.notice *,
.notice :active,
.notice :focus,
.notice :hover {
	color: inherit
}

.notice:before {
	content: "";
	width: 10px;
	height: 10px;
	display: block;
	position: absolute;
	background-color: inherit;
	left: -5px;
	top: 50%;
	margin-top: -5px;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	-o-transform: rotate(45deg);
	transform: rotate(45deg)
}

.notice.marker-on-top:before {
	top: 0;
	left: 50%;
	margin-left: -5px
}

.notice.marker-on-right:before {
	top: 50%;
	margin-top: -5px;
	left: 100%;
	margin-left: -5px
}

.notice.marker-on-bottom:before {
	top: 100%;
	margin-left: -5px;
	left: 50%;
	margin-top: -5px
}

.inbox-tabs.nav-tabs>li>a {
	background-color: #fff
}

.inbox-tabs.nav-tabs>li.active:not(.open)>a,
.inbox-tabs.nav-tabs>li.active:not(.open)>a:focus,
.inbox-tabs.nav-tabs>li.active:not(.open)>a:hover {
	background-color: #f2f2f2
}

.inbox-tabs.nav-tabs>li>a.btn-new-mail {
	background-color: transparent;
	border: none!important;
	padding: 0!important
}

.inbox-tabs.nav-tabs>li>a.btn-new-mail>.btn {
	-webkit-box-shadow: none!important;
	box-shadow: none!important
}

.inbox-tabs.nav-tabs.tab-lg-button>li>a.btn-new-mail>.btn {
	padding: 10px!important
}

@media only screen and (max-width:475px) {
	.inbox-tabs>.li-new-mail {
		display: block;
		text-align: right;
		margin-bottom: 8px!important;
		float: none!important
	}
	.inbox-tabs>.li-new-mail>.btn-new-mail {
		display: inline-block;
		width: auto
	}
}

.message-container {
	position: relative
}

.message-container label {
	margin-bottom: 0
}

.message-list {
	position: relative
}

.message-item {
	border: 1px solid #e2e1e1;
	border-bottom-width: 0;
	padding: 12px 15px 14px;
	line-height: 18px;
	position: relative;
	background-color: #fff
}

.message-item:first-child {
	border-top-width: 0
}

.message-item:last-child {
	border-bottom-width: 1px
}

.message-item:hover {
	border-color: #e2e1e1;
	background-color: #F7F7F7
}

.message-item:hover+.message-item {
	border-top-color: #e2e1e1
}

.message-item:hover+.message-item.selected {
	border-top-color: #fff
}

.message-item.selected {
	background-color: #F7F7F7;
	border-color: #fff #e2e1e1
}

.message-item.selected+.message-item,
.message-item.selected+.message-item:hover+.message-item {
	border-top-color: #fff
}

.message-item input.ace+.lbl::before,
.message-navbar input.ace+.lbl::before {
	top: auto
}

.message-item .sender {
	margin: 0 6px 0 4px;
	vertical-align: middle;
	display: inline-block;
	width: 110px;
	height: 18px;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	cursor: pointer
}

.message-item.message-unread .sender {
	color: #7bb7e5;
	font-weight: 700
}

.message-item .summary {
	vertical-align: middle;
	display: inline-block;
	position: relative;
	margin-left: 30px;
	max-width: 250px;
	max-width: calc(100% - 300px);
	min-width: 200px;
	white-space: nowrap
}

.message-item .summary .text {
	vertical-align: middle;
	display: inline-block;
	width: auto;
	max-width: 100%;
	height: 18px;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	cursor: pointer
}

.message-item .summary .text:hover {
	text-decoration: underline
}

.message-item .summary .message-flags {
	display: block;
	position: absolute;
	right: 100%;
	margin-right: 4px;
	height: 18px;
	white-space: nowrap
}

.message-item.message-unread .summary .text {
	color: #7bb7e5;
	font-weight: 700
}

.message-item .time {
	float: right;
	width: 60px;
	height: 18px;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap
}

.message-item.message-unread .time {
	font-weight: 700
}

.message-item.message-unread .message-content .time {
	font-weight: 400
}

.message-item .attachment {
	font-size: 18px;
	vertical-align: middle;
	float: right;
	margin: 0 12px;
	position: relative
}

.message-item.message-unread .attachment {
	color: #7bb7e5
}

.message-content .time {
	font-weight: 400
}

.message-star {
	vertical-align: middle;
	margin: 2px 4px 0 6px;
	font-size: 16px;
	cursor: pointer;
	color: #393939
}

.message-star.selected {
	color: #feb902!important;
	text-decoration: none!important
}

.message-star.selected:before {
	content: "\f005"
}

.mail-tag:empty {
	display: inline-block;
	width: 8px;
	height: 11px;
	padding: 0;
	line-height: normal;
	vertical-align: middle;
	margin: 0 1px 0 0
}

.badge.mail-tag {
	border-radius: 2px
}

.dropdown-menu>li>a>.mail-tag {
	vertical-align: inherit
}

@media only screen and (max-width:767px) {
	.message-item .summary {
		min-width: 0
	}
	.message-item .sender {
		width: 100px
	}
}

@media only screen and (max-width:450px) {
	.message-item .summary {
		margin: 8px 0 0 32px;
		max-width: 95%;
		min-width: 0;
		display: block
	}
	.message-item .sender {
		width: auto;
		max-width: 150px
	}
	.message-item .summary .text {
		max-width: 95%
	}
}

.message-content {
	padding: 16px 12px;
	border: 1px solid #E9E9E9;
	-webkit-box-shadow: 0 0 1px 1px rgba(0, 0, 0, .02);
	box-shadow: 0 0 1px 1px rgba(0, 0, 0, .02);
	background-color: #fff;
	border-top-width: 0
}

.message-item .message-content {
	margin-top: 16px;
	border-top-width: 1px
}

.message-content .message-header img {
	border: 2px solid #e2e1e1
}

.message-content .message-header .fa-star-o {
	color: #393939
}

.message-content .message-header .fa-star-o.selected {
	color: #feb902!important;
	text-decoration: none!important
}

.message-content .message-header .fa-star-o.selected:before {
	content: "\f005"
}

.message-body {
	padding: 0 9px
}

.message-navbar {
	line-height: 24px;
	padding: 10px 12px;
	border: 1px solid #e2e1e1;
	background-color: #f2f2f2;
	text-align: center;
	position: relative
}

.message-content .dropdown-toggle,
.message-navbar .dropdown-toggle {
	color: #393939
}

.message-content .dropdown-toggle:focus,
.message-content .dropdown-toggle:hover,
.message-navbar .dropdown-toggle:focus,
.message-navbar .dropdown-toggle:hover {
	text-decoration: none;
	color: #3498db
}

.message-bar {
	display: inline-block;
	min-height: 28px
}

.message-bar .message-infobar .title {
	font-size: 21px
}

.message-bar .message-infobar .title .badge {
	vertical-align: top
}

@media only screen and (max-width:480px) {
	.message-bar {
		display: block;
		min-height: 60px
	}
}

.message-footer {
	background-color: #f2f2f2;
	padding: 12px 15px;
	border: 1px solid #e2e1e1;
	border-top-width: 0
}

.message-footer .pagination {
	margin: 0
}

.message-footer .pagination>li {
	margin: 0;
	padding: 0
}

.message-footer .pagination>li>a,
.message-footer .pagination>li>span {
	padding: 3px 6px;
	margin-left: 1px;
	margin-right: 1px;
	background-color: transparent;
	border: 1px solid transparent;
	color: #393939
}

.message-footer .pagination>li.disabled>span {
	color: #BBB;
	cursor: default;
	background-color: transparent;
	border-color: transparent
}

.message-footer .pagination>li>a:hover {
	border-color: #e2e1e1;
	color: #7bb7e5;
	text-decoration: none;
	background-color: #fff
}

.message-footer input[type=text] {
	font-size: 12px;
	width: 34px;
	height: 24px;
	line-height: 20px;
	margin-bottom: 0;
	padding: 3px;
	vertical-align: middle;
	text-align: center
}

.message-footer.message-footer-2 {
	border-top: 0 none
}

.message-item.message-inline-open {
	background-color: #f2f2f2
}

.message-item.message-inline-open+.message-item {
	border-bottom-color: transparent
}

.message-loading-overlay {
	position: absolute;
	z-index: 14;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	background-color: rgba(255, 255, 255, .5);
	text-align: center
}

.message-loading-overlay>.fa {
	position: absolute;
	top: 15%;
	left: 0;
	right: 0;
	text-align: center
}

.message-content .sender {
	font-weight: 700;
	width: auto;
	text-overflow: inherit;
	vertical-align: middle;
	margin: 0
}

.message-content .time {
	width: auto;
	text-overflow: inherit;
	white-space: normal;
	float: none;
	vertical-align: middle
}

ul.attachment-list {
	margin: 6px 0 4px 8px
}

ul.attachment-list>li {
	margin-bottom: 3px
}

.message-attachment {
	padding-left: 10px;
	padding-right: 10px
}

.message-attachment .attachment-images img {
	width: 36px;
	height: 36px;
	border: 2px solid #e2e1e1
}

.attached-file {
	width: 175px;
	display: inline-block;
	color: #999
}

.attached-file>.fa,
.attached-file>.glyphicon {
	display: inline-block;
	width: 16px;
	margin-right: 2px;
	vertical-align: middle
}

.attached-file:hover {
	text-decoration: none;
	color: #3498db
}

.attached-file .attached-name {
	display: inline-block;
	max-width: 175px;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	vertical-align: middle;
	font-size: 13px
}

.messagebar-item-left,
.messagebar-item-right {
	position: absolute;
	bottom: 15px;
	left: 15px;
	text-align: left
}

.messagebar-item-right {
	right: 15px;
	left: auto
}

.message-navbar .mini-search {
	right: auto;
	left: 5px;
	top: auto;
	bottom: 11px
}

.message-navbar .messagebar-item-left~.mini-search {
	left: 60px
}

.message-form {
	border: 1px solid #ddd;
	border-top: none;
	padding-top: 22px
}

.message-form .cc-bcc {
	display: inline-block;
	top: 7px;
	right: 10px;
	color: #979797;
	cursor: pointer;
	position: absolute
}

@media only screen and (max-width:480px) {
	.message-form {
		padding-left: 16px;
		padding-right: 16px
	}
}

.message-form .form-actions {
	margin-bottom: 0
}

.message-form .wysiwyg-editor {
	overflow: auto;
	min-height: 150px;
	max-height: 250px;
	height: auto
}

.btn-send-message {
	position: relative;
	top: 6px;
	vertical-align: middle
}

.btn-back-message-list {
	color: #2b3539
}

.btn-back-message-list:hover {
	color: #7bb7e5;
	text-decoration: none
}

.message-condensed .message-item {
	padding-top: 8px;
	padding-bottom: 9px
}

.message-condensed .message-footer,
.message-condensed .message-navbar {
	padding-top: 7px;
	padding-bottom: 7px
}

.message-condensed .messagebar-item-left,
.message-condensed .messagebar-item-right {
	bottom: 9px
}

.message-condensed .message-navbar .mini-search {
	bottom: 7px
}

@media only screen and (max-width:480px) {
	.message-condensed .message-bar {
		min-height: 42px
	}
}



.gritter-item-wrapper {
	background-image: none;
	background-color: #2b3539;
	opacity: .9;
	filter: alpha(opacity=90)
}

.gritter-item-wrapper.gritter-light,
.gritter-item-wrapper.light {
	background: #f2f2f2
}

.gritter-item-wrapper.gritter-light .gritter-close,
.gritter-item-wrapper.gritter-light .gritter-item,
.gritter-item-wrapper.light .gritter-close,
.gritter-item-wrapper.light .gritter-item {
	color: #555
}

.gritter-item-wrapper.gritter-light .gritter-title,
.gritter-item-wrapper.light .gritter-title {
	text-shadow: none;
	color: #555
}

.gritter-item-wrapper.gritter-light .gritter-close:before,
.gritter-item-wrapper.light .gritter-close:before {
	color: #555
}

.gritter-item-wrapper .inverse .gritter-item,
.gritter-item-wrapper.bg-info .gritter-item,
.gritter-item-wrapper.bg-primary .gritter-item,
.gritter-item-wrapper.bg-success .gritter-item,
.gritter-item-wrapper.bg-succsss .gritter-item,
.gritter-item-wrapper.bg-warning .gritter-item,
.gritter-item-wrapper.dark .gritter-item {
	color: #fff
}

.gritter-item-wrapper .gritter-bottom,
.gritter-item-wrapper .gritter-item,
.gritter-item-wrapper .gritter-top {
	background-image: none!important
}

.gritter-item-wrapper .gritter-image {
	border: 2px solid #e2e1e1;
	margin: 2px 10px 0 0
}

.gritter-item {
	color: #f2f2f2;
	font-family: 'Open Sans', sans-serif!important;
	font-size: 12px
}

.gritter-close {
	left: auto;
	right: 10px;
	top: 7px;
	background: 0 0!important;
	width: 18px;
	height: 18px;
	line-height: 17px;
	text-align: center;
	border: 2px solid transparent;
	border-radius: 0;
	display: block!important;
	color: #f2f2f2
}

.gritter-close:before {
	content: 'x'!important;
	font-size: 19px!important;
	text-indent: 0!important;
	position: absolute!important;
	text-align: center!important;
	right: 0!important;
	top: 0!important;
	color: #fff
}

.gritter-close:active,
.gritter-close:focus,
.gritter-close:hover {
	color: #fff;
	text-decoration: none
}

.gritter-title {
	text-shadow: none;
	padding: 0 0 2px;
	font-weight: 500;
	color: #fff
}

.gritter-center {
	position: fixed;
	left: 33%;
	right: 33%;
	top: 33%
}

.gritter-center .gritter-with-image {
	float: left;
	width: auto
}

@media (max-width:767px) {
	.gritter-center {
		left: 30px;
		right: 30px
	}
}

.fc .label,
.fc-event .label {
	text-align: left!important;
	font-size: .85em;
	line-height: 1.42857143;
	height: auto;
	display: block
}

.fc th,
.fc-event th {
	padding: 7px 0;
	color: #555
}

.fc-event {
	border-width: 0;
	background-color: #7bb7e5;
	padding: 1px 1px 2px 2px;
	border-radius: 0
}

.fc-event.ui-draggable-dragging {
	cursor: move
}

.fc-event-vert>.fc-event,
.fc-event.fc-event-vert {
	padding: 0 0 1px
}

.fc-scroller {
	overflow-y: auto;
	overflow-x: hidden
}

.fc-month-view .fc-scroller {
	height: auto!important
}

.fc-button {
	outline: 0!important
}

.fc .fc-button-group>* {
	margin: 0 1px 0 0
}

.fc-toolbar h2 {
	font-size: 21px;
	color: #555;
	line-height: normal
}

.fc-widget-content,
.fc-widget-header {
	border: 1px solid #e2e1e1
}

.fc-widget-header {
	background: #f2f2f2
}

.fc-state-highlight,
.fc-unthemed .fc-today {
	background: #fff7eb
}

.fc-unthemed .fc-popover,
.fc-unthemed .fc-row,
.fc-unthemed hr,
.fc-unthemed tbody,
.fc-unthemed td,
.fc-unthemed th,
.fc-unthemed thead {
	border-color: #e2e1e1
}

.fc-grid .fc-day-number {
	color: #999
}

.fc-event-hori,
.fc-event-vert {
	border-radius: 0!important;
	border-color: transparent
}

.fc-event-vert .fc-event-content {
	padding-left: 1px;
	padding-right: 1px
}

.fc-event-vert .fc-event-time {
	padding: 0
}

.fc-state-default,
.fc-state-default .fc-button-inner {
	border: none;
	background-color: #999;
	color: #fff;
	background-image: none;
	box-shadow: none;
	text-shadow: none;
	border-radius: 0!important;
	margin-left: 2px
}

.fc-state-default .fc-button-effect {
	display: none
}

.fc-state-disabled,
.fc-state-disabled .fc-button-inner {
	opacity: .75;
	filter: alpha(opacity=75);
	color: #f2f2f2
}

.fc-state-active,
.fc-state-active .fc-button-inner {
	border-color: #103551;
	background-color: #7bb7e5
}

.fc-state-hover,
.fc-state-hover .fc-button-inner {
	background-color: #7bb7e5
}

.external-event {
	margin: 0 0 5px;
	padding: 0;
	cursor: default;
	display: block;
	color: #fff;
	background-color: #fff;
	line-height: 32px;
	text-align: left!important;
	font-size: 13px
}

.external-event:hover {
	opacity: 1;
	filter: alpha(opacity=100)
}

.external-event.ui-draggable-dragging {
	cursor: move
}

.external-event.label {
	height: auto
}

.external-event>[class*=fa-]:first-child {
	display: inline-block;
	height: 32px;
	width: 32px;
	text-align: center;
	line-height: 30px;
	margin-right: 5px;
	font-size: 15px;
	border-right: 1px solid #fff
}

@media only screen and (max-width:479px) {
	.fc-toolbar h2 {
		font-size: 14px
	}
	.fc-toolbar .fc-button {
		font-size: 10px
	}
}

.bootstrap-select .btn-default,
.bootstrap-select .btn-default.active,
.bootstrap-select .btn-default.disabled,
.bootstrap-select .btn-default.disabled:focus,
.bootstrap-select .btn-default:active,
.bootstrap-select .btn-default:focus,
.bootstrap-select .btn-default:hover,
.bootstrap-select .open .dropdown-toggle.btn-default,
.bootstrap-select.open .btn-default,
.bootstrap-select.open .btn-default.active,
.bootstrap-select.open .btn-default.disabled,
.bootstrap-select.open .btn-default.disabled:focus,
.bootstrap-select.open .btn-default:active,
.bootstrap-select.open .btn-default:focus,
.bootstrap-select.open .btn-default:hover,
.bootstrap-select.open .open .dropdown-toggle.btn-default {
	background: #fff;
	border-color: #e2e1e1;
	color: #666
}

.bootstrap-select .btn-group.open .dropdown-toggle {
	-webkit-box-shadow: none;
	box-shadow: none
}

.bootstrap-select .btn:focus {
	outline: 0!important;
	outline-offset: 0
}

.bootstrap-select .caret {
	border: 0;
	width: auto;
	height: auto;
	margin-top: -10px!important
}

.bootstrap-select .caret:before {
	content: "\f107";
	display: inline-block;
	border: 0;
	font-family: FontAwesome;
	font-style: normal;
	font-weight: 400
}

.bootstrap-tagsinput {
	background-color: #fff;
	border: 1px solid #e2e1e1;
	-webkit-box-shadow: none;
	box-shadow: none;
	display: inline-block;
	padding: 4px 6px;
	margin-bottom: 10px;
	color: #555;
	vertical-align: middle;
	border-radius: 0;
	max-width: 100%;
	width: 100%;
	line-height: 22px;
	cursor: text
}

.bootstrap-tagsinput input {
	border: 0;
	-webkit-box-shadow: none;
	box-shadow: none;
	outline: 0;
	background-color: transparent;
	padding: 0;
	margin: 0;
	width: auto!important;
	max-width: inherit
}

.bootstrap-tagsinput input:focus {
	border: 0;
	-webkit-box-shadow: none;
	box-shadow: none
}

.bootstrap-tagsinput .tag {
	margin-right: 2px;
	color: #fff
}

.bootstrap-tagsinput .tag [data-role=remove] {
	margin-left: 8px;
	cursor: pointer
}

.bootstrap-tagsinput .tag [data-role=remove]:after {
	content: "x";
	padding: 0 2px
}

.bootstrap-tagsinput .tag [data-role=remove]:hover,
.bootstrap-tagsinput .tag [data-role=remove]:hover:active {
	-webkit-box-shadow: none;
	box-shadow: none
}

.input-append.date .add-on i,
.input-group.date .input-group-addon span,
.input-prepend.date .add-on i {
	cursor: pointer;
	width: 14px;
	height: 14px
}

.spinner-buttons.btn-group-vertical .btn {
	margin: 0;
	height: 17px;
	width: 22px;
	font-size: 10px
}

.wizard-steps {
	list-style: none;
	display: table;
	width: 100%;
	padding: 0;
	margin: 0;
	position: relative
}

.wizard-steps li {
	display: table-cell;
	text-align: center;
	width: 1%
}

.wizard-steps li .step {
	border: 3px solid #e2e1e1;
	color: #999;
	font-size: 15px;
	border-radius: 100%;
	background-color: #fff;
	position: relative;
	z-index: 2;
	display: inline-block;
	width: 40px;
	height: 40px;
	line-height: 32px;
	text-align: center
}

.wizard-steps li:before {
	display: block;
	content: "";
	width: 100%;
	height: 1px;
	font-size: 0;
	overflow: hidden;
	border-top: 4px solid #e2e1e1;
	position: relative;
	top: 21px;
	z-index: 1
}

.wizard-steps li:last-child:before {
	max-width: 50%;
	width: 50%
}

.wizard-steps li:first-child:before {
	max-width: 51%;
	left: 50%
}

.wizard-steps li.active .step,
.wizard-steps li.active:before,
.wizard-steps li.complete .step,
.wizard-steps li.complete:before {
	border-color: #7bb7e5
}

.wizard-steps li.complete .step {
	cursor: default;
	color: #fff;
	-webkit-transition: transform ease .1s;
	-o-transition: transform ease .1s;
	transition: transform ease .1s
}

.wizard-steps li.complete .step:before {
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	line-height: 32px;
	text-align: center;
	border-radius: 100%;
	content: "\f00c";
	background-color: #fff;
	z-index: 3;
	font-family: FontAwesome;
	font-size: 17px;
	color: #7bb7e5
}

.wizard-steps li.complete:hover .step {
	-moz-transform: scale(1.1);
	-webkit-transform: scale(1.1);
	-o-transform: scale(1.1);
	-ms-transform: scale(1.1);
	transform: scale(1.1);
	cursor: pointer
}

.wizard-steps li .title {
	display: block;
	margin-top: 4px;
	max-width: 100%;
	color: #999;
	font-size: 14px;
	z-index: 104;
	text-align: center;
	table-layout: fixed;
	word-wrap: break-word
}

.wizard-steps li.active .title,
.wizard-steps li.complete .title {
	color: #555
}

.step-content .step-pane {
	display: none;
	min-height: 75px;
	padding: 4px 8px 12px;
	margin-top: 15px
}

.step-content .active {
	display: block
}

.wizard-actions {
	text-align: right
}

.wizard-actions.btn-group,
.wizard-actions.ui-group-buttons {
	float: right
}

@media only screen and (max-width:460px) {
	.wizard-steps li .step {
		width: 30px;
		height: 30px;
		line-height: 24px;
		border-width: 3px
	}
	.wizard-steps li:after,
	.wizard-steps li:before {
		border-width: 3px
	}
	.wizard-steps li.complete .step:before {
		line-height: 24px;
		font-size: 13px
	}
	.wizard-steps li:before {
		top: 16px
	}
	.step-content .step-pane {
		padding: 4px 4px 6px
	}
}

#bwizard .bw-navbar {
	border-bottom: 0 none;
	background-color: transparent;
	font-size: 13px;
	padding: 0;
	position: relative;
	min-height: 50px;
	margin-bottom: 10px
}

.bwizard-steps {
	display: inline-block;
	margin: 0;
	padding: 0;
	background: #fff
}

.bwizard-steps .active {
	color: #fff;
	background: #7bb7e5
}

.bwizard-steps .active a,
.bwizard-steps .active a:hover {
	color: #fff;
	cursor: default
}

.bwizard-steps .active .label {
	background-color: #555
}

.bwizard-steps .active:after {
	border-left-color: #7bb7e5
}

.bwizard-steps .label {
	position: relative;
	top: -1px;
	margin: 0 5px 0 0;
	padding: 1px 5px 2px
}

.bwizard-steps li {
	display: inline-block;
	position: relative;
	padding: 12px 17px 10px 30px;
	background: #f2f2f2;
	line-height: 18px;
	list-style: none
}

.bwizard-steps li:first-child {
	padding-left: 12px;
	-moz-border-radius: 4px 0 0 4px;
	-webkit-border-radius: 4px 0 0 4px;
	border-radius: 4px 0 0 4px
}

.bwizard-steps li:first-child:before {
	border: none
}

.bwizard-steps li:last-child {
	margin-right: 0;
	-moz-border-radius: 0 4px 4px 0;
	-webkit-border-radius: 0 4px 4px 0;
	border-radius: 0 4px 4px 0
}

.bwizard-steps li:last-child:after {
	border: none
}

.bwizard-steps li:before {
	position: absolute;
	left: 0;
	top: 0;
	height: 0;
	width: 0;
	border-bottom: 20px inset transparent;
	border-left: 20px solid #fff;
	border-top: 20px inset transparent;
	content: ""
}

.bwizard-steps li:after {
	position: absolute;
	right: -20px;
	top: 0;
	height: 0;
	width: 0;
	border-bottom: 20px inset transparent;
	border-left: 20px solid #f2f2f2;
	border-top: 20px inset transparent;
	content: "";
	z-index: 2
}

.bwizard-steps a,
.bwizard-steps a:hover {
	color: #999;
	text-decoration: none
}

.bwizard-steps.clickable li:not(.active) {
	cursor: pointer
}

.bwizard-steps.clickable li:hover:not(.active) {
	background: #e2e1e1
}

.bwizard-steps.clickable li:hover:not(.active) a {
	color: #3498db
}

.bwizard-steps.clickable li:hover:not(.active):after {
	border-left-color: #e2e1e1
}

@media (max-width:460px) {
	.bwizard-steps li {
		margin: 5px 0;
		padding: 6px 17px 10px 15px
	}
	.bwizard-steps li:first-child {
		border-radius: 0
	}
	.bwizard-steps li:last-child {
		margin-right: 0;
		border-radius: 0
	}
	.bwizard-steps li:before {
		border-bottom: 0 none;
		border-left: 0 none;
		border-top: 0 none;
		content: ""
	}
	.bwizard-steps li:after {
		border-bottom: 0 none;
		border-left: 0 none;
		border-top: 0 none
	}
}

.md-editor {
	display: block;
	border: 1px solid #e2e1e1
}

.md-editor .md-footer,
.md-editor>.md-header {
	display: block;
	padding: 6px 4px;
	background: #f2f2f2
}

.md-editor>.md-header {
	margin: 0
}

.md-editor>.md-preview {
	border-top: 1px dashed #e2e1e1;
	border-bottom: 1px dashed #e2e1e1;
	min-height: 10px;
	overflow: auto
}

.md-editor>textarea {
	outline: 0;
	margin: 0;
	display: block;
	padding: 10px;
	width: 100%;
	border: 0;
	border-top: 1px dashed #e2e1e1;
	border-bottom: 1px dashed #e2e1e1;
	background: #fff
}

.md-editor .btn {
	border: 1px solid #e2e1e1;
	background: #fff;
	color: #393939
}

.md-editor .btn.btn-primary {
	background: #7bb7e5;
	border-color: #6eb0e2;
	color: #fff
}

.md-editor .btn .fa {
	font-size: 14px
}

.md-editor .btn [class*=" fa-"],
.md-editor .btn [class*=" glyphicon-"],
.md-editor .btn [class^=fa-],
.md-editor .btn [class^=glyphicon-] {
	margin-right: 0
}

.md-editor .md-controls {
	float: right;
	padding: 3px
}

.md-editor .md-controls .md-control {
	right: 5px;
	color: #bebebe;
	padding: 3px 3px 3px 10px
}

.md-editor .md-controls .md-control:hover {
	color: #333
}

.md-editor.md-fullscreen-mode {
	width: 100%;
	height: 100%;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 99999;
	padding: 60px 30px 15px;
	background: #fff!important;
	border: 0!important
}

.md-editor.md-fullscreen-mode .md-footer {
	display: none
}

.md-editor.md-fullscreen-mode .md-input,
.md-editor.md-fullscreen-mode .md-preview {
	margin: 0 auto!important;
	height: 100%!important;
	font-size: 20px!important;
	padding: 20px!important;
	color: #999;
	line-height: 1.6em!important;
	resize: none!important;
	box-shadow: none!important;
	background: #fff!important;
	border: 0!important
}

.md-editor.md-fullscreen-mode .md-preview {
	color: #333;
	overflow: auto
}

.md-editor.md-fullscreen-mode .md-input:focus,
.md-editor.md-fullscreen-mode .md-input:hover {
	color: #333;
	background: #fff!important
}

.md-editor.md-fullscreen-mode .md-header {
	background: 0 0;
	text-align: center;
	position: fixed;
	width: 100%;
	top: 20px
}

.md-editor.md-fullscreen-mode .btn-group {
	float: none
}

.md-editor.md-fullscreen-mode .btn {
	border: 0;
	background: 0 0;
	color: #b3b3b3
}

.md-editor.md-fullscreen-mode .btn.active,
.md-editor.md-fullscreen-mode .btn:active,
.md-editor.md-fullscreen-mode .btn:focus,
.md-editor.md-fullscreen-mode .btn:hover {
	box-shadow: none;
	color: #333
}

.md-editor.md-fullscreen-mode .md-fullscreen-controls {
	position: absolute;
	top: 20px;
	right: 20px;
	text-align: right;
	z-index: 1002;
	display: block
}

.md-editor.md-fullscreen-mode .md-fullscreen-controls a {
	color: #b3b3b3;
	clear: right;
	margin: 10px;
	width: 30px;
	height: 30px;
	text-align: center
}

.md-editor.md-fullscreen-mode .md-fullscreen-controls a:hover {
	color: #333;
	text-decoration: none
}

.md-editor.md-fullscreen-mode .md-editor {
	height: 100%!important;
	position: relative
}

.md-editor .md-fullscreen-controls {
	display: none
}

.md-nooverflow {
	overflow: hidden;
	position: fixed;
	width: 100%
}

.editable-container .popover-title {
	color: #222;
	line-height: 18px!important
}

.editable-click,
a.editable-click {
	border-bottom: 1px dashed #e2e1e1;
	cursor: pointer;
	font-weight: 400
}

img.editable-click,
imga.editable-click {
	border: 1px dotted #e2e1e1
}

.editable-click:hover,
a.editable-click:hover {
	border-color: #3498db
}

img.editable-click:hover,
imga.editable-click:hover {
	opacity: .75;
	filter: alpha(opacity=75)
}

.editable-buttons,
.editable-input {
	display: inline-block
}

.editable-buttons .btn {
	margin: 0 2px 0 0
}

.editable-buttons .btn>[class*=fa-] {
	margin: 0
}

.editable-clear-x {
	cursor: pointer;
	color: #999;
	background: 0 0
}

.editable-clear-x:hover {
	color: #d15b47
}

.editable-clear-x:before {
	display: inline-block;
	content: "\f057";
	font-family: FontAwesome;
	font-size: 15px;
	position: absolute;
	margin-top: -9px;
	width: 16px;
	height: 30px;
	line-height: 30px;
	text-align: center
}

.editable-input .spinner {
	margin-right: 8px
}

.editable-inline .editable-slider {
	margin-top: 10px;
	margin-right: 4px
}

.editable-popup .editable-slider {
	display: block;
	margin-bottom: 16px;
	margin-top: 4px
}

.editable-slider input {
	display: none
}

.editable-input .file-input {
	display: block
}

.editable-image .file-multiple label.selected {
	border-color: transparent
}

.editable-image+.editable-buttons,
.editable-wysiwyg+.editable-buttons {
	display: block;
	text-align: center;
	margin-top: 8px
}

.editable-wysiwyg .wysiwyg-editor {
	height: auto;
	overflow-y: hidden
}

.editableform .input-append.dropdown-menu {
	display: none
}

.editableform .open .input-append.dropdown-menu {
	display: block
}

.editable-container .editableform {
	margin-bottom: 10px
}

.editable-inline .editableform {
	margin-bottom: 0
}

.editableform-loading {
	background: 0 0
}

.editableform-loading .progress,
.editableform-loading [class*=fa-] {
	position: relative;
	top: 35%
}

.jvectormap-label {
	position: absolute;
	display: none;
	border: 1px solid #eee;
	background: #292929;
	color: #fff;
	font-size: 10px!important;
	padding: 3px;
	z-index: 9999
}

.jvectormap-zoomin,
.jvectormap-zoomout {
	position: absolute;
	left: 10px;
	background: #7bb7e5;
	padding: 5px;
	color: #fff;
	cursor: pointer;
	line-height: 10px;
	text-align: center;
	font-weight: 700
}

.jvectormap-zoomin {
	top: 10px
}

.jvectormap-zoomout {
	top: 35px
}

.wysiwyg-editor {
	max-height: 250px;
	height: 250px;
	background-color: #fff;
	color: #393939;
	border-collapse: separate;
	border: 1px solid #e2e1e1;
	padding: 4px;
	box-sizing: content-box;
	overflow-y: scroll;
	overflow-x: hidden;
	outline: 0
}

.wysiwyg-editor:focus {
	background-color: #fff
}

.wysiwyg-toolbar {
	line-height: 33px;
	position: relative
}

.wysiwyg-toolbar .dropdown-menu {
	text-align: left
}

.wysiwyg-toolbar .btn-group {
	float: none!important;
	font-size: 0
}

.wysiwyg-toolbar .btn-group>.btn {
	float: none;
	padding-left: 0;
	padding-right: 0;
	text-align: center;
	margin-left: 1px;
	-webkit-box-shadow: none!important;
	box-shadow: none!important
}

.wysiwyg-toolbar .btn-group>.btn .glyphicon,
.wysiwyg-toolbar .btn-group>.btn>.fa {
	margin: 0 4px
}

.wysiwyg-toolbar .btn-group>.btn .glyphicon:first-child,
.wysiwyg-toolbar .btn-group>.btn>.fa:first-child {
	font-size: 14px;
	width: 25px;
	max-width: 25px;
	display: inline-block;
	border-width: 1px!important
}

.wysiwyg-toolbar .btn-group>.btn.dropdown-toggle>.fa:last-child,
.wysiwyg-toolbar .btn-group>.btn.dropdown-toggle>.glyphicon:last-child {
	margin-right: 4px
}

.editor-style1 {
	background: #fff;
	border: 1px solid #e2e1e1;
	border-bottom: 0 none
}

.editor-style1 .btn-group>.btn,
.editor-style1 .btn-group>.inline>.btn,
.editor-style2 .btn-group>.btn,
.editor-style2 .btn-group>.inline>.btn {
	margin: 0!important;
	background: #fff!important;
	color: #393939;
	border-width: 0!important;
	text-shadow: none!important;
	-webkit-box-shadow: none!important;
	box-shadow: none!important
}

.editor-style1 .btn-group>.btn.active,
.editor-style1 .btn-group>.inline>.btn.active,
.editor-style2 .btn-group>.btn.active,
.editor-style2 .btn-group>.inline>.btn.active {
	color: #7bb7e5!important
}

.editor-style1 .btn-group>.btn.active:after,
.editor-style1 .btn-group>.inline>.btn.active:after,
.editor-style2 .btn-group>.btn.active:after,
.editor-style2 .btn-group>.inline>.btn.active:after {
	display: none
}

.editor-style1 .btn-group,
.editor-style2 .btn-group {
	position: relative
}

.editor-style1 .btn-group:after,
.editor-style2 .btn-group:after {
	display: block;
	content: "";
	position: absolute;
	left: -2px;
	top: 6px;
	bottom: 6px;
	width: 0;
	max-width: 0;
	border-left: 1px solid #e2e1e1
}

.editor-style1 .btn-group:first-child:after,
.editor-style2 .btn-group:first-child:after {
	display: none
}

.editor-style1.wysiwyg-toolbar,
.editor-style2.wysiwyg-toolbar {
	margin: 0!important
}

.editor-style2 {
	background-color: #f2f2f2;
	border: 1px solid #e2e1e1;
	border-bottom: 0 none
}

.editor-style2+.wysiwyg-editor {
	border-color: #e2e1e1;
	background-color: #fff
}

.editor-style2 .btn-group>.btn,
.editor-style2 .btn-group>.inline>.btn {
	margin: 0 1px 0 0!important;
	background: #fff!important;
	border: 1px solid #e2e1e1!important;
	text-shadow: none!important;
	color: #393939
}

.editor-style2 .btn-group>.btn.active,
.editor-style2 .btn-group>.inline>.btn.active {
	color: #7bb7e5!important
}

.editor-style2 .btn-group:after,
.editor-style2 .btn-group>.btn.active:after,
.editor-style2 .btn-group>.inline>.btn.active:after {
	display: none
}

.wysiwyg-toolbar .btn-colorpicker {
	width: 24px;
	height: 24px;
	position: relative;
	background: #7bb7e5;
	background: -moz-linear-gradient(top, #cf3e73 10%, #fff 20%, #2283c5 30%, #fff 40%, #87b87f 50%, #fff 60%, #ffb752 70%, #fff 80%, #d15b47 90%, #fff 100%);
	background: -webkit-gradient(linear, left top, left bottom, color-stop(10%, #cf3e73), color-stop(20%, #fff), color-stop(30%, #2283c5), color-stop(40%, #fff), color-stop(50%, #87b87f), color-stop(60%, #fff), color-stop(70%, #ffb752), color-stop(80%, #fff), color-stop(90%, #d15b47), color-stop(100%, #fff));
	background: -webkit-linear-gradient(top, #cf3e73 10%, #fff 20%, #2283c5 30%, #fff 40%, #87b87f 50%, #fff 60%, #ffb752 70%, #fff 80%, #d15b47 90%, #fff 100%);
	background: -o-linear-gradient(top, #cf3e73 10%, #fff 20%, #2283c5 30%, #fff 40%, #87b87f 50%, #fff 60%, #ffb752 70%, #fff 80%, #d15b47 90%, #fff 100%);
	background: -ms-linear-gradient(top, #cf3e73 10%, #fff 20%, #2283c5 30%, #fff 40%, #87b87f 50%, #fff 60%, #ffb752 70%, #fff 80%, #d15b47 90%, #fff 100%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#CF3E73', endColorstr='#FFB752', GradientType=0);
	background: linear-gradient(top, #cf3e73 10%, #fff 20%, #2283c5 30%, #fff 40%, #87b87f 50%, #fff 60%, #ffb752 70%, #fff 80%, #d15b47 90%, #fff 100%)
}

.wysiwyg-toolbar .dropdown-colorpicker>.dropdown-menu {
	top: auto
}

.wysiwyg-toolbar input[type=file] {
	position: fixed;
	z-index: -10;
	opacity: 0;
	max-width: 0;
	max-height: 0;
	display: block
}

.wysiwyg-toolbar .wysiwyg-choose-file {
	display: inline-block;
	width: auto;
	margin: 4px auto 0;
	padding-left: 5px;
	padding-right: 5px
}

.wysiwyg-toolbar .dropdown-menu input[type=text] {
	margin-left: 8px;
	margin-bottom: 0
}

.wysiwyg-toolbar .dropdown-menu input[type=text].form-control {
	min-width: 150px
}

.wysiwyg-toolbar .dropdown-menu .btn {
	margin-right: 8px;
	margin-left: 8px
}

.editor-style1 .btn-colorpicker {
	width: 20px;
	height: 20px;
	margin-left: 4px
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
	.wysiwyg-editor img {
		display: inline!important
	}
	.wysiwyg-editor .ui-wrapper {
		border: 1px dotted #D00;
		overflow: visible!important;
		display: inline-block!important;
		vertical-align: middle
	}
	.wysiwyg-editor .ui-wrapper:after {
		content: "";
		display: block;
		position: absolute;
		right: -3px;
		bottom: -3px;
		width: 7px;
		height: 7px;
		border: 1px solid #D00;
		background-color: #fff;
		z-index: 1
	}
}

.dd {
	position: relative;
	display: block;
	margin: 0;
	padding: 0;
	max-width: 600px;
	list-style: none;
	line-height: 20px
}

.dd-list {
	display: block;
	position: relative;
	margin: 0;
	padding: 0;
	list-style: none
}

.dd-list .dd-list {
	padding-left: 30px
}

.dd-collapsed .dd-list {
	display: none
}

.dd-empty,
.dd-item,
.dd-placeholder {
	display: block;
	position: relative;
	margin: 0;
	padding: 0;
	min-height: 20px;
	line-height: 20px
}

.dd-handle,
.dd2-content {
	display: block;
	min-height: 38px;
	margin: 5px 0;
	padding: 8px 12px;
	border: 1px solid #e2e1e1;
	text-decoration: none;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box
}

.dd-handle[class*=btn-],
.dd2-content[class*=btn-] {
	border: none
}

.dd-handle[class*=btn-].btn-default,
.dd-handle[class*=btn-].btn-white,
.dd2-content[class*=btn-].btn-default,
.dd2-content[class*=btn-].btn-white {
	border: 1px solid #e2e1e1
}

.dd-handle[class*=btn-].btn-default,
.dd2-content[class*=btn-].btn-default {
	background: #f2f2f2;
	color: #393939
}

.dd-handle:hover.btn-default,
.dd2-content:hover.btn-default {
	background: #fafafa
}

.dd2-handle+.dd2-content,
.dd2-handle+.dd2-content[class*=btn-] {
	padding-left: 44px
}

.dd-item>button:hover~.dd-handle[class*=btn-],
.dd-item>button:hover~.dd2-content[class*=btn-],
.dd2-handle:hover~.dd2-content[class*=btn-] {
	opacity: 1;
	filter: alpha(opacity=100)
}

.dd2-item.dd-item>button {
	margin-left: 34px
}

.dd-item>button {
	display: block;
	position: relative;
	z-index: 1;
	cursor: pointer;
	float: left;
	width: 25px;
	height: 20px;
	margin: 5px 1px 5px 5px;
	padding: 0;
	text-indent: 100%;
	white-space: nowrap;
	overflow: hidden;
	border: 0;
	background: 0 0;
	line-height: 1;
	text-align: center;
	font-weight: 700;
	top: 4px;
	left: 1px;
	outline: 0!important
}

.dd-item>button:before {
	font-family: FontAwesome;
	content: '\f067';
	display: block;
	position: absolute;
	width: 100%;
	text-align: center;
	text-indent: 0;
	font-weight: 400
}

.dd-item>button[data-action=collapse]:before {
	content: '\f068'
}

.dd-item>button:hover {
	color: #7bb7e5
}

.dd-item.dd-colored>button,
.dd-item.dd-colored>button:hover {
	color: #fff
}

.dd-empty,
.dd-placeholder {
	margin: 5px 0;
	padding: 0;
	min-height: 30px;
	background: #f2f2f2;
	border: 2px dashed #e2e1e1;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box
}

.dd-empty {
	border-color: #e2e1e1;
	border-style: solid;
	background-color: #f2f2f2
}

.dd-dragel {
	position: absolute;
	pointer-events: none;
	z-index: 999;
	opacity: 1;
	filter: alpha(opacity=100)
}

.dd-dragel>li>.dd-handle {
	opacity: 1;
	filter: alpha(opacity=100);
	position: relative
}

.dd-dragel>.dd-item>.dd-handle {
	margin-top: 0
}

.dd-list>li[class*=item-] {
	border-width: 0;
	padding: 0
}

.dd-list>li[class*=item-]>.dd-handle {
	border-left: 2px solid;
	border-left-color: inherit
}

.dd-list>li>.dd-handle .sticker {
	position: absolute;
	right: 0;
	top: 0
}

.dd-dragel>li>.dd2-handle,
.dd2-handle {
	left: 0;
	top: 0;
	width: 36px;
	margin: 0;
	text-align: center;
	padding: 0!important;
	line-height: 38px;
	height: 38px;
	border: 1px solid #e2e1e1;
	cursor: pointer;
	overflow: hidden;
	position: absolute;
	z-index: 1
}

.dd2-handle[class*=btn-] {
	border-right: 1px solid #e2e1e1
}

.dd-dragel .dd2-handle[class*=btn-] {
	border-color: transparent;
	border-right-color: #e2e1e1
}

.dd-item>.dd2-handle .drag-icon {
	display: none
}

.dd-dragel>.dd-item>.dd2-handle .drag-icon {
	display: inline
}

.dd-dragel>.dd-item>.dd2-handle .normal-icon {
	display: none
}

.select2-container.form-control {
	border: 0;
	height: auto!important;
	padding: 0
}

.form-control .select2-choice {
	border: 1px solid #e2e1e1;
	background-color: #fff;
	background-image: none;
	filter: none;
	padding: 3px 0 0 12px;
	border-radius: 0
}

.select2-container .select2-choice {
	border-radius: 0;
	height: 34px;
	line-height: 28px
}

.select2-container.select2-drop-above .select2-choice {
	border-radius: 0
}

.select2-container[class*=input-] {
	max-width: none
}

.select2-container .select2-choice abbr,
.select2-search-choice-close {
	background: 0 0
}

.select2-container .select2-choice abbr:before,
.select2-search-choice-close:before {
	font-family: FontAwesome;
	font-size: 12px;
	display: inline;
	content: "\f00d";
	color: #393939;
	position: relative;
	top: -1px
}

.select2-container .select2-choice abbr:hover:before,
.select2-search-choice-close:hover:before {
	color: #555
}

.select2-container .select2-choice abbr:before {
	top: -7px
}

.select2-search-choice-close:hover {
	text-decoration: none!important
}

.select2-drop {
	border-radius: 0;
	border: 1px solid #e2e1e1;
	border-width: 0 1px 3px;
	color: #393939
}

.select2-drop.select2-drop-above {
	border-radius: 0;
	border-color: #e2e1e1!important
}

.select2-container .select2-choice {
	background: #fff
}

.select2-container-active .select2-choice,
.select2-container-active .select2-choices,
.select2-container-multi.select2-container-active .select2-choices,
.select2-dropdown-open.select2-drop-above .select2-choice,
.select2-dropdown-open.select2-drop-above .select2-choices {
	border-color: #e2e1e1
}

.select2-results .select2-highlighted {
	background: #7bb7e5
}

.select2-container .select2-choice .select2-arrow {
	border-radius: 0;
	background: 0 0;
	border: none
}

.select2-container .select2-choice .select2-arrow b {
	background: 0 0
}

.select2-container .select2-choice .select2-arrow b:before {
	font-family: FontAwesome;
	display: inline;
	content: "\f107";
	color: #393939;
	position: relative;
	left: 0
}

.select2-dropdown-open .select2-choice .select2-arrow b:before {
	content: "\f106"
}

.select2-search .select2-input {
	background: #fff;
	margin-top: 4px
}

.select2-search:after {
	font-family: FontAwesome;
	font-size: 14px;
	display: inline;
	content: "\f002";
	color: #393939;
	position: relative;
	top: 0;
	left: -20px;
	z-index: 0
}

.select2-dropdown-open.select2-drop-above .select2-choice,
.select2-dropdown-open.select2-drop-above .select2-choices {
	background-image: none;
	background-color: #fff
}

.select2-container-multi .select2-choices {
	border-color: #e2e1e1;
	background-image: none
}

.select2-container-multi .select2-choices .select2-search-field input {
	border: none!important;
	-webkit-box-shadow: none!important;
	box-shadow: none!important;
	background: 0 0!important;
	font-size: 13px
}

.select2-container-multi .select2-choices .select2-search-choice {
	line-height: 16px;
	padding-bottom: 4px
}

.select2-container-active .select2-choice,
.select2-container-active .select2-choices,
.select2-container-multi.select2-container-active .select2-choices,
.select2-dropdown-open.select2-drop-above .select2-choice,
.select2-dropdown-open.select2-drop-above .select2-choices {
	-webkit-box-shadow: none;
	box-shadow: none
}

.select2-search input.select2-active {
	background-color: #fff;
	position: relative;
	z-index: 1
}

@media only screen and (-webkit-min-device-pixel-ratio:1.5),
only screen and (min-resolution:144dpi) {
	.select2-container .select2-choice abbr,
	.select2-container .select2-choice div b,
	.select2-search input,
	.select2-search-choice-close {
		background-image: none!important;
		background-size: auto!important
	}
	.select2-search input {
		background-position: auto!important
	}
}

.form-group.has-error .select2-choice,
.form-group.has-error .select2-choices {
	border-color: #ce838f!important
}

.form-group.has-info .select2-choice,
.form-group.has-info .select2-choices {
	border-color: #70cee1!important
}

.form-group.has-warning .select2-choice,
.form-group.has-warning .select2-choices {
	border-color: #f0c670!important
}

.form-group.has-success .select2-choice,
.form-group.has-success .select2-choices {
	border-color: #a1cd7c!important
}

@media only screen and (-webkit-min-device-pixel-ratio:1.5),
only screen and (min-resolution:144dpi) {
	.select2-container .select2-choice .select2-arrow b,
	.select2-container .select2-choice abbr,
	.select2-search input,
	.select2-search-choice-close {
		background-image: none!important;
		background-repeat: no-repeat!important;
		background-size: auto!important
	}
	.select2-search input {
		background-position: auto!important
	}
}

.select2-container-multi.tag-input-style .select2-choices .select2-search-choice {
	background-image: none;
	background-color: #7bb7e5;
	color: #fff;
	display: inline-block;
	font-size: 13px;
	font-weight: 400;
	margin-bottom: 3px;
	margin-right: 0;
	padding: 6px 22px 7px 9px;
	position: relative;
	transition: all .2s ease 0s;
	vertical-align: baseline;
	white-space: nowrap;
	border: none;
	-webkit-box-shadow: none;
	box-shadow: none;
	border-radius: 0
}

.select2-container-multi.tag-input-style .select2-choices .select2-search-choice .select2-search-choice-close {
	position: absolute;
	top: 1px;
	bottom: 0;
	right: 0;
	left: auto;
	width: 18px;
	height: auto;
	line-height: 25px;
	text-align: center
}

.select2-container-multi.tag-input-style .select2-choices .select2-search-choice .select2-search-choice-close:before {
	color: #fff;
	position: static;
	font-size: 11px
}

.select2-container-multi.tag-input-style .select2-choices .select2-search-choice .select2-search-choice-close:hover {
	background-color: rgba(0, 0, 0, .2)
}

.select2-container-multi.tag-input-style .select2-choices .select2-search-choice .select2-search-choice-close:hover:before {
	color: #FFF
}

.modal-open .select2-drop-mask {
	z-index: 10051
}

.modal-open .select2-drop {
	z-index: 10052
}

.modal-open .select2-search {
	z-index: 10053
}

.colorpicker-saturation {
	width: 100px;
	height: 100px;
	background-image: url(../../images/saturation.png);
	cursor: crosshair;
	float: left
}

.colorpicker-saturation i {
	display: block;
	height: 5px;
	width: 5px;
	border: 1px solid #000;
	border-radius: 0;
	position: absolute;
	top: 0;
	left: 0;
	margin: -4px 0 0 -4px
}

.colorpicker-saturation i b {
	display: block;
	height: 5px;
	width: 5px;
	border: 1px solid #fff;
	border-radius: 0
}

.colorpicker-alpha,
.colorpicker-hue {
	width: 15px;
	height: 100px;
	float: left;
	cursor: row-resize;
	margin-left: 4px;
	margin-bottom: 4px
}

.colorpicker-alpha i,
.colorpicker-hue i {
	display: block;
	height: 1px;
	background: #000;
	border-top: 1px solid #fff;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	margin-top: -1px
}

.colorpicker-hue {
	background-image: url(../../images/hue.png)
}

.colorpicker-alpha {
	background-image: url(../../images/alpha.png);
	display: none
}

.colorpicker {
	top: 0;
	left: 0;
	padding: 4px;
	min-width: 120px;
	margin-top: 1px;
	border-radius: 4px
}

.colorpicker:after {
	clear: both
}

.colorpicker:before {
	content: '';
	display: inline-block;
	border-left: 7px solid transparent;
	border-right: 7px solid transparent;
	border-bottom: 7px solid #ccc;
	border-bottom-color: rgba(0, 0, 0, .2);
	position: absolute;
	top: -7px;
	left: 6px
}

.colorpicker:after {
	content: '';
	display: inline-block;
	border-left: 6px solid transparent;
	border-right: 6px solid transparent;
	border-bottom: 6px solid #fff;
	position: absolute;
	top: -6px;
	left: 7px
}

.colorpicker div {
	position: relative
}

.colorpicker.alpha {
	min-width: 140px
}

.colorpicker.alpha .colorpicker-alpha {
	display: block
}

.colorpicker-color {
	height: 10px;
	margin-top: 5px;
	clear: both;
	background-image: url(../../images/alpha.png);
	background-position: 0 100%
}

.colorpicker-color div {
	height: 10px
}

.input-append.color .add-on i,
.input-prepend.color .add-on i {
	display: block;
	cursor: pointer;
	width: 16px;
	height: 16px
}

.easy-pie-chart {
	position: relative;
	display: inline-block;
	text-align: center
}

.easy-pie-chart canvas {
	position: absolute;
	top: 0;
	left: 0
}

.easy-pie-chart .percent {
	display: inline-block;
	z-index: 2
}

.easy-pie-chart .percent:after {
	content: "%";
	margin-left: .1em
}

.easy-pie-chart .icon,
.easy-pie-chart .number {
	display: inline-block;
	z-index: 2
}

.easy-pie-chart a.title {
	display: block;
	text-align: center;
	font-size: 13px 1;
	color: #555;
	margin-top: 5px;
	margin-bottom: 10px;
	white-space: nowrap
}

.easy-pie-chart a.title:active,
.easy-pie-chart a.title:focus,
.easy-pie-chart a.title:hover {
	text-decoration: none
}

.easy-pie-chart a.title:after {
	content: "\f101";
	margin-left: 4px;
	font-family: FontAwesome;
	font-style: normal;
	font-weight: 400;
	color: #7bb7e5
}

.rating-input .rating-clear {
	text-decoration: none
}

.sparkline-chart {
	margin-top: 10px
}

.sparkline-chart a.title {
	display: block;
	text-align: center;
	font-size: 14px;
	color: #555;
	margin-top: 5px;
	margin-bottom: 10px;
	white-space: nowrap
}

.sparkline-chart a.title:active,
.sparkline-chart a.title:focus,
.sparkline-chart a.title:hover {
	text-decoration: none
}

.sparkline-chart a.title:after {
	content: "\f101";
	margin-left: 4px;
	font-family: FontAwesome;
	font-style: normal;
	font-weight: 400;
	color: #3498db
}

.jqstooltip {
	-webkit-box-sizing: content-box;
	-moz-box-sizing: content-box;
	box-sizing: content-box
}

.profile-pic {
	display: block;
	padding: 3px;
	border: 1px solid #f2f2f2;
	background: #fff;
	margin-bottom: 5px
}

.profile-pic img {
	width: 100%;
	max-width: 100%
}

.profile-pic :after,
.profile-pic :before {
	content: "";
	height: 4px;
	position: absolute;
	right: 0;
	top: -5px
}

.profile-pic :before {
	border-left: 35px solid #82af6f;
	border-right: 34px solid #3a87ad;
	margin: 0 30px 0 0
}

.profile-pic :after {
	border-left: 34px solid #428bca;
	border-right: 35px solid #2b3539;
	margin: 0 99px 0 0
}

ul.wysihtml5-toolbar li {
	margin: 0 5px 5px 0
}

ul.wysihtml5-toolbar .btn {
	background: #fff;
	color: #393939;
	border-color: #e2e1e1;
	-webkit-box-shadow: none!important;
	box-shadow: none!important
}

ul.wysihtml5-toolbar .btn .fa,
ul.wysihtml5-toolbar .btn .glyphicon {
	font-size: 14px
}

ul.wysihtml5-toolbar a.btn.wysihtml5-command-active {
	background: #7bb7e5;
	color: #fff
}

ul.wysihtml5-toolbar a.btn.wysihtml5-command-active .fa,
ul.wysihtml5-toolbar a.btn.wysihtml5-command-active .glyphicon {
	color: #fff
}

.slimScrollBar {
	border-radius: 0!important;
	background: #7bb7e5!important
}

.slimScrollRail {
	border-radius: 0!important
}

.morris-hover.morris-default-style {
	background: #292929;
	opacity: .95;
	filter: alpha(opacity=95)
}

.morris-hover-point,
.morris-hover-row-label {
	color: #fff!important
}

.morris-hover.morris-default-style {
	padding: 0 10px 5px;
	border: 0;
	border-radius: 0
}

.chart-holder {
	overflow: hidden;
	position: relative;
	width: 100%!important
}

.chart-holder>svg {
	width: 100%!important
}

td.legendColorBox {
	padding-right: 4px
}

td.legendColorBox>div {
	border: 1px solid #e2e1e1!important
}

.quick-list a {
	color: #393939
}

.quick-list a:active,
.quick-list a:focus,
.quick-list a:hover {
	text-decoration: none
}

.quick-list .media {
	position: relative;
	padding: 7.5px 10px;
	margin-top: 5px;
	border-bottom: 1px dotted #e2e1e1
}

.quick-list .media .media-body {
	font-weight: 600;
	color: #7bb7e5
}

.quick-list .media .media-body small {
	font-weight: 400;
	color: #393939
}

.quick-list .media img {
	border: 2px solid #7bb7e5
}

.quick-list .media>.tools {
	position: absolute;
	right: 10px;
	bottom: 10px;
	display: none
}

.quick-list .media:hover {
	background: #F5F5F5
}

.quick-list .media:hover>.tools {
	display: inline-block
}

.pace .pace-progress {
	background: #fee188;
	position: fixed;
	z-index: 2000;
	top: 0;
	left: 0;
	height: 3px;
	-webkit-transition: width 1s;
	-o-transition: width 1s;
	transition: width 1s
}

.pace-inactive {
	display: none
}

.widget {
	margin-bottom: 15px;
	position: relative;
	border: 1px solid #e2e1e1
}

.widget .body {
	min-height: 100px;
	height: auto;
	color: inherit;
	padding: 15px;
	overflow: hidden;
	position: relative;
	font-size: 13px
}

.widget .body h1,
.widget .body h2,
.widget .body h3,
.widget .body h4,
.widget .body p:last-child {
	margin: 0
}

.widget .body a,
.widget .body a:active,
.widget .body a:focus,
.widget .body a:hover {
	color: inherit;
	text-decoration: none
}

.widget .body .background-icon-left [class*=fa-],
.widget .body .background-icon-left [class*=glyphicon-],
.widget .body .background-icon-right [class*=fa-],
.widget .body .background-icon-right [class*=glyphicon-] {
	font-size: 7.5em;
	position: absolute;
	opacity: .1;
	filter: alpha(opacity=10)
}

.widget .body .background-icon-left [class*=fa-],
.widget .body .background-icon-left [class*=glyphicon-] {
	top: -15px;
	left: -15px
}

.widget .body .background-icon-right [class*=fa-],
.widget .body .background-icon-right [class*=glyphicon-] {
	top: -15px;
	right: -15px
}

#colorbox:active,
#colorbox:focus,
button {
	outline: 0!important
}

#cboxBottomCenter,
#cboxBottomLeft,
#cboxBottomRight,
#cboxMiddleLeft,
#cboxMiddleRight,
#cboxTopCenter,
#cboxTopLeft,
#cboxTopRight {
	background: 0 0!important;
	opacity: 0
}

#cboxContent {
	border: 12px solid #222;
	background-color: #fff;
	padding: 7px
}

#cboxOverlay {
	background: #000
}

#cboxCurrent {
	left: 64px;
	margin-bottom: 4px;
	font-size: 14px
}

#cboxTitle {
	margin-bottom: 4px;
	font-size: 14px;
	color: #555
}

#cboxClose,
#cboxNext,
#cboxPrevious {
	background: 0 0;
	text-indent: 0;
	width: 26px;
	height: 26px;
	line-height: 22px;
	padding: 0 4px;
	text-align: center;
	border: 2px solid #f2f2f2;
	border-radius: 16px;
	color: #666;
	font-size: 12px;
	margin-left: 5px;
	margin-bottom: 5px
}

#cboxNext:hover,
#cboxPrevious:hover {
	color: #555;
	border-color: #e2e1e1
}

#cboxContent {
	overflow: visible
}

#cboxClose {
	background-color: #fff;
	border: 2px solid #fff;
	border-radius: 32px;
	color: #fff;
	font-size: 21px;
	height: 28px;
	width: 28px;
	padding-bottom: 2px;
	margin-left: 0;
	right: -14px;
	top: -14px
}

#cboxLoadingOverlay {
	background: 0 0!important
}

#cboxLoadingGraphic {
	background: #fff!important;
	text-align: center
}

#cboxLoadingGraphic>[class*=fa-] {
	display: inline-block;
	background-color: #fff;
	border-radius: 8px;
	width: 32px;
	height: 32px;
	position: relative;
	top: 48%;
	text-align: center;
	vertical-align: middle;
	font-size: 24px;
	color: #ffb752
}

.daterangepicker .ranges li {
	color: #999;
	border-radius: 0
}

.daterangepicker .ranges li.active,
.daterangepicker .ranges li:hover {
	background: #7bb7e5;
	border: 1px solid transparent
}

.daterangepicker .calendar-date {
	border-radius: 0
}

.datepicker {
	padding: 6px 12px
}

.datepicker.datepicker-dropdown {
	padding: 4px
}

.paper-clip {
	display: none
}

@media (min-width:992px) {
	.paper-clip {
		width: 60px;
		height: 85px;
		background: url(../../images/paper-clip.png);
		position: absolute;
		top: -14px;
		right: -13px;
		display: block
	}
}

.select2-container-multi .select2-choices .select2-search-choice {
	border: 1px solid #e2e1e1;
	background-color: #fff
}

.ribbon-wrapper {
	width: 85px;
	height: 88px;
	overflow: hidden;
	position: absolute;
	top: -3px;
	right: -3px
}

.ribbon-wrapper .ribbon {
	display: block;
	font-size: 15px;
	color: #fff;
	text-align: center;
	-moz-transform: rotate(45deg);
	-webkit-transform: rotate(45deg);
	-o-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
	position: relative;
	padding: 7px 0;
	left: -5px;
	top: 15px;
	width: 120px;
	line-height: 20px;
	background-color: rgba(0, 0, 0, .7);
	-webkit-box-shadow: 0 0 3px rgba(0, 0, 0, .3);
	box-shadow: 0 0 3px rgba(0, 0, 0, .3)
}

.ribbon-wrapper.small {
	width: 65px;
	height: 68px
}

.ribbon-wrapper.small .ribbon {
	width: 90px;
	padding: 0;
	font-size: 13px
}

.profile-card {
	width: 100%;
	position: relative;
	margin-bottom: 15px;
	overflow: hidden;
	color: #999;
	text-align: center
}

.profile-card .animate {
	-webkit-transition: all .3s ease-in-out;
	-o-transition: all .3s ease-in-out;
	transition: all .3s ease-in-out
}

.profile-card>img {
	width: 100px;
	padding: 1px;
	background: rgba(0, 0, 0, .5)
}

.profile-card .profile-card-details {
	width: 100%;
	height: 100%;
	position: absolute;
	bottom: -100%;
	left: 0;
	padding: 0 15px
}

.profile-card .profile-card-details .profile-card-header {
	width: 100%;
	height: 100%;
	height: auto;
	position: absolute;
	bottom: -100%;
	bottom: 100%;
	left: 0;
	padding: 10px 5px;
	background: rgba(0, 0, 0, .7)
}

.profile-card .profile-card-details .profile-card-header h4,
.profile-card .profile-card-details .profile-card-header span {
	margin: 0;
	padding: 0
}

.profile-card .profile-card-details .profile-card-detail .social {
	list-style: none;
	padding: 0;
	margin-top: 25px
}

.profile-card .profile-card-details .profile-card-detail .social li {
	padding: 0;
	display: inline-block
}

.profile-card .profile-card-details::-webkit-scrollbar {
	width: 8px
}

.profile-card .profile-card-details::-webkit-scrollbar-button {
	width: 8px;
	height: 0
}

.profile-card .profile-card-details::-webkit-scrollbar-track {
	background: 0 0
}

.profile-card .profile-card-details::-webkit-scrollbar-thumb {
	background: 0 0
}

.profile-card .profile-card-details::-webkit-scrollbar-thumb:hover {
	background: 0 0
}

.profile-card:hover .profile-card-details {
	bottom: 0;
	overflow: auto;
	padding-bottom: 15px;
	background: rgba(0, 0, 0, .7)
}

.profile-card:hover .profile-card-details .profile-card-header {
	position: relative;
	bottom: 0;
	padding-top: 45px;
	padding-bottom: 25px;
	background: 0 0
}

.rollerblade-img {
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	-webkit-user-drag: none
}

.rollerblade-drag {
	cursor: move
}

.bootstrap-touchspin .input-group-btn-vertical {
	position: relative;
	white-space: nowrap;
	width: 1%;
	vertical-align: middle;
	display: table-cell
}

.bootstrap-touchspin .input-group-btn-vertical>.btn {
	display: block;
	float: none;
	width: 100%;
	max-width: 100%;
	padding: 8px 10px;
	margin-left: -1px;
	position: relative
}

.bootstrap-touchspin .input-group-btn-vertical .bootstrap-touchspin-up {
	border-radius: 0 4px 0 0
}

.bootstrap-touchspin .input-group-btn-vertical .bootstrap-touchspin-down {
	margin-top: -2px;
	border-radius: 0 0 4px
}

.bootstrap-touchspin .input-group-btn-vertical i {
	position: absolute;
	top: 3px;
	left: 5px;
	font-size: 9px;
	font-weight: 400
}

.mini-search {
	position: absolute;
	right: 22px;
	line-height: 24px
}

.mini-search .form-search {
	margin-bottom: 0
}

.mini-search .nav-search-input {
	border: 1px solid #e2e1e1;
	width: 0;
	max-width: 0;
	height: 28px!important;
	padding-top: 2px;
	padding-bottom: 2px;
	border-radius: 100%!important;
	z-index: 11;
	-webkit-transition: width ease .15s;
	-o-transition: width ease .15s;
	transition: width ease .15s
}

.mini-search .nav-search-input+.dropdown-menu {
	min-width: 0;
	left: 0;
	right: 0
}

.mini-search .nav-search-input:focus,
.mini-search .nav-search-input:hover {
	border-color: #e2e1e1
}

.mini-search .nav-search-icon {
	margin: 7px 5px!important;
	background-color: transparent
}

.mini-search .input-icon input {
	padding-left: 18px!important
}

.mini-search:focus .nav-search-input,
.mini-search:hover .nav-search-input {
	border-color: #e2e1e1;
	border-radius: 4px!important;
	width: 152px;
	max-width: 152px
}

.mini-search:focus .input-icon input,
.mini-search:hover .input-icon input {
	padding-left: 23px!important
}

.tc-box {
	margin-bottom: 15px
}

.tc-box a,
.tc-box:active,
.tc-box:focus,
.tc-box:hover {
	text-decoration: none!important
}

.tc-box .media {
	padding: 10px 0
}

.tc-box .media .pull-left {
	margin-right: 0
}

.tc-box .border-right {
	border-right: 1px dotted #e2e1e1
}

@media (max-width:992px) {
	.tc-box .border-right {
		border-right: 1px dotted transparent;
		border-bottom: 1px dotted #e2e1e1
	}
}

.tc-box .media-body {
	padding-right: 10px;
	padding-left: 10px
}

.tc-box .icon-box {
	font-size: 26px;
	width: 50px;
	height: 50px;
	line-height: 45px;
	border: 2px solid #e2e1e1;
	border-radius: 100%;
	margin: 0 auto;
	text-align: center;
	color: #999;
	padding-right: 0
}

.tc-box:focus .icon-box,
.tc-box:hover .icon-box {
	color: #fff;
	background: #7bb7e5;
	border-color: #7bb7e5
}

.tc-box.no-hover .icon-box {
	border: 2px solid transparent
}

.tc-box.no-hover:focus .icon-box,
.tc-box.no-hover:hover .icon-box {
	background: 0 0;
	border-color: transparent
}

.tc-box.no-hover:focus .icon-box .fa,
.tc-box.no-hover:focus .icon-box .glyphicon,
.tc-box.no-hover:hover .icon-box .fa,
.tc-box.no-hover:hover .icon-box .glyphicon {
	color: inherit
}

.tc-box.s2 {
	padding: 20px 15px;
	text-align: center;
	margin-bottom: 15px;
	background: #fff
}

.tc-box.s2 .icon-box {
	font-size: 26px;
	width: 50px;
	height: 50px;
	line-height: 45px;
	border: 2px solid #e2e1e1;
	border-radius: 100%;
	margin: 0 auto;
	color: #7bb7e5
}

.tc-box.s2:focus,
.tc-box.s2:hover {
	color: #fff;
	background: #7bb7e5
}

.tc-box.s2:focus .icon-box,
.tc-box.s2:hover .icon-box {
	border-color: #fff;
	color: #fff
}

.tc-box.s3 {
	text-align: center;
	background: #fff;
	padding: 15px
}

.tc-box.s3 h3 {
	color: #2b3539
}

.tc-box.s3 .fa,
.tc-box.s3 .glyphicon {
	font-size: 5em
}

.tc-box.s3.active .fa,
.tc-box.s3.active .glyphicon,
.tc-box.s3.active h3,
.tc-box.s3:focus .fa,
.tc-box.s3:focus .glyphicon,
.tc-box.s3:focus h3,
.tc-box.s3:hover .fa,
.tc-box.s3:hover .glyphicon,
.tc-box.s3:hover h3 {
	color: #7bb7e5
}

