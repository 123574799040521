@include media-breakpoint-up(xl) {
	footer {

		.container,
		.container-lg,
		.container-md,
		.container-sm,
		.container-xl {
			max-width: 1500px !important;
		}
	}

	#nav_top {
		padding: 0 !important;

		.row {
			max-width: 1500px;
			margin: 0 auto;
		}
	}

	#nav_middle {
		.row {
			.col {
				max-width: 1500px;
				margin: 0 auto;
			}
		}
	}

	#breadcrumb_row {
		.row {
			max-width: 1500px;
			margin: 0 auto;
		}
	}
}

@include media-breakpoint-down(md) {
	footer {

		.container,
		.container-lg,
		.container-md,
		.container-sm,
		.container-xl {
			max-width: none !important;
		}
	}
}

@include media-breakpoint-down(sm) {
	footer {

		.container,
		.container-lg,
		.container-md,
		.container-sm,
		.container-xl {
			max-width: none !important;
		}
	}
}

#back-to-top {
	display: none;
}

footer {
	font-family: "Nunito Sans", "Noto Sans HK", "Noto Sans SC", sans-serif;
	position: relative;

	.fa-twitter {
		background-image: url(https://www.dataplugs.com/wp-content/uploads/2024/11/dp-icon-tweet-v2.png);
		background-size: 20px;
		z-index: 9999999999;
		height: 25px;
		width: 34px;
		background-position: center center;
		background-repeat: no-repeat;

		&::before {
			content: '' !important;
		}
	}

	.sales_bar {
		padding: 33px 0;
		color: #fff;
		background: linear-gradient(270deg, #0044a9, #045cde, #012862);
		background-size: 400% 400%;
		animation: gradientAnimation2 8s ease infinite;
		background-position: top left;
		background-repeat: no-repeat;

		.sales_col {
			display: grid;
			grid-template-columns: .45fr 1fr;
			gap: 10px;
			font-size: 16px;

			@include media-breakpoint-down(lg) {
				grid-template-columns: 1fr;

				.sales_col_1 {
					.social_icon {
						display: none !important;
					}

					div {
						width: 100%;

						.contact {
							width: 100% !important;
						}
					}
				}

				.sales_col_2 {
					display: none !important;
				}

				.sales_col_3 {
					grid-template-columns: 1fr !important;
					gap: 15px !important;

					.chat,
					.email,
					.findus {
						margin-top: 15px;
						background-position: top left !important;
						background-size: 45px !important;
						border-left: none !important;

						.img {
							margin-top: 0 !important;
							text-align: left !important;
							margin-left: 50px;
							position: relative;

							a {
								position: absolute;
								top: 0;
								right: 0;
								font-size: 0px !important;
								margin: 0 !important;
								background-position: center center !important;
								border-radius: 25px !important;
								height: 100%;
								width: 50px !important;
								min-width: 50px !important;
							}
						}
					}
				}
			}
			
			.sales_col_1 {
				display: flex;
				-ms-flex-line-pack: justify;
				align-content: center;
				-ms-flex-wrap: wrap;
				flex-wrap: wrap;

				.contact {
					text-align: left;
					display: block;
					margin-top: 10px;
					border: 1px solid #ccc;
					padding: 7px 15px;
					border-radius: 7px;
					width: 75%;
					background-image: url(https://www.dataplugs.com/wp-content/uploads/2024/10/dp-menu-2024-arrow-white.png);
					background-position: center right 10px;
					background-repeat: no-repeat;
					width: fit-content;
					padding-right: 61px;
					color: #fff;

					&:hover {
						border: 1px solid #f29600;
						background-color: #f29600;
						color: white;
					}
				}

				.dp_slogan {
					background-image: url(https://www.dataplugs.com/wp-content/uploads/2024/11/footer-dp-icon.png);
					background-repeat: no-repeat;
					background-size: 50px;
					padding-left: 55px;
					background-position: center left;

					span {
						display: block;
						line-height: normal;

						&.text1 {
							font-weight: 800;
							line-height: 20px;
						}

						&.text2 {
							font-style: italic;
							font-weight: 300;
							line-height: 20px;
						}
					}
				}

				.social_icon {
					margin-top: 25px;
					display: flex;
					justify-content: flex-start;
					align-items: center;
					width: 100%;

					ul {
						display: inline-flex;
						gap: 15px;
						margin-left: 15px;
						align-items: center;

						.facebook {
							background-image: url(https://www.dataplugs.com/wp-content/uploads/2024/11/dp-icon-fb.png);
							background-repeat: no-repeat;
							display: block;
							width: 20px;
							height: 20px;
							background-position: center;
							background-size: cover;

							&:hover {
								opacity: 0.7;
							}
						}

						.linkedin {
							background-image: url(https://www.dataplugs.com/wp-content/uploads/2024/11/dp-icon-in.png);
							background-repeat: no-repeat;
							display: block;
							width: 20px;
							height: 20px;
							background-position: center;
							background-size: cover;

							&:hover {
								opacity: 0.7;
							}
						}

						.twitter {
							background-image: url(https://www.dataplugs.com/wp-content/uploads/2024/11/dp-icon-tweet-v2.png);
							background-repeat: no-repeat;
							display: block;
							width: 20px;
							height: 20px;
							background-position: center;
							background-size: cover;

							&:hover {
								opacity: 0.7;
							}
						}
					}
				}
			}

			.sales_col_2 {
				display: none;
				grid-template-columns: 1fr;
				-ms-flex-line-pack: justify;
				align-content: center;

				.address {
					font-size: 15px;
					background-image: url(https://www.dataplugs.com/wp-content/uploads/2024/11/dp-footer-mappin.png);
					background-position: top left;
					background-repeat: no-repeat;
					background-size: 32px;
					padding-left: 40px;
					margin-bottom: 23px;
					line-height: 19px;
					height: 38px;
					display: inline-grid;
					align-items: center;
				}

				.email {
					font-size: 15px;
					background-image: url(https://www.dataplugs.com/wp-content/uploads/2024/11/dp-footer-mail.png);
					background-position: 2px -2px;
					background-repeat: no-repeat;
					background-size: 29px;
					padding-left: 40px;
					margin-bottom: 23px;
					line-height: 25px;

					a {
						color: #fff;
					}
				}

				.livechat {
					font-size: 15px;
					background-image: url(https://www.dataplugs.com/wp-content/uploads/2024/11/dp-footer-msg.png);
					background-position: 3px 3px;
					background-repeat: no-repeat;
					background-size: 28px;
					padding-left: 40px;
					line-height: 30px;
					cursor: pointer;
				}
			}

			.sales_col_3 {
				display: grid;
				grid-template-columns: 1fr 1fr 1fr;
				gap: 15px;

				.chat,
				.email,
				.findus {
					background-position: top left 35px;
					background-repeat: no-repeat;
					background-size: 75px;
					border-left: 1px solid rgba(255, 255, 255, 0.155);
					padding-left: 35px;
					
					.img {
						text-align: left;
						font-size: 20px;
						display: block;
						margin-top: 80px;
						font-weight: 700;
						line-height: 25px;

						.orange {
							font-size: 15px;
							display: block;
							color: #f29600;
							font-weight: 600;
						}

						a {
							font-size: 17px;
							display: block;
							border: 1px solid #ccc;
							padding: 7px 15px;
							border-radius: 7px;
							text-align: left;
							//margin: 0 auto;
							margin-top: 5px;
							background-image: url(https://www.dataplugs.com/wp-content/uploads/2024/10/dp-menu-2024-arrow-white.png);
							background-position: center right 10px;
							background-repeat: no-repeat;
							width: 70%;
							padding-right: 49px;
							font-weight: 600;
							font-size: 16px;
							color: #fff !important;
							min-width: 180px;

							&:hover {
								border: 1px solid #f29600;
								background-color: #f29600;
								color: #fff !important;
							}
						}
					}
				}

				.chat {
					background-image: url(https://www.dataplugs.com/wp-content/uploads/2024/11/dp-footer-icon1.png);
				}

				.email {
					background-image: url(https://www.dataplugs.com/wp-content/uploads/2024/11/dp-footer-icon2.png);
					background-position: top left 25px;
				}

				.findus {
					background-image: url(https://www.dataplugs.com/wp-content/uploads/2024/11/dp-footer-icon3.png);
					background-position: top left 25px;
				}
			}
		}
	}

	.about_menu_bar {
		@include media-breakpoint-down(lg) {
			display: none;
		}

		background-color: #ededed;

		.menu_col {
			padding-top: 10px;
			padding-bottom: 10px;

			.col-wrapper {
				>a {
					display: none;
				}

				@include media-breakpoint-up(xs) {
					>.sub-menu>li:not(:first-child) {
						margin-top: 30px;
					}
				}
			}

			>ul {
				margin-left: 0;

				>li {
					padding-right: 15px;
					padding-left: 0;
					display: grid;
					grid-template-columns: 0.2fr 1fr;
					gap: 10px;
					padding: 10px 0;

					&:not(:last-child) {
						border-bottom: 1px solid #e5e5e5;
					}

					>a {
						font-weight: bold;
						text-transform: uppercase;
					}

					.sub-menu {
						display: grid;
						grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
						gap: 5px;

						a {
							&::before {
								content: "\f0c8";
								font-family: "Font Awesome 5 Free";
								margin-right: 5px;
								transform: rotate(45deg);
								display: inline-block;
								font-size: 8px;
								width: 1.5em;
								color: #f29600;
							}
						}
					}

					@include media-breakpoint-only(xs) {
						margin-bottom: 10px;
					}
				}

				li.current-menu-item a {
					color: $linkhovercolor;
				}
			}

			a {
				font-size: 12.5px;
				color: #333;
				//margin-bottom: 3px;
				display: list-item;

				&:hover {
					color: $linkhovercolor;
				}
			}
		}
	}

	.foote_menu_bar {
		@include media-breakpoint-down(lg) {
			display: none;
		}

		background-color: #f3f3f3;

		.menu_col {
			padding-top: 30px;
			padding-bottom: 30px;

			.col-wrapper {
				>a {
					display: none;
				}

				@include media-breakpoint-up(xs) {
					>.sub-menu>li:not(:first-child) {
						margin-top: 30px;
					}
				}
			}

			>ul {
				margin-left: 0;
				display: flex;

				>li {
					padding-right: 15px;
					padding-left: 0;
					//display: grid;
					//grid-template-columns: 0.2fr 1fr;
					//gap: 10px;
					padding: 10px 10px;

					&:not(:last-child) {
						//border-bottom: 1px solid #e5e5e5;
					}

					>a {
						font-weight: bold;
						text-transform: uppercase;
					}

					.sub-menu {

						//display: grid;
						//grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
						//gap: 5px;
						li {
							margin: 5px 0;
						}

						a {
							/*
							&::before {
								content: "\f0c8";
								font-family: "Font Awesome 5 Free";
								margin-right: 5px;
								transform: rotate(45deg);
								display: inline-block;
								font-size: 8px;
								width: 1.5em;
								color: #f29600;
							}
								*/
						}
					}

					@include media-breakpoint-only(xs) {
						margin-bottom: 10px;
					}
				}

				li.current-menu-item a {
					color: $linkhovercolor;
				}
			}

			a {
				font-size: 12.5px;
				color: #333;
				//margin-bottom: 3px;
				display: list-item;

				&:hover {
					color: $linkhovercolor;
				}
			}
		}

		.menu_col .title>a,
		.technology_col h6 {
			font-size: 13px;
			border-bottom: 1px solid #dad7d7;
			color: #454545;
			font-weight: 700;
			padding: 0 0 5px;
			margin: 5px 0;
			display: block;
			line-height: 1;
			pointer-events: none;
			text-transform: uppercase;
		}

		.technology_col {
			background-color: #404040;
			color: #404040;
			padding-top: 15px;
			padding-bottom: 15px;

			.logos {
				display: flex;
				flex-wrap: wrap;

				span {
					display: block;
					margin: 10px 12px;
					background-clip: padding-box;
					background-repeat: no-repeat;
					background-position: left;

					@include media-breakpoint-down(md) {
						width: 50% !important;
						margin: 10px 0;
						background-position: center;
					}
				}
			}
		}
	}

	.copyright_bar {
		background-color: #25282f;

		a {
			color: #fff;

			&:hover {
				color: #f29600 !important;
			}
		}

		.container>.align-items-center {
			padding: 7px 0;
		}

		.social_medias ul {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: center;

			a {
				color: #fff;
				display: flex;
				margin-right: 5px;

				&:hover {
					opacity: 0.8;
				}
			}
		}

		.copyright {
			color: #fff;
			font-size: 12px;

			@include media_breakpoint_down(md) {
				text-align: center;
			}

			>.row>div {
				padding: 0 5px;

				@include media_breakpoint_down(xs) {
					padding-top: 7px;
				}
			}

			.menu li {
				display: inline;

				&:not(:last-child) {
					border-right: 1px solid #fff;
					margin-right: 3px;
					padding-right: 5px;
				}
			}
		}
	}

	@include media_breakpoint_down(xs) {
		padding-bottom: 70px;
	}
}

.mobile_footer_bar {
	position: fixed;
	bottom: 0;
	left: 0;
	height: 70px;
	//background: linear-gradient(to bottom, rgba(226,255,255,1) 0%,rgba(220,220,220,1) 100%);
	background: #fff;
	z-index: 2147483640;
	box-shadow: -10px 0 10px .5px rgba(0, 0, 0, .7);
	transition: left 0.3s;

	a {
		color: #666;
		padding: 0;

		.logo {
			height: 45px;
			margin-top: 5px;
		}

		&:hover {
			color: #5fcbfc;
		}

		p {
			line-height: 1;
			margin: 0;
			text-transform: uppercase;
			font-size: 10px;
		}
	}

	.home .logo {
		background: url(https://www.dataplugs.com/wp-content/themes/dataplugs/img/mobile_footer/home.png) no-repeat center / contain;

		&:hover {
			background: url(https://www.dataplugs.com/wp-content/themes/dataplugs/img/mobile_footer/home_hover.png) no-repeat center / contain;
		}
	}

	.order .logo {
		background: url(https://www.dataplugs.com/wp-content/themes/dataplugs/img/mobile_footer/cart.png) no-repeat center / contain;

		&:hover {
			background: url(https://www.dataplugs.com/wp-content/themes/dataplugs/img/mobile_footer/cart_hover.png) no-repeat center / contain;
		}
	}

	.login .logo {
		background: url(https://www.dataplugs.com/wp-content/themes/dataplugs/img/mobile_footer/login.png) no-repeat center / contain;

		&:hover {
			background: url(https://www.dataplugs.com/wp-content/themes/dataplugs/img/mobile_footer/login_hover.png) no-repeat center / contain;
		}
	}

	.livechat .logo {
		background: url(https://www.dataplugs.com/wp-content/themes/dataplugs/img/mobile_footer/chat.png) no-repeat center / contain;

		&:hover {
			background: url(https://www.dataplugs.com/wp-content/themes/dataplugs/img/mobile_footer/chat_hover.png) no-repeat center / contain;
		}
	}

	.whatsapp .logo {
		background: url(https://www.dataplugs.com/wp-content/themes/dataplugs/img/mobile_footer/whatsapp.png) no-repeat center / contain;
		//mask: url(https://www.dataplugs.com/wp-content/themes/dataplugs/img/mobile_footer/whatsapp.svg) no-repeat center/contain;
		//mask-size: 32.5px;
		//background-color: #666;

		&:hover {
			background: url(https://www.dataplugs.com/wp-content/themes/dataplugs/img/mobile_footer/whatsapp_hover.png) no-repeat center / contain;
			//background-color: #5fcbfc;
		}
	}

	.telegram .logo {
		background: url(https://www.dataplugs.com/wp-content/themes/dataplugs/img/mobile_footer/tg.png) no-repeat center / contain;

		&:hover {
			background: url(https://www.dataplugs.com/wp-content/themes/dataplugs/img/mobile_footer/tg_hover.png) no-repeat center / contain;
		}
	}
}

.footer_message {
	position: fixed;
	padding: 0;
	right: 8px;
	color: transparent;
	text-align: center;
	z-index: 99999;
	font-size: 40px;
	cursor: pointer;
	font-weight: 300;
	/* box-shadow: 1px 1px 5px #666; */
	box-shadow: rgba(0, 0, 0, 0.3) 0px 4px 12px;
	border-radius: 50%;
	width: 60px;
	height: 60px;
	background-color: #fff !important;

	a {
		color: transparent;
	}
}

.footer_whatsapp {
	bottom: 230px;
	background: url(https://www.dataplugs.com/wp-content/themes/dataplugs/img/mobile_footer/whatsapp_icon.png) no-repeat center / contain;
}

.footer_skype {
	bottom: 160px;
	background: url(https://www.dataplugs.com/wp-content/themes/dataplugs/img/mobile_footer/skype_icon.png) no-repeat center / contain;
}

.footer_telegram {
	bottom: 90px;
	right: 55px;
	background: url(https://www.dataplugs.com/wp-content/themes/dataplugs/img/mobile_footer/tg_icon.png) no-repeat center / contain;
}

.menu_opened .mobile_footer_bar {
	left: -315px;
}

body.menu_opened_two .mobile_footer_bar {
	right: -430px;
}

.copyright_bar .social_medias ul a .fa-inverse {
	font-family: "Font Awesome 5 Brands" !important;
}

@include media_breakpoint_only(xs) {
	#livechat-full {
		bottom: 70px !important;
	}
}