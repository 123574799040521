/* *****************************************************

    Custom Css by Vito

    Any custom styling you want to apply should be
    defined here.

***************************************************** */

/************************* global  **************************/
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+HK:wght@100..900&family=Noto+Sans+SC:wght@100..900&family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&family=Roboto:wght@100;300;400;500;700;900&display=swap');

html {
  font-size: 14px;

  body {
    //font-family: "Open Sans", "Helvetica Neue", "Helvetica", "Arial", "微軟正黑體", "Microsoft JhengHei", "微軟正黑體", "Hei", "sans-serif" !important;
    font-family: "Nunito Sans", "Noto Sans HK", "Noto Sans SC", "Open Sans", "Helvetica Neue", "Helvetica", "Arial", "微軟正黑體", "Microsoft JhengHei", "微軟正黑體", "Hei", "sans-serif" !important;
    margin: 0;

    h1,
    h2 {
      //font-family: Roboto, "Open Sans", "Helvetica Neue", "Helvetica", "Arial", "微軟正黑體", "Microsoft JhengHei","微軟正黑體", "Hei", "sans-serif";
      font-family: "Nunito Sans", "Noto Sans HK", "Noto Sans SC", "Open Sans", "Helvetica Neue", "Helvetica", "Arial", "微軟正黑體", "Microsoft JhengHei", "微軟正黑體", "Hei", "sans-serif" !important;

    }

    a,
    p,
    button,
    input {
      transition: 0.3s;
    }
  }
}

#page-wrapper {
  max-width: 1500px;
  margin: 0 auto !important;
}

body.login {
  #page-wrapper {
    max-width: none !important;
    margin: 0 !important;
  }
}

body.loggedin {
  #page-wrapper {
    max-width: none !important;
  }
}

.using-password-strength {
  .glyphicon {
    position: absolute;
    right: 25px;
    padding: 8px;
  }

  .glyphicon-warning-sign {
    color: orange;
  }

  .glyphicon-ok {
    color: green;
  }

  .glyphicon-remove {
    color: red;
  }
}

.eno-tabs {
  .nav-item {
    .active {
      color: #555;
      border-color: #e2e1e1 #e2e1e1 transparent;
      border-top: 1px solid #7db4d8;
      background-color: #fff;
      z-index: 12;
      line-height: 16px;

      &:focus {
        color: #555;
        border-color: #e2e1e1 #e2e1e1 transparent;
        border-top: 1px solid #7db4d8;
        background-color: #fff;
        z-index: 12;
        line-height: 16px;
      }
    }
  }
}

#stripeElements {
  .form-group {
    display: flex;
  }
}

a {
  &:hover {
    text-decoration: none;
    outline: 0;
  }

  &:focus {
    text-decoration: none;
    outline: 0;
  }
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

#main-container {
  div {
    &.row {
      margin-left: -15px;
      margin-right: -15px;

      >[class*="col-"] {
        padding-left: 15px;
        padding-right: 15px;
      }
    }
  }

  ul {
    &.row {
      margin-left: -15px;
      margin-right: -15px;

      >[class*="col-"] {
        padding-left: 15px;
        padding-right: 15px;
      }
    }
  }
}

.container-fluid {
  padding-left: 15px;
  padding-right: 15px;
}

.container {
  padding-left: 15px;
  padding-right: 15px;
}

.badge {
  padding: 4px 6px;
  font-weight: 400;
  color: #fff;
  font-size: 11px;
}

.list-group-item {
  .label {
    padding: 4px 6px;
    font-weight: 400;
    color: #fff;
    font-size: 11px;
  }
}

.alert {
  padding-right: 30px;
  position: relative;
  width: 100%;

  p {
    margin-bottom: 0;
  }

  .close {
    font-size: 20px;
    position: absolute;
    right: 10px;
    top: calc(50% - 10px);
  }
}

.alert-warning {
  background-color: #fcf8e3;
  border-color: #faebcc;
  color: #8a6d3b;
}

.large-btn {
  padding: 10px 25px !important;
  font-size: 20px;
}

.std-btn {
  padding: 0 15px;
  line-height: 36px;
  cursor: pointer;
  background-color: #357ebd;
  border: 0;
  color: #fff !important;

  &:hover,
  &:active,
  &:focus {
    background-color: #0e5095 !important;
  }

  &.style1 {
    background-color: #357ebd !important;
    border: 4px solid #357ebd;
    line-height: 28px;

    &:hover,
    &:active,
    &:focus {
      background-color: #0e5095 !important;
      border: 4px solid #357ebd;
    }
  }

  &.style2 {
    background-color: #87b87f !important;
    border: 4px solid #87b87f;
    line-height: 28px;

    &:hover,
    &:active,
    &:focus {
      background-color: #629b58 !important;
      border: 4px solid #87b87f;
    }
  }

  &.style3 {
    background-color: #a0a0a0 !important;
    border: 4px solid #a0a0a0;
    line-height: 28px;

    &:hover,
    &:active,
    &:focus {
      background-color: #888 !important;
      border: 4px solid #a0a0a0;
    }
  }

  &.style4 {
    background-color: #d15b47 !important;
    border: 4px solid #d15b47;
    line-height: 28px;

    &:hover,
    &:active,
    &:focus {
      background-color: #b7422e !important;
      border: 4px solid #d15b47;
    }
  }
}

.float-none {
  float: none !important;
}

.margin-right {
  margin-right: 15px !important;
}

.text-domain {
  font-size: 12px;
  color: #006687;
}

table {
  thead {
    tr {
      color: #707070;
      font-weight: 400;
      background-image: linear-gradient(to bottom, #f8f8f8 0, #ececec 100%);

      th {
        &:first-child {
          border-left: 0 !important;
        }

        &:last-child {
          border-right: 0 !important;
        }
      }
    }
  }

  tbody {
    tr {
      td {
        border: none;

        .label {
          text-align: center;
          padding: 4px 10px;
          width: 100%;
        }
      }
    }
  }
}

.table-container {
  .dataTables_wrapper {
    .label {
      display: inline-block;
    }

    .row {
      margin-left: -15px;
      margin-right: -15px;

      &:first-child {
        padding-top: 12px;
        padding-bottom: 12px;
        border-bottom: 0 none;
      }
    }

    input[type="text"] {
      margin-bottom: 0 !important;
      margin: 0 4px;
    }

    select {
      margin-bottom: 0 !important;
      margin: 0 6px;
    }
  }

  .dataTables_length {
    select {
      width: auto;
      padding: 2px 3px;
    }

    label {
      font-weight: 400;
      display: flex;
      line-height: 2;
    }
  }

  .dataTables_filter {
    @include media-breakpoint-up(sm) {
      text-align: right;
    }
  }

  .dataTable {
    margin-bottom: 0;

    th[class*="sort"] {
      cursor: pointer;
      outline: 0 !important;

      &::after {
        content: "\f0dc";
        display: inline-block;
        color: #999;
        font-family: "Font Awesome 5 Free";
        font-size: 13px;
        font-weight: 400;
        float: right;
        margin-right: 4px;
        position: relative;
      }
    }

    th[class*="sort"],
    th[class*="sorting_"] {
      color: #3498db;
    }

    th.sorting_desc:after {
      content: "\f0dd";
      top: -6px;
      color: #999;
    }

    th.sorting_asc:after {
      content: "\f0de";
      top: 4px;
      color: #999;
    }

    th.sorting_disabled {
      cursor: initial;

      &::after {
        display: none;
      }
    }
  }
}

.datatable {
  .btn-group {
    .dropdown-toggle {
      background-color: #6fb3e0;
    }

    .dropdown-menu {
      &.show {
        transform: none !important;
        left: auto !important;
        right: 0 !important;
        top: 100% !important;
      }
    }
  }
}

.dataTable {
  &.table {
    &.has-columns-hidden {
      >tbody {
        >tr {
          &.detail-show {
            >td {
              span {
                &.responsiveExpander {
                  &::before {
                    font-family: "Font Awesome 5 Free";
                    font-style: normal;
                    font-weight: 400;
                    content: "\f068";
                    padding-right: 5px;
                    font-size: 18px;
                    color: #888;
                  }
                }
              }
            }
          }
        }

        &.row-detail {
          >td {
            >ul {
              >li {
                >span {
                  &.columnTitle {
                    font-weight: 700;
                  }
                }
              }
            }
          }
        }

        >td {
          >span {
            &.responsiveExpander {
              cursor: pointer;
              vertical-align: middle;

              &::before {
                font-family: "Font Awesome 5 Free";
                font-style: normal;
                font-weight: 400;
                content: "\f067";
                padding-right: 5px;
                font-size: 18px;
                color: #888;
              }
            }
          }
        }
      }
    }
  }

  th {
    &.sorting_disabled {
      color: #707070;
    }
  }
}

.dataTables_filter {
  input[type="text"] {
    width: 125px;
    padding: 4px 6px;
  }

  label {
    font-weight: 400;
  }
}

.dataTables_wrapper {
  .row {
    &:last-child {
      padding-top: 12px;
      padding-bottom: 12px;
      border-top: 0 none;
    }
  }
}

.dataTables_info {
  font-size: 13px;
  margin: 10px 0;
}

.dataTables_paginate {
  text-align: right;

  .pagination {
    margin: 10px 0;
  }
}

.footable-row-detail-row {
  display: block;
  line-height: 1.5em;
}

.disabled {
  opacity: 0.5;
}

.creditbalance {
  background-color: #428bca;
  color: #fff;
  margin-left: 10px;
  padding: 5px 25px;
  position: relative;

  &::before {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 15px 0 15px 15px;
    border-color: transparent transparent transparent #fff;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
  }

  &::after {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 15px 15px 15px 0;
    border-color: transparent #fff transparent transparent;
    content: "";
    position: absolute;
    top: 0;
    right: 0;
  }
}

.card {
  margin-bottom: 20px;
  background-color: #fff;
  border: 1px solid transparent;
  border-radius: 0;

  &.card-info {
    border-color: #bce8f1;

    >.card-heading {
      color: #31708f;
      background-color: #d9edf7;
      border-color: #bce8f1;

      .card-title {
        font-size: 16px !important;
        margin-bottom: unset;
      }
    }
  }

  .card-heading {
    padding: 10px 15px;
  }
}

.hidden {
  display: none !important;
}

.modal-dialog {
  top: 30%;
}

.pull-right {
  float: right;
}

.modal-backdrop {
  z-index: 9999 !important;
}

.modal {
  z-index: 10001 !important;
}

.markdown-content h1:before,
.markdown-content h2:before,
.md-preview>h1:before,
.md-preview>h2:before {
  content: "#";
}

/************************* global  **************************/

/************************* password  **************************/

.password_icon_position {
  float: right;
  margin-top: -25px;
  margin-right: 15px;
}

.generate-password {
  margin-top: 5px;
}

#inputNewPassword2Msg {
  color: red;
  font-size: 13.5px;
  font-weight: 700;
}

/************************* password  **************************/

/************************* Sidebar  **************************/

.navbar-side {
  top: 155px;
  height: 100%;
  position: fixed;

  @include media-breakpoint-down(sm) {
    top: 0 !important;
    left: 0;
  }

  .sidebar-scroll {
    max-height: calc(100vh - 105px) !important;
  }

  #side .active>a,
  #side .active>a i {
    color: #55a0dc;
  }

  .media .sidebar-shortcuts li .btn {
    border-radius: 0;
  }
}

.panel-sidebar {
  .card-footer {
    .row {
      margin-left: 0;
      margin-right: 0;
    }
  }
}

aside {
  .card {
    margin-bottom: 20px;
    border-radius: 0;

    &.card-sidebar {
      border-color: #eee;
      font-size: 15px;
    }

    .card-header {
      color: #333;
      background-color: #f5f5f5;
      border-bottom: 0;

      h3 {
        &.card-title {
          margin: 0;
          font-size: 18px !important;

          .card-minimise {
            color: #ddd;
            cursor: pointer;
            transition: all 0.3s;

            &.minimised {
              transform: rotate(180deg);
              margin-top: 3px;
            }
          }
        }
      }
    }

    .card-footer {
      border-top: 0;

      &.row {
        margin: 0px 0px;
      }

      form {
        width: 100%;
      }
    }

    .list-group-item {
      border-width: 1px 0;
      color: #555;
      padding: 10px 15px;

      &:not(.active):hover {
        color: #555;
        background-color: #f5f5f5;
      }

      .badge {
        float: right;
      }

      &.active {
        color: #fff;

        .badge {
          color: #337ab7;
        }
      }
    }

    &.panel-default {
      padding: 15px;
      border-color: #ccc;

      .card-header {
        position: relative;
        padding: 0 0 10px;
        background-color: #fff;
        border-bottom: 1px dotted #ccc;

        .card-title {
          font-size: 18px !important;
          font-weight: 100;
          color: #4383b4;
        }
      }

      &:not(.panel-addon) {
        border: 1px solid #ccc;
        padding: 15px;

        .card-header {
          border-bottom: 1px dotted #ccc;
          background-color: transparent;
        }
      }
    }
  }
}

/************************* Sidebar  **************************/

/************************* Login Page  **************************/
body.login {
  background: none;

  .main-content {
    //background: url(../img/clientBG.jpg) center center no-repeat;
    //background-size: cover;
    background-size: contain;
    background-image: url(https://www.dataplugs.com/wp-content/uploads/2024/11/dp-2024-hp-banner-left-normal.png), url(https://www.dataplugs.com/wp-content/uploads/2024/11/dp-2024-hp-banner-right-normal.png);
    background-position: left 28%, right 1%;
    background-repeat: no-repeat;
    background-size: 62%, 53%;

    #login_field3 {
      margin-right: 16px;
    }

    .row {
      justify-content: center;
      align-items: center;
    }

    @include media-breakpoint-down(lg) {
      background-image: url(https://www.dataplugs.com/wp-content/uploads/2024/11/dp-2024-hp-banner-left-normal.png);
      background-position: left top;
      background-size: auto;
    }

    .login-wrapper {
      margin-top: 85px;
      margin-bottom: 80px;
      margin-left: 0 !important;

      .login-bubble {
        background-color: #fff;
        padding: 15px;
        border-radius: 15px;
        border: 1px solid #e5e5e5;
        margin-bottom: 27px;
        border-radius: .8rem;
        -moz-border-radius: .8rem;
        -webkit-border-radius: .8rem;
        border: 0 solid #000;
        -webkit-box-shadow: -1px 2px 21px 1px rgba(0, 0, 0, .17);
        box-shadow: -1px 2px 21px 1px rgba(0, 0, 0, .17);
        background: #fff;
        padding: 20px;
        padding-bottom: 35px;

        /*
        &::before {
          position: absolute;
          top: -40px;
          right: 72px;
          content: "";
          display: block;
          border-top: 40px solid transparent;
          border-right: 40px solid #fff;
        }
        */

        h1 {
          /*
          margin-top: 5px;
          font-size: 26px;
          color: #0d5cbc;
          text-transform: uppercase;
          border-bottom: 1px solid #ccc;
          padding-bottom: 8px;
          */
          margin-top: 5px;
          font-size: 26px;
          color: #0d5cbc;
          text-transform: uppercase;
          border-bottom: 1px solid #efeded;
          font-weight: bold;
          padding-bottom: 16px;
        }

        .login_field {
          position: relative;
          padding: 0 15px;

          .userlogin {
            line-height: 40px;
          }

          &:focus label {
            opacity: 0.8;
          }

          label {
            position: absolute;
            left: 15px;
            content: "\f007";
            color: rgb(255, 255, 255);
            font-size: 20px;
            line-height: 40px;
            box-shadow: none;
            border-top-left-radius: 4px !important;
            border-bottom-left-radius: 4px !important;
            padding: 0px 10px;
            background: orange;
            background-position: 50% 50%;

            &:after {
              background: orange;
              box-shadow: none;
              border-right: none;
              width: 12px;
              height: 12px;
              content: "";
              position: absolute;
              right: -6px;
              top: calc(50% - 7px);
              transform: rotate(45deg);
            }
          }
        }

        .username {
          margin-top: 20px;
        }

        input[type="email"],
        input[type="text"],
        input[type="password"] {
          width: 100%;
          line-height: 42px;
          height: 42px;
          padding: 0 5px 0 75px;
          border-radius: 4px !important;
          margin-bottom: 15px;
          border: 0;
          //box-shadow: inset -2px 0 2px 0 rgba(0, 0, 0, 0.25) !important;
          color: #333;
          border: 1px solid orange;
        }

        input[type="checkbox"] {
          margin: 0;
          vertical-align: middle;

          +label {
            font-size: 13px;
            margin: 0;
          }
        }

        a {
          font-size: 13px;
          //color: #0e9eac;
          color: #0d5cbc;
        }
      }

      .login_btn {
        padding: 0 15px;

        input {
          border-radius: 4px;
          padding: 4px;
          font-weight: bold;
          font-size: 17px;
          letter-spacing: 2px;
          margin: 0;
        }

        input,
        a {
          color: #fff;
          text-transform: uppercase;
          background-color: #0d5cbc;
          border: 0;
          line-height: 42px;
          display: block;
          width: 100%;
          //border-bottom-left-radius: 5px;
          //border-bottom-right-radius: 5px;
          border-radius: 100px;
          padding: 0;

          &:hover {
            background-color: #0e5095;
          }
        }
      }

      #submitlogin {
        cursor: pointer;
        border-radius: 100px;
      }

      .support_box {
        background-color: rgb(244, 249, 254);
        color: #333;
        margin: 25px 0 0;
        padding: 25px 0;
        border-radius: 10px;

        p {
          margin: 0;
          font-size: 13px;
        }

        a {
          font-weight: 600;
          color: #0d5cbc;
          -webkit-transition: opacity 0.3s;
          /* Safari */
          transition: opacity 0.3s;

          &:hover {
            opacity: 0.8;
          }
        }
      }
    }
  }
}

/************************* Login Page  *************************/

/************************* Logout Page  *************************/

body.login .logout_success {
  margin: 36px 0 25px;
  color: #7e7e7e;
}

/************************* Logout Page  *************************/

/************************* Reset PW Page  *************************/

body.login .back {
  position: absolute;
  right: 30px;
  top: 20px;
  font-size: 15px !important;
  text-transform: uppercase;
}

.reset-btns input {
  color: #fff;
  text-transform: uppercase;
  background-color: #357ebd;
  border: 0;
  line-height: 42px;
  display: block;
  padding: 0;
  float: left;

  &[type="submit"] {
    width: calc(70% - 15px);
    margin-right: 15px;
  }

  &[disabled="disabled"] {
    border: 1px solid #ccc;
  }

  &[type="reset"] {
    width: 30%;
  }
}

body.login .main-content .pwreset .login-bubble input[type="text"],
body.login .main-content .pwreset .login-bubble input[type="password"] {
  padding: 0 15px;
}

/************************* Reset PW Page  *************************/

/************************* Client area page  *************************/
#page-wrapper {
  background-color: #fff;
  color: #393939;

  &.opened {
    margin: auto 0;
    padding: 0 15px;
  }

  @include media-breakpoint-down(md) {
    margin: 0 !important;
  }

  .heading {
    font-size: 14px;
    height: 25px;
  }

  h3 {
    font-size: 24px;
  }

  h4 {
    font-size: 18px;
  }

  .page-header {
    &.title {
      border: none;
      margin-top: 0;
      margin-bottom: 0;
      padding: 15px 0;

      h1 {
        border-bottom: 1px dotted #e2e2e2;
        padding-top: 10px;
        padding-bottom: 15px;
        font-size: 24px;
        font-weight: lighter;
        color: #2679b5;
        margin: 0;
      }

      .short_desc {
        color: #393939;
        margin: 15px;
        font-size: 13px;
      }
    }

    .sub-title {
      &::before {
        //font-size: 22px;
        font-size: 13px;
        margin: 0 5px;

        content: "\f101";
        margin-right: 4px;
        font-family: "Font Awesome 5 Free";
        color: #7bb7e5;
      }

      font-size: 60%;
      text-transform: none;
      color: #999;
    }
  }

  .main-content {
    min-height: calc(100vh - 550px);

    #cPanelPackagePanel {
      .panel-body {
        >p {
          @include media-breakpoint-down(sm) {
            display: grid;
          }

          .btn {
            margin-bottom: inherit;

            @include media-breakpoint-down(sm) {
              margin-bottom: 2px;
            }
          }
        }
      }
    }

    #frmCreateEmailAccount {
      .col-sm {
        margin-bottom: inherit;

        @include media-breakpoint-down(sm) {
          margin-bottom: 5px;
        }

        .input-group {
          .input-group-prepend {
            display: flex;

            @include media-breakpoint-down(sm) {
              display: grid;
            }
          }
        }
      }
    }

    .btn.tile-button {
      border: none;
      background-color: #fff;

      &:hover {
        opacity: 0.7;

        .tile-content-wrapper .tile-content,
        .tile-content-wrapper small {
          opacity: 1;
        }
      }

      .tile-content-wrapper {
        padding: 0;

        .top {
          padding: 10px 15px;
          background-color: #468847;
        }

        .bottom {
          background-color: #468847;
          padding: 0px 15px;
          line-height: 28px;
          font-size: 11px;
          position: relative;
          opacity: 0.7;

          &:after {
            content: "\f0a9";
            position: absolute;
            right: 15px;
            top: 0;
            font-family: "Font Awesome 5 Free";
          }
        }

        &.my_domains .top,
        &.my_domains .bottom {
          background-color: #357ebd;
        }

        &.tickets .top,
        &.tickets .bottom {
          background-color: #eea236;
        }

        &.due_invoices .top,
        &.due_invoices .bottom {
          background-color: #b74635;
        }

        small,
        i {
          color: #fff !important;
        }

        .fa,
        .fas,
        .glyphicon {
          top: 35px;
          left: 10px;
        }

        .tile-content {
          font-weight: 100;
          color: #fff !important;
        }
      }
    }

    .panel-default,
    .panel-main-content {
      border-radius: 0;
      margin-bottom: 20px;

      .card-header {
        color: #4383b4;
        position: relative;
        padding: 0 0 10px;
        background-color: transparent;
        border: 0;

        .card-title {
          font-size: 18px;
          font-weight: lighter;
          margin-bottom: 0;

          i {
            margin-right: 5px;
          }

          .card-minimise {
            margin-top: 1px;
            color: #ddd;
            cursor: pointer;
            transition: transform 0.2s ease;
            margin-right: 9px !important;

            &.minimised {
              transform: rotate(180deg);
              margin-top: 3px;
            }
          }
        }
      }

      .card-body {
        padding: 0;
      }
    }

    .panel-default {
      &:not(.panel-addon) {
        border: 1px solid #ccc;
        padding: 15px;

        .card-header {
          border-bottom: 1px dotted #ccc;
          background-color: transparent;
        }
      }

      .panel-heading {
        border-bottom: 1px dotted #ccc;

        .panel-title {
          font-size: 18px;
          font-weight: lighter;
          color: rgb(67, 131, 180);
        }
      }

      .panel-body {
        padding: 15px;

        h5 {
          font-size: 14px;
        }

        .cpanel-package-details {
          h4 {
            font-size: 18px;
          }
        }

        &.cpanel-usage-stats {
          padding: 17px 15px;
        }

        .form-inline {
          justify-content: center;
          display: flex;

          @include media-breakpoint-down(lg) {
            display: grid;
          }

          select {
            font-size: 12px;
            height: 30px;
            margin: 0 2px;

            @include media-breakpoint-down(lg) {
              margin-bottom: 3px;
            }
          }

          .btn {
            font-size: 12px;
            height: 30px;
            margin: 0 2px;

            @include media-breakpoint-down(lg) {
              margin-bottom: 3px;
              width: 100%;
            }
          }
        }
      }
    }

    .panel-main-content {
      border: 0;

      .creditbalance {
        background-color: #428bca;
        color: #fff;
        margin-left: 10px;
        padding: 5px 25px;
        position: relative;

        &::before {
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 15px 0 15px 15px;
          border-color: transparent transparent transparent #fff;
          content: "";
          position: absolute;
          top: 0;
          left: 0;
        }

        &::after {
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 15px 15px 15px 0;
          border-color: transparent #fff transparent transparent;
          content: "";
          position: absolute;
          top: 0;
          right: 0;
        }
      }

      .badge {
        border-radius: 0 !important;
      }

      tr {
        font-size: 13px;

        th {
          border: 1px solid #ddd !important;
        }
      }
    }

    .client-home-panels {
      .panel-default {
        .card-header {
          border-bottom: 0;

          .card-title {
            font-weight: 400;

            .btn {
              font-size: 11px;
              padding: 2px 5px 3px;
              border: 0;
              display: inherit;
            }
          }
        }

        .card-body {
          p {
            padding: 10px 15px;
          }

          form {
            margin: 10px;
          }
        }

        .list-group {
          max-height: 170px;
          overflow: auto;
          border-top: 1px solid #ddd;
          border-bottom: 1px solid #ddd;

          .list-group-item {
            padding: 4px 10px;
            border-width: 1px 0;
            color: #555;

            &:first-child {
              border-top-width: 0;
            }

            &:hover {
              background-color: #f5f5f5;
            }

            label {
              padding: 3px 7px 4px;
              color: #fff;
              line-height: 1;
              font-size: 11px;
              margin: 0 0 0 5px;
              vertical-align: text-top;
            }

            small {
              font-size: 12px;
              color: #888;
            }
          }
        }
      }
    }

    .action_row {
      margin-left: unset;
      margin-right: unset;

      .action_row_wrapper {
        width: 100%;
        padding: unset;

        .option_row {
          width: 100%;
          background-color: #f5f5f5;
          border-top: 1px solid #e5e5e5;
          padding: 20px;
          margin: 15px 0;
        }
      }
    }
  }
}

.loggedin {
  #page-wrapper {
    &.opened {
      margin: 0 0 0 210px !important;
      transition: margin 0.3s;
    }
  }

  #footer {
    margin: 0 0 0 210px;
    transition: margin 0.3s;

    @include media-breakpoint-down(md) {
      margin: 0 !important;
    }
  }
}

.btn {
  &.tile-button {
    &:hover {
      .tile-content-wrapper {

        .fa,
        .fas,
        .glyphicon {
          margin-top: -75px;
          opacity: 0.8;
        }

        .fas {
          left: -20px;
          margin-top: -45px;
          font-size: 110px !important;
          -webkit-transform: rotate(20deg);
          transform: rotate(20deg);
        }
      }
    }

    .tile-content-wrapper {
      .fas {
        position: absolute;
        font-size: 57px;
        top: 50%;
        margin-top: -25px;
        left: 15px;
        -webkit-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
        -webkit-transform: rotate(-20deg);
        transform: rotate(-20deg);
      }
    }
  }
}

.products {
  >.row {
    flex-wrap: wrap;

    >.product_col {
      display: flex;
      flex-direction: column;
      /*border: 1px solid #ccc;*/
      padding: 10px;
      /*margin: 0 15px 30px;*/
      /*width: calc(50% - 30px); */
      width: 100%;
    }

    .product_col {
      .product_style {
        border: 1px solid #ccc;
        padding: 10px;
        border-radius: 2.5px;
        height: 100%;

        &:hover {
          background-color: #fff9e6;
        }
      }

      .product_name {
        color: #69aa46;
        font-size: 22px;
        margin: 10px 0 5px;
        font-weight: 300;

        i {
          margin-right: 5px;
        }
      }

      .field {
        margin-bottom: 10px;
      }

      .price {
        margin-bottom: 10px;
      }
    }
  }
}

.open {
  .btn-info {
    &.dropdown-toggle {
      background-color: #4f99c6 !important;
      border-color: #6fb3e0;
    }
  }
}

.btn-info {
  background-color: #6fb3e0 !important;
  border-color: #6fb3e0;

  &:focus {
    background-color: #6fb3e0 !important;
    border-color: #6fb3e0;
  }

  &:hover {
    background-color: #4f99c6 !important;
    border-color: #6fb3e0;
  }
}

.btn-group {
  .btn-info {
    i {
      margin: 0;
    }
  }
}

.dropdown-menu {
  &.float-right {
    &.dropdown-close {
      left: auto;
      right: -5px;
    }

    &.dropdown-caret {
      &::before {
        left: auto;
        right: 9px;
      }

      &::after {
        left: auto;
        right: 10px;
      }
    }
  }

  &.pull-right {
    &.dropdown-close {
      left: auto;
      right: -5px;
    }

    &.dropdown-caret {
      &::before {
        left: auto;
        right: 9px;
      }

      &::after {
        left: auto;
        right: 10px;
      }
    }
  }
}

.widget-toolbar {
  >.dropdown {
    >.dropdown-menu {
      &.float-right {
        &::before {
          left: auto;
          right: 9px;
        }

        &::after {
          left: auto;
          right: 10px;
        }
      }

      &.pull-right {
        &::before {
          left: auto;
          right: 9px;
        }

        &::after {
          left: auto;
          right: 10px;
        }
      }
    }
  }
}

.footer_drop {
  left: auto;
  top: auto;
  margin: 0;
}

#tabAutorenew {
  .label {
    padding: 15px 25px;
    font-size: 24px;
    display: inline-block;
  }
}

#tabReglock {
  .label {
    padding: 15px 25px;
    font-size: 24px;
    display: inline-block;
  }
}

#tabAddons {
  .addon_item {
    border: 1px solid #ccc;
    padding: 15px;
    margin-bottom: 30px;

    input {
      margin-top: 15px;
    }

    i {
      font-size: 100px;
      position: relative;

      &::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 1px;
        bottom: 0;
        left: 0;
        box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.3);
      }
    }
  }
}

#order-standard_cart {
  .domain-lookup-result {
    .domain-suggestion {
      .btn {
        height: auto !important;
        border-radius: 0;
        border-color: #357ebd;
      }
    }
  }

  .field {
    margin: 2px 0;
  }

  .form-control {
    margin: 2px 0;
  }
}

h4 {
  &.box_title {
    border-bottom: 1px dotted #e2e2e2;
    margin-bottom: 15px;
    padding-bottom: 10px;
    font-size: 18px;
    font-weight: lighter;
    color: #2679b5;
  }
}

.masspay-invoice-detail {
  td {
    font-size: 1em !important;

    &:first-child {
      padding-left: 30px !important;
    }
  }
}

.open_ticket {
  font-size: 20px;
  padding: 15px;

  a {
    color: #fff !important;
    display: block;
  }
}

.btn-row {
  margin: 10px 0;
}

.invoice_detail {
  p {
    margin: 0;
    font-size: 13px;

    &:nth-child(even) {
      border-top: 1px dotted #ccc;
      padding-top: 10px;
      margin-top: 10px;
    }
  }
}

.invoice_list {
  &:not(:first-child) {
    border-top: 1px dotted #ccc;
    padding-top: 10px;
    margin-top: 10px;
  }
}

#tableInvoicesList {
  td {
    position: relative;
  }
}

.invoice_detail_showmore {
  position: absolute;
  right: 10px;
  top: 4px;
  font-size: 20px;
  color: #707070;
}

.invoice_detail_wrapper {
  -webkit-transition: height 0.3s;
  /* Safari */
  transition: height 0.3s;

  &.short {
    max-height: 45px;
    overflow: hidden;
  }
}

.payment-btn-container {
  form {
    input[type="image"] {
      margin: 0 3px;
    }
  }
}

.addon-btn {
  cursor: default;
  margin-top: 15px;
}

.panel {
  &.invoice_panel {
    border: 0;
    padding: 0;

    .panel-body {
      padding: 10px 0 0;
    }
  }
}

.invoice-container {
  .payment_method {
    background-color: #f7f7f7;

    td {
      padding: 10px;
    }
  }
}

.invoicedisclaimer {
  padding: 20px 25px;

  ul {
    list-style: disc;
  }
}

.invoice_footer {
  color: #4383b4;
  font-size: 12px;
  margin-top: 30px;

  .items {
    display: table;
    width: 100%;

    span {
      display: table-cell;
      text-align: center;
    }
  }
}

#frmPayment {
  input {
    /* margin: 0 5px; */
  }
}

fieldset {
  &.input-group {
    display: grid;
  }
}

.datawww_clinet {
  &::before {
    content: "www.";
    font-size: 12.5px;
    padding: 10px;
    position: absolute;
    z-index: 90;
  }

  .client_regdomain {
    position: relative;
    padding-left: 42.5px;
    padding-top: 8px;
  }
}

/************************* Client area page  *************************/

/************************* Invoices  *************************/

.invoice-container {
  font-size: 14px;
  color: #393939;
}

@media print {
  .invoice-container {
    max-width: unset !important;
  }

  body {
    filter: grayscale(100%);
    /* future-proof */
    /* print back and white*/
  }
}

.invoice_panel {
  &.card {
    border: 0;
    padding: 0;
  }

  .card-body {
    padding: 10px 0 0;
  }
}

.payment_method {
  table {
    thead {
      tr {
        td {
          font-size: 14px;
        }
      }
    }
  }
}

.card-success {
  border-color: #d6e9c6;

  >.card-header {
    color: #3c763d;
    background-color: #dff0d8;
    border-color: #d6e9c6;
  }
}

.card-info {
  border-color: #bce8f1;

  >.card-header {
    color: #31708f;
    background-color: #d9edf7;
    border-color: #bce8f1;
  }
}

.card-danger {
  border-color: #ebccd1;

  >.card-header {
    color: #a94442;
    background-color: #f2dede;
    border-color: #ebccd1;
  }
}

.card-default {
  border-color: #e2e1e1;

  >.card-header {
    color: #4383b4;
    position: relative;
    padding: 0 0 10px;
  }

  .card-header {
    border-bottom: 1px dotted #ccc;
    background-color: transparent;
  }
}

.card-title {
  font-size: 16px;
  margin: 0;
}

.control-label {
  text-align: right;
  margin-left: 0;
  padding-left: 7px;
}

/************************* Invoices  *************************/

/************************* Colors  *************************/

.dark {
  color: #333 !important;
}

.white {
  color: #fff !important;
}

.red {
  color: #dd5a43 !important;
}

.light-red {
  color: #f77 !important;
}

.blue {
  color: #478fca !important;
}

.light-blue {
  color: #93cbf9 !important;
}

.green {
  color: #69aa46 !important;
}

.light-green {
  color: #b0d877 !important;
}

.orange {
  color: #ff892a !important;
}

.light-orange {
  color: #fcac6f !important;
}

.orange2 {
  color: #feb902 !important;
}

.purple {
  color: #a069c3 !important;
}

.pink {
  color: #c6699f !important;
}

.pink2 {
  color: #d6487e !important;
}

.brown {
  color: brown !important;
}

.grey {
  color: #777 !important;
}

.light-grey {
  color: #bbb !important;
}

.badge {
  &.pending {
    background-color: #f89406 !important;
  }

  &.active {
    background-color: #46a546 !important;
    color: #fff;
  }

  &.suspended {
    background-color: #0768b8 !important;
    color: #fff;
  }

  &.terminated {
    background-color: #c43c35 !important;
    color: #fff;
  }

  &.cancelled {
    background-color: #bfbfbf !important;
    color: #fff;
  }

  &.expired {
    background-color: #888 !important;
    color: #fff;
  }

  &.fraud {
    background-color: #000 !important;
  }

  &.unpaid {
    background-color: #cc0000 !important;
  }

  &.paid {
    background-color: #779500 !important;
  }

  &.refunded {
    background-color: #224488 !important;
  }

  &.collections {
    background-color: #d3c403 !important;
  }

  &.pendingtransfer {
    background-color: #f89406 !important;
  }
}

.well {
  &.active {
    background-color: #46a546 !important;
    color: #fff;
  }

  &.suspended {
    background-color: #0768b8 !important;
    color: #fff;
  }

  &.terminated {
    background-color: #c43c35 !important;
    color: #fff;
  }

  &.cancelled {
    background-color: #bfbfbf !important;
    color: #fff;
  }

  &.expired {
    background-color: #888 !important;
    color: #fff;
  }
}

.status-trial_to_live {
  color: #446688 !important;
}

/************************* Colors  *************************/

/**
 * Variables declared here can be overridden by consuming applications, with
 * the help of the `!default` flag.
 *
 * @example
 *     // overriding $hoverColor
 *     $hoverColor: rgba(red, 0.05);
 *
 *     // overriding image path
 *     $flagsImagePath: "images/";
 *
 *     // import the scss file after the overrides
 *     @import "bower_component/intl-tel-input/src/css/intlTelInput";
 */
.intl-tel-input {
  position: relative;
  display: block;
}

.intl-tel-input * {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}

.intl-tel-input .hide {
  display: none;
}

.intl-tel-input .v-hide {
  visibility: hidden;
}

.intl-tel-input input,
.intl-tel-input input[type="text"],
.intl-tel-input input[type="tel"] {
  position: relative;
  z-index: 0;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding-right: 36px;
  margin-right: 0;
}

.intl-tel-input .flag-container {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  padding: 1px;
}

.intl-tel-input .selected-flag {
  z-index: 1;
  position: relative;
  width: 36px;
  height: 100%;
  padding: 0 0 0 8px;
}

.intl-tel-input .selected-flag .iti-flag {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
}

.intl-tel-input .selected-flag .iti-arrow {
  position: absolute;
  top: 50%;
  margin-top: -2px;
  right: 6px;
  width: 0;
  height: 0;
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
  border-top: 4px solid #555;
}

.intl-tel-input .selected-flag .iti-arrow.up {
  border-top: none;
  border-bottom: 4px solid #555;
}

.intl-tel-input .country-list {
  position: absolute;
  z-index: 2;
  list-style: none;
  text-align: left;
  padding: 0;
  margin: 0 0 0 -1px;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
  background-color: white;
  border: 1px solid #ccc;
  white-space: nowrap;
  max-height: 200px;
  overflow-y: scroll;
}

.intl-tel-input .country-list.dropup {
  bottom: 100%;
  margin-bottom: -1px;
}

.intl-tel-input .country-list .flag-box {
  display: inline-block;
  width: 20px;
}

@media (max-width: 500px) {
  .intl-tel-input .country-list {
    white-space: normal;
  }
}

.intl-tel-input .country-list .divider {
  padding-bottom: 5px;
  margin-bottom: 5px;
  border-bottom: 1px solid #ccc;
}

.intl-tel-input .country-list .country {
  padding: 5px 10px;
}

.intl-tel-input .country-list .country .dial-code {
  color: #999;
}

.intl-tel-input .country-list .country.highlight {
  background-color: rgba(0, 0, 0, 0.05);
}

.intl-tel-input .country-list .flag-box,
.intl-tel-input .country-list .country-name,
.intl-tel-input .country-list .dial-code {
  vertical-align: middle;
}

.intl-tel-input .country-list .flag-box,
.intl-tel-input .country-list .country-name {
  margin-right: 6px;
}

.intl-tel-input.allow-dropdown input,
.intl-tel-input.allow-dropdown input[type="text"],
.intl-tel-input.allow-dropdown input[type="tel"],
.intl-tel-input.separate-dial-code input,
.intl-tel-input.separate-dial-code input[type="text"],
.intl-tel-input.separate-dial-code input[type="tel"] {
  padding-right: 6px;
  padding-left: 52px;
  margin-left: 0;
}

.intl-tel-input.allow-dropdown .flag-container,
.intl-tel-input.separate-dial-code .flag-container {
  right: auto;
  left: 0;
}

.intl-tel-input.allow-dropdown .selected-flag,
.intl-tel-input.separate-dial-code .selected-flag {
  width: 46px;
}

.intl-tel-input.allow-dropdown .flag-container:hover {
  cursor: pointer;
}

.intl-tel-input.allow-dropdown .flag-container:hover .selected-flag {
  background-color: rgba(0, 0, 0, 0.05);
}

.intl-tel-input.allow-dropdown input[disabled]+.flag-container:hover,
.intl-tel-input.allow-dropdown input[readonly]+.flag-container:hover {
  cursor: default;
}

.intl-tel-input.allow-dropdown input[disabled]+.flag-container:hover .selected-flag,
.intl-tel-input.allow-dropdown input[readonly]+.flag-container:hover .selected-flag {
  background-color: transparent;
}

.intl-tel-input.separate-dial-code .selected-flag {
  background-color: rgba(0, 0, 0, 0.05);
  display: table;
}

.intl-tel-input.separate-dial-code .selected-dial-code {
  display: table-cell;
  vertical-align: middle;
  padding-left: 28px;
}

.intl-tel-input.separate-dial-code.iti-sdc-2 input,
.intl-tel-input.separate-dial-code.iti-sdc-2 input[type="text"],
.intl-tel-input.separate-dial-code.iti-sdc-2 input[type="tel"] {
  padding-left: 66px;
}

.intl-tel-input.separate-dial-code.iti-sdc-2 .selected-flag {
  width: 60px;
}

.intl-tel-input.separate-dial-code.allow-dropdown.iti-sdc-2 input,
.intl-tel-input.separate-dial-code.allow-dropdown.iti-sdc-2 input[type="text"],
.intl-tel-input.separate-dial-code.allow-dropdown.iti-sdc-2 input[type="tel"] {
  padding-left: 76px;
}

.intl-tel-input.separate-dial-code.allow-dropdown.iti-sdc-2 .selected-flag {
  width: 70px;
}

.intl-tel-input.separate-dial-code.iti-sdc-3 input,
.intl-tel-input.separate-dial-code.iti-sdc-3 input[type="text"],
.intl-tel-input.separate-dial-code.iti-sdc-3 input[type="tel"] {
  padding-left: 74px;
}

.intl-tel-input.separate-dial-code.iti-sdc-3 .selected-flag {
  width: 68px;
}

.intl-tel-input.separate-dial-code.allow-dropdown.iti-sdc-3 input,
.intl-tel-input.separate-dial-code.allow-dropdown.iti-sdc-3 input[type="text"],
.intl-tel-input.separate-dial-code.allow-dropdown.iti-sdc-3 input[type="tel"] {
  padding-left: 84px;
}

.intl-tel-input.separate-dial-code.allow-dropdown.iti-sdc-3 .selected-flag {
  width: 78px;
}

.intl-tel-input.separate-dial-code.iti-sdc-4 input,
.intl-tel-input.separate-dial-code.iti-sdc-4 input[type="text"],
.intl-tel-input.separate-dial-code.iti-sdc-4 input[type="tel"] {
  padding-left: 82px;
}

.intl-tel-input.separate-dial-code.iti-sdc-4 .selected-flag {
  width: 76px;
}

.intl-tel-input.separate-dial-code.allow-dropdown.iti-sdc-4 input,
.intl-tel-input.separate-dial-code.allow-dropdown.iti-sdc-4 input[type="text"],
.intl-tel-input.separate-dial-code.allow-dropdown.iti-sdc-4 input[type="tel"] {
  padding-left: 92px;
}

.intl-tel-input.separate-dial-code.allow-dropdown.iti-sdc-4 .selected-flag {
  width: 86px;
}

.intl-tel-input.separate-dial-code.iti-sdc-5 input,
.intl-tel-input.separate-dial-code.iti-sdc-5 input[type="text"],
.intl-tel-input.separate-dial-code.iti-sdc-5 input[type="tel"] {
  padding-left: 90px;
}

.intl-tel-input.separate-dial-code.iti-sdc-5 .selected-flag {
  width: 84px;
}

.intl-tel-input.separate-dial-code.allow-dropdown.iti-sdc-5 input,
.intl-tel-input.separate-dial-code.allow-dropdown.iti-sdc-5 input[type="text"],
.intl-tel-input.separate-dial-code.allow-dropdown.iti-sdc-5 input[type="tel"] {
  padding-left: 100px;
}

.intl-tel-input.separate-dial-code.allow-dropdown.iti-sdc-5 .selected-flag {
  width: 94px;
}

.intl-tel-input.iti-container {
  position: absolute;
  top: -1000px;
  left: -1000px;
  z-index: 1060;
  padding: 1px;
}

.intl-tel-input.iti-container:hover {
  cursor: pointer;
}

.iti-mobile .intl-tel-input.iti-container {
  top: 30px;
  bottom: 30px;
  left: 30px;
  right: 30px;
  position: fixed;
}

.iti-mobile .intl-tel-input .country-list {
  max-height: 100%;
  width: 100%;
}

.iti-mobile .intl-tel-input .country-list .country {
  padding: 10px 10px;
  line-height: 1.5em;
}

.iti-flag {
  width: 20px;
}

.iti-flag.be {
  width: 18px;
}

.iti-flag.ch {
  width: 15px;
}

.iti-flag.mc {
  width: 19px;
}

.iti-flag.ne {
  width: 18px;
}

.iti-flag.np {
  width: 13px;
}

.iti-flag.va {
  width: 15px;
}

@media only screen and (-webkit-min-device-pixel-ratio: 2),
only screen and (min--moz-device-pixel-ratio: 2),
only screen and (-o-min-device-pixel-ratio: 2 / 1),
only screen and (min-device-pixel-ratio: 2),
only screen and (min-resolution: 192dpi),
only screen and (min-resolution: 2dppx) {
  .iti-flag {
    background-size: 5630px 15px;
  }
}

.iti-flag.ac {
  height: 10px;
  background-position: 0px 0px;
}

.iti-flag.ad {
  height: 14px;
  background-position: -22px 0px;
}

.iti-flag.ae {
  height: 10px;
  background-position: -44px 0px;
}

.iti-flag.af {
  height: 14px;
  background-position: -66px 0px;
}

.iti-flag.ag {
  height: 14px;
  background-position: -88px 0px;
}

.iti-flag.ai {
  height: 10px;
  background-position: -110px 0px;
}

.iti-flag.al {
  height: 15px;
  background-position: -132px 0px;
}

.iti-flag.am {
  height: 10px;
  background-position: -154px 0px;
}

.iti-flag.ao {
  height: 14px;
  background-position: -176px 0px;
}

.iti-flag.aq {
  height: 14px;
  background-position: -198px 0px;
}

.iti-flag.ar {
  height: 13px;
  background-position: -220px 0px;
}

.iti-flag.as {
  height: 10px;
  background-position: -242px 0px;
}

.iti-flag.at {
  height: 14px;
  background-position: -264px 0px;
}

.iti-flag.au {
  height: 10px;
  background-position: -286px 0px;
}

.iti-flag.aw {
  height: 14px;
  background-position: -308px 0px;
}

.iti-flag.ax {
  height: 13px;
  background-position: -330px 0px;
}

.iti-flag.az {
  height: 10px;
  background-position: -352px 0px;
}

.iti-flag.ba {
  height: 10px;
  background-position: -374px 0px;
}

.iti-flag.bb {
  height: 14px;
  background-position: -396px 0px;
}

.iti-flag.bd {
  height: 12px;
  background-position: -418px 0px;
}

.iti-flag.be {
  height: 15px;
  background-position: -440px 0px;
}

.iti-flag.bf {
  height: 14px;
  background-position: -460px 0px;
}

.iti-flag.bg {
  height: 12px;
  background-position: -482px 0px;
}

.iti-flag.bh {
  height: 12px;
  background-position: -504px 0px;
}

.iti-flag.bi {
  height: 12px;
  background-position: -526px 0px;
}

.iti-flag.bj {
  height: 14px;
  background-position: -548px 0px;
}

.iti-flag.bl {
  height: 14px;
  background-position: -570px 0px;
}

.iti-flag.bm {
  height: 10px;
  background-position: -592px 0px;
}

.iti-flag.bn {
  height: 10px;
  background-position: -614px 0px;
}

.iti-flag.bo {
  height: 14px;
  background-position: -636px 0px;
}

.iti-flag.bq {
  height: 14px;
  background-position: -658px 0px;
}

.iti-flag.br {
  height: 14px;
  background-position: -680px 0px;
}

.iti-flag.bs {
  height: 10px;
  background-position: -702px 0px;
}

.iti-flag.bt {
  height: 14px;
  background-position: -724px 0px;
}

.iti-flag.bv {
  height: 15px;
  background-position: -746px 0px;
}

.iti-flag.bw {
  height: 14px;
  background-position: -768px 0px;
}

.iti-flag.by {
  height: 10px;
  background-position: -790px 0px;
}

.iti-flag.bz {
  height: 14px;
  background-position: -812px 0px;
}

.iti-flag.ca {
  height: 10px;
  background-position: -834px 0px;
}

.iti-flag.cc {
  height: 10px;
  background-position: -856px 0px;
}

.iti-flag.cd {
  height: 15px;
  background-position: -878px 0px;
}

.iti-flag.cf {
  height: 14px;
  background-position: -900px 0px;
}

.iti-flag.cg {
  height: 14px;
  background-position: -922px 0px;
}

.iti-flag.ch {
  height: 15px;
  background-position: -944px 0px;
}

.iti-flag.ci {
  height: 14px;
  background-position: -961px 0px;
}

.iti-flag.ck {
  height: 10px;
  background-position: -983px 0px;
}

.iti-flag.cl {
  height: 14px;
  background-position: -1005px 0px;
}

.iti-flag.cm {
  height: 14px;
  background-position: -1027px 0px;
}

.iti-flag.cn {
  height: 14px;
  background-position: -1049px 0px;
}

.iti-flag.co {
  height: 14px;
  background-position: -1071px 0px;
}

.iti-flag.cp {
  height: 14px;
  background-position: -1093px 0px;
}

.iti-flag.cr {
  height: 12px;
  background-position: -1115px 0px;
}

.iti-flag.cu {
  height: 10px;
  background-position: -1137px 0px;
}

.iti-flag.cv {
  height: 12px;
  background-position: -1159px 0px;
}

.iti-flag.cw {
  height: 14px;
  background-position: -1181px 0px;
}

.iti-flag.cx {
  height: 10px;
  background-position: -1203px 0px;
}

.iti-flag.cy {
  height: 13px;
  background-position: -1225px 0px;
}

.iti-flag.cz {
  height: 14px;
  background-position: -1247px 0px;
}

.iti-flag.de {
  height: 12px;
  background-position: -1269px 0px;
}

.iti-flag.dg {
  height: 10px;
  background-position: -1291px 0px;
}

.iti-flag.dj {
  height: 14px;
  background-position: -1313px 0px;
}

.iti-flag.dk {
  height: 15px;
  background-position: -1335px 0px;
}

.iti-flag.dm {
  height: 10px;
  background-position: -1357px 0px;
}

.iti-flag.do {
  height: 13px;
  background-position: -1379px 0px;
}

.iti-flag.dz {
  height: 14px;
  background-position: -1401px 0px;
}

.iti-flag.ea {
  height: 14px;
  background-position: -1423px 0px;
}

.iti-flag.ec {
  height: 14px;
  background-position: -1445px 0px;
}

.iti-flag.ee {
  height: 13px;
  background-position: -1467px 0px;
}

.iti-flag.eg {
  height: 14px;
  background-position: -1489px 0px;
}

.iti-flag.eh {
  height: 10px;
  background-position: -1511px 0px;
}

.iti-flag.er {
  height: 10px;
  background-position: -1533px 0px;
}

.iti-flag.es {
  height: 14px;
  background-position: -1555px 0px;
}

.iti-flag.et {
  height: 10px;
  background-position: -1577px 0px;
}

.iti-flag.eu {
  height: 14px;
  background-position: -1599px 0px;
}

.iti-flag.fi {
  height: 12px;
  background-position: -1621px 0px;
}

.iti-flag.fj {
  height: 10px;
  background-position: -1643px 0px;
}

.iti-flag.fk {
  height: 10px;
  background-position: -1665px 0px;
}

.iti-flag.fm {
  height: 11px;
  background-position: -1687px 0px;
}

.iti-flag.fo {
  height: 15px;
  background-position: -1709px 0px;
}

.iti-flag.fr {
  height: 14px;
  background-position: -1731px 0px;
}

.iti-flag.ga {
  height: 15px;
  background-position: -1753px 0px;
}

.iti-flag.gb {
  height: 10px;
  background-position: -1775px 0px;
}

.iti-flag.gd {
  height: 12px;
  background-position: -1797px 0px;
}

.iti-flag.ge {
  height: 14px;
  background-position: -1819px 0px;
}

.iti-flag.gf {
  height: 14px;
  background-position: -1841px 0px;
}

.iti-flag.gg {
  height: 14px;
  background-position: -1863px 0px;
}

.iti-flag.gh {
  height: 14px;
  background-position: -1885px 0px;
}

.iti-flag.gi {
  height: 10px;
  background-position: -1907px 0px;
}

.iti-flag.gl {
  height: 14px;
  background-position: -1929px 0px;
}

.iti-flag.gm {
  height: 14px;
  background-position: -1951px 0px;
}

.iti-flag.gn {
  height: 14px;
  background-position: -1973px 0px;
}

.iti-flag.gp {
  height: 14px;
  background-position: -1995px 0px;
}

.iti-flag.gq {
  height: 14px;
  background-position: -2017px 0px;
}

.iti-flag.gr {
  height: 14px;
  background-position: -2039px 0px;
}

.iti-flag.gs {
  height: 10px;
  background-position: -2061px 0px;
}

.iti-flag.gt {
  height: 13px;
  background-position: -2083px 0px;
}

.iti-flag.gu {
  height: 11px;
  background-position: -2105px 0px;
}

.iti-flag.gw {
  height: 10px;
  background-position: -2127px 0px;
}

.iti-flag.gy {
  height: 12px;
  background-position: -2149px 0px;
}

.iti-flag.hk {
  height: 14px;
  background-position: -2171px 0px;
}

.iti-flag.hm {
  height: 10px;
  background-position: -2193px 0px;
}

.iti-flag.hn {
  height: 10px;
  background-position: -2215px 0px;
}

.iti-flag.hr {
  height: 10px;
  background-position: -2237px 0px;
}

.iti-flag.ht {
  height: 12px;
  background-position: -2259px 0px;
}

.iti-flag.hu {
  height: 10px;
  background-position: -2281px 0px;
}

.iti-flag.ic {
  height: 14px;
  background-position: -2303px 0px;
}

.iti-flag.id {
  height: 14px;
  background-position: -2325px 0px;
}

.iti-flag.ie {
  height: 10px;
  background-position: -2347px 0px;
}

.iti-flag.il {
  height: 15px;
  background-position: -2369px 0px;
}

.iti-flag.im {
  height: 10px;
  background-position: -2391px 0px;
}

.iti-flag.in {
  height: 14px;
  background-position: -2413px 0px;
}

.iti-flag.io {
  height: 10px;
  background-position: -2435px 0px;
}

.iti-flag.iq {
  height: 14px;
  background-position: -2457px 0px;
}

.iti-flag.ir {
  height: 12px;
  background-position: -2479px 0px;
}

.iti-flag.is {
  height: 15px;
  background-position: -2501px 0px;
}

.iti-flag.it {
  height: 14px;
  background-position: -2523px 0px;
}

.iti-flag.je {
  height: 12px;
  background-position: -2545px 0px;
}

.iti-flag.jm {
  height: 10px;
  background-position: -2567px 0px;
}

.iti-flag.jo {
  height: 10px;
  background-position: -2589px 0px;
}

.iti-flag.jp {
  height: 14px;
  background-position: -2611px 0px;
}

.iti-flag.ke {
  height: 14px;
  background-position: -2633px 0px;
}

.iti-flag.kg {
  height: 12px;
  background-position: -2655px 0px;
}

.iti-flag.kh {
  height: 13px;
  background-position: -2677px 0px;
}

.iti-flag.ki {
  height: 10px;
  background-position: -2699px 0px;
}

.iti-flag.km {
  height: 12px;
  background-position: -2721px 0px;
}

.iti-flag.kn {
  height: 14px;
  background-position: -2743px 0px;
}

.iti-flag.kp {
  height: 10px;
  background-position: -2765px 0px;
}

.iti-flag.kr {
  height: 14px;
  background-position: -2787px 0px;
}

.iti-flag.kw {
  height: 10px;
  background-position: -2809px 0px;
}

.iti-flag.ky {
  height: 10px;
  background-position: -2831px 0px;
}

.iti-flag.kz {
  height: 10px;
  background-position: -2853px 0px;
}

.iti-flag.la {
  height: 14px;
  background-position: -2875px 0px;
}

.iti-flag.lb {
  height: 14px;
  background-position: -2897px 0px;
}

.iti-flag.lc {
  height: 10px;
  background-position: -2919px 0px;
}

.iti-flag.li {
  height: 12px;
  background-position: -2941px 0px;
}

.iti-flag.lk {
  height: 10px;
  background-position: -2963px 0px;
}

.iti-flag.lr {
  height: 11px;
  background-position: -2985px 0px;
}

.iti-flag.ls {
  height: 14px;
  background-position: -3007px 0px;
}

.iti-flag.lt {
  height: 12px;
  background-position: -3029px 0px;
}

.iti-flag.lu {
  height: 12px;
  background-position: -3051px 0px;
}

.iti-flag.lv {
  height: 10px;
  background-position: -3073px 0px;
}

.iti-flag.ly {
  height: 10px;
  background-position: -3095px 0px;
}

.iti-flag.ma {
  height: 14px;
  background-position: -3117px 0px;
}

.iti-flag.mc {
  height: 15px;
  background-position: -3139px 0px;
}

.iti-flag.md {
  height: 10px;
  background-position: -3160px 0px;
}

.iti-flag.me {
  height: 10px;
  background-position: -3182px 0px;
}

.iti-flag.mf {
  height: 14px;
  background-position: -3204px 0px;
}

.iti-flag.mg {
  height: 14px;
  background-position: -3226px 0px;
}

.iti-flag.mh {
  height: 11px;
  background-position: -3248px 0px;
}

.iti-flag.mk {
  height: 10px;
  background-position: -3270px 0px;
}

.iti-flag.ml {
  height: 14px;
  background-position: -3292px 0px;
}

.iti-flag.mm {
  height: 14px;
  background-position: -3314px 0px;
}

.iti-flag.mn {
  height: 10px;
  background-position: -3336px 0px;
}

.iti-flag.mo {
  height: 14px;
  background-position: -3358px 0px;
}

.iti-flag.mp {
  height: 10px;
  background-position: -3380px 0px;
}

.iti-flag.mq {
  height: 14px;
  background-position: -3402px 0px;
}

.iti-flag.mr {
  height: 14px;
  background-position: -3424px 0px;
}

.iti-flag.ms {
  height: 10px;
  background-position: -3446px 0px;
}

.iti-flag.mt {
  height: 14px;
  background-position: -3468px 0px;
}

.iti-flag.mu {
  height: 14px;
  background-position: -3490px 0px;
}

.iti-flag.mv {
  height: 14px;
  background-position: -3512px 0px;
}

.iti-flag.mw {
  height: 14px;
  background-position: -3534px 0px;
}

.iti-flag.mx {
  height: 12px;
  background-position: -3556px 0px;
}

.iti-flag.my {
  height: 10px;
  background-position: -3578px 0px;
}

.iti-flag.mz {
  height: 14px;
  background-position: -3600px 0px;
}

.iti-flag.na {
  height: 14px;
  background-position: -3622px 0px;
}

.iti-flag.nc {
  height: 10px;
  background-position: -3644px 0px;
}

.iti-flag.ne {
  height: 15px;
  background-position: -3666px 0px;
}

.iti-flag.nf {
  height: 10px;
  background-position: -3686px 0px;
}

.iti-flag.ng {
  height: 10px;
  background-position: -3708px 0px;
}

.iti-flag.ni {
  height: 12px;
  background-position: -3730px 0px;
}

.iti-flag.nl {
  height: 14px;
  background-position: -3752px 0px;
}

.iti-flag.no {
  height: 15px;
  background-position: -3774px 0px;
}

.iti-flag.np {
  height: 15px;
  background-position: -3796px 0px;
}

.iti-flag.nr {
  height: 10px;
  background-position: -3811px 0px;
}

.iti-flag.nu {
  height: 10px;
  background-position: -3833px 0px;
}

.iti-flag.nz {
  height: 10px;
  background-position: -3855px 0px;
}

.iti-flag.om {
  height: 10px;
  background-position: -3877px 0px;
}

.iti-flag.pa {
  height: 14px;
  background-position: -3899px 0px;
}

.iti-flag.pe {
  height: 14px;
  background-position: -3921px 0px;
}

.iti-flag.pf {
  height: 14px;
  background-position: -3943px 0px;
}

.iti-flag.pg {
  height: 15px;
  background-position: -3965px 0px;
}

.iti-flag.ph {
  height: 10px;
  background-position: -3987px 0px;
}

.iti-flag.pk {
  height: 14px;
  background-position: -4009px 0px;
}

.iti-flag.pl {
  height: 13px;
  background-position: -4031px 0px;
}

.iti-flag.pm {
  height: 14px;
  background-position: -4053px 0px;
}

.iti-flag.pn {
  height: 10px;
  background-position: -4075px 0px;
}

.iti-flag.pr {
  height: 14px;
  background-position: -4097px 0px;
}

.iti-flag.ps {
  height: 10px;
  background-position: -4119px 0px;
}

.iti-flag.pt {
  height: 14px;
  background-position: -4141px 0px;
}

.iti-flag.pw {
  height: 13px;
  background-position: -4163px 0px;
}

.iti-flag.py {
  height: 11px;
  background-position: -4185px 0px;
}

.iti-flag.qa {
  height: 8px;
  background-position: -4207px 0px;
}

.iti-flag.re {
  height: 14px;
  background-position: -4229px 0px;
}

.iti-flag.ro {
  height: 14px;
  background-position: -4251px 0px;
}

.iti-flag.rs {
  height: 14px;
  background-position: -4273px 0px;
}

.iti-flag.ru {
  height: 14px;
  background-position: -4295px 0px;
}

.iti-flag.rw {
  height: 14px;
  background-position: -4317px 0px;
}

.iti-flag.sa {
  height: 14px;
  background-position: -4339px 0px;
}

.iti-flag.sb {
  height: 10px;
  background-position: -4361px 0px;
}

.iti-flag.sc {
  height: 10px;
  background-position: -4383px 0px;
}

.iti-flag.sd {
  height: 10px;
  background-position: -4405px 0px;
}

.iti-flag.se {
  height: 13px;
  background-position: -4427px 0px;
}

.iti-flag.sg {
  height: 14px;
  background-position: -4449px 0px;
}

.iti-flag.sh {
  height: 10px;
  background-position: -4471px 0px;
}

.iti-flag.si {
  height: 10px;
  background-position: -4493px 0px;
}

.iti-flag.sj {
  height: 15px;
  background-position: -4515px 0px;
}

.iti-flag.sk {
  height: 14px;
  background-position: -4537px 0px;
}

.iti-flag.sl {
  height: 14px;
  background-position: -4559px 0px;
}

.iti-flag.sm {
  height: 15px;
  background-position: -4581px 0px;
}

.iti-flag.sn {
  height: 14px;
  background-position: -4603px 0px;
}

.iti-flag.so {
  height: 14px;
  background-position: -4625px 0px;
}

.iti-flag.sr {
  height: 14px;
  background-position: -4647px 0px;
}

.iti-flag.ss {
  height: 10px;
  background-position: -4669px 0px;
}

.iti-flag.st {
  height: 10px;
  background-position: -4691px 0px;
}

.iti-flag.sv {
  height: 12px;
  background-position: -4713px 0px;
}

.iti-flag.sx {
  height: 14px;
  background-position: -4735px 0px;
}

.iti-flag.sy {
  height: 14px;
  background-position: -4757px 0px;
}

.iti-flag.sz {
  height: 14px;
  background-position: -4779px 0px;
}

.iti-flag.ta {
  height: 10px;
  background-position: -4801px 0px;
}

.iti-flag.tc {
  height: 10px;
  background-position: -4823px 0px;
}

.iti-flag.td {
  height: 14px;
  background-position: -4845px 0px;
}

.iti-flag.tf {
  height: 14px;
  background-position: -4867px 0px;
}

.iti-flag.tg {
  height: 13px;
  background-position: -4889px 0px;
}

.iti-flag.th {
  height: 14px;
  background-position: -4911px 0px;
}

.iti-flag.tj {
  height: 10px;
  background-position: -4933px 0px;
}

.iti-flag.tk {
  height: 10px;
  background-position: -4955px 0px;
}

.iti-flag.tl {
  height: 10px;
  background-position: -4977px 0px;
}

.iti-flag.tm {
  height: 14px;
  background-position: -4999px 0px;
}

.iti-flag.tn {
  height: 14px;
  background-position: -5021px 0px;
}

.iti-flag.to {
  height: 10px;
  background-position: -5043px 0px;
}

.iti-flag.tr {
  height: 14px;
  background-position: -5065px 0px;
}

.iti-flag.tt {
  height: 12px;
  background-position: -5087px 0px;
}

.iti-flag.tv {
  height: 10px;
  background-position: -5109px 0px;
}

.iti-flag.tw {
  height: 14px;
  background-position: -5131px 0px;
}

.iti-flag.tz {
  height: 14px;
  background-position: -5153px 0px;
}

.iti-flag.ua {
  height: 14px;
  background-position: -5175px 0px;
}

.iti-flag.ug {
  height: 14px;
  background-position: -5197px 0px;
}

.iti-flag.um {
  height: 11px;
  background-position: -5219px 0px;
}

.iti-flag.us {
  height: 11px;
  background-position: -5241px 0px;
}

.iti-flag.uy {
  height: 14px;
  background-position: -5263px 0px;
}

.iti-flag.uz {
  height: 10px;
  background-position: -5285px 0px;
}

.iti-flag.va {
  height: 15px;
  background-position: -5307px 0px;
}

.iti-flag.vc {
  height: 14px;
  background-position: -5324px 0px;
}

.iti-flag.ve {
  height: 14px;
  background-position: -5346px 0px;
}

.iti-flag.vg {
  height: 10px;
  background-position: -5368px 0px;
}

.iti-flag.vi {
  height: 14px;
  background-position: -5390px 0px;
}

.iti-flag.vn {
  height: 14px;
  background-position: -5412px 0px;
}

.iti-flag.vu {
  height: 12px;
  background-position: -5434px 0px;
}

.iti-flag.wf {
  height: 14px;
  background-position: -5456px 0px;
}

.iti-flag.ws {
  height: 10px;
  background-position: -5478px 0px;
}

.iti-flag.xk {
  height: 15px;
  background-position: -5500px 0px;
}

.iti-flag.ye {
  height: 14px;
  background-position: -5522px 0px;
}

.iti-flag.yt {
  height: 14px;
  background-position: -5544px 0px;
}

.iti-flag.za {
  height: 14px;
  background-position: -5566px 0px;
}

.iti-flag.zm {
  height: 14px;
  background-position: -5588px 0px;
}

.iti-flag.zw {
  height: 10px;
  background-position: -5610px 0px;
}

.iti-flag {
  width: 20px;
  height: 15px;
  box-shadow: 0px 0px 1px 0px #888;
  background-image: url("../img/flags.png");
  background-repeat: no-repeat;
  background-color: #dbdbdb;
  background-position: 20px 0;
}

@media only screen and (-webkit-min-device-pixel-ratio: 2),
only screen and (min--moz-device-pixel-ratio: 2),
only screen and (-o-min-device-pixel-ratio: 2 / 1),
only screen and (min-device-pixel-ratio: 2),
only screen and (min-resolution: 192dpi),
only screen and (min-resolution: 2dppx) {
  .iti-flag {
    background-image: url("../img/flags@2x.png");
  }
}

.iti-flag.np {
  background-color: transparent;
}